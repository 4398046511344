import { useState, useEffect, useRef } from "react";

const CustomSelect = ({
  options,
  preSelectedItems,
  onSelectionChange,
  placeholder,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleSelect = (item) => {
    setSelectedItem(item);
    onSelectionChange(item.value); // Notify parent component about the new selection
    setIsDropdownOpen(false); // Close dropdown after selection
  };

  const handleDelete = () => {
    setSelectedItem(null);
    onSelectionChange(null); // Notify parent component about the deselection
  };

  const filteredOptions = options.filter(
    (option) =>
      option.name &&
      option.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Match preSelectedItems with options
  useEffect(() => {
    if (preSelectedItems !== null && options.length > 0) {
      // Convert preSelectedItems to a number (if necessary) and find the matching option
      const matchingOption = options.find(
        (option) => String(option.value) === String(preSelectedItems)
      );

      if (matchingOption) {
        setSelectedItem(matchingOption); // Set the whole option object
      } else {
        setSelectedItem(null); // Reset if no match is found
      }
    } else {
      setSelectedItem(null);
    }
  }, [preSelectedItems, options]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="single-select" ref={dropdownRef}>
      <div className="input-single" onClick={toggleDropdown}>
        {selectedItem ? (
          <div className="selected-item-single">
            <div>{selectedItem.name}</div>{" "}
            {/* Display name instead of whole object */}
            <button onClick={handleDelete}>x</button>
          </div>
        ) : (
          <span className="placeholder">{placeholder}</span>
        )}
      </div>

      {isDropdownOpen && (
        <div className="dropdown-single">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input-single"
          />
          <div className="dropdown-list-single">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <div
                  key={option.value}
                  className={`dropdown-item ${
                    selectedItem && selectedItem.name === option.name
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handleSelect(option)}
                >
                  {option.name}
                </div>
              ))
            ) : (
              <div className="no-results">No results found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
