import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios, { formToJSON } from 'axios';
import $ from 'jquery';
import '../css/Login.css';
import '../css/Materialize.css';
import '../css/Style.css';
import '../css/new.css';
import reloadToken from '../helper/reloadtoken';
import helperFilter from '../helper/helperfilter';
import HeaderMenu from '../template/HeaderMenu';
import { apiConfig } from '../helper/apiConfig';
import { hide, bikinInfoSum, sumDataKerja, getFrozen, createRatioZoom, getRatioZoom, saveheighrow, getheighrow } from '../helper/hide';
import { ToastContainer, toast } from 'react-toastify';
import { dataconfig, showtoolbarConfig, ct } from '../helper/luckysheetHelper';
import 'react-toastify/dist/ReactToastify.css';
import Modal from './modaldetail';
import Modalpreview from './modalpreview';

const tipedata = "FCLADM";
const host = process.env.REACT_APP_API_URL_ONLINE;
const hostdrop = process.env.REACT_APP_API_HOST_DROPDOWN;
const hostfcl = process.env.REACT_APP_API_HOST_FCLADM;
const hostdatcont = process.env.REACT_APP_API_HOST_DATA_CONT;
const hostcc = process.env.REACT_APP_API_HOST_COLOR_COMMENT;

const ADMIN = "admin"
const POL = "pol"
const CNEE = "cnee"
const SALES = "sales"
const KONSEPTOR = "koseptor"
const TIPE_TRANS = "tipe_trans"
const MARKING = "marking"
const PORT = "port"
const STATUS_BL = "status_bl"
const TUJUAN_BONGKAR = "tujuan_bongkar"
const GROUPS = "group_bongkar"
const UK = "uk"
const PPN_BR = "ppn_br"
const COMMODITY = "commodity"

const SENDBONGKARAN = "send_bongkaran"

const notify_cant_update_br_cont = (message, time = 1200) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: time,
  });
};

const notify_update_br_cont = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 800,
  });
};

const FclAdm = () => {
  let cellDataJsonA = [];
  let datahasil = [];
  let totaldata = 0;
  var jmlReq = 0
  let savedColumnWidths =1
  var config
  const luckysheet = window.luckysheet;
  const [token, setToken] = useState(null);
  const [prosesdata, setprosesdata] = useState(true)
  const [defaultkolom, saveDefaultKolom] = useState()

  const [inputText, setInputText] = useState('');
  const [formattedData, setFormattedData] = useState([]);
  const debounceTimer = useRef(null);

  var b = 0;
  const pagecolumn = "FCLCOLUMNADM"
  const pagerow = "FCLROWADM"
  var columnWidths = [];
  var kiri, atas, tinggi;
  var options = null;
  var type_row = "";
  var type_jml = 0;
  let valueppn = "YA,TIDAK";
  let valueukuran = "20,40,45";
  let valuestatusbl = "TELEX,DRAFT,ORI,HBL";
  // let valuetujuanbongkar = "JKT-Jakarta,SMG-Semarang,MDN-Medan,SBY-Surabaya,BDG-Bandung,JKT,MKSR,MDN,PLG,SBY";
  // let valuegroupbongkar = "MB 5,MB 8";
  var end_column_freze = 1;
  var size_scroll_left = 0;
  var defaultRowHeight = 30;
  var defaultratio = 1;

  const [modalVisible, setModalVisible] = useState(false);
  const [datamodal, setDataModal] = useState(false);
  const [modalVisible_V, setModalVisible_V] = useState(false);
  const [datamodal_V, setDataModal_V] = useState(false);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const toggleModal_V = () => {
    setModalVisible_V(!modalVisible_V);
  };

  function callFunctionlocalstorage() {
    const ratiozoom = getRatioZoom();
    if (ratiozoom == null || ratiozoom == "" || ratiozoom == undefined) {
      defaultratio = 1;
    } else {
      defaultratio = ratiozoom;
    }

    const h_c = getheighrow();
    if (h_c == null || h_c == "") {
      defaultRowHeight = 30;
    } else {
      defaultRowHeight = h_c;
    }
  }

  const loadToken = async () => {
    await new Promise((resolve) => setTimeout(resolve, 100));
    const newToken = await reloadToken();
    return newToken;
  };

  useEffect(() => {
    savedColumnWidths = JSON.parse(localStorage.getItem(pagecolumn));
    if (savedColumnWidths === null || Object.keys(savedColumnWidths).length === 0) {
      savedColumnWidths = null;
    } else {
      savedColumnWidths = savedColumnWidths;
    }


    callFunctionlocalstorage();
    const fetchData = async () => {
      const datatoken = await loadToken();
      setToken(datatoken);

      new Promise((resolve) => setTimeout(resolve, 100));
      if (token) {
        const config = apiConfig(token);

        const nama_column = await getColumnNames();

        const data_pilihan = ["custom", "kapal", "fe", "gudang", "biaya_lain", "inv_ppn_br", "biaya_trucking"];
        
        const cariindex = data_pilihan.map(columnName => nama_column.indexOf(columnName));
        for (let i = 0; i < cariindex.length; i++) {
          const columnNumber = cariindex[i];
          const columnPosition = getColumnPosition(columnNumber);
          datahasil.push(columnPosition)
        }

        async function cekFile() {
          let cekfile = await cekStatusFile();
          if (cekfile.length !== 0) {
            if (cekfile.count > 0) {
              setTimeout(async function () {
                var dataKirimModal = {
                  id: "1",
                  nama: "test"
                };
                setDataModal_V(dataKirimModal);
                toggleModal_V();
              }, 2000)
            }
          }
        }
        cekFile();
        const intervalId = setInterval(cekFile, 3600000);//1 jam
        
        const [page0Data] = await Promise.all([
          loaddata(0),
        ]);

        const page0 = page0Data || [];

        const merged = [
          ...page0.data,
        ];

        const mergedformula = [
          ...page0.dataformula,
        ];

        console.log("data merged ", merged)
        const mergedDataRO = page0.readonly;//readonly 1 for all 
        const dataformula = await loaddata(0);
        const canformual = page0.dataformula;
        var testdata;
        options = {
          container: "luckysheet",
          title: "DataCont",
          lang: 'en',
          showinfobar: false,
          allowCopy: true,
          allowEdit: true,
          column: 0,
          filter: {
            ref: 'A1:C1',
          },
          forceCalculation: true,
          showtoolbarConfig: [],
          cellRightClickConfig: showtoolbarConfig,
          data: [
            {
              name: "Sheet",
              color: "",
              row: 100,
              index: "0",
              celldata: merged,
              calcChain: mergedformula,
              defaultRowHeight: defaultRowHeight,
              config: {
                columnlen: savedColumnWidths,
                colhidden: {
                  0: false,
                },
                rowlen: {
                  0: 40,
                },
              },
            },
          ],
          enableAddRow: false,
          textWrapMode: true,
          hook: {
            cellRenderAfter: function (cell, position, sheetFile, ctx) {
              if (b < 1) {
                const data = [];
                const endIndex = 150;
                for (let i = 0; i <= endIndex; i++) {
                  data.push(i);
                }
                var columnWidths = luckysheet.getColumnWidth(data);
                const colWidthA = columnWidths[1];
                for (let columnIndex of data) {
                  const width = columnWidths[columnIndex];
                  columnWidths[columnIndex.toString()] = width;
                }
                localStorage.setItem(pagecolumn, JSON.stringify(columnWidths));

                setTimeout(function () {
                  b = 0;
                }, 500);
              }
              b++;
            },
            rangePasteBefore: function (range, data) { },

            afterzoomfiki: function (ration) {
              createRatioZoom(ration);
            },
            cellEditBefore: function (r, c, value, isRefresh) {
              jmlReq = 0;
            },
            cellUpdated: function (r, c, oldValue, newValue, isRefresh) {
              const alldata = luckysheet.getcellvalue(null, 1);
              const filteredData = alldata.filter(cell => cell !== null && cell !== undefined && cell !== "");
              const mValues = filteredData
              const uniqueMValues = Array.from(new Set(filteredData
                .filter((_, index) => index > 0) // Filter hanya indeks > 0
                .map(cell => cell.m)));
              const result = uniqueMValues.map(mValue => ({ no_cont: mValue, id: null }));

              if (newValue && c == 1) {
                const id = luckysheet.getCellValue(r, 0);
                var datax = {};
                var nilai = newValue;
                if (nama_column[c] !== undefined) {
                  if (nilai !== null && nilai !== undefined && nilai !== "") {
                    datax[nama_column[c]] = nilai.m;
                  } else {
                    datax[nama_column[c]] = "";
                  }
                  datax["id"] = id;

                  var dataxArray = [datax];
                  //console.log("dataxArray ", dataxArray);
                  UpdateFromPaste(result,"0");
                } else {
                  notify_cant_update_br_cont("Gagal!. Silahkan Refresh halaman ini.");
                }
              }
            },

            updated: function (operate) {
              //console.log("hasil operate: ",operate);
              let data = luckysheet.getLuckysheetfile();
              if (operate) {
                if (operate.ctrlType == "resizeR") {
                  let dataukuran = operate.curconfig.rowlen;

                  const keys = Object.keys(dataukuran);
                  const lastKey = keys[keys.length - 1];
                  const lastValue = dataukuran[lastKey];
                  saveheighrow(lastValue);
                }
              }
            },

            rangePasteBeforefiki: function (range, data, fki) {
              const isConfirmed = window.confirm('Yakin akan melakukan paste data?');
              if (isConfirmed) {
                setTimeout(() => {
                  let jsonData = fki.luckysheetfile[0].luckysheet_select_save[0];
                  const rows = jsonData.row;
                  const columns = jsonData.column;
                  // extra columnya fk
                  const [startRow, endRow] = rows;
                  const [startCol, endCol] = columns;

                  var dataArray = [];
                  var idCounter = 1;

                  for (let row = startRow; row <= endRow; row++) {
                    let datax = { id: luckysheet.getCellValue(row, 0) }; // Convert to string and remove leading/trailing spaces

                    for (let col = startCol; col <= endCol; col++) {
                      const nilai = luckysheet.getCellValue(row, col);
                      datax[nama_column[col]] = nilai !== null && nilai !== undefined && nilai !== "" ? nilai.toString().trim() : ""; // Convert to string and remove leading/trailing spaces
                    }
                    dataArray.push(datax);
                  }
                  console.log("defaultkolom ", defaultkolom);
                  //setCellValuesWithRowDelay(defaultkolom);

                  jmlReq = 0
                  UpdateFromPaste(dataArray, "0");
                }, 400);
              } else {
                return false;
              }
            },
          },
        }

        options.loading = {
          image: () => {
            return `<svg viewBox="25 25 50 50" class="circular">
					<circle cx="50" cy="50" r="20" fill="none"></circle>
					</svg>`
          },
          imageClass: "loadingAnimation"
        }

        if (totaldata > 0) {
          luckysheet.create(options)
        } else {
          notify_cant_update_br_cont("Error, Tidak dapat tersambung dengan server!", 2500);
        }

        function validasidropdown1(kolom, data) {
          return true;
        }

        function rollbackvalidasidropdown(valueppnArray, data) {
          if (data === null || data === undefined || data.trim() === "") {
            console.log("Data is empty or null.");
            return false;
          }
          if (valueppnArray.includes(data)) {
            return true;
          } else {
            return false;
          }
        }

        function getColumnIndexByTitle(sheetId, title) {
          const sheetData = luckysheet.getSheetData(sheetId);
          if (!sheetData) return -1; // Sheet data not found

          const firstRowData = sheetData[0].data;
          for (let colIndex = 0; colIndex < firstRowData.length; colIndex++) {
            if (firstRowData[colIndex].v === title) {
              return colIndex;
            }
          }
          return -1;
        }

        function isValidFormula(value) {
          const formulaPattern = /^=([A-Za-z]+\d+\+)*[A-Za-z]+\d+$/;
          return formulaPattern.test(value);
        }

        $(document).on('click', '.luckysheet-cols-menuitem', function () {
          helperFilter(11);
        });

        $(document).on('mousedown', '.sp-thumb-el', function () {
          // var warna = $(this).attr('data-color');
          var title = $(this).attr('title');
          var datas = [];
          var jlh = 0;
          var selectedRange = luckysheet.getRange();
          if (!selectedRange) {
            console.error('No cells selected.');
            return;
          }
          var baris_awal = selectedRange[0].row[0];
          var baris_akhir = selectedRange[0].row[1];
          var kolom_awal = selectedRange[0].column[0];
          var kolom_akhir = selectedRange[0].column[1];

          const column = nama_column[kolom_awal];
          for (var row = baris_awal; row <= baris_akhir; row++) {
            for (var col = kolom_awal; col <= kolom_akhir; col++) {
              const id = luckysheet.getCellValue(row, 0);
              var datax = {};
              datax['id'] = id;
              datax['warna'] = title;
              datax['ColumnName'] = column;
              datax['ColumnField'] = "FCL";
              datas[jlh] = datax;
              jlh++;
            }
          }
          var dataxx = {};
          dataxx['data'] = JSON.stringify(datas);

          const config = apiConfig(token);
          axios.post(`${hostcc}ColorBrcontainers`, dataxx, config)
            .then((response) => {
              notify_update_br_cont("Color sukses di tambahkan");
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
            });
        });

        function getColumnPosition(columnNumber) {
          let dividend = columnNumber + 1;
          let columnName = '';

          while (dividend > 0) {
            const modulo = (dividend - 1) % 26;
            columnName = String.fromCharCode(65 + modulo) + columnName;
            dividend = Math.floor((dividend - modulo) / 26);
          }
          return columnName;
        }

        const luckysheetContainer = document.getElementById('luckysheet');
        luckysheetContainer.addEventListener('scroll', () => {
          const scrollLeft = luckysheetContainer.scrollLeft;
          const frozenColumns = document.querySelectorAll('.luckysheet-cell.luckysheet-cell-frozen');
          frozenColumns.forEach((column) => {
            column.style.transform = `translateX(${scrollLeft}px)`;
          });
        });

        function isMobileDevice() {
          const mobileScreenWidth = 768;
          return window.innerWidth < mobileScreenWidth;
        }

        setTimeout(function () {
          var calcChainLength = 0;
          if (totaldata > 0) {
            var total_data = luckysheet.getAllSheets()[0].data.length;
            calcChainLength = total_data + 1;
            luckysheet.setBothFrozen(true, { range: { row_focus: 0, column_focus: end_column_freze } });
            helperFilter(11);
            if (!isMobileDevice()) {
              const ratiozoom = getRatioZoom();
              if (ratiozoom == null || ratiozoom == "" || ratiozoom == undefined) {
                luckysheet.setSheetZoom(1);
              } else {
                luckysheet.setSheetZoom(ratiozoom);
              }
            }
            setleftScrollbarstart();
          }

          function createDataVeri(kolom, value) {
            if (typeof luckysheet !== 'undefined') {
              console.log("total_data " + luckysheet)
              const kolom_ = nama_column.indexOf(kolom);
              if (kolom_ > 0) {
                const columnPosition = getColumnPosition(kolom_);
                const output = columnPosition + "1:" + columnPosition + "" + calcChainLength;

                console.log("output " + output)
                luckysheet.setRangeShow([output]);
                if (value.length > 0) {
                  return luckysheet.setDataVerification(
                    {
                      "type": "dropdown",
                      "type2": null,
                      "value1": value,
                      "value2": "",
                      "checked": false,
                      "remote": false,
                      "prohibitInput": false,
                      "hintShow": false,
                      "hintText": ""
                    }
                  );
                }
              }
            }
          }
        }, 500);

        function filter_data(c, atas, kiri, tinggi) {
          const datakolom = nama_column[c]
          const isValueValid = cekdata(datakolom);

          $("#luckysheet-rich-text-editor").on('keyup', function () {
            if (isValueValid) {
              const searchText = $("#luckysheet-rich-text-editor").text().toUpperCase();
              $("#luckysheet-dataVerification-dropdown-List").hide();
              $(".dropdown-List-item").each(function () {
                const itemText = $(this).text().toUpperCase();
                if (itemText.indexOf(searchText) > -1) {
                  $(this).show();
                } else {
                  $(this).hide();
                }
              });

              // fiki
              const editorHeight = $("#luckysheet-rich-text-editor").outerHeight();
              const topValue = parseFloat($("#luckysheet-cell-selected-focus").css("top"));
              const leftValue = parseFloat($("#luckysheet-input-box").css("left"));

              $("#luckysheet-dataVerification-dropdown-List").css({
                display: 'block',
                // left: leftValue - 44 + 'px',
                // top: topValue + editorHeight + editorHeight - 5 + 'px',
                right: 'auto',
                left: kiri + 'px',
                top: atas + tinggi + 'px',
              });
            } else {
              $("#luckysheet-dataVerification-dropdown-List").css({
                display: 'none',
                // left: leftValue - 44 + 'px',
                // top: topValue + editorHeight + editorHeight - 5 + 'px',
                right: 'auto',
                left: kiri + 'px',
                top: atas + tinggi + 'px',
              });
            }
          });
        }

        const cekdata = (v) => {
          const datacari = [ADMIN, POL, CNEE, SALES, KONSEPTOR, TIPE_TRANS, MARKING, PORT, STATUS_BL, TUJUAN_BONGKAR, GROUPS, UK, PPN_BR];
          return datacari.includes(v);
        };

        return () => clearInterval(intervalId);


      }
    };

    fetchData();
    const handleVisibilityChange = () => {
      if (document.hidden) {
        if (luckysheet) {
          console.log("luckysheet ",luckysheet)
          luckysheet.refresh();
        } else {
          window.location.reload();
        }
      } else {

      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
  }, [prosesdata, token]);

  const convertToAlphabet = (number) => {
    let result = '';
    while (number >= 0) {
      result = String.fromCharCode(65 + (number % 26)) + result;
      number = Math.floor(number / 26) - 1;
    }
    return result;
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  function setleftScrollbar(kiri) {
    const luckysheetContainerX = document.getElementById('luckysheet-scrollbar-x');
    luckysheetContainerX.addEventListener('scroll', () => {
      if (luckysheetContainerX.scrollLeft > 1) {
        document.getElementById("luckysheet-dataVerification-dropdown-btn").style.setProperty('display', 'none', 'important');
        size_scroll_left = luckysheetContainerX.scrollLeft;
        const arrowCanvas = document.getElementById("arrowCanvas-overshow");
        if (arrowCanvas !== null) {
          document.getElementById("luckysheet-postil-overshow").style.display = "none";
        }
      }
    });
    if (kiri) {
      size_scroll_left = 1
      luckysheetContainerX.scrollLeft = size_scroll_left;
    }
    else {
      luckysheetContainerX.scrollLeft = size_scroll_left;
    }
  }

  function setleftScrollbarstart() {
    const luckysheetContainerX = document.getElementById('luckysheet-scrollbar-x');
    luckysheetContainerX.addEventListener('scroll', () => {
      if (luckysheetContainerX.scrollLeft > 1) {
        size_scroll_left = luckysheetContainerX.scrollLeft;
      }
      luckysheetContainerX.scrollLeft = size_scroll_left;
      // console.log("SCROLL AUTO", luckysheetContainerX.scrollLeft);
    });
  }

  const go_to_home = () => {
    window.location.href = "/fcladmin";
  };

  function clearCellOne() {
    const sheetData = luckysheet.getAllSheets()[0].data; // Mengambil data dari sheet pertama
    const rowCount = sheetData.length;
    const columnCount = sheetData[0].length;

    for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
      for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
        luckysheet.setCellValue(rowIndex, columnIndex, null);
      }
    }
  }

  function setCellValuesWithRowDelay(dataArray, index = 0) {
    if (index < dataArray.length) {
      const cellData = dataArray[index];
      luckysheet.setCellValue(cellData.r, cellData.c, cellData.v);
      let delay = 0;
      if (index > 0 && cellData.r !== dataArray[index - 1].r) {
        delay = 100; // 1 second delay
      }
      setTimeout(() => {
        setCellValuesWithRowDelay(dataArray, index + 1);
      }, delay);
    }
  }

  function ClearData() {
    let sheetData = luckysheet.getSheetData();
    for (let row = 0; row < sheetData.length; row++) {
      for (let col = 0; col < sheetData[row].length; col++) {
        luckysheet.setCellValue(row, col, '');
      }
    }
  }

  function isObjectEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  const getColumnNames = async () => {
    try {
      const config = apiConfig(token);
      const response = await axios.post(`${hostfcl}ReactBrcontainerDetailColumn`, null, config);
      return response.data.column;
    } catch (error) {
      console.error('Error fetching column names:', error);
      return [];
    }
  };

  const getCellData = async (data, savedColumnWidths, urutanload) => {
    try {
      const config = apiConfig(token);
      const response = await axios.post(`${hostfcl}ReactBrcontainersDetail`, { data, savedColumnWidths, urutanload }, config);
      return response;
    } catch (error) {
      console.error('Error fetching cell data:', error);
      return [];
    }
  };

  const cekStatusFile = async () => {
    try {
      const config = apiConfig(token);
      const response = await axios.post(`${hostfcl}Cekstatusfile`, null, config);
      return response.data;
    } catch (error) {
      console.error('Error fetching cell data:', error);
      return [];
    }
  };

  const getDropdownMarking = async (data) => {
    try {
      const config = apiConfig(token);
      const response = await axios.get(data, config);
      return response.data;
    } catch (error) {
      console.error('Error fetching column names:', error);
      return [];
    }
  };

  async function loaddata(offset) {
    cellDataJsonA = await getCellData(datahasil, savedColumnWidths, offset);
    if (cellDataJsonA.length === 0) {
      return {
        "data": [{}],
        "dataformula": [{}]
      };
    } else {
      if (isObjectEmpty(cellDataJsonA.data)) {
        return {
          "data": [{}],
          "dataformula": [{}]
        };
      } else {
        totaldata = totaldata + cellDataJsonA.data.totaldata + 1;
        for (let i = 0; i < cellDataJsonA.data.data.length; i++) {
          if (cellDataJsonA.data.data[i].v.ct == "0") {//title
            cellDataJsonA.data.data[i].v.ct = {
              "fa": "@",
              "t": "s",
            };
            cellDataJsonA.data.data[i].v.bl = 1;
            cellDataJsonA.data.data[i].v.ff = 1;
            cellDataJsonA.data.data[i].v.ht = 0;
            cellDataJsonA.data.data[i].v.fs = 9;
            cellDataJsonA.data.data[i].v.vt = 0;
            cellDataJsonA.data.data[i].v.tb = 2;
          } else if (cellDataJsonA.data.data[i].v.ct == "1") {//angka
            cellDataJsonA.data.data[i].v.ct = {
              "fa": "#,##0",
              "t": "n",
            };
            cellDataJsonA.data.data[i].v.bl = 2;
            cellDataJsonA.data.data[i].v.ff = 2;
            cellDataJsonA.data.data[i].v.ht = 2;
            cellDataJsonA.data.data[i].v.fs = 9;
            cellDataJsonA.data.data[i].v.vt = 0;
            cellDataJsonA.data.data[i].v.tb = 2;
          } else if (cellDataJsonA.data.data[i].v.ct == "2") {//date
            cellDataJsonA.data.data[i].v.ct = {
              "fa": "yyyy-MM-dd",
              "t": "d",
            };
            cellDataJsonA.data.data[i].v.bl = 2;
            cellDataJsonA.data.data[i].v.ff = 2;
            cellDataJsonA.data.data[i].v.ht = 0;
            cellDataJsonA.data.data[i].v.fs = 9;
            cellDataJsonA.data.data[i].v.vt = 0;
            cellDataJsonA.data.data[i].v.tb = 2;
          } else if (cellDataJsonA.data.data[i].v.ct == "3") {//link
            cellDataJsonA.data.data[i].v.ct = {
              "fa": "@",
              "t": "l",
            };
            cellDataJsonA.data.data[i].v.bl = 2;
            cellDataJsonA.data.data[i].v.ff = 2;
            cellDataJsonA.data.data[i].v.ht = 0;
            cellDataJsonA.data.data[i].v.fs = 9;
            cellDataJsonA.data.data[i].v.vt = 0;
            cellDataJsonA.data.data[i].v.tb = 2;
          } else if (cellDataJsonA.data.data[i].v.ct == "5") {//link
            cellDataJsonA.data.data[i].v.ct = {
              "fa": "General",
              "t": "g",
            };
            cellDataJsonA.data.data[i].v.bl = 2;
            cellDataJsonA.data.data[i].v.ff = 2;
            cellDataJsonA.data.data[i].v.ht = 0;
            cellDataJsonA.data.data[i].v.fs = 9;
            cellDataJsonA.data.data[i].v.vt = 0;
            cellDataJsonA.data.data[i].v.tb = 2;
          } else {
            cellDataJsonA.data.data[i].v.ct = {//text
              "fa": "@",
              "t": "n",
            };
            cellDataJsonA.data.data[i].v.bl = 2;
            cellDataJsonA.data.data[i].v.ff = 2;
            cellDataJsonA.data.data[i].v.ht = 1;
            cellDataJsonA.data.data[i].v.fs = 9;
            cellDataJsonA.data.data[i].v.vt = 0;
            cellDataJsonA.data.data[i].v.tb = 2;
          }
        }
      }
      const data_all = cellDataJsonA.data;
      saveDefaultKolom(data_all)
      return data_all;
    }
  }

  async function loaddataPaste(cellDataJsonA, type) {
    if (cellDataJsonA.length === 0) {
      return {
        "data": [{}],
        "dataformula": [{}]
      };
    } else {
      if (isObjectEmpty(cellDataJsonA.data)) {
        console.log("test data 1")
        return {
          "data": [{}],
          "dataformula": [{}]
        };
      } else {
        console.log("test data 2")
        totaldata = totaldata + cellDataJsonA.data.totaldata + 1;
        for (let i = 0; i < cellDataJsonA.data.data.length; i++) {
          if (cellDataJsonA.data.data[i].v.ct == "0") {//title
            cellDataJsonA.data.data[i].v.ct = {
              "fa": "@",
              "t": "s",
            };
            cellDataJsonA.data.data[i].v.bl = 1;
            cellDataJsonA.data.data[i].v.ff = 1;
            cellDataJsonA.data.data[i].v.ht = 0;
            cellDataJsonA.data.data[i].v.fs = 9;
            cellDataJsonA.data.data[i].v.vt = 0;
            cellDataJsonA.data.data[i].v.tb = 2;
          } else if (cellDataJsonA.data.data[i].v.ct == "1") {//angka
            cellDataJsonA.data.data[i].v.ct = {
              "fa": "#,##0",
              "t": "n",
            };
            cellDataJsonA.data.data[i].v.bl = 2;
            cellDataJsonA.data.data[i].v.ff = 2;
            cellDataJsonA.data.data[i].v.ht = 2;
            cellDataJsonA.data.data[i].v.fs = 9;
            cellDataJsonA.data.data[i].v.vt = 0;
            cellDataJsonA.data.data[i].v.tb = 2;
          } else if (cellDataJsonA.data.data[i].v.ct == "2") {//date
            cellDataJsonA.data.data[i].v.ct = {
              "fa": "yyyy-MM-dd",
              "t": "d",
            };
            cellDataJsonA.data.data[i].v.bl = 2;
            cellDataJsonA.data.data[i].v.ff = 2;
            cellDataJsonA.data.data[i].v.ht = 0;
            cellDataJsonA.data.data[i].v.fs = 9;
            cellDataJsonA.data.data[i].v.vt = 0;
            cellDataJsonA.data.data[i].v.tb = 2;
          } else if (cellDataJsonA.data.data[i].v.ct == "3") {//link
            cellDataJsonA.data.data[i].v.ct = {
              "fa": "@",
              "t": "l",
            };
            cellDataJsonA.data.data[i].v.bl = 2;
            cellDataJsonA.data.data[i].v.ff = 2;
            cellDataJsonA.data.data[i].v.ht = 0;
            cellDataJsonA.data.data[i].v.fs = 9;
            cellDataJsonA.data.data[i].v.vt = 0;
            cellDataJsonA.data.data[i].v.tb = 2;
          } else if (cellDataJsonA.data.data[i].v.ct == "5") {//link
            cellDataJsonA.data.data[i].v.ct = {
              "fa": "General",
              "t": "g",
            };
            cellDataJsonA.data.data[i].v.bl = 2;
            cellDataJsonA.data.data[i].v.ff = 2;
            cellDataJsonA.data.data[i].v.ht = 0;
            cellDataJsonA.data.data[i].v.fs = 9;
            cellDataJsonA.data.data[i].v.vt = 0;
            cellDataJsonA.data.data[i].v.tb = 2;
          } else {
            cellDataJsonA.data.data[i].v.ct = {//text
              "fa": "@",
              "t": "n",
            };
            cellDataJsonA.data.data[i].v.bl = 2;
            cellDataJsonA.data.data[i].v.ff = 2;
            cellDataJsonA.data.data[i].v.ht = 1;
            cellDataJsonA.data.data[i].v.fs = 9;
            cellDataJsonA.data.data[i].v.vt = 0;
            cellDataJsonA.data.data[i].v.tb = 2;
          }
        }
      }
      const data_all = cellDataJsonA.data;
      const cellDataArray = data_all.data
      if (type=="0"){
        clearCellOne();
      }
      setCellValuesWithRowDelay(cellDataArray);
    }
  }

  async function UpdateFromPaste(datax,type) {
    const datatoken = await loadToken();
    new Promise((resolve) => setTimeout(resolve, 100));
    console.log("Token ",datatoken)
    const config = apiConfig(datatoken);
    if (jmlReq == 0) {
      axios.post(`${hostfcl}ReactUpdateBrcontainerDetailpaste`, datax, config)
        .then(response => {
          if (response.data.status == "success") {
            //notify_update_br_cont("Data Cont Updated")
          }
          loaddataPaste(response, type)
        })
        .catch(error => {
          console.error('Error fetching data:', error.response.data.message);
          notify_cant_update_br_cont(error.response.data.message);
          // luckysheet.setCellValue(r, c, "");
          luckysheet.refresh();
        });
    }
    jmlReq++
  }

  const processText = useCallback((datatext) => {
    const items = datatext.split(/\s+/).filter(item => item);
    const data = items.map(item => ({
      id: null,
      no_cont: item
    }));
    console.log("DATA ",data)
    UpdateFromPaste(data, "1")
  }, []);

  const handleChange = useCallback((text) => {
    setInputText(text);
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(() => {
      processText(text);
    }, 100);
  }, [processText]);

  useEffect(() => {
    console.log("hallooo")
  }, []);

  const luckyCss = {
    margin: '120px 0px 0px 0px',
    padding: '0px',
    position: 'absolute',
    width: '100%',
    height: '87%',
    left: '0px',
    top: '0px',
    overflowX: 'auto',
  };

  const forumLinkStyle = {
    zIndex: 2,
    width: '50px',
    height: '50px',
    lineHeight: '50px',
    position: 'fixed',
    right: '40px',
    bottom: '86px',
    borderRadius: '50px',
    cursor: 'pointer',
    background: 'rgb(71,133,249)',
    color: '#fff',
    textAlign: 'center',
    textDecoration: 'none',
  };

  return (
    <div>
      <HeaderMenu title="FCL SPPB" subtitle={"Page data & file FCL Stuffing"} />
      <ToastContainer />
      <div className="menu-container" style={{ marginTop: '4px' }}>
        <button className="styled-button mini_icon home margin-btn-action" onClick={go_to_home}>
          <img
            src={`${process.env.PUBLIC_URL}/images/png/house.png`}
            alt="Home"
            className="icon_free_margin text-white"
          />
        </button>

        <button className="styled-button mini_icon green margin-btn-action" onClick={go_to_home}>
          Refresh
        </button>
        <input
          type="text"
          placeholder="Nomor Container"
          onChange={(e) => handleChange(e.target.value)}
          //value={inputText}
          className="C_form-input margin-left-0 text_search_cont_adm"
        />
      </div>
      <div id="luckysheet" className="luckyCssResponsiveADM"/>
      <Modal modalVisible={modalVisible} toggleModal={toggleModal} data={datamodal} />
      <Modalpreview modalVisible={modalVisible_V} toggleModal={toggleModal_V} data={datamodal_V} />
    </div>
  );

};

export default FclAdm;