import React, { useEffect, useState } from 'react';
import axios from 'axios';
import $ from 'jquery';
import '../css/Login.css';
import '../css/Materialize.css';
import '../css/Style.css';
import '../css/new.css';
import reloadToken from '../helper/reloadtoken';
import helperFilter from '../helper/helperfilter';
import HeaderMenu from '../template/HeaderMenu';
import { apiConfig } from '../helper/apiConfig';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const host = process.env.REACT_APP_API_URL_ONLINE;
const hostdrop = process.env.REACT_APP_API_HOST_DROPDOWN;
const hostfcl = process.env.REACT_APP_API_HOST_FCL;
const hostdatcont = process.env.REACT_APP_API_HOST_DATA_CONT;
const hostcc = process.env.REACT_APP_API_HOST_COLOR_COMMENT;
const tipedata = "BTM";

const notify_cant_update_br_cont = (message) => {
    toast.error(message, {
    position: toast.POSITION.TOP_RIGHT
    });
};    
const notify_update_br_cont = (message) => {
    toast.success(message, {
    position: toast.POSITION.TOP_RIGHT
    });
};    

const BTM = () => {
    const luckysheet = window.luckysheet;
    const [token, setToken] = useState(null);
    var b = 0;
    const pagecolumn = "BTMCOLUMN"

    useEffect(() => {

        const fetchData = async () => {
            await new Promise((resolve) => setTimeout(resolve, 200));
            const newToken = await reloadToken();
            // console.log("test " + newToken);
            setToken(newToken);
            if (newToken) {
                initializeLuckySheet(newToken);
            }
        };

        const initializeLuckySheet = async (token) => {
            new Promise((resolve) => setTimeout(resolve, 300));
            const config = apiConfig(token);

            const getColumnNamesDataCont = async () => {
                try {
                    const response = await axios.post(`${hostfcl}ReactDataContBtmColumn`, null, config);
                    return response.data;
                    
                } catch (error) {
                    console.error('Error fetching column names:', error);
                    return [];
                }
            };
//test
            const getCellData = async () => {
                try {
                    const response = await axios.post(`${hostfcl}ReactDataContBtm`, null, config);
                    //console.log(response.data);
                    return response.data;
                } catch (error) {
                    console.error('Error fetching cell data:', error);
                    return [];
                }
            };

            const nama_column = await getColumnNamesDataCont();
            const cellDataJsonA = await getCellData();
            //console.log(`Number of data items: ${cellDataJsonA}`);
            if (Array.isArray(cellDataJsonA)) {
                const dataCount = cellDataJsonA.length;
            }
            const savedColumnWidths = JSON.parse(localStorage.getItem(pagecolumn));
            if (savedColumnWidths) {
              //console.log(savedColumnWidths);
            }

            luckysheet.create({
                title: "DataBtm",
                showinfobar: false,
                showtoolbarConfig: {
                    undoRedo: false, //Undo redo
                    paintFormat: false, //Format brush
                    currencyFormat: false, //currency format
                    percentageFormat: false, //Percentage format
                    numberDecrease: false, //'Decrease the number of decimal places'
                    numberIncrease: false, //'Increase the number of decimal places
                    moreFormats: true, //'More Formats'
                    font: false, //'font'
                    fontSize: false, //'Font size'
                    bold: false, //'Bold (Ctrl+B)'
                    italic: false, //'Italic (Ctrl+I)'
                    strikethrough: false, //'Strikethrough (Alt+Shift+5)'
                    underline: false, // 'Underline (Alt+Shift+6)'
                    textColor: false, //'Text color'
                    fillColor: true, //'Cell color'
                    border: false, //'border'
                    mergeCell: false, //'Merge cells' 
                    horizontalAlignMode: false, //'Horizontal alignment'
                    verticalAlignMode: false, //'Vertical alignment'
                    textWrapMode: true, //'Wrap mode'
                    textRotateMode: false, //'Text Rotation Mode'
                    image: false, // 'Insert picture'
                    link: false, // 'Insert link'
                    chart: false, //'chart' (the icon is hidden, but if the chart plugin is configured, you can still create a new chart by right click)
                    postil: true, //'comment'
                    pivotTable: false, //'PivotTable'
                    function: false, //'formula'
                    frozenMode: false, //'freeze mode'
                    sortAndFilter: true, //'Sort and filter'
                    conditionalFormat: true, //'Conditional Format'
                    dataVerification: false, // 'Data Verification'
                    splitColumn: false, //'Split column'
                    screenshot: false, //'screenshot'
                    findAndReplace: false, //'Find and Replace'
                    protection: false, // 'Worksheet protection'
                    print: false, // 'Print'

                },
                cellRightClickConfig: {
                    copy: true, // copy
                    copyAs: false, // copy as
                    paste: false, // paste
                    insertRow: false, // insert row
                    insertColumn: false, // insert column
                    deleteRow: false, // delete the selected row
                    deleteColumn: false, // delete the selected column
                    deleteCell: false, // delete cell
                    hideRow: false, // hide the selected row and display the selected row
                    hideColumn: false, // hide the selected column and display the selected column
                    rowHeight: false, // row height
                    columnWidth: false, // column width
                    clear: false, // clear content
                    matrix: false, // matrix operation selection
                    sort: false, // sort selection
                    filter: false, // filter selection
                    chart: false, // chart generation
                    image: false, // insert picture
                    link: false, // insert link
                    data: false, // data verification
                    cellFormat: false // Set cell format
                },
                data: [
                    {
                        name: "Sheet",
                        color: "",
                        row: 1,
                        index: "0",
                        celldata: cellDataJsonA,
                        defaultRowHeight: 25,
                        config: {
                        columnlen: savedColumnWidths,
                        },

                    },
                ],
                enableAddRow: true,
                container: "luckysheet",
                textWrapMode: true,
                hook: {
                    cellRenderAfter: function (cell, position, sheetFile, ctx) {
                      if (b < 1) {
                        const data = [];
                        const endIndex = 150;
                        for (let i = 0; i <= endIndex; i++) {
                          data.push(i);
                        }
                        var columnWidths = luckysheet.getColumnWidth(data);
                        // const colWidthA = columnWidths[1]; 
                        // console.log(`Width of column B: ${colWidthA}`);
                        for (let columnIndex of data) {
                           const width = columnWidths[columnIndex];
                           columnWidths[columnIndex.toString()] = width;
                        }
                        localStorage.setItem(pagecolumn, JSON.stringify(columnWidths));
          
                        setTimeout(function () {
                          b = 0;
                        },500);
                      }
                      b++;
                    },
                    commentUpdateBefore: async function (r, c, value) {
                        const id = luckysheet.getCellValue(r, 0);
                        var column = nama_column[c].toLowerCase();
                        column = column.replace(/ /g,"_");
                        // Create a new FormData object
                        const formData = new FormData();
                        formData.append("code_1", id);
                        formData.append("code_2", tipedata);
                        formData.append("code_3", "");
                        formData.append("row", r);
                        formData.append("col", c);
                        formData.append("comment", value);
                        formData.append("column", column);

                        try {
                            const response = await axios.post(`${hostcc}Updatecomment`, formData, config);
                            //console.log(response.data);
                        } catch (error) {
                            console.error('Error fetching data:', error);
                        }
                    },

                    cellUpdated: function (r, c, oldValue, newValue, isRefresh) {
                        const newData = newValue.m;
                        const id = luckysheet.getCellValue(r, 0);
                        var datax = {};
                        var nilai = newData;
                        var column = nama_column[c];
                        column = column.replace(/ /g,"_");
                        if(column == "harga_custom" || column =="harga_kapal" || column =="lainnya" || column =="jual" || column =="pib" || column =="pph_bebas" || column =="notul" || column =="dana_kerja" || column =="biaya_do" || column =="biaya_lainnya" || column =="demmurage" || column =="jaminan_overpayment" || column =="sisa_jaminan" || column =="total_biaya_do" || column =="pendo_c" || column =="penarikkan" || column =="handling_kbkb" || column =="bc" || column =="tila_muat" || column =="gerakkan" || column =="storages" || column =="ppjk" || column =="lainnya_bh" || column =="total_biaya_lapangan" || column =="biaya_trucking" || column =="lolo" || column =="bongkar" || column =="parkir"|| column =="repo" || column =="penitipan_inap" || column =="etc" || column =="total_trucking" || column =="lainnya_bw" || column =="kapal" || column =="form_e" || column =="ls"|| column =="daop" || column =="biaya_kerja" || column =="sisa_dana_kerja" || column =="sewa_pt" || column =="jasa_kerja" || column =="biaya_kerja_daop_pib" || column =="Total_biaya_tanpa_kapal"|| column =="asuransi" || column =="biaya_pelabuhan" || column =="do_lapangan_transport_fe_ls_bc" || column =="pib_biaya" || column =="total_biaya" || column =="dk_total_handling" || column =="dk_bayar_lapangan" || column =="dk_cross_check" || column =="dk_selisih_hitungan" || column =="dk_total_biaya" || column =="dk_sisa_dana" || column =="dk_profit" || column =="daop_jasa" || column =="daop_penarikkan" || column =="daop_kuli_cntr" || column =="daop_pemeriksaan_bagasi" || column =="daop_koord_periksa" || column =="daop_staff_periksa" || column =="daop_p2_bawah" || column =="daop_p2_bawah_wilayah" || column =="daop_analis_p2_bawah" || column =="daop_pfpd" || column =="daop_pusat" || column =="daop_wilayah_atas" || column =="daop_pelayanan_atas" || column =="daop_penyidik" || column =="daop_ops_bahendle")
                        {
                            nilai = parseFloat(nilai);
                        }
                        if (nilai !== null && nilai !== undefined && nilai !== "") {
                            datax[column] = nilai;
                          } else {
                            datax[column] = "";// console.log(oldValue.m);
                          }
                        datax[column] = nilai;
                            if (oldValue !== null && oldValue.m != newData && nama_column[c] != "") {
                                datax["id"] = id; 
                                axios.post(`${hostfcl}React_UpdateDataBtm`, datax, config)
                                    .then(response => {
                                        if(response.data.status == "success") notify_update_br_cont("Data Cont Btm Updated");
                                        //console.log('POST request successful:', response.data);
                                    })
                                    .catch(error => {
                                        console.error('Error fetching data:', error.response.data.message);
                                        notify_cant_update_br_cont(error.response.data.message);
                                        // luckysheet.setCellValue(r, c, "");
                                        luckysheet.refresh();
                                    });
                            }
                    }
                },
            });

            $("#luckysheet-bottom-add-row").on("click", async function () {
                var data = $("#luckysheet-bottom-add-row-input").val();
                //console.log(data);
                const currentDate = getCurrentDate();
                var b = luckysheet.getRange()[0].row[0];
                // luckysheet.setCellValue(b, 3, currentDate);
                // luckysheet.setCellValue(b, 4, currentDate);
                // luckysheet.setCellValue(b, 5, currentDate);
                // luckysheet.refresh();
                for (var i = 1; i <= data; i++) {
                    try {
                        const response = await axios.post(`${hostfcl}CreateDataBtm/New`, null, config);
                        //luckysheet.setCellValue(b, 0, response.data.data);
                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }
                    b++;
                }
                window.location.reload();
            });


            $(document).on('mousedown', '.sp-thumb-el', function () {
                // var warna = $(this).attr('data-color');
                var title = $(this).attr('title');
                var datas = [];
                var jlh = 0;
                var selectedRange = luckysheet.getRange();
                if (!selectedRange) {
                    console.error('No cells selected.');
                    return;
                }
                var baris_awal = selectedRange[0].row[0];
                var baris_akhir = selectedRange[0].row[1];
                var kolom_awal = selectedRange[0].column[0];
                var kolom_akhir = selectedRange[0].column[1];

                // console.log('baris awal', baris_awal);
                // console.log('baris akhir', baris_akhir);
                // console.log('kolom awal', kolom_awal);
                // console.log('kolom akhir', kolom_akhir);
                var column = nama_column[kolom_awal].toLowerCase();
                column = column.replace(/ /g,"_");
                for (var row = baris_awal; row <= baris_akhir; row++) {
                    for (var col = kolom_awal; col <= kolom_akhir; col++) {
                        const id = luckysheet.getCellValue(baris_awal, 0);
                        var datax = {};
                        datax['id'] = id;
                        datax['warna'] = title;
                        datax['ColumnName'] = column;
                        datax['ColumnField'] = "BTM";
                        datas[jlh] = datax;
                        jlh++;
                    }
                }
                var dataxx = {};
                dataxx['data'] = JSON.stringify(datas);
                axios.post(`${hostcc}ColorBrcontainers`, dataxx, config)
                    .then((response) => {

                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error);
                    });
            });

            luckysheet.setBothFrozen(true, { range: { row_focus: 0, column_focus: 10 } });
            setTimeout(async function () {
                if (typeof (luckysheet) !== 'undefined') {
                    luckysheet.hideColumn(0, 0);
                    luckysheet.setRangeFormat("ct", { "fa": "yyyy-MM-dd", "t": "d" });
                }
                filter_data();
                await helperFilter(10);
            }, 700);

        };

        fetchData();
        const handleVisibilityChange = () => {
            if (document.hidden) {
            } else {
              if (luckysheet) { 
                luckysheet.refresh();
              } else{
                window.location.reload();
              }
            }
          };
      
          document.addEventListener('visibilitychange', handleVisibilityChange);
    }, []);

    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    function filter_data() {
        $("#luckysheet-rich-text-editor").on('keyup', function () {
            console.log($(this).html());
            $(".dropdown-List-item").each(function () {
                if ($(this).text().toUpperCase().indexOf($("#luckysheet-rich-text-editor").text().toUpperCase()) > -1) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
        });
    }

    const luckyCss = {
        margin: '75px 0px 0px 0px',
        padding: '0px',
        position: 'absolute',
        width: '100%',
        height: '90%',
        left: '0px',
        top: '0px',
    };
    const forumLinkStyle = {
        zIndex: 2,
        width: '50px',
        height: '50px',
        lineHeight: '50px',
        position: 'fixed',
        right: '40px',
        bottom: '86px',
        borderRadius: '50px',
        cursor: 'pointer',
        background: 'rgb(71,133,249)',
        color: '#fff',
        textAlign: 'center',
        textDecoration: 'none',
    };

    return (
        <div>
            <HeaderMenu title="Halaman Data Container" />
            <ToastContainer />
            <div id="luckysheet" style={luckyCss} />
        </div>
    );
};

export default BTM;