
const go_to_home = () => {
  window.location.href = '/ceisalistaju';
};

const go_to_master_aju = () => {
  window.location.href = '/ceisalistaju';
};

const go_to_master_entitas = () => {
  window.location.href = '/masterentitas';
};

const go_to_entitas = () => {
  window.location.href = '/ceisareferensi';
}

export { go_to_home, go_to_master_entitas, go_to_master_aju, go_to_entitas};