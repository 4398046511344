import React from "react";

const MenuCsa = ({
  go_to_home,
  collapse_all,
  expand_all,
  sendToCeisa,
  isLoadingSendToCeisa,
}) => {
  return (
    <div className="menu-container-main">
      <div className="menu-container">
        <div className="menu-item" onClick={go_to_home}>
          <img
            src="/images/png/back.png"
            height="15"
            alt="Icon"
            className="iconMenuCeisa"
          />
          <span className="menu-text">Back</span>
        </div>
        <div className="menu-item" onClick={collapse_all}>
          <img
            src="/images/png/minimize.png"
            height="15"
            alt="Icon"
            className="iconMenuCeisa"
          />
          <span className="menu-text">Collapse all</span>
        </div>
        <div className="menu-item" onClick={expand_all}>
          <img
            src="/images/png/fullscreen.png"
            height="15"
            alt="Icon"
            className="iconMenuCeisa"
          />
          <span className="menu-text">Expand all</span>
        </div>
      </div>

      <button
        onClick={sendToCeisa}
        class="styled-button small green margin-btn-action"
      >
        {isLoadingSendToCeisa ? "Loading..." : "Simpan & Kirim data"}
      </button>
    </div>
  );
};

export default MenuCsa;
