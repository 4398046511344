function Convertformatlucky(cellDataJsonA) {
    for (let i = 0; i < cellDataJsonA.data.data.length; i++) {
        if (cellDataJsonA.data.data[i].v.ct == "0") {//title
          cellDataJsonA.data.data[i].v.ct = {
            "fa": "@",
            "t": "s",
          };
          cellDataJsonA.data.data[i].v.bl = 1;
          cellDataJsonA.data.data[i].v.ff = 1;
          cellDataJsonA.data.data[i].v.ht = 0;
          cellDataJsonA.data.data[i].v.fs = 9;
          cellDataJsonA.data.data[i].v.vt = 0;
          cellDataJsonA.data.data[i].v.tb = 2;
        } else if (cellDataJsonA.data.data[i].v.ct == "1") {//angka
          cellDataJsonA.data.data[i].v.ct = {
            "fa": "#,##0",
            "t": "n",
          };
          cellDataJsonA.data.data[i].v.bl = 2;
          cellDataJsonA.data.data[i].v.ff = 3;
          cellDataJsonA.data.data[i].v.ht = 2;
          cellDataJsonA.data.data[i].v.fs = 9;
          cellDataJsonA.data.data[i].v.vt = 0;
          cellDataJsonA.data.data[i].v.tb = 2;
        } else if (cellDataJsonA.data.data[i].v.ct == "2") {//date
          cellDataJsonA.data.data[i].v.ct = {
            "fa": "yyyy-MM-dd",
            "t": "d",
          };
          cellDataJsonA.data.data[i].v.bl = 2;
          cellDataJsonA.data.data[i].v.ff = 3;
          cellDataJsonA.data.data[i].v.ht = 0;
          cellDataJsonA.data.data[i].v.fs = 9;
          cellDataJsonA.data.data[i].v.vt = 0;
          cellDataJsonA.data.data[i].v.tb = 2;
        } else if (cellDataJsonA.data.data[i].v.ct == "3") {//link
          cellDataJsonA.data.data[i].v.ct = {
            "fa": "@",
            "t": "l",
          };
          cellDataJsonA.data.data[i].v.bl = 2;
          cellDataJsonA.data.data[i].v.ff = 3;
          cellDataJsonA.data.data[i].v.ht = 0;
          cellDataJsonA.data.data[i].v.fs = 9;
          cellDataJsonA.data.data[i].v.vt = 0;
          cellDataJsonA.data.data[i].v.tb = 2;
        } else if (cellDataJsonA.data.data[i].v.ct == "5") {//link
          cellDataJsonA.data.data[i].v.ct = {
            "fa": "General",
            "t": "g",
          };
          cellDataJsonA.data.data[i].v.bl = 2;
          cellDataJsonA.data.data[i].v.ff = 3;
          cellDataJsonA.data.data[i].v.ht = 1;
          cellDataJsonA.data.data[i].v.fs = 9;
          cellDataJsonA.data.data[i].v.vt = 0;
          cellDataJsonA.data.data[i].v.tb = 2;
        } else if (cellDataJsonA.data.data[i].v.ct == "7") {//url
          cellDataJsonA.data.data[i].v.ct = {
            "fa": "@",
            "t": "ur",
          };
          cellDataJsonA.data.data[i].v.bl = 2;
          cellDataJsonA.data.data[i].v.ff = 3;
          cellDataJsonA.data.data[i].v.ht = 0;
          cellDataJsonA.data.data[i].v.fs = 9;
          cellDataJsonA.data.data[i].v.vt = 0;
          cellDataJsonA.data.data[i].v.tb = 2;
        } else {
          cellDataJsonA.data.data[i].v.ct = {//text
            "fa": "@",
            "t": "n",
          };
          cellDataJsonA.data.data[i].v.bl = 2;
          cellDataJsonA.data.data[i].v.ff = 3;
          cellDataJsonA.data.data[i].v.ht = 1;
          cellDataJsonA.data.data[i].v.fs = 9;
          cellDataJsonA.data.data[i].v.vt = 0;
          cellDataJsonA.data.data[i].v.tb = 2;
        }
      }
  }
  
  export default Convertformatlucky;
  