import axios from 'axios';
import { decrypt,encrypt } from '../helper/Decrypte';
const host = process.env.REACT_APP_API_URL_ONLINE;

const reloadtokenselect = async (user) => {
  try {
    const verificationURL = host + "loginselect";
    const formData = new FormData();
    if (user) {
      formData.append('user', user);
      const response = await axios.post(verificationURL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const newToken = response.data.token;
      localStorage.setItem("user", encrypt(user));
      localStorage.setItem("pass", encrypt(response.data.ps));
      localStorage.setItem('token', newToken);
      localStorage.setItem("pengguna", response.data.pengguna);
      localStorage.setItem("master_field", response.data.master_field);
      localStorage.setItem("role", response.data.role);
      localStorage.setItem('branch', response.data.branch);
      setTimeout(() => {
        if (response.data.page != ""){
          window.location.href = "/"+response.data.page; 
        }else {

          window.location.reload();
        }
      }, 500);
      return newToken;
    } else {
      window.location.href = "/"; 
    }
  } catch (error) {
    console.error('Error reloading token:', error);
    window.location.href = "/"; 
   //throw error;
  }
};

export default reloadtokenselect;