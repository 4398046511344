import React, { useEffect, useState } from 'react';
import axios from 'axios';
import $ from 'jquery';
import '../css/Login.css';
import '../css/Materialize.css';
import '../css/Style.css';
import '../css/new.css';
import reloadToken from '../helper/reloadtoken';
import helperFilter from '../helper/helperfilter';
import HeaderMenu from '../template/HeaderMenu';
import { apiConfig } from '../helper/apiConfig';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const host = process.env.REACT_APP_API_URL_ONLINE;
const hostdrop = process.env.REACT_APP_API_HOST_DROPDOWN;
const hostfcl = process.env.REACT_APP_API_HOST_FCL;
const hostdatcont = process.env.REACT_APP_API_HOST_DATA_CONT;
const hostcc = process.env.REACT_APP_API_HOST_COLOR_COMMENT;
const tipedata = "FCL";

const notify_cant_update_br_cont = (message) => {
  toast.error(message, {
  position: toast.POSITION.TOP_RIGHT
  });
};    
const notify_update_br_cont = (message) => {
  toast.success(message, {
  position: toast.POSITION.TOP_RIGHT
  });
};  

const Bongkaran = () => {
  const luckysheet = window.luckysheet;
  const [token, setToken] = useState(null);
  var b = 0;
  const pagecolumn = "FCLCOLUMN"
  var columnWidths = [];
  var kiri, atas, tinggi;

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 500)); // Delay (if needed)
      const newToken = await reloadToken()
      // console.log("test " + newToken);
      setToken(newToken);
      if (newToken) {
        initializeLuckySheet(newToken);
      }
    };

    const initializeLuckySheet = async (token) => {
      new Promise((resolve) => setTimeout(resolve, 200));
      const config = apiConfig(token);

      const getColumnNames = async () => {
        try {
          const config = apiConfig(token);
          const response = await axios.post(`${hostfcl}ReactBrcontainerDetailColumn`, null, config);
          return response.data.column;
        } catch (error) {
          console.error('Error fetching column names:', error);
          return [];
        }
      };

      const getCellData = async () => {
        try {
          const response = await axios.post(`${hostfcl}ReactBrcontainersDetailBongkaran`, null, config);
          return response.data;
        } catch (error) {
          console.error('Error fetching cell data:', error);
          return [];
        }
      };
      // const nama_column = await getColumnNames();
      // const cellDataJsonA = await getCellData();

     

      await new Promise((resolve) => setTimeout(resolve, 1100));
      

      const nama_column = await getColumnNames();
      const cellDataJsonA = await getCellData();

      if (Array.isArray(cellDataJsonA)) {
        const dataCount = cellDataJsonA.length;
      }

      const savedColumnWidths = JSON.parse(localStorage.getItem(pagecolumn));
      if (savedColumnWidths) {
        //console.log(savedColumnWidths);
      }

      const data_pilihan = ["custom", "kapal", "fe", "gudang", "biaya_lain", "inv_ppn_br", "inv_ppn_kadena"];

      luckysheet.create({
        title: "DataCont",
        lang: 'en',
        showinfobar: false,
        showtoolbarConfig: {
          undoRedo: false, //Undo redo
          paintFormat: false, //Format brush
          currencyFormat: false, //currency format
          percentageFormat: false, //Percentage format
          numberDecrease: false, //'Decrease the number of decimal places'
          numberIncrease: false, //'Increase the number of decimal places
          moreFormats: true, //'More Formats'
          font: false, //'font'
          fontSize: false, //'Font size'
          bold: false, //'Bold (Ctrl+B)'
          italic: false, //'Italic (Ctrl+I)'
          strikethrough: false, //'Strikethrough (Alt+Shift+5)'
          underline: false, // 'Underline (Alt+Shift+6)'
          textColor: false, //'Text color'
          fillColor: true, //'Cell color'
          border: false, //'border'
          mergeCell: false, //'Merge cells' 
          horizontalAlignMode: false, //'Horizontal alignment'
          verticalAlignMode: false, //'Vertical alignment'
          textWrapMode: true, //'Wrap mode'
          textRotateMode: false, //'Text Rotation Mode'
          image: false, // 'Insert picture'
          link: false, // 'Insert link'
          chart: false, //'chart' (the icon is hidden, but if the chart plugin is configured, you can still create a new chart by right click)
          postil: true, //'comment'
          pivotTable: false, //'PivotTable'
          function: true, //'formula'
          frozenMode: false, //'freeze mode'
          sortAndFilter: true, //'Sort and filter'
          conditionalFormat: true, //'Conditional Format'
          dataVerification: false, // 'Data Verification'
          splitColumn: false, //'Split column'
          screenshot: false, //'screenshot'
          findAndReplace: false, //'Find and Replace'
          protection: false, // 'Worksheet protection'
          print: false, // 'Print'

        },
        cellRightClickConfig: {
          copy: true, // copy
          copyAs: false, // copy as
          paste: false, // paste
          insertRow: false, // insert row
          insertColumn: false, // insert column
          deleteRow: false, // delete the selected row
          deleteColumn: false, // delete the selected column
          deleteCell: false, // delete cell
          hideRow: false, // hide the selected row and display the selected row
          hideColumn: false, // hide the selected column and display the selected column
          rowHeight: false, // row height
          columnWidth: false, // column width
          clear: false, // clear content
          matrix: false, // matrix operation selection
          sort: false, // sort selection
          filter: false, // filter selection
          chart: false, // chart generation
          image: false, // insert picture
          link: false, // insert link
          data: false, // data verification
          cellFormat: false // Set cell format
        },
        data: [
          {
            name: "Sheet",
            color: "",
            row: 1,
            index: "0",
            celldata: cellDataJsonA,
            defaultRowHeight: 25,
            config: {
              columnlen: savedColumnWidths,
              colhidden: {
                0: false,
                11: false,
              },
            },
            // filter_select: {
            //   row: [0],  // Define the range of rows
            //   column: [1, 3],  // Define the range of columns
            // },
          },
        ],
        enableAddRow: true,
        container: "luckysheet",
        textWrapMode: true,
        hook: {
          cellRenderAfter: function (cell, position, sheetFile, ctx) {
            if (b < 1) {
              const data = [];
              const endIndex = 150;
              for (let i = 0; i <= endIndex; i++) {
                data.push(i);
              }
              var columnWidths = luckysheet.getColumnWidth(data);
              // const colWidthA = columnWidths[1]; 
              // console.log(`Width of column B: ${colWidthA}`);
              for (let columnIndex of data) {
                const width = columnWidths[columnIndex];
                columnWidths[columnIndex.toString()] = width;
              }
              localStorage.setItem(pagecolumn, JSON.stringify(columnWidths));

              setTimeout(function () {
                b = 0;
              }, 500);
            }
            b++;
          },

          commentUpdateBefore: async function (r, c, value) {
            const id = luckysheet.getCellValue(r, 0);
            const column = nama_column[c];
            // Create a new FormData object
            const formData = new FormData();
            formData.append("code_1", id);
            formData.append("code_2", tipedata);
            formData.append("code_3", "");
            formData.append("row", r);
            formData.append("col", c);
            formData.append("comment", value);
            formData.append("column", column);
            try {
              const response = await axios.post(`${hostcc}Updatecomment`, formData, config);
              //console.log(response.data);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          },

          cellUpdated: function (r, c, oldValue, newValue, isRefresh) {
            const newData = newValue.m;
            const id = luckysheet.getCellValue(r, 0);
            var datax = {};
            var nilai = newData;
            if (nama_column[c] == "harga_custom" || nama_column[c] == "harga_kapal" || nama_column[c] == "lainnya" || nama_column[c] == "Jual") {
              nilai = parseFloat(nilai);
            }

            if (nilai !== null && nilai !== undefined && nilai !== "") {
              datax[nama_column[c]] = nilai;
            } else {
              datax[nama_column[c]] = "";// console.log(oldValue.m);
            }

            if (nilai !== null && nilai !== undefined && nilai !== "") {
              if (data_pilihan.includes(nama_column[c])) {
                datax[nama_column[c]] = nilai.replace(/,/g, '');
              } else {
                datax[nama_column[c]] = nilai;
              }
            } else {
              datax[nama_column[c]] = "";
            }

            //console.log(nilai);
            // if (oldValue !== null && oldValue.m != newData nama_column[c] != "") {
              if (oldValue !== null && oldValue.m != newData && nama_column[c] != "") {
              datax["id"] = id;
              axios.post(`${hostfcl}React_UpdateBrcontainersDetail`, datax, config)
                .then(response => {
                  if(response.data.status == "success") notify_update_br_cont("FCL Updated");

                  //console.log('POST request successful:', response.data);
                })
                .catch(error => {
                  console.error('Error fetching data:', error.response.data.message);
                  notify_cant_update_br_cont(error.response.data.message);
                  // alert(error.response.data.message);
                  // if (oldValue != "") {
                  //   luckysheet.setCellValue(r, c, "");
                  // } else {
                  //   luckysheet.setCellValue(r, c, "");
                  // }
                  luckysheet.refresh();
                });
            }
          },
          cellEditBefore: function (range) {
            const c = range[0].column[0];
            var title_column_select = luckysheet.getCellValue(0, c);
            console.log(title_column_select);
          },
          rangeSelect: function (index, sheet) {
            //console.log(sheet)
            var top = sheet[0].top;
            var left = sheet[0].left;
            var height = sheet[0].height;
            kiri = left;
            atas = top;
            tinggi = height;
            filter_data(top, left, height);
          },
        },
      });

      $("#luckysheet-bottom-add-row").on("click", async function () {
        var data = $("#luckysheet-bottom-add-row-input").val();

        const currentDate = getCurrentDate();
        var b = luckysheet.getRange()[0].row[0];
        // luckysheet.setCellValue(b, 3, currentDate);
        // luckysheet.setCellValue(b, 4, currentDate);
        // luckysheet.setCellValue(b, 5, currentDate);
        // luckysheet.refresh();

        for (var i = 1; i <= data; i++) {
          try {
            const response = await axios.post(`${hostfcl}BrcontainersDetail/New`, null, config);
            //luckysheet.setCellValue(b, 0, response.data.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
          b++;
        }

        window.location.reload();
      });


      $(document).on('mousedown', '.sp-thumb-el', function () {
        // var warna = $(this).attr('data-color');
        var title = $(this).attr('title');
        var datas = [];
        var jlh = 0;
        var selectedRange = luckysheet.getRange();
        //console.log(selectedRange);
        if (!selectedRange) {
          console.error('No cells selected.');
          return;
        }
        var baris_awal = selectedRange[0].row[0];
        var baris_akhir = selectedRange[0].row[1];
        var kolom_awal = selectedRange[0].column[0];
        var kolom_akhir = selectedRange[0].column[1];

        const column = nama_column[kolom_awal];
        for (var row = baris_awal; row <= baris_akhir; row++) {
          for (var col = kolom_awal; col <= kolom_akhir; col++) {
            const id = luckysheet.getCellValue(baris_awal, 0);
            var datax = {};
            datax['id'] = id;
            datax['warna'] = title;
            datax['ColumnName'] = column;
            datax['ColumnField'] = "FCL";
            datas[jlh] = datax;
            jlh++;
          }
        }
        var dataxx = {};
        dataxx['data'] = JSON.stringify(datas);
        // console.log(dataxx);
        axios.post(`${hostcc}ColorBrcontainers`, dataxx, config)
          .then((response) => {

          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      });

      luckysheet.setBothFrozen(true, { range: { row_focus: 0, column_focus: 10 } });


      setTimeout(async function () {
        // filter_data();
        if (typeof (luckysheet) !== 'undefined') {
    
         
        let datahasil = [];
        // console.log(nama_column.column);
        const cariindex = data_pilihan.map(columnName => nama_column.indexOf(columnName));
        for (let i = 0; i < cariindex.length; i++) {
          datahasil.push(convertToAlphabet(cariindex[i]))
        }

        const tempatformula = ["total"];
        const cariindextempat = tempatformula.map(columnName => nama_column.indexOf(columnName));

        var totalRows = luckysheet.getluckysheetfile()[0].data.length;
        var totalRowsInt = parseInt(totalRows - 1, 10);
        //console.log("Number of rows:", luckysheet.getluckysheetfile());
        for (var row = 1; row <= totalRowsInt; row++) {
          //var formula = "=sum(X" + parseInt(row + 1) + ":Y" + parseInt(row + 1) + ")";
          var formula = "=(" + datahasil.map(col => col + parseInt(row + 1)).join("+") + ")";
          luckysheet.setCellValue(row, cariindextempat[0], formula);
        }
      }
        await helperFilter(10);
      }, 500);
    };

    fetchData();
    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log('Tab is hidden');
      } else {
        console.log('Tab is visible');
        luckysheet.refresh();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
  }, []); // The empty

  const convertToAlphabet = (number) => {
    let result = '';
    while (number >= 0) {
      result = String.fromCharCode(65 + (number % 26)) + result;
      number = Math.floor(number / 26) - 1;
    }
    return result;
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  //   function filter_data() {
  //     $("#luckysheet-rich-text-editor").on('keyup', function () {
  //         console.log($(this).html());
  //         $(".dropdown-List-item").each(function () {
  //             if ($(this).text().toUpperCase().indexOf($("#luckysheet-rich-text-editor").text().toUpperCase()) > -1) {
  //                 $(this).show();
  //             } else {
  //                 $(this).hide();
  //             }
  //         });
  //     });
  // }

  function filter_data(atas, kiri, tinggi) {
    $("#luckysheet-rich-text-editor").on('keyup', function () {
      const searchText = $("#luckysheet-rich-text-editor").text().toUpperCase();
      // Hide the dropdown initially
      $("#luckysheet-dataVerification-dropdown-List").hide();
      $(".dropdown-List-item").each(function () {
        const itemText = $(this).text().toUpperCase();
        if (itemText.indexOf(searchText) > -1) {
          $(this).show();
        } else {
          $(this).hide();
        }
      });

      // fiki
      const editorPosition = $("#luckysheet-rich-text-editor").position();
      //console.log("Posisi : "+editorPosition.left);
      const editorHeight = $("#luckysheet-rich-text-editor").outerHeight();
      //console.log("editor height : "+editorPosition.top);

      const topValue = parseFloat($("#luckysheet-cell-selected-focus").css("top"));
      const leftValue = parseFloat($("#luckysheet-input-box").css("left"));

      // console.log("Top attribute value:", atas);
      // console.log("Left attribute value:", kiri);

      $("#luckysheet-dataVerification-dropdown-List").css({
        display: 'block',
        // left: leftValue - 44 + 'px',
        // top: topValue + editorHeight + editorHeight - 5 + 'px',
        right: 'auto',
        left: kiri + 'px',
        top: atas + tinggi + 'px',
      });
    });
  }

  const luckyCss = {
    margin: '75px 0px 0px 0px',
    padding: '0px',
    position: 'absolute',
    width: '100%',
    height: '90%',
    left: '0px',
    top: '0px',
  };

  const forumLinkStyle = {
    zIndex: 2,
    width: '50px',
    height: '50px',
    lineHeight: '50px',
    position: 'fixed',
    right: '40px',
    bottom: '86px',
    borderRadius: '50px',
    cursor: 'pointer',
    background: 'rgb(71,133,249)',
    color: '#fff',
    textAlign: 'center',
    textDecoration: 'none',
  };

  return (
    <div>
      <HeaderMenu title="Halaman FCL" subtitle={"Halaman untuk data FCL"} />
      <ToastContainer />
      <div id="luckysheet" style={luckyCss} />
    </div>
  );
};

export default Bongkaran;