const HarbourModal = ({
  isOpen,
  toggleModal,
  handleSubmit,
  listHarbours,
  select_pel_tujuan,
  HandleUpdatePabeanName,
  pabean_office_val,
}) => {
  if (!isOpen) return null;

  return (
    <div className="modal-lartas-overlay">
      <div className="modal-content-lartas">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              backgroundColor: "#ffcc00",
              padding: "5px",
              width: "fit-content",
              borderRadius: "5px",
              color: "black",
              fontWeight: "500",
            }}
          >
            Isi pelabuhan terlebih dahulu!
          </div>
          <button className="modal-close" onClick={toggleModal}>
            &times;
          </button>
        </div>
        <div className="modal-body">
          <form
            style={{ display: "flex", flexDirection: "column" }}
            onSubmit={handleSubmit}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label className="C_form-label">Pelabuhan Tujuan</label>
              <select
                ref={select_pel_tujuan}
                className="C_select-input_table margin-left-0"
                style={{ minWidth: "100%" }}
                onChange={HandleUpdatePabeanName}
                required
              >
                <option key={0} value={""}>
                  {" - "}
                </option>
                {listHarbours.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label className="C_form-label">Kantor Pabean</label>
              <input
                type="text"
                value={pabean_office_val}
                placeholder="Kantor pabean"
                className="C_form-input margin-left-0"
                style={{
                  minWidth: "100%",
                  cursor: "not-allowed",
                }}
                disabled
              />
            </div>
            <div
              style={{
                textAlign: "-webkit-right",
                marginRight: "-12px",
                marginTop: "5px",
              }}
            >
              <button type="submit" className="styled-button mini_icon brmd">
                Create Aju
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HarbourModal;
