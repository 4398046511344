import React from 'react';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

// import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
// import "primereact/resources/themes/lara-light-cyan/theme.css";

import './App.css';
// import DataLcl from "./components/DataLcl";
import DataAju from "./components/DataAju";
import DataBtm from "./components/DataBtm";
import DataJobFe from "./components/DataJobFe";
import DataCont from './components/DataCont';
import DataContHistory from './components/DataContHistory';
import FCL from './components/FCL';
import FCLMobile from './components/FCLMobile';
import FCLadds from './components/FCLadds';
import FCLHistory from './components/FCLHistory';
import Login from './components/Login';
import Home from './components/Luckysheet';
import DocumentUpload from './components/DocumentUpload';
import DocumentUploadBiaya from "./components/DocumentUploadBiaya";
import Pib from './components/biaya/Pib';
import PibDetail from './components/biaya/PibDetail';
import DrafPengajuan from './components/biaya/DrafPengajuan';
import DrafPengajuanDetail from './components/biaya/DrafPengajuanDetail';
import LapBongkaran from './components/LapBongkaran';
import DataContJ3 from './components/DataContJ3';
import DataContJ4 from './components/DataContJ4';
import DataContPage from './components/DataContPage';
import FCLPage from './components/FCLPage';
import DataContOne from './components/DataContOne';
import Ls from './components/Ls';
import SheetLS from './components/SheetLS';
import DataContReport from './components/DatacontReport';
import FCLTesting from './components/FCLTesting';
import LCL from './components/LCL';
import Bkk from './components/Bkk';
import BkkPage from './components/BkkPage.js';
import BiayaCont from './components/BiayaCont.js';
import BiayaContPage from './components/BiayaContPage.js';
import Blueray_Employee from './components/Blueray_Employee.js';
import Role from './components/masteruser/Role'
import KartuHutang from './components/KartuHutang'
import HutangDagang from './components/HutangDagang'
import PackingList from './components/PackingList'
import PL_upload from './components/PL_upload';
import FclAdmin from './components/FclAdmin'
import FCLPic from './components/FCLPic';
import PicMenu from './components/MenuPic';

import LoginMaster from './components/LoginMaster';
import FclMenu from './components/FclMenu';

import OrgChartComponent from './components/OrgChartComponent';
import Ceisa from './components/ceisa/Ceisa';
import MasterEntitas from './components/ceisa/MasterEntitas.js';

import ListAju from './components/ceisa/ListAju.js';
import CeisaReferensi from './components/ceisa/CeisaReferensi.js';
import CeisaDoc from './components/ceisa/CeisaDoc';

import FCLBTM from './components/FclBtm';

import Unauthorized from './components/Unauthorized';


const App: React.FC = () => {
  const userRole = localStorage.getItem("role");
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/OrgChart" element={<OrgChartComponent />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/DataCont" element={<DataCont />} />
          <Route path="/FCL" element={<FCL />} />
          <Route
            path="/FCLBTM"
            element={
              userRole === "ADMSALES+" ? <FCLBTM /> : <Navigate to="/unauthorized" replace />
            }
          />
          <Route path="/FCL/:param1/:param2" element={<FCL />} />
          <Route path="/FCLADDS" element={<FCLadds />} />
          <Route path="/FCLHistory" element={<FCLHistory />} />
          <Route path="/FCLMobile" element={<FCLMobile />} />
          <Route path="/Pib" element={<Pib />} />
          <Route path="/PibDetail/:param1/:param2" element={<PibDetail />} />
          <Route path="/DrafPengajuan" element={<DrafPengajuan />} />
          <Route path="/DrafPengajuan/:param1" element={<DrafPengajuanDetail />} />
          {/* <Route path="/DataLcl" element={<DataLcl />} /> */}
          <Route path="/DataAju" element={<DataAju />} />
          <Route path="/DataBtm" element={<DataBtm />} />
          <Route path="/DataJobFe" element={<DataJobFe />} />
          <Route path="/DocumentUpload" element={<DocumentUpload/>} />
          <Route path="/DocumentUploadBiaya" element={<DocumentUploadBiaya/>} />
          <Route path="/LapBongkaran" element={<LapBongkaran/>} />
          <Route path="/KartuHutang" element={<KartuHutang/>} />
          <Route path="/HutangDagang" element={<HutangDagang/>} /> 
          <Route path="/DataContJ3" element={<DataContJ3 />} />
          <Route path="/DataContJ4" element={<DataContJ4 />} />
          <Route path="/DataContHistory" element={<DataContHistory />} />
          <Route path="/DataContPage" element={<DataContPage />} />
          <Route path="/FCLPage" element={<FCLPage />} />
          <Route path="/DatacontReport" element={<DataContReport />} />
          <Route path="/DataContOne" element={<DataContOne />} />
          <Route path="/LS" element={<Ls />} />
          <Route path="/SheetLS" element={<SheetLS />} />
          <Route path='/FCLTesting' element={<FCLTesting/>}/>
          <Route path='/LCL' element={<LCL/>}/>
          <Route path='/Bkk' element={<Bkk/>}/>
          <Route path="/BkkPage" element={<BkkPage />} />
          <Route path="/BiayaCont" element={<BiayaCont />} />
          <Route path="/BiayaContPage" element={<BiayaContPage />} />
          <Route path="/Blueray_Employee" element={<Blueray_Employee />} />
          <Route path="/PackingList" element={<PackingList />} />
          <Route path="/PL_upload" element={<PL_upload />} />
          <Route path="/FclAdmin" element={<FclAdmin />} />
          <Route path="/FCL-PIC" element={<FCLPic />} />
          <Route path="/Pic-menu" element={<PicMenu />} />
          
          <Route path="/LoginMaster" element={<LoginMaster />} />
          <Route path="/Menu" element={<FclMenu />} /> 

          <Route path="/Role" element={<Role />} />

          <Route path="/Ceisa/:nomorAju" element={<Ceisa />} />
          <Route path="/MasterEntitas" element={<MasterEntitas />} />
          <Route path="/CeisaListAju" element={<ListAju />} />
          <Route path="/CeisaReferensi" element={<CeisaReferensi />} />
          <Route path="/CeisaDoc" element={<CeisaDoc />} />


          <Route path="/unauthorized" element={<Unauthorized />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
