export const formatPrice = (value) => {
  const validInput = /^[0-9,.]*$/;

  if (!validInput.test(value)) {
    return;
  }

  // Remove any existing commas
  let cleanedValue = String(value).replace(/,/g, "");

  // Convert the string to a number
  let number = parseFloat(cleanedValue);

  // Check if it's a valid number
  if (isNaN(number)) {
    return "Invalid number";
  }

  // Split the number into the integer and decimal parts
  let parts = cleanedValue.split(".");

  // Format the integer part with commas
  parts[0] = parseInt(parts[0], 10).toLocaleString("en-US");

  // Rejoin the integer and decimal parts, if any
  return parts.join(".");
};
