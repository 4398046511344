import React, { useEffect, useState } from 'react';
import axios from 'axios';
import $ from 'jquery';
import '../css/Login.css';
import '../css/Materialize.css';
import '../css/Style.css';
import '../css/new.css';
import reloadToken from '../helper/reloadtoken';
import helperFilter from '../helper/helperfilter';
import { dataconfig, showtoolbarConfig, ct } from '../helper/luckysheetHelper';
import { hide, bikinInfoSum, sumDataKerja, getFrozen,createRatioZoom,getRatioZoom,saveheighrow, getheighrow, bikinselect, getFilterPage} from '../helper/hide';
import HeaderMenu from '../template/HeaderMenu';
import { apiConfig } from '../helper/apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dataformula from '../sheetFormula.js';
import pako from 'pako';
import Modal from './modal';
import ModalFilter from './modalFilter';

const tipedata = "DATACONT";
const host = process.env.REACT_APP_API_URL_ONLINE;
const hostdrop = process.env.REACT_APP_API_HOST_DROPDOWN;
const hostbiayacont = process.env.REACT_APP_API_HOST_BIAYA_CONT;
const hostdatcogitnt = process.env.REACT_APP_API_HOST_DATA_CONT;
const hostcc = process.env.REACT_APP_API_HOST_COLOR_COMMENT;
const fullURL = window.location.href;
const saveSearch = "search" + fullURL;

const notify_cant_update_br_cont = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 500,
  });
};
const notify_update_br_cont = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1200,
  });
};

const BIAYACONT = () => {
  const luckysheet = window.luckysheet;
  const [token, setToken] = useState(null);
  var b = 0;
  const pagecolumn = "BIAYACONTPAGECOLUMN"
  var columnWidths = [];
  var kiri, atas, tinggi;
  var options = null;
  var type_link = "";
  var type_row = "";
  var type_jml = 0;
  let valueppn = "YA,TIDAK";
  let valueukuran = "20,40,45";
  let valuestatusbl = "TELEX,DRAFT,ORI,HBL";
  let dataserver = [];
  let dataserver1 = [];
  var end_column_freze = 10;
  var size_scroll_left = 0;

  const [modalVisible, setModalVisible] = useState(false);
  const [datamodal, setDataModal] = useState(false);
  const [fikiArray, setFikiArray] = useState([]);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const [modalVisible_V, setModalVisible_V] = useState(false);
  const [datamodal_V, setDataModal_V] = useState(false);

  const [optionsSearch, setOptionsSearch] = useState([]);
  const [loadingsearch, setLoadingSearch] = useState(true);
  const [modalFilterVisible, setModalFilterVisible] = useState(false);

  const [selectTypeSearch, setSelectTypeSearch] = useState('-');
  const [selectport, setSelectPort] = useState('ALL');
  const [selectcolumn, setSelectColumn] = useState('tgl_bongkar');
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(1); // Default to January (1)

  const handleSelectedPort = (e) => {
    setSelectPort(e.target.value);
  };

  const handleTypeChange = (event) => {
    setSelectTypeSearch(event.target.value);
    localStorage.setItem(saveSearch, event.target.value);
  };

  const handleColumnselected = (e) => {
    setSelectColumn(e.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value, 10));
  };

  const toggleFilterModal = () => {
    setModalFilterVisible(!modalFilterVisible);
  };

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, index) => currentYear - index);

  const onYearChange = (year) => {
    setSelectedYear(year);
  };

  const handleSearchClick = async () => {
    try {
      const payload = {
        month: selectedMonth,
        year: selectedYear,
      };

      const newToken = await reloadToken()
      setToken(newToken);
      if (newToken) {
         initializeLuckySheet(newToken,selectedMonth,selectedYear,selectcolumn,selectport,selectTypeSearch);
      }
      console.log('Search results:');
    } catch (error) {
      console.error('Error searching:', error);
    }
  };

  function getDataFilterTersimpan() {
    let parsedData = [];
    var titlefilter = getTitleFilter()
    const fullURL = window.location.href;
    const nameFrozen = "filtersave-" + fullURL;
    const retrievedData = localStorage.getItem(nameFrozen);

    if (retrievedData){
        parsedData = JSON.parse(retrievedData);
    }

    if (Array.isArray(parsedData)) {
        // cari index sesuai pencarian fki
        const index = parsedData.findIndex(item => Object.keys(item)[0] === titlefilter);
        if (index !== -1) {
            const fikiArray = parsedData[index][titlefilter];
            //console.log("fikiArray ", fikiArray);
            setFikiArray(fikiArray);
            return fikiArray;
        } else {
            //console.log("Data not found");
        }
    } else {
        //console.log("Invalid or empty data");
    }
    return [];
  }

  function getTitleFilter(){
    const fullURL = window.location.href;
    const nameFrozen = "filtersaveTitle-" + fullURL;
    const retrievedData = localStorage.getItem(nameFrozen);
    return retrievedData;
}

    useEffect(() => {
      const fetchData = async () => {
        await new Promise((resolve) => setTimeout(resolve, 100));
        const newToken = await reloadToken()
        setToken(newToken);
        if (newToken) {
          initializeLuckySheet(newToken, null, null, null, null, getFilterPage());
        }
      };

      setSelectTypeSearch(getFilterPage())
      fetchData();
      
      const handleVisibilityChange = () => {
        if (document.hidden) {
        } else {
          if (luckysheet) { 
            luckysheet.refresh();
          } else{
            window.location.reload();
          }
        }
      };
  
      document.addEventListener('visibilitychange', handleVisibilityChange);
    }, []);

    const initializeLuckySheet = async (token, bln, thn, tipe, port, namatable) => {
      let datacari = [];
      datacari.push(bln + "-" + thn + "-" +tipe+ "-" +port)

      new Promise((resolve) => setTimeout(resolve, 100));
      const config = apiConfig(token);

      const getValueDropdownSearch = async () => {
        try {
          const config = apiConfig(token);
          const response = await axios.post(`${hostbiayacont}MenudropdownPage`, null, config);
          return response.data;
        } catch (error) {
          console.error('Error fetching column names:', error);
          return [];
        }
      };
      setOptionsSearch(await getValueDropdownSearch())

      const getColumnNames = async () => {
        try {
          const config = apiConfig(token);
          const response = await axios.post(`${hostbiayacont}ReactBiayaContColumn/${selectTypeSearch}`, null, config);
          return response.data.column;
        } catch (error) {
          console.error('Error fetching column names:', error);
          return [];
        }
      };
//
      const getCellData = async (data, savedColumnWidths, urutanload, namatable) => {
        let fh = getDataFilterTersimpan();
        try {
          const response = await axios.post(`${hostbiayacont}ReactBiayaConts`, { data, savedColumnWidths, urutanload, datacari, namatable, fh }, config);
          return response;
        } catch (error) {
          console.error('Error fetching cell data:', error);
          return [];
        }
      };

      const nama_column = await getColumnNames();
      const data_pilihan = ["harga_custom", "harga_kapal", "lainnya", "jual", "pib", "pph_bebas", "notul", "daop_penyidik", "daop_ops_bahendle"];
      let datahasil = [];
      const cariindex = data_pilihan.map(columnName => nama_column.indexOf(columnName));
      for (let i = 0; i < cariindex.length; i++) {
        const columnNumber = cariindex[i];
        const columnPosition = getColumnPosition(columnNumber);
        datahasil.push(columnPosition)
      }

      const luckysheet = window.luckysheet;
      let savedColumnWidths = JSON.parse(localStorage.getItem(pagecolumn));
      if (savedColumnWidths === null || Object.keys(savedColumnWidths).length === 0) {
        savedColumnWidths = null;
      } else {
        savedColumnWidths = savedColumnWidths;
      }

      let cellDataJsonA = [];
      let totaldata = 0;

      async function loaddata(offset) {
        cellDataJsonA = await getCellData(datahasil, savedColumnWidths, offset, namatable);
        const isEmptyData = Object.keys(cellDataJsonA.data).length === 0;

        if (cellDataJsonA.length === 0) {
          return {
            "data": [{}],
            "dataformula": [{}]
          };
        } else {
          if (!isEmptyData){
          totaldata = totaldata + cellDataJsonA.data.totaldata + 1;
          for (let i = 0; i < cellDataJsonA.data.data.length; i++) {
            if (cellDataJsonA.data.data[i].v.ct == "0") {//title
              cellDataJsonA.data.data[i].v.ct = {
                "fa": "@",
                "t": "s",
              };
              cellDataJsonA.data.data[i].v.bl = 1;
              cellDataJsonA.data.data[i].v.ff = 1;
              cellDataJsonA.data.data[i].v.ht = 0;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
              //cellDataJsonA.data.data[i].v.fc ="#8c8c8c"
            } else if (cellDataJsonA.data.data[i].v.ct == "1") {//angka
              cellDataJsonA.data.data[i].v.ct = {
                "fa": "#,##0",
                "t": "n",
              };
              cellDataJsonA.data.data[i].v.bl = 2;
              cellDataJsonA.data.data[i].v.ff = 2;
              cellDataJsonA.data.data[i].v.ht = 2;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
            } else if (cellDataJsonA.data.data[i].v.ct == "2") {//date
              cellDataJsonA.data.data[i].v.ct = {
                "fa": "yyyy-MM-dd",
                "t": "d",
              };
              cellDataJsonA.data.data[i].v.bl = 2;
              cellDataJsonA.data.data[i].v.ff = 2;
              cellDataJsonA.data.data[i].v.ht = 0;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
            } else if (cellDataJsonA.data.data[i].v.ct == "3") {//link
              cellDataJsonA.data.data[i].v.ct = {
                "fa": "@",
                "t": "l",
              };
              cellDataJsonA.data.data[i].v.bl = 2;
              cellDataJsonA.data.data[i].v.ff = 2;
              cellDataJsonA.data.data[i].v.ht = 0;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
            } else if (cellDataJsonA.data.data[i].v.ct == "4") {//text center
              cellDataJsonA.data.data[i].v.ct = {
                "fa": "0",
                "t": "n",
              };
              cellDataJsonA.data.data[i].v.bl = 2;
              cellDataJsonA.data.data[i].v.ff = 2;
              cellDataJsonA.data.data[i].v.ht = 0;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
            } else {
              cellDataJsonA.data.data[i].v.ct = {//text
                "fa": "@",
                "t": "n",

              };
              cellDataJsonA.data.data[i].v.bl = 2;
              cellDataJsonA.data.data[i].v.ff = 2;
              cellDataJsonA.data.data[i].v.ht = 1;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
            }
          }
          const data_all = cellDataJsonA.data;
          return data_all;
        } else {
          return {
            "data": [{}],
            "dataformula": [{}]
          };
        }
      }
      }

      const [page0Data, page1Data, page2Data, page3Data, page4Data, page5Data] = await Promise.all([
        loaddata(0),
        loaddata(1),
        loaddata(2),
        loaddata(3),
        loaddata(4),
        loaddata(5),
      ]);
      
      const page0 = page0Data || [];
      const page1 = page1Data || [];
      const page2 = page2Data || [];
      const page3 = page3Data || [];
      const page4 = page4Data || [];
      const page5 = page5Data || [];

      const merged = [...page0.data,
      ...page1.data,
      ...page2.data,
      ...page3.data,
      ...page4.data,
      ...page5.data,
      ];

      const canformual = [...page0.dataformula,
      ...page1.dataformula,
      ...page2.dataformula,
      ...page3.dataformula,
      ...page4.dataformula,
      ...page5.dataformula,
      ];
    
      const dk_selisih_hitungan_ = nama_column.indexOf("dk_selisih_hitungan")
      const dk_selisih_hitungan = getColumnPosition(dk_selisih_hitungan_);
      const dk_selisih_hitungan_read = dk_selisih_hitungan + '0:' + dk_selisih_hitungan + '1';

      const dk_tgl_bayar_ = nama_column.indexOf("dk_tgl_bayar_kembali")
      const dk_tgl_bayar = getColumnPosition(dk_tgl_bayar_);

      const dk_cross_check_ = nama_column.indexOf("dk_cross_check")
      const dk_cross_check = getColumnPosition(dk_cross_check_);
      const dk_tgl_bayar_read = dk_cross_check + '0:' + dk_cross_check + '1';

      const dk_bayar_lapangan_ = nama_column.indexOf("dk_bayar_lapangan")
      const dk_bayar_lapangan = getColumnPosition(dk_bayar_lapangan_);
      const dk_bayar_lapangan_read = dk_bayar_lapangan + '0:' + dk_bayar_lapangan + '1';

      const mergedDataRO = page1.readonly + "," + dk_selisih_hitungan_read + ","+dk_tgl_bayar_read + "," +dk_bayar_lapangan_read;
      
      options = {
        container: "luckysheet",
        title: "DataCont",
        lang: 'en',
        showinfobar: false,
        allowCopy: true,
        allowEdit: true,
        column: 0,
        forceCalculation: true,
        showtoolbarConfig: dataconfig,
        cellRightClickConfig: showtoolbarConfig,
        data: [
          {
            name: "Sheet",
            color: "",
            row: 1,
            index: "0",
            celldata: merged,
            calcChain: canformual,
            defaultRowHeight: 40,
            config: {
              columnlen: savedColumnWidths,
              colhidden: {
                0: false,
              },
              rowlen: {
                0: 40,
              },
              authority: {
                sheet: 1,
                hintText: "Kolom ini tidak bisa anda edit", //The text of the pop-up prompt
                allowRangeList: [
                  {
                    "name": "area",
                    "sqref": mergedDataRO,
                  },
                ],
              },
            },
            // filter_select: {
            //   row: [0, totaldata],
            //   column: [1, nama_column.length - 1],
            // }
          },
        ],
        enableAddRow: false,
        textWrapMode: true,
        hook: {
          cellRenderAfter: function (cell, position, sheetFile, ctx) {
            // console.log("cell ",cell)
            // console.log("position ",position)
            // console.log("sheetFile ",sheetFile)
            // console.log("ctx ",ctx)
            if (b < 1) {
              const data = [];
              const endIndex = 150;
              for (let i = 0; i <= endIndex; i++) {
                data.push(i);
              }
              var columnWidths = luckysheet.getColumnWidth(data);
              for (let columnIndex of data) {
                const width = columnWidths[columnIndex];
                columnWidths[columnIndex.toString()] = width;
              }
              localStorage.setItem(pagecolumn, JSON.stringify(columnWidths));
              setTimeout(function () {
                b = 0;
              }, 500);
            }
            b++;
          },

          frozenCreateAfter:function(data){
            //console.log("cell")
          },
          rangePasteBefore: function (range, data) {
            // return false; //Can intercept paste
          },
          commentUpdateBefore: async function (r, c, value) {
            const id = luckysheet.getCellValue(r, 0);
            const column = nama_column[c];
            const formData = new FormData();
            const cleanedValue = value.trim().replace(/\r\n?|\n/g, ' ');
            formData.append("code_1", id);
            formData.append("code_2", tipedata);
            formData.append("code_3", "");
            formData.append("row", r);
            formData.append("col", c);
            formData.append("comment", cleanedValue);
            formData.append("column", column);
            try {
              const response = await axios.post(`${hostcc}Updatecomment`, formData, config);
              notify_update_br_cont("Note sukses di tambahkan");
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          },
          cellEditBefore: function (r, c, value, isRefresh) {
            // console.log("Edit before");
            // console.log(value);
          },
          cellUpdateBefore: function (r, c, value, isRefresh) {
            if (!isValidFormula(value)) {
              return true;
            } else {
              return false;
            }
          },
          cellUpdated: function (r, c, oldValue, newValue, isRefresh) {
            if (r > 0) {
            if (oldValue && newValue) {
              const newData = newValue.m;
              const id = luckysheet.getCellValue(r, 0);
              var datax = {};
              var nilai = newData;
              var column = nama_column[c];
              //column = column.replace(/ /g, "_");
              if (nama_column[c] !== undefined) {
              if (nilai !== null && nilai !== undefined && nilai !== "") {
                // console.log(nama_column[c]);
                // console.log(data_pilihan);
                if (data_pilihan.includes(nama_column[c])) {
                  //console.log("oke ada ", nilai.replace(/,/g, ''));
                  datax[nama_column[c]] = nilai.replace(/,/g, '');
                } else {
                  datax[nama_column[c]] = nilai;
                }
              } else {
                datax[nama_column[c]] = "";
              }
              if (oldValue !== null && oldValue.m != newData && nama_column[c] != "") {
                datax["id"] = id;
                  axios.post(`${hostbiayacont}ReactUpdateBiayaConts/${selectTypeSearch}`, datax, config)
                  .then(response => {
                    if (response.data.status == "success") notify_update_br_cont("Data Cont Updated");
                    //console.log('POST request successful:', response.data);
                  })
                  .catch(error => {
                    console.error('Error fetching data:', error.response.data.message);
                    notify_cant_update_br_cont(error.response.data.message);
                    // luckysheet.setCellValue(r, c, "");
                    luckysheet.refresh();
                  });
              }
            } else {
              notify_cant_update_br_cont("Gagal!. Silahkan Refresh halaman ini.");
            }

            }
          }
          },
          cellEditBefore: function (range) {
            const c = range[0].column[0];
            var title_column_select = luckysheet.getCellValue(0, c);
            //console.log(title_column_select);
          },
          rangeSelect: function (index, sheet) {
            //console.log("Column ", sheet[0].column[0]);
            if (sheet[0].column[0] <= end_column_freze) {
              setleftScrollbar(true);
            } else {
              setleftScrollbar(false);
            }
            var top = sheet[0].top;
            var left = sheet[0].left;
            var height = sheet[0].height;
            kiri = left;
            atas = top;
            tinggi = height;
            filter_data(top, left, height);
          },
          cellMousedown:function(cell,postion,sheetFile,ctx){
             var dat = postion.r+""+postion.c;
             if (type_row == dat){
              type_jml ++;
             } else {
              type_jml = 0;
             }
             type_row = postion.r+""+postion.c;

             if (type_jml > 0){
             if (cell.ct.t =="l"){
              const id = luckysheet.getCellValue(postion.r, 0);
              const judul = luckysheet.getCellValue(0, postion.c);
              var dataKirimModal = {
                id: id,
                nama: judul
              };
              //console.log(dataKirimModal)
              setDataModal(dataKirimModal);
              toggleModal();
             }
            }
					},cellMousedown: function (cell, postion, sheetFile, ctx) {
            // console.log("klik ", ctx)
            // console.log("cek ",luckysheet.getRangeAxis())
            var dat = postion.r + "" + postion.c;
            if (type_row == dat) {
              type_jml++;
            } else {
              type_jml = 0;
            }
            type_row = postion.r + "" + postion.c;

            if (type_jml > 0) {
              if (cell.ct.t == "l") {
                const id = luckysheet.getCellValue(postion.r, 0);
                const judul = luckysheet.getCellValue(0, postion.c);
                var dataKirimModal = {
                  id: id,
                  nama: judul
                };
                setDataModal(dataKirimModal);
                toggleModal();
              }
            }
          },
          afterzoomfiki: function (ration) {
            createRatioZoom(ration);
            luckysheet.setCellFormat(1, 1, "ct", { fa: "#,###", t: "n" })
            luckysheet.jfrefreshgrid();
          },
          aftercreatefilterfiki: function (data) {
            const freezen_3 = document.getElementById('freezen_3');
            if (freezen_3) {
              freezen_3.style.zIndex = '20';
            }
          },
          sheetCopyAfter: function (range, data) {
            // console.log("paste ",range)
            // console.log("data ",data)
          },
          updated: function (operate) {
            //console.log("hasil operate: ",operate);
            let data = luckysheet.getLuckysheetfile();
            if (operate) {
              if (operate.ctrlType == "resizeR") {
                let dataukuran = operate.curconfig.rowlen;

                const keys = Object.keys(dataukuran);
                const lastKey = keys[keys.length - 1];
                const lastValue = dataukuran[lastKey];

                saveheighrow(lastValue);
              }
            }
          },

          rangeSelect: function (data1, data2) {
            //  console.log("data ", data1);
            //  console.log("data2 ", data2);
          },

          rangePasteBeforefiki: function (range, data, fki) {
            // console.log("range ",fki.luckysheetfile[0].luckysheet_select_save[0])
            // console.log("data ",fki)
            const isConfirmed = window.confirm('Yakin akan melakukan paste data?');
            if (isConfirmed) {
              setTimeout(() => {
                let jsonData = fki.luckysheetfile[0].luckysheet_select_save[0];
                const rows = jsonData.row;
                const columns = jsonData.column;
                // extra columnya fk
                const [startRow, endRow] = rows;
                const [startCol, endCol] = columns;

                var dataArray = [];
                var idCounter = 1;

                for (let row = startRow; row <= endRow; row++) {
                  let datax = { id: luckysheet.getCellValue(row, 0) }; // Convert to string and remove leading/trailing spaces

                  for (let col = startCol; col <= endCol; col++) {
                    const nilai = luckysheet.getCellValue(row, col);
                    datax[nama_column[col]] = nilai !== null && nilai !== undefined && nilai !== "" ? nilai.toString().trim() : ""; // Convert to string and remove leading/trailing spaces
                  }
                  dataArray.push(datax);
                }
                //console.log(dataArray);
                UpdateFromPaste(dataArray);
              }, 400);
            } else {
              return false;
            }
          },
        },
      }

      options.loading = {
        image: () => {
          return `<svg viewBox="25 25 50 50" class="circular">
					<circle cx="50" cy="50" r="20" fill="none"></circle>
					</svg>`
        },
        imageClass: "loadingAnimation"
      }
      console.log("luckysheet ",luckysheet);
      if (totaldata > 0) {
        luckysheet.create(options)
        setTimeout(() => {
          if (typeof luckysheet !== 'undefined') {
          sumDataKerja(luckysheet,nama_column);
          }
        }, 4000);
      } else {
        notify_cant_update_br_cont("Data kosong", 2500);
      }

      setTimeout(() => {
        console.log("test");
        bikinselect();
      }, 500);

      function rollbackvalidasidropdown(valueppnArray, data) {
        if (data === null || data === undefined || data.trim() === "") {
          console.log("Data is empty or null.");
          return false;
        }
        if (valueppnArray.includes(data)) {
          return true;
        } else {
          return false;
        }
      }

      function getColumnIndexByTitle(sheetId, title) {
        const sheetData = luckysheet.getSheetData(sheetId);
        if (!sheetData) return -1; // Sheet data not found

        const firstRowData = sheetData[0].data;
        for (let colIndex = 0; colIndex < firstRowData.length; colIndex++) {
          if (firstRowData[colIndex].v === title) {
            return colIndex;
          }
        }
        return -1; // Column title not found
      }

      function removeCrLfFromString(inputString) {
        return inputString.replace(/\r/g, ' ');
      }

      function removeCrLfFromString_(inputString) {
        return inputString.replace(/\n/g, ' ');
      }

      $("#luckysheet-bottom-add-row").on("click", async function () {
        var data = $("#luckysheet-bottom-add-row-input").val();

        const currentDate = getCurrentDate();
        var b = luckysheet.getRange()[0].row[0];
        // luckysheet.setCellValue(b, 3, currentDate);
        // luckysheet.refresh();
        for (var i = 1; i <= data; i++) {
          try {
            const response = await axios.post(`${hostbiayacont}BiayaCont/New`, null, config);
            //luckysheet.setCellValue(b, 0, response.data.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
          b++;
        }
        window.location.reload();
      });
      function UpdateFromPaste(datax) {
        axios.post(`${hostbiayacont}ReactUpdateBiayaContpaste`, datax, config)
          .then(response => {
            if (response.data.status == "success") notify_update_br_cont("Data Biayacont Updated");
            //console.log('POST request successful:', response.data);
          })
          .catch(error => {
            console.error('Error fetching data:', error.response.data.message);
            notify_cant_update_br_cont(error.response.data.message);
            // luckysheet.setCellValue(r, c, "");
            luckysheet.refresh();
          });
      }


      function isValidFormula(value) {
        const formulaPattern = /^=([A-Za-z]+\d+\+)*[A-Za-z]+\d+$/;
        return formulaPattern.test(value);
      }

      $(document).on('mousedown', '.sp-thumb-el', function () {
        // var warna = $(this).attr('data-color');
        var title = $(this).attr('title');
        var datas = [];
        var jlh = 0;
        var selectedRange = luckysheet.getRange();
        console.log("selectedRange ",selectedRange)
        if (!selectedRange) {
          console.error('No cells selected.');
          return;
        }
        var baris_awal = selectedRange[0].row[0];
        var baris_akhir = selectedRange[0].row[1];
        var kolom_awal = selectedRange[0].column[0];
        var kolom_akhir = selectedRange[0].column[1];

        const column = nama_column[kolom_awal];
        for (var row = baris_awal; row <= baris_akhir; row++) {
          for (var col = kolom_awal; col <= kolom_akhir; col++) {
            const id = luckysheet.getCellValue(row, 0);
            var datax = {};
            datax['id'] = id;
            datax['warna'] = title;
            datax['ColumnName'] = column;
            datax['ColumnField'] = "BIAYACONT";
            datas[jlh] = datax;
            jlh++;
          }
        }
        
        var dataxx = {};
        dataxx['data'] = JSON.stringify(datas);
        axios.post(`${hostcc}ColorBrcontainers`, dataxx, config)
          .then((response) => {
            notify_update_br_cont("Color sukses di tambahkan");
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      });

      $(document).on('mousedown', '[itemname="create filter"]', function () {
        setTimeout(async function () {
          helperFilter(11);
        }, 100);
    });

    $(document).ready(function () {
      const handleClickCustomFilter = () => {
        toggleFilterModal();
        console.log('Element with id luckysheet-btn-filterCustom clicked!');
      };
    
      $('#luckysheet-btn-filterCustom').on('click', handleClickCustomFilter);
    });

    
      function getColumnPosition(columnNumber) {
        let dividend = columnNumber + 1;
        let columnName = '';

        while (dividend > 0) {
          const modulo = (dividend - 1) % 26;
          columnName = String.fromCharCode(65 + modulo) + columnName;
          dividend = Math.floor((dividend - modulo) / 26);
        }
        return columnName;
      }

      const luckysheetContainer = document.getElementById('luckysheet');
      luckysheetContainer.addEventListener('scroll', () => {
        const scrollLeft = luckysheetContainer.scrollLeft;
        const frozenColumns = document.querySelectorAll('.luckysheet-cell.luckysheet-cell-frozen');
        frozenColumns.forEach((column) => {
          column.style.transform = `translateX(${scrollLeft}px)`;
        });
      });

      setTimeout(async function () {
        if (totaldata > 0) {
          luckysheet.setBothFrozen(true, { range: { row_focus: 0, column_focus: end_column_freze } });
          helperFilter(11);
          setleftScrollbarstart();
        }
      }, 100);
    };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  function setleftScrollbar(kiri) {
    const luckysheetContainerX = document.getElementById('luckysheet-scrollbar-x');
    luckysheetContainerX.addEventListener('scroll', () => {
      if (luckysheetContainerX.scrollLeft > 1) {
        size_scroll_left = luckysheetContainerX.scrollLeft;
      }
    });
    if (kiri) {
      size_scroll_left = 0
      luckysheetContainerX.scrollLeft = size_scroll_left;
    }
    else {
      luckysheetContainerX.scrollLeft = size_scroll_left;
      //console.log("SCROLL ", size_scroll_left);
    }
  }

  function setleftScrollbarstart() {
    const luckysheetContainerX = document.getElementById('luckysheet-scrollbar-x');
    luckysheetContainerX.addEventListener('scroll', () => {
      if (luckysheetContainerX.scrollLeft > 1) {
        size_scroll_left = luckysheetContainerX.scrollLeft;
      }
      luckysheetContainerX.scrollLeft = size_scroll_left;
      //console.log("SCROLL AUTO", luckysheetContainerX.scrollLeft);
    });
  }

  function filter_data(atas, kiri, tinggi) {
    $("#luckysheet-rich-text-editor").on('keyup', function () {
      const searchText = $("#luckysheet-rich-text-editor").text().toUpperCase();
      $("#luckysheet-dataVerification-dropdown-List").hide();
      $(".dropdown-List-item").each(function () {
        const itemText = $(this).text().toUpperCase();
        if (itemText.indexOf(searchText) > -1) {
          $(this).show();
        } else {
          $(this).hide();
        }
      });

      // fiki
      const editorHeight = $("#luckysheet-rich-text-editor").outerHeight();
      const topValue = parseFloat($("#luckysheet-cell-selected-focus").css("top"));
      const leftValue = parseFloat($("#luckysheet-input-box").css("left"));
      $("#luckysheet-dataVerification-dropdown-List").css({
        display: 'block',
        // left: leftValue - 44 + 'px',
        // top: topValue + editorHeight + editorHeight - 5 + 'px',
        right: 'auto',
        left: kiri + 'px',
        top: atas + tinggi + 'px',
      });
    });
  }

  const luckyCss = {
    margin: '110px 0px 0px 0px',
    padding: '0px',
    position: 'absolute',
    width: '100%',
    height: '85%',
    left: '0px',
    top: '0px',
    overflowX: 'auto',
  };


  const forumLinkStyle = {
    zIndex: 2,
    width: '50px',
    height: '50px',
    lineHeight: '50px',
    position: 'fixed',
    right: '40px',
    bottom: '86px',
    borderRadius: '50px',
    cursor: 'pointer',
    background: 'rgb(71,133,249)',
    color: '#fff',
    textAlign: 'center',
    textDecoration: 'none',
  };

  const selectStyle = {
    // Define your custom CSS styles here
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '2px',
    fontSize: '16px',
    width: 'auto',
    height: '10%',
    marginTop: '10px',
  };

  console.log('getFilterPage() value:', getFilterPage());
  return (
    <div>
      <HeaderMenu title="BIAYA CONT" subtitle={"Halaman untuk data Kontainer"} />
      <ToastContainer />
      <select style={selectStyle} value={selectTypeSearch} onChange={handleTypeChange}>
  {optionsSearch.map((optionsSearch) => (
    <option key={optionsSearch.value} value={optionsSearch.value} selected={optionsSearch.value === 'nina_air'}>
      {optionsSearch.label}
    </option>
  ))}
</select>



      <button onClick={handleSearchClick} className='btn btn-default'>View</button>
      <div id="luckysheet" style={luckyCss} />
      <Modal modalVisible={modalVisible} toggleModal={toggleModal} data={datamodal} />
      <ModalFilter modalVisible={modalFilterVisible} toggleModal={toggleFilterModal} data={datamodal} />
    </div>
  );
};

export default BIAYACONT;