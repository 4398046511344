import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { apiConfig } from "../../helper/apiConfig";
import { formatPrice } from "../../helper/formatPrice";
import { toast } from "react-toastify";

const hostcsamaster = process.env.REACT_APP_API_HOST_MASTER_ENTITAS;

const notify_cant_update_br_cont = (message, time = 1200) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: time,
  });
};
const notify_update_br_cont = (message) => {
  toast.success(message, {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: 800,
  });
};

export const convertDate = (dateString) => {
  const [day, month, year] = dateString.split("/");

  // Return the date in YYYY/MM/DD format
  return `${year}-${month}-${day}`;
};

export const formatJsonToText = (json) => {
  let formattedText = "";
  for (const [key, value] of Object.entries(json)) {
    if (typeof value !== "object" || value === null) {
      formattedText += `${key} : ${value}\n`;
    }
  }
  return formattedText;
};

function transformDataPasteKMS(dataArray) {
  return dataArray
    .map((item) => {
      console.log("item ", item[0]);
      return {
        id: item[0],
        no_aju: item[1],
        no_seri: item[2],
        nomor: item[3],
        ukuran: item[4],
        jenis: item[5],
        merk: item[6],
        type: item[7],
      };
      return null;
    })
    .filter((item) => item !== null);
}

//
export function sortProperties(item) {
  return {
    id: item.id,
    no_aju: item.no_aju,
    no_seri: item.no_seri,
    nomor: item.nomor,
    ukuran: item.ukuran,
    jenis: item.jenis,
    merk: item.merk,
    type: item.type,
    CreatedAt: item.CreatedAt,
    UpdatedAt: item.UpdatedAt,
    DeletedAt: item.DeletedAt,
  };
}

export function sortPropertiesbrg(item) {
  return {
    id: item.id,
    nomorAju: item.nomorAju,
    seriBarang: item.seriBarang,
    no_container: item.container?.no_container,
    hsCode: item.hsCode,
    kodeBarang: "",
    uraian: item.uraian,
    merk: item.merk,
    tipe: item.tipe,
    ukuran: "",
    spec: "",
    kodeSatuan: item.kodeSatuan,
    jumlahSatuan: formatPrice(item.jumlahSatuan),
    kodeKemasan: item.kodeKemasan,
    jumlahKemasan: formatPrice(item.jumlahKemasan),
    netto: formatPrice(item.netto),
    cif: item.cif,
    cifRupiah: item.cifRupiah,
    ndpbm: item.ndpbm,
    fob: item.fob,
    asuransi: formatPrice(item.asuransi),
    freight: item.freight,
    nilai_tambah: "",
    diskon: item.diskon,
    harga_penyerahan: "",
    harga_perolehan: "",
    hargaSatuan: formatPrice(item.hargaSatuan),
    harga_expor: "",
    nilai_barang: "",
    nilai_jasa: "",
    kode_jenis_nilai: "",
    kode_kondisi_barang: "",
    kode_negara_asal: "",
    pernyataanLartas: item.pernyataanLartas,
    isiPerkemasan: "",
  };
}

//BARANG
export function brg_UD(changes, source, hotRef, token) {
  const config = apiConfig(token);
  const hotInstance = hotRef.current ? hotRef.current.hotInstance : hotRef;
  if (!hotInstance) {
    notify_cant_update_br_cont("HotTable instance is not available");
    return;
  }
  let id;
  let lartas;
  if (source === "edit") {
    setTimeout(function () {
      id = hotInstance.getDataAtRow(changes[0][0])[0];
      // if the position change (templating), you have to change this line with find the right position "lartas"
      lartas = hotInstance.getDataAtRow(changes[0][0])[32];
      const formData = new FormData();
      formData.append("pernyataanLartas", lartas);
      axios
        .patch(`${hostcsamaster}goods/${id}`, formData, config)
        .then((response) => {
          console.log("response ", response.data.status);
          if (response.data.status === "success") {
            notify_update_br_cont("Lartas success updated!");
            hotInstance.render();
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error.response.data.message);
          notify_cant_update_br_cont(error.response.data.message);
          //hotInstance.undo();
        });
    }, 500);
  }
}

//KEMASAN & PETI KEMAS
const url_kemasan = "packagedocument";
export function doc_CKMS(index, amount, hotRef, token, aju, type) {
  const config = apiConfig(token);
  const hotInstance = hotRef.current.hotInstance;
  const data = hotInstance.getData();
  const payload = {
    no_aju: aju,
    no_seri: `${index + 1}`,
    type: type,
  };
  axios
    .post(`${hostcsamaster}${url_kemasan}`, payload, config)
    .then((response) => {
      console.log("response ", response.data.status);
      if (response.data.status === "success") {
        hotInstance.setDataAtCell(index, 0, response.data.data.id);
        hotInstance.setDataAtCell(index, 1, response.data.data.no_aju);
        hotInstance.setDataAtCell(index, 2, response.data.data.no_seri);
        hotInstance.setDataAtCell(index, 7, response.data.data.type);
        hotInstance.render();
        notify_update_br_cont("Data sukses di proses");
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error.response.data.message);
      notify_cant_update_br_cont(error.response.data.message);
      //hot.render();
    });
}

export function doc_UKMS(changes, source, hotRef, token, aju) {
  const config = apiConfig(token);
  const hotInstance = hotRef.current ? hotRef.current.hotInstance : hotRef;
  if (!hotInstance) {
    notify_cant_update_br_cont("HotTable instance is not available");
    return;
  }
  if (source === "edit") {
    setTimeout(function () {
      const payload = changes.map((change) => {
        return {
          id: hotInstance.getDataAtRow(change[0])[0],
          no_aju: hotInstance.getDataAtRow(change[0])[1],
          no_seri: hotInstance.getDataAtRow(change[0])[2],
          nomor: hotInstance.getDataAtRow(change[0])[3],
          ukuran: hotInstance.getDataAtRow(change[0])[4],
          jenis: hotInstance.getDataAtRow(change[0])[5],
          merk: hotInstance.getDataAtRow(change[0])[6],
          type: hotInstance.getDataAtRow(change[0])[7],
        };
      });
      axios
        .patch(`${hostcsamaster}${url_kemasan}/bulk`, payload, config)
        .then((response) => {
          console.log("response ", response.data.status);
          if (response.data.status === "success") {
            //notify_update_br_cont('Data sukses di proses');
            hotInstance.render();
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error.response.data.message);
          notify_cant_update_br_cont(error.response.data.message);
          //hotInstance.undo();
        });
    }, 500);
  }
}

export function doc_DKMS(
  index,
  amount,
  physicalRows,
  [source],
  hotRef,
  token,
  aju
) {
  const config = apiConfig(token);
  const hotInstance = hotRef.current ? hotRef.current.hotInstance : hotRef;
  if (!hotInstance) {
    notify_cant_update_br_cont("HotTable instance is not available");
    return;
  }
  const payload = [];
  physicalRows.forEach((index) => {
    const id = hotInstance.getDataAtRow(index, 0)[0];
    payload.push({ id });
  });

  console.log("id ", payload);
  setTimeout(function () {
    axios
      .delete(`${hostcsamaster}${url_kemasan}/bulk`, {
        ...config,
        data: payload,
      })
      .then((response) => {
        console.log("response", response.data.status);
        var a = 0;
        if (response.data.status === "success") {
          console.log("a ", a);
          notify_update_br_cont("Data successfully deleted");
          hotInstance.render();
        }
        a++;
      })
      .catch((error) => {
        console.error(
          "Error deleting data:",
          error.response ? error.response.data.message : error.message
        );
        notify_cant_update_br_cont("Failed to delete data");
        //hotInstance.undo();
      });
  }, 500);
}

export function doc_PKMS(data, coords, hotRef, token, aju) {
  const config = apiConfig(token);
  const hotInstance = hotRef.current ? hotRef.current.hotInstance : hotRef;
  if (!hotInstance) {
    notify_cant_update_br_cont("HotTable instance is not available");
    return;
  }

  var colHeaderList = hotInstance.getColHeader().length;
  hotInstance.selectCell(
    coords[0]["startRow"],
    0,
    coords[0]["endRow"],
    colHeaderList - 1
  );
  const selected = hotInstance.getSelected() || [];
  const data_selected = [];

  setTimeout(function () {
    for (let i = 0; i < selected.length; i += 1) {
      const item = selected[i];
      data_selected.push(hotInstance.getData(...item));
    }
    const payload = transformDataPasteKMS(data_selected[0]);
    console.log("payload ", payload);
    axios
      .patch(`${hostcsamaster}${url_kemasan}/bulk`, payload, config)
      .then((response) => {
        console.log("response ", response.data.status);
        if (response.data.status === "success") {
          // notify_update_br_cont('Data sukses di proses');
          hotInstance.render();
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error.response.data.message);
        notify_cant_update_br_cont(error.response.data.message);
        //hotInstance.undo();
      });
  }, 500);
}

export const ProsesFileChange = (event, aju, token, containerId, format) => {
  return new Promise((resolve, reject) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("no_aju", aju);
      formData.append("file_konsep", file);
      formData.append("container_id", containerId);
      formData.append("format", format);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      axios
        .post(`${hostcsamaster}goods/`, formData, config)
        .then((response) => {
          if (response.data.status === "success") {
            // Resolve the response here, but don't notify
            resolve(response);
          } else {
            reject(new Error("Failed to upload file"));
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error.response?.data?.message);
          reject(error);
        });
    } else {
      reject(new Error("No file selected"));
    }
  });
};
