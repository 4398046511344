import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { HotTable, HotColumn } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import Header from '../../template/HeaderMenu';
import styleHs from '../../css/Hs.module.css';
import { apiConfig } from '../../helper/apiConfig';
import reloadToken from '../../helper/reloadtoken';
import styles from '../../css/Hs.module.css';

// register Handsontable's modules
registerAllModules();
const host = process.env.REACT_APP_API_URL_ONLINE;
const hostbiaya = process.env.REACT_APP_API_HOST_BIAYA
const hostdrop = process.env.REACT_APP_API_HOST_DROPDOWN;
const hostcc = process.env.REACT_APP_API_HOST_COLOR_COMMENT;

const Pib = () => {
  const [tableHeight, setTableHeight] = useState(350); 
  const hotRef = useRef(null);
  const [hotInstance, setHotInstance] = useState(null);
  const [token, setToken] = useState(null);
  let nama_pt = "BIAYA-PIB";
  let tipe = "1";

  useEffect(() => {
    const setUP = async () => {
      const hot = hotRef.current.hotInstance;
      setHotInstance(hot);
      const token = await reloadToken();
      setToken(token);
    }
    setUP();

      // Calculate the desired table height based on the screen layout
      const screenHeight = window.innerHeight;
      const headerHeight = 70;
      const footerHeight = 60;
      const calculatedHeight = screenHeight - headerHeight - footerHeight;
      setTableHeight(calculatedHeight);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      console.log(token);
      if (hotInstance && token) {
        const res = await loadDataPib(token);
        hotInstance.addHook('afterColumnResize', handleColumnResize);
        loadColumnWidthsFromLocalStorage();
        initializeForSearchColumn();
          if (res && Array.isArray(res.data)) {
            hotInstance.loadData(res.data);
          } else {
            console.error("Invalid data format or data is missing:", res);
          }
      }
    };
    fetchData();
  }, [token]);

  const loadDataPib = async (token) => {
    try {
      const config = apiConfig(token);
      const response = await axios.post(`${hostbiaya}getData/PIB`, null, config);
      if (hotInstance) {
        hotInstance.loadData(response.data.data);
      } else {
        console.log("data kosong");
      }
      return response.data;
    } catch (error) {
      console.error('Error fetching column names:', error);
      return [];
    }
  };

  const initializeForSearchColumn = () => {
    const debounceFn = (colIndex, event) => {
      const filtersPlugin = hotInstance.getPlugin('filters');
      filtersPlugin.removeConditions(colIndex);
      filtersPlugin.addCondition(colIndex, 'contains', [event.target.value]);
      filtersPlugin.filter();
    };

    const addEventListeners = (input, colIndex) => {
      input.addEventListener('keydown', (event) => {
        debounceFn(colIndex, event);
      });
    };

    const addInput = (col, TH) => {
      if (typeof col !== 'number') {
        return col;
      }

      if (col >= 0 && TH.childElementCount < 2) {
        TH.appendChild(getInitializedElements(col));
      }
    };

    const getInitializedElements = (colIndex) => {
      const div = document.createElement('div');
      const input = document.createElement('input');
      addEventListeners(input, colIndex);
      div.appendChild(input);
      return div;
    };

    const doNotSelectColumn = (event, coords) => {
      if (coords.row === -1 && event.target.nodeName === 'INPUT') {
        event.stopImmediatePropagation();
        hotInstance.deselectCell();
      }
    };

    hotInstance.updateSettings({
      afterGetColHeader: addInput,
      beforeOnCellMouseDown: doNotSelectColumn,
    });
  }

  const handleSaveButtonClick = () => {
    const config = apiConfig(token);
    var requestData = { data: hotInstance.getData() }; // No need to stringify
    var alertConfirm = window.confirm("Data akan di proses?");
    if (alertConfirm) {
      axios.post(`${hostbiaya}saveBiaya/PIB`, requestData, config)
        .then(response => {
          if (response.data.status === 'success') {
            hotInstance.loadData([]);
            var data = response.data.data;
            var kode = response.data.group;
            window.location.href = `/pibdetail/pib/${kode}`;
            console.log('data:', data);
            console.log('Kode:', kode);
          } else {
            var message = response.data.message;
            console.log('Error:', message);
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error.response.data.message);
        });
    }
  };

  const loadColumnWidthsFromLocalStorage = () => {
    const savedWidths = {};
    for (let i = 0; i < hotInstance.countCols(); i++) {
      const width = localStorage.getItem(`${nama_pt}-${tipe}-${i}`);
      savedWidths[i] = width ? parseInt(width) : 100;
    }
    hotInstance.updateSettings({
      colWidths: function (column) {
        return savedWidths[column];
      },
    });
  };

  const handleColumnResize = (size, column) => {
    localStorage.setItem(`${nama_pt}-${tipe}-${column}`, size);
  };

  const columns = [
    { type: 'text', title: 'ID', align: 'center' },
    { type: 'text', title: 'Consignee', align: 'center' },
    { type: 'text', title: 'No. BL', align: 'center' },
    { type: 'text', title: 'No. Container' },
    { type: 'text', title: 'AJU', align: 'center' },
    { type: 'text', title: 'ETA' },
    { type: 'text', title: 'Item', align: 'center' },
    {
      type: 'numeric',
      title: 'PIB IDR',
      align: 'right',
      numericFormat: {
        pattern: '0,0', // Use the pattern for thousands separators
        culture: 'id-ID',
      },
    },
    { type: 'text', title: 'Tgl Minta PIB', align: 'center' },
  ];
  const colHeaders = columns.map(column => column.title);
  return (
    <div>
      <Header title="Pengajuan Biaya PIB" />
      <div>
        <HotTable
          ref={hotRef}
          contextMenu={true}
          rowHeaders={true}
          filters={true}
          outsideClickDeselects={false}
          allowInsertColumn={true}
          height={tableHeight}
          width={1465}
          autoRowSize={true}
          readOnly={true}
          manualColumnResize={true}
          dropdownMenu={['filter_by_condition', 'filter_by_value', 'filter_action_bar']}
          columns={columns}
          // colWidths={[100, 185, 185, 185, 185, 185, 185, 185, 185, 185, 185]}
          licenseKey="non-commercial-and-evaluation"
          hiddenColumns={{
            copyPasteEnabled: true,
            indicators: true,
            columns: [0]
          }}
        />
        <div className="positionfix">
          <div className={`${styleHs.flexbox100}`}>
            <div id="footer_data" style={{ margin: '5px', verticalAlign: 'text-bottom' }}>
              <span id="detailValue"></span>
            </div>
            <div id="footer_data_1" style={{ margin: '5px', verticalAlign: 'text-bottom' }}>
              <button className={`${styleHs.button} ${styleHs.buttonproses}`} id="btn_save" onClick={handleSaveButtonClick}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pib;