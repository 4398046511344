export const hide = () => {
    // document.getElementById("luckysheet-dataVerification-dropdown-List").style.display = "none";
    document.getElementById("luckysheet-input-box").style.display = "none";
    document.getElementById("luckysheet-input-box-index").style.display = "none";
};

export const setSum = (data) => {
    //console.log("Set sum ", data)
    var titleElement = document.getElementsByClassName('docs-title-widget');
    //console.log("Set sum ", titleElement)
    if (titleElement) {
        titleElement.innerText = 'New Data Container Value';
    }
}

export const bikinselect = () => {
  setTimeout(() => {
    var luckysheetSheetContent = document.getElementById('luckysheet-sheet-content');
    if (luckysheetSheetContent) {
      var divleft = document.createElement('div');
      divleft.setAttribute('id', 'left-div-bottom-view');
      luckysheetSheetContent.appendChild(divleft);

      var newDiv = document.createElement('div');
      newDiv.setAttribute('id', 'divfkifilter');
      divleft.appendChild(newDiv);

      const fullURL = window.location.href;
      const nameFrozen = "filtersave-" + fullURL;
      const dataString = localStorage.getItem(nameFrozen);
      let data = [];

      // Parse the JSON string into an array
      if (dataString) {
        data = JSON.parse(dataString);
      } else {
        data = [];
      }

      var newSpan = document.createElement('span');
      newSpan.textContent = "Filter by : ";
      newSpan.setAttribute('id', 'spanSelectFilter');
      newDiv.appendChild(newSpan);
      
      // Create a select element
      var selectDropdown = document.createElement('select');
      selectDropdown.setAttribute('class', 'selectFilter');
      var option = document.createElement('option');
      option.value = "default";
      option.text = "default";
      selectDropdown.appendChild(option);

      data.forEach(item => {
        var key = Object.keys(item)[0];
        var option = document.createElement('option');
        option.value = key;
        option.text = key;
        // Check if the option's value matches "fiki" and set it as selected

        if (key === getTitleFilter()) {
          option.selected = true;
        }
        selectDropdown.appendChild(option);
      });

      // Add onChange handler to the select element
      selectDropdown.addEventListener('change', function (event) {
        // Save the selected value to local storage
        saveTitleFilter(event.target.value);
        window.location.reload();
      });

      // Append the select element to the newDiv
      newDiv.appendChild(selectDropdown);
    }
  }, 1000); // Use an appropriate timeout value
};

function saveTitleFilter(titlefilter) {
  const fullURL = window.location.href;
  const nameFrozen = "filtersaveTitle-" + fullURL;
  localStorage.setItem(nameFrozen, titlefilter);
}

function getTitleFilter(){
  const fullURL = window.location.href;
  const nameFrozen = "filtersaveTitle-" + fullURL;
  const retrievedData = localStorage.getItem(nameFrozen);
  return retrievedData;
}

export const getFilterPage = () => {
  const fullURL = window.location.href;
  const saveSearch = "search" + fullURL;
  const retrievedData = localStorage.getItem(saveSearch);
  return retrievedData;
}

export const getFilterPageAll = (data) => {
  const saveSearch = data;
  const retrievedData = localStorage.getItem(saveSearch);
  return retrievedData;
}

export const getFilterPageAllJson = (data) => {
  const saveSearch = data;
  const retrievedData = localStorage.getItem(saveSearch);
  let parsedValues;

  if (retrievedData !== null && retrievedData !== '') {
    try {
      parsedValues = JSON.parse(retrievedData);
    } catch (error) {
      console.error('Error parsing JSON:', error);
      parsedValues = [];
    }
  } else {
    parsedValues = [];
  }

  return parsedValues;
}




export const bikinInfoSumUMD = (sum, count) => {
    var SUMFINISH = "Total : Rp. "+sum.toLocaleString();
    var COUNTFINISH = "Jml : "+count;
    var luckysheetSheetContent = document.getElementById('luckysheet-sheet-content');
    if (luckysheetSheetContent) {
        var divToRemove = document.getElementById('divfkisum');
        if (divToRemove && divToRemove.parentNode === luckysheetSheetContent) {
            luckysheetSheetContent.removeChild(divToRemove);
        } else {
            //console.error("Element with ID 'divfkisum' not found or not a child of luckysheet-sheet-content.");
        }  
        
        var newDivtop = document.createElement('div');
        newDivtop.setAttribute('id', 'right-div-bottom-view ');
        luckysheetSheetContent.appendChild(newDivtop);

        var newDiv = document.createElement('div');
        newDiv.setAttribute('id', 'divfkisum');
        newDivtop.appendChild(newDiv);

        var newSpan = document.createElement('span');
        newSpan.textContent = SUMFINISH;
        newSpan.setAttribute('id', 'fkisum');
        newDiv.appendChild(newSpan);

        var newSpan = document.createElement('span');
        newSpan.textContent = COUNTFINISH;
        newSpan.setAttribute('id', 'fkicount');
        newDiv.appendChild(newSpan);
      }
}

// export const bikinInfoSum = (luckysheet, sheet, index) => {
//     // console.log("data : ",luckysheet.getRangeAxis())
//     // console.log("data 2 : ", luckysheet.getRangeValue())

//     // const dat = luckysheet.getLuckysheetfile()[0]?.visibledatarow?.length
//     // if (dat !== undefined) {
//     //     jml_baris = dat;
//     // }

//     var jml_baris = 0;
//     const selectedColumns = sheet[0].column;
//     const selectedRows = sheet[0].row;

//     if (selectedRows[0] !== selectedRows[1] || selectedColumns[0] !== selectedColumns[1]) {
//         let SUM = 0;
//         let COUNT = 0;
//         var data = luckysheet.getRangeValue();
//         for (const subarray of data) {
//             for (const cell of subarray) {
//                 if (cell.m) {
//                     const numericValue = parseFloat(cell.m.replace(/,/g, ''));
//                     if (!isNaN(numericValue)) {
//                         SUM += numericValue;
//                     }
//                 }
//             }
//             COUNT ++
//         }
//         var SUMFINISH = "Total : Rp. "+SUM.toLocaleString();
//         var COUNTFINISH = "Jml : "+COUNT;
//         var luckysheetSheetContent = document.getElementById('luckysheet-sheet-content');
//         if (luckysheetSheetContent) {
//             var divToRemove = document.getElementById('divfkisum');
//             if (divToRemove && divToRemove.parentNode === luckysheetSheetContent) {
//                 luckysheetSheetContent.removeChild(divToRemove);
//             } else {
//                 //console.error("Element with ID 'divfkisum' not found or not a child of luckysheet-sheet-content.");
//             }  
        
//             var newDiv = document.createElement('div');
//             newDiv.setAttribute('id', 'divfkisum');
//             luckysheetSheetContent.appendChild(newDiv);

//             var newSpan = document.createElement('span');
//             newSpan.textContent = SUMFINISH;
//             newSpan.setAttribute('id', 'fkisum');
//             newDiv.appendChild(newSpan);

//             var newSpan = document.createElement('span');
//             newSpan.textContent = COUNTFINISH;
//             newSpan.setAttribute('id', 'fkicount');
//             newDiv.appendChild(newSpan);
//         } else {
//             //console.error("Element with ID 'luckysheet-sheet-content' not found.");
//         }
//     } else {
//         var luckysheetSheetContent = document.getElementById('luckysheet-sheet-content');
//         var divToRemove = document.getElementById('divfkisum');
//         if (divToRemove && divToRemove.parentNode === luckysheetSheetContent) {
//             luckysheetSheetContent.removeChild(divToRemove);
//         } else {
//             //console.error("Element with ID 'divfkisum' not found or not a child of luckysheet-sheet-content.");
//         }  
//     }
// }

export const sumDataKerja = (luckysheet, nama_column) => {
  const dk_tgl_bayar_handling_ = nama_column.indexOf("dk_tgl_bayar_handling")
  const dk_tgl_bayar_handling = getColumnPosition(dk_tgl_bayar_handling_);
  console.log("dk_tgl_bayar_handling : ",  dk_tgl_bayar_handling)

  const dk_selisih_hitungan_ = nama_column.indexOf("dk_selisih_hitungan")
  const dk_selisih_hitungan = getColumnPosition(dk_selisih_hitungan_);
  //console.log("dk_selisih_hitungan : ",dk_selisih_hitungan)
  const dk_tgl_bayar_ = nama_column.indexOf("dk_tgl_bayar_kembali")
  const dk_tgl_bayar = getColumnPosition(dk_tgl_bayar_);
  //console.log("dk_tgl_bayar : ",dk_tgl_bayar)
  
  const dk_cross_check_ = nama_column.indexOf("dk_cross_check")
  const dk_cross_check = getColumnPosition(dk_cross_check_);
  const dk_bayar_lapangan_ = nama_column.indexOf("dk_bayar_lapangan")
  const dk_bayar_lapangan = getColumnPosition(dk_bayar_lapangan_);

  var dat = luckysheet.getLuckysheetfile()[0]?.visibledatarow?.length
  var jml_baris = 0;
  var start_row = 2;
  if (dat !== undefined) {
    jml_baris = dat;
  }

  if (dk_selisih_hitungan && dk_tgl_bayar) {
    //fki
    const formula = '=TEXT(sumif(' + dk_tgl_bayar + start_row + ':' + dk_tgl_bayar + jml_baris + ',"-",' + dk_selisih_hitungan + start_row + ':' + dk_selisih_hitungan + jml_baris + '),"#,##0")';
    if (typeof luckysheet !== 'undefined') {
      luckysheet.setCellValue(0, dk_selisih_hitungan_, '=CONCATENATE("DK SELISIH HITUNGAN : ",' + formula, { f: formula })
      //luckysheet.setcellvalue(0, kolom_dk_sh, luckysheet.flowdata(), "=SUM(B2:B3)");    
      luckysheet.jfrefreshgrid();
    }
  }

  if (dk_cross_check) {
    //fki
    const formulaCROSCHEK = '=TEXT(sum(' + dk_cross_check + start_row + ':' + dk_cross_check + jml_baris+ '),"#,##0")';
    //formulaCROSCHEK)
    if (typeof luckysheet !== 'undefined') {
      luckysheet.setCellValue(0, dk_cross_check_, '=CONCATENATE("DK CROSS CHECK : ",' + formulaCROSCHEK, { f: formulaCROSCHEK })   
      luckysheet.jfrefreshgrid();
    }
  }

  if (dk_bayar_lapangan) {
    //fki
    // =CONCATENATE("DK BYR LAPANGAN : ",=TEXT(sumif(O2:O1124,"-",Q2:Q1124) - sumif(U2:U1124,"-",S2:S1124),"#,##0"))

    // Q = dk_bayar_lapangan
    // O = dk_tgl_bayar_handling
    // U = dk_tgl_bayar
    // S = dk_selisih_hitungan

    const sumif_1 ='sumif(' + dk_tgl_bayar_handling + start_row + ':' + dk_tgl_bayar_handling + jml_baris + ',"-",' + dk_bayar_lapangan + start_row + ':' + dk_bayar_lapangan + jml_baris + ')';
    const sumif_2 ='sumif(' + dk_tgl_bayar + start_row + ':' + dk_tgl_bayar + jml_baris + ',"-",' + dk_selisih_hitungan + start_row + ':' + dk_selisih_hitungan + jml_baris + ')';
    const formulaBYRLapangan = '=TEXT('+sumif_1 +' - '+ sumif_2 +',"#,##0")';
    //console.log(formulaBYRLapangan)
    if (typeof luckysheet !== 'undefined') {
      luckysheet.setCellValue(0, dk_bayar_lapangan_, '=CONCATENATE("DK BYR LAPANGAN : ",' + formulaBYRLapangan, { f: formulaBYRLapangan })  
      luckysheet.jfrefreshgrid();
    }
  }
}



function getColumnPosition(columnNumber) {
    let dividend = columnNumber + 1;
    let columnName = '';

    while (dividend > 0) {
      const modulo = (dividend - 1) % 26;
      columnName = String.fromCharCode(65 + modulo) + columnName;
      dividend = Math.floor((dividend - modulo) / 26);
    }
    return columnName;
  }


  export function getFrozen() {
    const fullURL = window.location.href;
    const nameratio = "frozen" + fullURL;
    const data = localStorage.getItem(nameratio);
    let val = 11;
    if (data) {
      const parsedData = JSON.parse(data);
      if (typeof parsedData === 'object' && parsedData !== null) {
        if (Object.keys(parsedData).length === 0) {
          val = 11;
        } else {
          const positionValue = parsedData.position;
          val = positionValue;
        }
      } else {
        val = 11;
      }
    }
    return val;
  }

  export function createRatioZoom(ration){
    const fullURL = window.location.href;
    const nameFrozen = "rationzoom" + fullURL;
    const dataToSave = { position: ration};
    localStorage.setItem(nameFrozen, JSON.stringify(dataToSave));
  }

  export function getRatioZoom() {
    const fullURL = window.location.href;
    const nameratio = "rationzoom" + fullURL;
    const data = localStorage.getItem(nameratio);
    let val = 1;
    if (data) {
      const parsedData = JSON.parse(data);
      if (typeof parsedData === 'object' && parsedData !== null) {
        if (Object.keys(parsedData).length === 0) {
          val = 1;
        } else {
          const positionValue = parsedData.position;
          val = positionValue;
        }
      } else {
        val = 1;
      }
    }
    return val;
  }

  export function saveheighrow(height){
    const fullURL = window.location.href;
    const nameFrozen = "saveheighrow" + fullURL;
    const dataToSave = { position: height};
    localStorage.setItem(nameFrozen, JSON.stringify(dataToSave));
  }

  export function getheighrow() {
    const fullURL = window.location.href;
    const nameratio = "saveheighrow" + fullURL;
    const data = localStorage.getItem(nameratio);
    let val = 40;
    if (data) {
      const parsedData = JSON.parse(data);
      if (typeof parsedData === 'object' && parsedData !== null) {
        if (Object.keys(parsedData).length === 0) {
          val = 40;
        } else {
          const positionValue = parsedData.position;
          val = positionValue;
        }
      } else {
        val = 40;
      }
    }
    return val;
  }
