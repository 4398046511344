// modal.js
import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import '../css/Modal.css';
import axios from 'axios';
import { HotTable, HotColumn } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import styleHs from '../css/Hs.module.css';
import reloadToken from '../helper/reloadtoken';
import { apiConfig } from '../helper/apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const notify_cant_update_br_cont = (message, time = 500) => {
    toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: time,
    });
};

const notify_update_br_cont = (message) => {
    toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 800,
    });
};


const ModalFilterFcl = ({ modalVisible, toggleModal, data }) => {
    const [role, setRole] = useState("");
    const [idpublic, setIDPublic] = useState("");
    const [tableHeight, setTableHeight] = useState(480);
    const hotRef = useRef(null);
    const isMounted = useRef(true);
    const [hotInstance, setHotInstance] = useState(null);
    const [token, setToken] = useState(null);
    const [newRowValue, setNewRowValue] = useState('');
    const [colHeaders, setColHeaders] = useState([]);
    const [titlefilter, setTitleFilter] = useState("");
    let nama_pt = "ROLE";
    let tipe = "1";
    var loaddata = 0;
    const hostcont = process.env.REACT_APP_API_HOST_FCL;
    const hostfile = process.env.REACT_APP_API_HOST_UPLOAD;
    var data_column = [];
    var id_data = data.id;
    const operator = ['>', '<', '=', '>=', '<=', '<>', 'contains', 'begin', 'end', 'in range'];

    const setUP = async () => {
        const hot = hotRef.current && hotRef.current.hotInstance;
        if (isMounted.current && hot) {
            setHotInstance(hot);
            const newToken = await reloadToken();
            if (isMounted.current) {
                setToken(newToken);
            }
        }
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);

    // Function to open the modal
    const openModal = () => {
        setModalIsOpen(true);
    };
      
    // Function to close the modal

    // useEffect(() => {
    //     const role = localStorage.getItem('role');
    //     setRole(role);

    //         // Call openModal when modalVisible prop is true
    // if (modalVisible) {
    //     openModal();
    //   }
    // }, [role]);

    const fetchData = async () => {
        try {   
            const newToken = await reloadToken();
            setToken(newToken);
            if (hotRef.current && token && isMounted.current) {
                const hot = hotRef.current.hotInstance;
                setHotInstance(hot);
                const res = await loadData(token);
                if (res && Array.isArray(res.data.column)) {
                    //data_column.push(res.data.column);
                    data_column.push(...res.data.column);
                    //console.log("hallo : ",res.data.column);
                } else {
                    console.error("Invalid data format or data is missing:", res.data.column);
                }
            }
        } catch (error) {
            //console.error("Error fetching data:", error);
        }
    };

    const convertToStructuredData = (dataArray, keyToCheck) => {
        const structuredData = {};
      
        // Check if the key already exists
        if (structuredData[keyToCheck]) {
          structuredData[keyToCheck].push(...dataArray.map(subArray => [...subArray]));
        } else {
          structuredData[keyToCheck] = dataArray.map(subArray => [...subArray]);
        }
      
        return structuredData;
      };

    function fetchDataValue() {
        let parsedData = [];
        const fullURL = window.location.href;
        const nameFrozen = "filtersave-" + fullURL;
        const retrievedData = localStorage.getItem(nameFrozen);
        if (retrievedData){
            parsedData = JSON.parse(retrievedData);
        }
        const hot = hotRef.current && hotRef.current.hotInstance;

        if (hot && Array.isArray(parsedData)) {
            // Find index of existing data with the same key
            const index = parsedData.findIndex(item => Object.keys(item)[0] === titlefilter);
        
            if (index !== -1) {
                const fikiArray = parsedData[index][titlefilter];
        
                // Set the values in Handsontable
                fikiArray.forEach((rowData, rowIndex) => {
                    rowData.forEach((value, colIndex) => {
                        hot.setDataAtCell(rowIndex, colIndex, value);
                    });
                });
            } else {
                console.log("Data for 'fiki' not found");
            }
        } else {
            console.log("Invalid or empty data");
        }
    }
    
    const isDataEmpty = !data || !data.nama;
    if (modalVisible) {
        document.body.classList.add('active-modal');
        fetchData();
        setTimeout(() => {
                fetchDataValue();
                var nama = getTitleFilter()
                setTitleFilter(nama);
        }, 200);
    } else {
        document.body.classList.remove('active-modal');
    }


    const newData = () => {
        const hot = hotRef.current && hotRef.current.hotInstance;
        if (hot) {
            hot.suspendRender();
            var rowIndex = hot.countRows();
            hot.alter('insert_row_below', hot.ending, 1);
            hot.resumeRender();
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
        toggleModal(); // Call the toggleModal function to close the modal
    };

    const uploadModal = () => {
        alert('Upload');
    };

    const validateArray = (arr) => {
        return (
            arr[0] !== null && arr[0] !== undefined && arr[0].length !== 0 &&
            arr[1] !== null && arr[1] !== undefined && arr[1].length !== 0
        );
    };

    const hapusfilter = () => {
        const isConfirmed = window.confirm('Yakin akan hapus data?');
            if (isConfirmed) {
                 deleteDataByTitle(titlefilter);
                 window.location.reload();
                 saveTitleFiltervalue("default");
            }else {
                return false;
            }
    }

    const newFilter = () => {
        document.getElementById("title_filter").value="";
    }

    const downloadModal = () => {
        let datasavefilter = [];
        const hot = hotRef.current && hotRef.current.hotInstance;
        if (hot) {
            const datahot = hot.getData();
            
            const validationResults = datahot.map((subArray, rowIndex) => {
                const isValidArray = validateArray(subArray);
                return {
                    rowIndex: rowIndex,
                    isValid: isValidArray
                };
            });

            validationResults.forEach(result => {
                if (result.isValid) {
                    const isDuplicate = datasavefilter.some(existingArray => JSON.stringify(existingArray) === JSON.stringify(datahot[result.rowIndex]));
                    if (!isDuplicate) {
                        datasavefilter.push(datahot[result.rowIndex]);
                    }
                }
            });

            datasavefilter.forEach((rowData, rowIndex) => {
                rowData.forEach((value, colIndex) => {
                    hot.setDataAtCell(rowIndex, colIndex, value);
                });
            });

            deleteDataByTitle(titlefilter)
            pushdatafilter(datasavefilter);
            saveTitleFilter();

            setModalIsOpen(false);
            toggleModal();

            setTimeout(() => {
                window.location.reload();
            }, 500);

        } else {
            console.log("Error ");
        }
    };

    const saveInputText = (value) => {
        setTitleFilter(value);
        const fullURL = window.location.href;
        const nameFrozen = "filtersaveTitle-" + fullURL;
        localStorage.setItem(nameFrozen, value);
      };

    function saveTitleFilter(){
        const fullURL = window.location.href;
        const nameFrozen = "filtersaveTitle-" + fullURL;
        localStorage.setItem(nameFrozen, titlefilter);
    }

    function saveTitleFiltervalue(data){
        const fullURL = window.location.href;
        const nameFrozen = "filtersaveTitle-" + fullURL;
        localStorage.setItem(nameFrozen, data);
    }

    function getTitleFilter(){
        const fullURL = window.location.href;
        const nameFrozen = "filtersaveTitle-" + fullURL;
        const retrievedData = localStorage.getItem(nameFrozen);
        return retrievedData;
    }

    function deleteDataByTitle(titlefocus) {
        const fullURL = window.location.href;
        const nameFrozen = "filtersave-" + fullURL;
        let parsedData = localStorage.getItem(nameFrozen);
        if (parsedData) {
            parsedData = JSON.parse(parsedData);
            const index = parsedData.findIndex(item => Object.keys(item)[0] === titlefocus);
    
            if (index !== -1) {
                parsedData.splice(index, 1);
                localStorage.setItem(nameFrozen, JSON.stringify(parsedData));
            } else {
                console.log(`Data for '${titlefocus}' not found`);
            }
        } else {
            console.log("Invalid or empty data");
        }
        fetchDataValue();
    }

    function pushdatafilter(datasavefilter) {
        const datalama = pulldatafilter();
        const structuredData = convertToStructuredData(datasavefilter, titlefilter);
        let existingData = datalama ? JSON.parse(datalama) : [];
        const index = existingData.findIndex(item => Object.keys(item)[0] === titlefilter);
        if (index !== -1) {
            const existingArray = existingData[index][titlefilter];
            const newDataArray = structuredData[titlefilter];
            newDataArray.forEach(newItem => {
                const existingIndex = existingArray.findIndex(existingItem => existingItem[0] === newItem[0]);
                if (existingIndex !== -1) {
                    // kalau ada upate
                    existingArray[existingIndex][1] = newItem[1];
                } else {
                    existingArray.push(newItem);
                }
            });
        } else {
            existingData.push(structuredData);
        }
        const updatedDataString = JSON.stringify(existingData);
    
        const fullURL = window.location.href;
        const nameFrozen = "filtersave-" + fullURL;
        localStorage.setItem(nameFrozen, updatedDataString);
    }
    
    function pulldatafilter(){
        const fullURL = window.location.href;
        const nameFrozen = "filtersave-" + fullURL;
        const retrievedData = localStorage.getItem(nameFrozen);
        return retrievedData;
    }

    const downloadPacketFile = () => {
        downloadPacketFile_(data.id);
    };

    const loadData = async (token) => {
        try {
            const config = apiConfig(token);
            const response = await axios.post(`${hostcont}dropdownFilter`, null, config);
            return response;
        } catch (error) {
            console.error('Error fetching column names:', error);
            return [];
        }
    };

    const downloadPacketFile_ = async (id) => {
        try {
            const response = await axios.get(`${hostcont}Downloapacketfile/${id}`, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`, // Include your token here
                },
            });

            const contentDisposition = response.headers['content-disposition'];
            const filename = contentDisposition.split('filename=')[1].replace(/"/g, '');
            const blob = new Blob([response.data], { type: 'application/zip' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const handleAfterCreateRow = async (index) => {
        // var i = parseInt(index);
        // try {
        //   const response = await axios.post(`${hostuser}Role/New`, null, apiConfig(token));
        //   if (response.data.status === 'success') {
        //     hotInstance.setDataAtCell(i, 0, response.data.data);
        //   } else {
        //     console.log('Error:', response.data.message);
        //   }
        // } catch (error) {
        //   console.error('Error:', error);
        // }
    };

    const handleAfterChange = async (changes, source) => {
        if (source === 'edit') {
            
            changes.forEach(([row, col, oldValue, newValue]) => {
                if (col === 1) {
                    const column2Value = newValue;
                    const column4CellMeta = hotRef.current.hotInstance.getCellMeta(row, 3);
                    column4CellMeta.readOnly = (column2Value !== 'in range');
                    hotRef.current.hotInstance.render();
                }
            });

            // let old_data = changes[0][2];
            // let new_data = changes[0][3];
            // if (old_data!=new_data) {
            //   setTimeout(async function () {
            //     const rowIndex = changes[0][0];
            //     const id = hotInstance.getDataAtCell(rowIndex, 0);
            //     const datapost = {
            //       "msg": hotInstance.getDataAtCell(rowIndex, 3),
            //       "col": hotInstance.getDataAtCell(rowIndex, 4),
            //       "tipe": hotInstance.getDataAtCell(rowIndex, 5),
            //     };
            //     try {
            //       const response = await axios.post(`${hostfile}Updatecomment/${id}`, datapost, apiConfig(token));
            //       if (response.data.status === 'success') {
            //         notify_update_br_cont(response.data.message)
            //       } else {
            //         const message = response.data.message;
            //         console.log('Error:', message);
            //       }
            //     } catch (error) {
            //       console.error('Error:', error);
            //     }
            //   }, 100);
            //  }
        }
    };

    const handleAfterRemoveRow = async (index, amount, physicalRows, [source]) => {
        // const id = hotInstance.getDataAtCell(index, 0);
        // try {
        //   const response = await axios.post(`${hostuser}Role/${id}`, null, apiConfig(token));
        //   if (response.data.status === 'success') {

        //   } else {
        //     const message = response.data.message;
        //     console.log('Error:', message);
        //   }
        // } catch (error) {
        //   console.error('Error:', error);
        // }
    };

    const handleInputChange = (event) => {
        //setNewRowValue(event.target.value);
    };

    const handleAddRowClick = () => {
        const valueTotalinput = newRowValue;
        const hotInstance = hotRef.current.hotInstance;
        hotInstance.suspendRender();
        hotInstance.alter('insert_row_below', hotInstance.ending, 1);
        hotInstance.resumeRender();
    };

    const handleClick = (data) => {
        if (data == "role") {
            window.location.href = "/role";
        } else if (data == "role_detail") {
            window.location.href = "/roledetail";
        } else if (data == "masteruser") {
            window.location.href = "/masteruser";
        }
    };

    const initializeForSearchColumn = () => {
        const debounceFn = (colIndex, event) => {
            const filtersPlugin = hotInstance.getPlugin('filters');
            filtersPlugin.removeConditions(colIndex);
            filtersPlugin.addCondition(colIndex, 'contains', [event.target.value]);
            filtersPlugin.filter();
        };

        const addEventListeners = (input, colIndex) => {
            input.addEventListener('keydown', (event) => {
                debounceFn(colIndex, event);
            });
        };

        const addInput = (col, TH) => {
            if (typeof col !== 'number') {
                return col;
            }

            if (col >= 0 && TH.childElementCount < 2 && col == 1) {
                TH.appendChild(getInitializedElements(col));
            }
        };

        const getInitializedElements = (colIndex) => {
            const div = document.createElement('div');
            const input = document.createElement('input');
            addEventListeners(input, colIndex);
            div.appendChild(input);
            return div;
        };

        const doNotSelectColumn = (event, coords) => {
            if (coords.row === -1 && event.target.nodeName === 'INPUT') {
                event.stopImmediatePropagation();
                hotInstance.deselectCell();
            }
        };
        try {
            hotInstance.updateSettings({
                afterGetColHeader: addInput,
                beforeOnCellMouseDown: doNotSelectColumn,
            });
        } catch (error) {
            //console.error('Error updating settings:', error);
        }
    }

    const columns = [
        { type: 'dropdown', title: '<b>Nama Kolom</b>', source: data_column },
        { type: 'dropdown', title: '<b>Operator</b>', source: operator },
        { type: 'text', title: '<b>Value</b>', align: 'center' },
        { type: 'text', title: '<b>Value</b>', align: 'center' },
    ];

    const customRenderer = (instance, td, row, col, prop, value, cellProperties) => {
        if (typeof value === 'string') {
            td.innerHTML = value;
        }
    };

    return createPortal(
        modalVisible ? (
            <div className="modal" >
                <div onClick={closeModal} className="overlay"></div>
                <div className="modal-content-detail-filter">
                    <div><h6>Filter Column</h6>
                        <p style={{ marginTop: '-5px' }}>Nama Filter :
                            <input className='inputTextFilter' id="title_filter" type="text" value={titlefilter} onChange={(e) => saveInputText(e.target.value)} />
                            <button className="btn-modal-filter btn_add" onClick={newFilter}>
                            New +
                    </button>
                        </p></div>
                    <div>
                        <HotTable
                            ref={hotRef}
                            columns={columns}
                             settings={{
                            //     afterCreateRow: handleAfterCreateRow,
                                 afterChange: handleAfterChange,
                            //     beforeRemoveRow: handleAfterRemoveRow
                            }}
                            allowInsertRow={true}
                            //data={data_column}
                            renderer={customRenderer}
                            contextMenu={true}
                            rowHeaders={true}
                            filters={true}
                            dropdownMenu={false}
                            outsideClickDeselects={false}
                            allowInsertColumn={true}
                            rowHeights={25}
                            autoRowSize={false}
                            manualColumnResize={true}
                            fixedColumnsStart={5}
                            height={220}
                            width={705}
                            colHeaders={colHeaders}
                            colWidths={[120, 70, 175, 175, 70, 95, 95]}
                            licenseKey="non-commercial-and-evaluation"
                            hiddenColumns={{
                                copyPasteEnabled: true,
                                indicators: true,
                                columns: [0, 4, 5]
                            }}
                        >
                            {colHeaders.map((colName, index) => (
                                <HotColumn key={index} renderer={"html"} editor={false} />
                            ))}
                        </HotTable>
                    </div>
                    <p>
                        {/* Your modal content */}
                    </p>
                    {/* <button className="btn_close-modal btn_upload" onClick={uploadModal}>
                        Upload
                    </button>
                    <button className="btn_close-modal btn_download" onClick={downloadModal}>
                        Download
                    </button> */}

                    {titlefilter != "default" ? (
                    <button className="btn-modal-filter btn_add" onClick={newData}>
                        Add row
                    </button>
                    ) : ""}
                    {titlefilter != "default" ? (
                    <button className="btn-modal-filter btn_download" onClick={downloadModal}>
                        Save
                    </button>
                    ) : ""}
                    <button className="btn-modal-filter btn_close" onClick={closeModal}>
                        Close
                    </button>
                    {titlefilter != "default" ? (
                    <button className="btn-modal-filter btn_delete" onClick={hapusfilter}>
                        Delete
                    </button>
                    ) : ""}

                    {role == "TIMKONSEP" ? (
                        <button className="btn-modal-filter btn_upload" onClick={downloadPacketFile}>
                            Download all
                        </button>
                    ) : ""}

                </div>
            </div>
        ) : null,
        document.body
    );
};

export default ModalFilterFcl;