const messages = (data="") => ({
    column: {
      sukses: {
        s1: "",
      },
      error: {
        e1: "Error fetching column names: ",
      },
    },
  
    value: {
      sukses: {
        s1: "",
      },
      error: {
        e1: "Error fetching cell data: ",
      },
    },
  
    file: {
      sukses: {
        s1: "",
      },
      error: {
        e1: "Error fetching status file : ",
      },
    },
  
    command: {
      sukses: {
        s1: "Note sukses di tambahkan",
      },
      error: {
        e1: "Gagal menambahkan Note : ",
      },
    },
  
    update: {
      sukses: {
        s1: "Data <b>"+data+"</b> berhasil di input",
      },
      error: {
        e1: "Gagal menambahkan Note : ",
      },
    },
  
    all: {
      suksess : {
        s1 : "Update data sukses",
        paste : "test"
      },
      error : {
        error_update : "Update Gagal! : ",
        error_update_1 : "Gagal! kode #1. Data tidak valid ",
        error_update_2 : "Gagal!  kode #2. Silahkan Refresh halaman ini.",
        error_update_3 : "Gagal!  kode #3. Silahkan Refresh halaman ini.",
      },
      konfirm : {
        paste : "test"
      },
    },
  
  
  });
  export default messages;