// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonHS {
    border: none;
    color: white;
    padding: 5px 6px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    transition-duration: 0.4s;
    border-radius: 3px;
}
  
  .button1 {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }

  .buttonAdd{
    background-color: #40be58; /* Green */
  }

  .buttonAdd:hover {
    background-color: #2b963f;
    color: white;
  }

  .button2:hover {
    background-color: #008CBA;
    color: white;
  }
  .buttonPilih{
    background-color: #4569c2; /* Green */
  }

  .buttonHapus{
    background-color: #d02316; /* Green */
  }

  .buttonHapus:hover {
    background-color: #91130a;
    color: white;
  }

  .buttonHapusSelected{
    background-color: #d02316; /* Green */
  }

  .buttonHapusSelected:hover {
    background-color: #91130a;
    color: white;
  }

  .max-width-table {
    max-width: 200px; /* Adjust the maximum width as needed */
  }`, "",{"version":3,"sources":["webpack://./src/css/Element.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,qBAAqB;IACrB,qBAAqB;IACrB,eAAe;IACf,eAAe;IACf,eAAe;IACf,yBAAyB;IACzB,kBAAkB;AACtB;;EAEE;IACE,uEAAuE;EACzE;;EAEA;IACE,yBAAyB,EAAE,UAAU;EACvC;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;EACA;IACE,yBAAyB,EAAE,UAAU;EACvC;;EAEA;IACE,yBAAyB,EAAE,UAAU;EACvC;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB,EAAE,UAAU;EACvC;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,gBAAgB,EAAE,uCAAuC;EAC3D","sourcesContent":[".buttonHS {\n    border: none;\n    color: white;\n    padding: 5px 6px;\n    text-align: center;\n    text-decoration: none;\n    display: inline-block;\n    font-size: 16px;\n    margin: 4px 2px;\n    cursor: pointer;\n    transition-duration: 0.4s;\n    border-radius: 3px;\n}\n  \n  .button1 {\n    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);\n  }\n\n  .buttonAdd{\n    background-color: #40be58; /* Green */\n  }\n\n  .buttonAdd:hover {\n    background-color: #2b963f;\n    color: white;\n  }\n\n  .button2:hover {\n    background-color: #008CBA;\n    color: white;\n  }\n  .buttonPilih{\n    background-color: #4569c2; /* Green */\n  }\n\n  .buttonHapus{\n    background-color: #d02316; /* Green */\n  }\n\n  .buttonHapus:hover {\n    background-color: #91130a;\n    color: white;\n  }\n\n  .buttonHapusSelected{\n    background-color: #d02316; /* Green */\n  }\n\n  .buttonHapusSelected:hover {\n    background-color: #91130a;\n    color: white;\n  }\n\n  .max-width-table {\n    max-width: 200px; /* Adjust the maximum width as needed */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
