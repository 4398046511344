import React from "react";

const HeaderCsa = ({
  tg_collapse_header,
  isCollapsedHeader,
  select_pel_tujuan,
  val_pel_tujuan,
  dataHeader,
  hi_kantor_pabean,
  val_kantor_pabeban,
  select_jenis_pib,
  val_jenis_pib,
  hi_jenis_pib,
  select_jenis_import,
  val_jenis_import,
  change,
  select_cara_bayar,
  val_cara_bayar,
  hi_cara_bayar,
}) => {
  return (
    <div
      className="C_form-container"
      style={{
        marginTop: "55px",
        overflow: "visible",
      }}
    >
      <div
        className="title_form"
        onClick={tg_collapse_header}
        style={{ cursor: "pointer" }}
      >
        Header
      </div>
      <div className={isCollapsedHeader ? "hidden" : "visible"}>
        <div className="space"></div>
        <div style={{ display: "flex", alignItems: "start" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div
                className="C_form-row"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "baseline",
                }}
              >
                <table border="0">
                  <tr valign="top">
                    <td className="tar">
                      <label className="C_form-label">Pelabuhan Tujuan</label>
                    </td>
                    <td className="tal">
                      <select
                        ref={select_pel_tujuan}
                        className="js-example-basic-multiple C_select-input_table"
                        value={val_pel_tujuan || ""}
                      >
                        <option key={0} value={""}>
                          {" - "}
                        </option>
                        {dataHeader.pelabuhanTujuan.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td className="tar">
                      <label className="C_form-label">Kantor Pabean</label>
                    </td>
                    <td className="tal">
                      <input
                        type="text"
                        placeholder="Kantor pabean"
                        onChange={hi_kantor_pabean}
                        value={val_kantor_pabeban}
                        className="C_form-input margin-left-0"
                        style={{ minWidth: "100%" }}
                      />
                    </td>
                  </tr>
                  <tr valign="top">
                    <td className="tar">
                      <label className="C_form-label">Jenis PIB</label>
                    </td>
                    <td className="tal">
                      <select
                        ref={select_jenis_pib}
                        className="js-example-basic-multiple C_select-input_table"
                        value={val_jenis_pib || ""}
                        onChange={hi_jenis_pib}
                      >
                        <option key={0} value={""}>
                          {" - "}
                        </option>
                        {dataHeader.pib.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                      <div className="space_input"></div>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td className="tar">
                      <label className="C_form-label">Jenis Import</label>
                    </td>
                    <td className="tal">
                      <select
                        ref={select_jenis_import}
                        className="js-example-basic-multiple C_select-input_table"
                        value={val_jenis_import || ""}
                        onChange={change}
                      >
                        <option key={0} value={""}>
                          {" - "}
                        </option>
                        {dataHeader.impor.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                      <div className="space_input"></div>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td className="tar">
                      <label className="C_form-label">Cara Bayar</label>
                    </td>
                    <td className="tal">
                      <select
                        ref={select_cara_bayar}
                        className="js-example-basic-multiple C_select-input_table"
                        value={val_cara_bayar || ""}
                        onChange={hi_cara_bayar}
                      >
                        <option key={0} value={""}>
                          {" - "}
                        </option>
                        {dataHeader.caraBayar.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                      <div className="space_input"></div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderCsa;
