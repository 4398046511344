import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import $ from "jquery";
import "../../css/Select2.css";
import "../../css/Login.css";
import "../../css/Materialize.css";
import "../../css/Style.css";
import "../../css/new.css";
import reloadToken from "../../helper/reloadtoken";
import helperFilter from "../../helper/helperfilter";
import Header from "../../template/Header";
import { apiConfig } from "../../helper/apiConfig";
import {
  hide,
  bikinInfoSum,
  sumDataKerja,
  getFrozen,
  createRatioZoom,
  getRatioZoom,
  saveheighrow,
  getheighrow,
  removeComma,
  getFilterPageAll,
  getFilterPageAllJson,
} from "../../helper/hide";
import { ToastContainer, toast } from "react-toastify";
import {
  dataconfig,
  showtoolbarConfig,
  ct,
} from "../../helper/luckysheetHelper";
import "react-toastify/dist/ReactToastify.css";
import Modal from "./../modaldetail";
import Modalpreview from "./../modalpreview";
import Convertformatlucky from "../../helper/convertformatlucky";
import messages from "../../helper/messages";
import Loading from "../ceisa/ceisaview/Ld";
import MenuBtn from "../ceisa/ceisaview/MenuBtn";
import {
  go_to_home,
  go_to_master_entitas,
  go_to_entitas,
} from "../ceisa/ceisaview/go";
import HarbourModal from "./ceisaview/HarbourModal";

const notify_cant_update_br_cont = (message, time = 1200) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: time,
  });
};
const notify_update_br_cont = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 800,
  });
};
//test

const tipedata = "AJU";

const historyback = "aju";
// END REPLACE INI SAJA
const hostaju = process.env.REACT_APP_API_HOST_CEISA;

const API_COLUMN = hostaju + "ReactListAjuColumn";
const API_VALUES = hostaju + "ReactListAjuDetail";
const API_PASTE = hostaju + "ReactUpdateBiayaContpaste";
// const API_REK_DROPDOWN = hostdrop + "MasterDropdownReact"

var luckysheet;
let savedColumnWidths;
let data_all = [];
let data_all_default = [];
let data_all_reset = [];

const Aju = () => {
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(false);
  const config = apiConfig(token);
  var b = 0;
  const pagecolumn = "AJUCOLUMN";
  const pagerow = "AJUROW";
  var options = null;
  var type_row = "";
  var type_jml = 0;
  var end_column_freze = 1;
  var size_scroll_left = 0;
  var defaultRowHeight = 27;
  var defaultratio = 1;
  var nama_column = [];
  let datahasil = [];
  let cellDataJsonA = [];
  let totaldata = 0;

  const [modalVisible, setModalVisible] = useState(false);
  const [isModalHarbour, setIsModalHarbour] = useState(false);
  const [datamodal, setDataModal] = useState(false);
  const [modalVisible_V, setModalVisible_V] = useState(false);
  const [datamodal_V, setDataModal_V] = useState(false);
  const selectForPT = useRef(null);
  const [TokenNew, setToken] = useState(null);
  const { param1, param2 } = useParams();
  const [selectPT, setselectPT] = useState("");
  const [selectNamaPT, setselectNamaPT] = useState("");
  const [optionsSearchPort, setoptionsSearchPort] = useState([]);
  const [searchvaluedata, setselectContainer] = useState(getFilterPageAll());
  const [namaColumn, setNamaColumn] = useState();
  const [datacell, set_datacell] = useState([]);

  // harbour lists & ref
  const [listHarbours, setListHarbours] = useState([]);
  const [selected_ID_pelabuhan, setSelectedIdPelabuhan] = useState("");
  const select_pel_tujuan = useRef(null);
  const [pabean_office_val, setPabeanVal] = useState("");

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const toggleModal_V = () => {
    setModalVisible_V(!modalVisible_V);
  };

  const toggleModalHarbour = () => setIsModalHarbour((prev) => !prev);

  const handleChange = () => {};

  const handleBackClick = () => {
    const hostUrl = window.location.origin;
    window.location.href = hostUrl + "/" + historyback;
  };

  function findDataByCondition(data, condition) {
    if (!Array.isArray(data) || data.length === 0) {
      console.log("Data array is empty.");
      return [];
    }
    const foundData = [];
    data.forEach((item) => {
      if (condition(item)) {
        foundData.push(item);
      }
    });

    // Return the found data
    return foundData;
  }

  const fkisearch = (event) => {
    const modifiedValue = event.target.value;
    setselectContainer(modifiedValue);
    if (modifiedValue === "") {
      localStorage.removeItem();
      window.location.reload();
    } else {
      localStorage.setItem(modifiedValue);
    }
  };

  function resetsearch() {
    localStorage.removeItem();
    window.location.reload();
  }

  function search() {
    console.log("namaColumn ", namaColumn);
    if (searchvaluedata == "") {
    } else {
      const searchData = findDataByCondition(
        data_all.data,
        (item) => item.v && item.v.m && item.v.m.includes(searchvaluedata)
      );
      if (searchData.length > 0) {
        data_all_default.data = searchData;
        var jml_all = 0;
        var jml_cari = 0;
        var sheet = luckysheet.getLuckysheetfile();
        var data_cari = luckysheet.find(searchvaluedata);
        if (sheet) {
          jml_all = sheet[0].data.length;
        }
        if (data_cari) {
          jml_cari = data_cari.length;
        }

        data_all = sheet[0].data;
        console.log(data_cari);
        let rowsToDelete = [];
        if (data_all && data_all.length) {
          data_all.forEach((row) => {
            row.forEach((cell) => {
              let found = false;
              if (data_cari && data_cari.length > 0) {
                data_cari.forEach((cariItem) => {
                  if (cell.row === cariItem.row) {
                    found = true;
                  }
                });
                if (!found) {
                  if (cell.row > 0 && !rowsToDelete.includes(cell.row)) {
                    var id = luckysheet.getCellValue(cell.row, 0);
                    console.log(id);
                    rowsToDelete.push(cell.row);
                  }
                }
              }
            });
          });
        } else {
          console.error("data_all is not defined or has no length property");
        }

        rowsToDelete.sort((a, b) => b - a);
        rowsToDelete.forEach((row) => {
          luckysheet.deleteRow(row, row);
        });
      } else {
        console.log("No matching data found.");
      }
    }
  }

  function callFunctionlocalstorage() {
    const frozenPosition = getFrozen();
    end_column_freze = frozenPosition;

    const ratiozoom = getRatioZoom();
    if (ratiozoom == null || ratiozoom == "" || ratiozoom == undefined) {
      defaultratio = 1;
    } else {
      defaultratio = ratiozoom;
    }

    const h_c = getheighrow();
    if (h_c == null || h_c == "") {
      defaultRowHeight = 28;
    } else {
      defaultRowHeight = h_c;
    }
  }

  // handleChange list harbour
  const HandleUpdatePabeanName = (event) => {
    getOfficePabean(event.target.value);
    setSelectedIdPelabuhan(event.target.value);
  };

  const handleSubmitCreateAju = (event) => {
    event.preventDefault();
    createAju();
  };

  // get harbour lists
  const getHarbour = async () => {
    const config = apiConfig(token);
    try {
      const response = await axios.get(
        `${hostaju}pelabuhan/get-select?type=tujuan`,
        config
      );
      setListHarbours(response.data.data);
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
    }
  };

  // get pabean office
  const getOfficePabean = async (id) => {
    if (token && id) {
      const config = apiConfig(token);
      try {
        const response = await axios.get(
          `${hostaju}kantor-pabean/${id}`,
          config
        );
        setPabeanVal(response.data.data);
      } catch (error) {
        setPabeanVal("");
      }
    }
  };

  const createAju = async () => {
    if (window.confirm("Yakin akan membuat dokument baru?")) {
      setIsLoading(true);
      const newToken = await reloadToken();
      setToken(newToken);
      if (newToken) {
        const config = apiConfig(newToken);
        setTimeout(function () {
          const payload = { kode_pelabuhan: selected_ID_pelabuhan };
          axios
            .post(`${hostaju}docpabean`, payload, config)
            .then((response) => {
              if (response.data.status === "success") {
                const noaju = response.data.data.no_aju;
                if (noaju !== "") {
                  notify_update_br_cont("Create aju sukses!");
                  go_to_detail(noaju);
                }
              }
              setSelectedIdPelabuhan("");
              setIsModalHarbour(false);
              setPabeanVal("");
              setSelectedIdPelabuhan("");
            })
            .catch((error) => {
              console.error(
                "Error fetching data:",
                error.response.data.message
              );
              notify_cant_update_br_cont(error.response.data.message);
              setIsLoading(false);
            });
        }, 500);
      }
    }
  };

  const go_to_detail = (nomor_aju) => {
    setTimeout(() => {
      window.location.href = `/ceisa/${nomor_aju}`;
    }, 1500);
  };

  const handleSelectChange = (event) => {
    const selectedOption =
      event.target.options[event.target.selectedIndex].text;
    const selectValue = event.target.options[event.target.selectedIndex].value;
  };

  $(selectForPT.current).on("change", handleSelectChange);

  // Call the async function and set the options when the data is retrieved
  const setDropdownOptions = async () => {
    // const options = await fetchDropdownOptions();
    // setoptionsSearchPort(options);
  };

  //set lebar kolom
  savedColumnWidths = JSON.parse(localStorage.getItem(pagecolumn));
  if (
    savedColumnWidths === null ||
    Object.keys(savedColumnWidths).length === 0
  ) {
    savedColumnWidths = null;
  } else {
    savedColumnWidths = savedColumnWidths;
  }

  // async function cekFile() {
  //   let cekfile = await cekStatusFile();
  //   if (cekfile.length !== 0) {
  //     if (cekfile.count > 0) {
  //       setTimeout(async function () {
  //         var dataKirimModal = {
  //           id: "1",
  //           nama: "test"
  //         };
  //         setDataModal_V(dataKirimModal);
  //         toggleModal_V();
  //       }, 2000)
  //     }
  //   }
  // }

  //1
  function CreateTable(pageData) {
    console.log("data ", pageData);
    callFunctionlocalstorage();
    const page0 = pageData || [];
    const merged = [...page0.data];
    const mergedformula = [...page0.dataformula];

    const mergedDataRO = page0.readonly;
    options = {
      container: "luckysheet",
      title: tipedata,
      lang: "en",
      showinfobar: false,
      allowCopy: true,
      allowEdit: true,
      column: 0,
      filter: {
        ref: "A1:C1",
      },
      forceCalculation: true,
      showtoolbarConfig: [],
      cellRightClickConfig: showtoolbarConfig,
      data: [
        {
          name: "Sheet",
          color: "",
          row: 1,
          index: "0",
          celldata: merged,
          calcChain: mergedformula,
          defaultRowHeight: defaultRowHeight,
          config: {
            columnlen: savedColumnWidths,
            colhidden: {
              0: false,
            },
            rowlen: {
              0: 35,
            },
            authority: {
              sheet: 1,
              hintText: "Kolom ini tidak bisa anda edit",
              allowRangeList: [
                {
                  name: "area",
                  sqref: mergedDataRO,
                },
              ],
            },
          },
        },
      ],
      enableAddRow: false,
      textWrapMode: true,
      hook: {
        cellMousedown: function (cell, postion, sheetFile, ctx) {
          var dat = postion.r + "" + postion.c;
          if (type_row == dat) {
            type_jml++;
          } else {
            type_jml = 0;
          }
          type_row = postion.r + "" + postion.c;

          if (type_jml > 0) {
            if (cell.ct.t == "l") {
              const id = luckysheet.getCellValue(postion.r, 0);
              const judul = luckysheet.getCellValue(0, postion.c);
              const url = cell.url;
              var dataKirimModal = {
                id: id,
                nama: judul,
                action: url,
              };
              setDataModal(dataKirimModal);
              toggleModal();
            } else if (cell.ct.t == "ur") {
              const id = luckysheet.getCellValue(postion.r, 0);
              const judul = luckysheet.getCellValue(0, postion.c);
              const url = cell.url;
              var dataKirimModal = {
                id: id,
                nama: judul,
              };
              window.location.href = url;
            }
          }
        },
      },
    };

    options.loading = {
      image: () => {
        return `<svg viewBox="25 25 50 50" class="circular">
          <circle cx="50" cy="50" r="20" fill="none"></circle>
          </svg>`;
      },
      imageClass: "loadingAnimation",
    };

    if (totaldata > 0) {
      luckysheet.create(options);
    } else {
      notify_cant_update_br_cont("Error, Belum ada data!", 2500);
    }
  }

  function validasidropdown(kolom, data) {
    return true;
  }

  function UpdateFromPaste(datax) {
    console.log("POST request successful:", datax);
    axios
      .post(`${API_PASTE}`, datax, config)
      .then((response) => {
        if (response.data.status == "success")
          notify_update_br_cont(response.data.message);
      })
      .catch((error) => {
        console.error("Error fetching data:", error.response.data.message);
        notify_cant_update_br_cont(error.response.data.message);
        // luckysheet.setCellValue(r, c, "");
        luckysheet.refresh();
      });
  }

  function isValidFormula(value) {
    const formulaPattern = /^=([A-Za-z]+\d+\+)*[A-Za-z]+\d+$/;
    return formulaPattern.test(value);
  }

  function filter_data(c, atas, kiri, tinggi) {
    const datakolom = nama_column[c];

    $("#luckysheet-rich-text-editor").on("keyup", function () {
      const searchText = $("#luckysheet-rich-text-editor").text().toUpperCase();
      $("#luckysheet-dataVerification-dropdown-List").hide();
      $(".dropdown-List-item").each(function () {
        const itemText = $(this).text().toUpperCase();
        if (itemText.indexOf(searchText) > -1) {
          $(this).show();
        } else {
          $(this).hide();
        }
      });

      // fiki
      const editorHeight = $("#luckysheet-rich-text-editor").outerHeight();
      const topValue = parseFloat(
        $("#luckysheet-cell-selected-focus").css("top")
      );
      const leftValue = parseFloat($("#luckysheet-input-box").css("left"));

      $("#luckysheet-dataVerification-dropdown-List").css({
        display: "block",
        // left: leftValue - 44 + 'px',
        // top: topValue + editorHeight + editorHeight - 5 + 'px',
        right: "auto",
        left: kiri + "px",
        top: atas + tinggi + "px",
      });
    });
  }
  //2
  $(document).on("click", ".luckysheet-cols-menuitem", function () {
    helperFilter(11);
  });

  useEffect(() => {
    //TYPE
    luckysheet = window.luckysheet;

    const getIdFromUrl = () => {
      const urlParts = window.location.href.split("/");
      const id = urlParts[urlParts.length - 1];
      return id;
    };
    setselectPT(getIdFromUrl());
    setDropdownOptions();

    // cekFile();
    // const intervalId = setInterval(cekFile, 3600000);//1 jam

    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 100));
      const newToken = await reloadToken();
      setToken(newToken);
    };

    new Promise((resolve) => setTimeout(resolve, 100));
    const getColumnNames = async () => {
      try {
        const config = apiConfig(token);
        const response = await axios.post(`${API_COLUMN}`, null, config);
        setNamaColumn(response.data.column);
        return response.data.column;
      } catch (error) {
        console.error(messages().column.error.e1, error);
        return [];
      }
    };

    const getColumnNames_c = async () => {
      const options = await getColumnNames();
      return options;
    };

    const getCellData = async (data, savedColumnWidths, urutanload) => {
      try {
        const response = await axios.post(
          `${API_VALUES}`,
          { data, savedColumnWidths, urutanload },
          config
        );
        return response;
      } catch (error) {
        console.error(messages().value.error.e1, error);
        return [];
      }
    };

    const getCellData_c = async () => {
      const options = await getCellData();
      return options;
    };

    getColumnNames_c()
      .then((result) => {
        nama_column = result;
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    function isObjectEmpty(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    }

    async function loaddata(offset) {
      try {
        cellDataJsonA = await getCellData_c(
          datahasil,
          savedColumnWidths,
          offset
        );
        if (cellDataJsonA.length === 0) {
          return {
            data: [{}],
            dataformula: [{}],
          };
        } else {
          if (isObjectEmpty(cellDataJsonA.data)) {
            return {
              data: [{}],
              dataformula: [{}],
            };
          } else {
            totaldata = totaldata + cellDataJsonA.data.totaldata + 1;
            Convertformatlucky(cellDataJsonA);
          }
          data_all = cellDataJsonA.data;
          setselectNamaPT(cellDataJsonA.data.ot_data);
          return data_all;
        }
      } catch (error) {
        console.error("Error loading data:", error);
        return {
          data: [{}],
          dataformula: [{}],
        };
      }
    }

    const loaddata_c = async () => {
      const options = await loaddata(0); // Pass the offset here
      return options;
    };

    loaddata_c()
      .then((result) => {
        data_all_default = JSON.parse(JSON.stringify(result));
        data_all_reset = JSON.parse(JSON.stringify(result));
        CreateTable(result);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    function getColumnPosition(columnNumber) {
      let dividend = columnNumber + 1;
      let columnName = "";

      while (dividend > 0) {
        const modulo = (dividend - 1) % 26;
        columnName = String.fromCharCode(65 + modulo) + columnName;
        dividend = Math.floor((dividend - modulo) / 26);
      }
      return columnName;
    }

    const luckysheetContainer = document.getElementById("luckysheet");
    luckysheetContainer.addEventListener("scroll", () => {
      const scrollLeft = luckysheetContainer.scrollLeft;
      const frozenColumns = document.querySelectorAll(
        ".luckysheet-cell.luckysheet-cell-frozen"
      );
      frozenColumns.forEach((column) => {
        column.style.transform = `translateX(${scrollLeft}px)`;
      });
    });

    function isMobileDevice() {
      const mobileScreenWidth = 768;
      return window.innerWidth < mobileScreenWidth;
    }

    setTimeout(function () {
      var calcChainLength = 0;
      if (totaldata > 0) {
        var total_data = luckysheet.getAllSheets()[0].data.length;
        calcChainLength = total_data + 1;
        luckysheet.setHorizontalFrozen(true, { range: "B1" });
        if (end_column_freze > 1) {
          luckysheet.setBothFrozen(true, {
            range: { row_focus: 0, column_focus: end_column_freze },
          });
        }
        helperFilter(11);
        const mobileScreenWidth = 768;
        if (window.innerWidth <= mobileScreenWidth) {
          // Do not set sheet zoom if the display is small
        } else {
          luckysheet.setSheetZoom(defaultratio);
        }
        setleftScrollbarstart();
      }
    }, 500);

    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    } else {
      // If token is not found in localStorage, reload it
      reloadToken().then((newToken) => {
        setToken(newToken);
        // Store the token in localStorage for future use
        localStorage.setItem("token", newToken);
      });
    }

    fetchData();
    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log("Tab is hidden");
        luckysheet.refresh();
      } else {
        console.log("Tab is visible");
      }
    };
    getHarbour();

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      // clearInterval(intervalId);
      $(selectForPT.current).off("change", handleSelectChange);
    };
  }, []);

  const convertToAlphabet = (number) => {
    let result = "";
    while (number >= 0) {
      result = String.fromCharCode(65 + (number % 26)) + result;
      number = Math.floor(number / 26) - 1;
    }
    return result;
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  function setleftScrollbar(kiri) {
    const luckysheetContainerX = document.getElementById(
      "luckysheet-scrollbar-x"
    );
    luckysheetContainerX.addEventListener("scroll", () => {
      if (luckysheetContainerX.scrollLeft > 1) {
        document
          .getElementById("luckysheet-dataVerification-dropdown-btn")
          .style.setProperty("display", "none", "important");
        size_scroll_left = luckysheetContainerX.scrollLeft;
        const arrowCanvas = document.getElementById("arrowCanvas-overshow");
        if (arrowCanvas !== null) {
          document.getElementById("luckysheet-postil-overshow").style.display =
            "none";
        }
      }
    });
    if (kiri) {
      size_scroll_left = 0.5;
      luckysheetContainerX.scrollLeft = size_scroll_left;
    } else {
      luckysheetContainerX.scrollLeft = size_scroll_left;
    }
  }

  function setleftScrollbarstart() {
    const luckysheetContainerX = document.getElementById(
      "luckysheet-scrollbar-x"
    );
    luckysheetContainerX.addEventListener("scroll", () => {
      if (luckysheetContainerX.scrollLeft > 1) {
        size_scroll_left = luckysheetContainerX.scrollLeft;
      }
      luckysheetContainerX.scrollLeft = size_scroll_left;
      // console.log("SCROLL AUTO", luckysheetContainerX.scrollLeft);
    });
  }

  const luckyCss = {
    margin: "120px 0px 0px 0px",
    padding: "0px",
    position: "absolute",
    width: "100%",
    height: "90%",
    left: "0px",
    top: "0px",
    overflowX: "auto",
  };
  return (
    <div>
      {isLoading && <Loading />}
      <Header title="LIST DATA AJU" subtitle={"Daftar Aju Ceisa"} />
      <ToastContainer />
      <HarbourModal
        isOpen={isModalHarbour}
        toggleModal={toggleModalHarbour}
        handleSubmit={handleSubmitCreateAju}
        listHarbours={listHarbours}
        select_pel_tujuan={select_pel_tujuan}
        HandleUpdatePabeanName={HandleUpdatePabeanName}
        pabean_office_val={pabean_office_val}
      />
      <MenuBtn
        go_to_home={go_to_home}
        toggleModalHarbour={toggleModalHarbour}
        go_to_master_entitas={go_to_master_entitas}
        go_to_entitas={go_to_entitas}
      />
      <div id="luckysheet" style={luckyCss} />
      <Modal
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        data={datamodal}
      />
      <Modalpreview
        modalVisible={modalVisible_V}
        toggleModal={toggleModal_V}
        data={datamodal_V}
      />
    </div>
  );
};

export default Aju;
