import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { encrypt, decrypt } from '../helper/Decrypte';
import $, { data } from 'jquery';
import '../css/StylesHeader.css';
import '../css/HorizontalMenu.css'; // Import your custom CSS for the menu
import { apiConfig } from '../helper/apiConfig';
import reloadtokenselect from '../helper/reloadtokenselect';
const mainhost = process.env.REACT_APP_API_HOST_MASTER;
const host = process.env.REACT_APP_API_HOST_COLOR_COMMENT;
const master = process.env.REACT_APP_API_HOST_DROPDOWN;
const API_PT_DROPDOWN = master + "dropdownuser"
const API_SET_USER = master + "dropdownuser"

const HeaderMenu = ({ title, subtitle, updateTitle }) => {
  // console.log(title);
  // console.log(updateTitle);
  const [menuData, setMenuData] = useState([]);
  const [pengguna, setPengguna] = useState("");
  const [role, setRole] = useState("");
  const [currentDate, setCurrentDate] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const selectForPT = useRef(null);
  const [selectPT, setselectUser] = useState('');
  const [optionsSearchPort, setoptionsSearchPort] = useState([]);

  const token = localStorage.getItem('token');
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  function toSentenceCase(str) {
    if (typeof str !== 'string') return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const toggleDropdown = () => {
    setShowDropdown(prevState => !prevState);
  };

  const handleChange = () => {

  }

  const fetchDropdownOptions = async () => {
    try {
      const config = apiConfig(token);
      const response = await axios.post(`${API_PT_DROPDOWN}`, null, config);
      return response.data;
    } catch (error) {
      console.error('Error fetching column names:', error);
      return [];
    }
  };

  // Call the async function and set the options when the data is retrieved
  const setDropdownOptions = async () => {
    const options = await fetchDropdownOptions();
    setoptionsSearchPort(options);
  };

  const fetchData = async () => {
    await new Promise((resolve) => setTimeout(resolve, 100));
    const newToken = await reloadtokenselect("", "")

  };

  const handleSelectChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex].text;
    const selectValue = event.target.options[event.target.selectedIndex].value;

    if (selectValue != "") {
      setTimeout(() => {

        setselectUser(selectValue);
        reloadtokenselect(selectValue)
        // setselectNamaPT(selectedOption);
        // localStorage.setItem(savePT, selectValue);
        // localStorage.setItem(saveNamaPT, selectedOption);
        // const newId = selectValue;

        // const currentUrl = window.location.href;
        // const parts = currentUrl.split('/');
        // parts[parts.length - 1] = newId;
        // const newUrl = parts.join('/');
        // window.history.replaceState(null, '', newUrl);
        // window.location.reload();
      }, 0);
    }
  };

  $(selectForPT.current).on('change', handleSelectChange);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const pengguna = localStorage.getItem('pengguna');
    const pengguna_id = localStorage.getItem('user');
    const role = localStorage.getItem('role');
    setselectUser(decrypt(pengguna_id))
    setPengguna(toSentenceCase(pengguna));
    setRole(role);
    if (token) {
      getColumnNames();
      setDropdownOptions();
    }

    const updateDate = () => {
      const today = new Date();
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const formattedDate = today.toLocaleDateString('ID', options);
      setCurrentDate(formattedDate);
    };
    updateDate();
    const intervalId = setInterval(updateDate, 1000);
    return () => clearInterval(intervalId);

  }, []);


  const getColumnNames = async () => {
    try {
      const response = await axios.post(`${host}ReactMenu`, null, config);
      setMenuData(response.data); // Set the menuData state with the fetched data
      //console.log('test data:', response.data);
    } catch (error) {
      console.error('Error fetching column names:', error);
    }
  };


  const selectStyle = {
    // Define your custom CSS styles here
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '2px',
    fontSize: '16px',
    width: '100px',
    height: '10%',
    marginTop: '5px',
  };
  const labelStyel = {
    marginTop: '22px',
  };

  return (
    <header key="1" id="1">
      <div className="container-main-view">
        <div className="header">
          <div className="icon">
            <img src='/images/logoBR.png' height="35" alt="Icon" />
          </div>
          <div className="title">
            <h1 className='docs-title-widget'>{title}</h1>
            <div className="menu">
              <ul className="horizontal-menu">
                {Object.keys(menuData).map((menuCategory) => (
                  <li key={menuCategory}>
                    <span className="menu-category">{menuCategory}</span>
                    <ul className="submenu">
                      {menuData[menuCategory].map((menuItem, index) => (
                        <li key={`${menuCategory}-${index}`}>
                          <a href={menuItem.link}>
                            <i className={menuItem.icon} /> {menuItem.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="iconprofils" style={{ verticalAlign: 'top', display: 'flex', alignItems: 'center' }}>
            <div style={{ float: 'right', textAlign: 'right', marginRight: '10px' }}>
              <span onClick={toggleDropdown}>
                Hallo, {pengguna}
              </span>
              <div style={{ marginBottom: '-5px' }}></div>
              <span style={{ fontSize: '10px', fontWeight: 'bold' }}>
                <select style={selectStyle} ref={selectForPT} className="js-example-basic-single style-select-report" value={selectPT} onChange={handleChange}>
                  {optionsSearchPort.map((optionsSearchPort) => (
                    <option key={optionsSearchPort.value} value={optionsSearchPort.value}>
                      {optionsSearchPort.label}
                    </option>
                  ))}
                </select>
                | {currentDate}
              </span>
            </div>
            <img id="image_loading" style={{ display: 'none' }} src="/app-assets/css/loadi.gif" height="32" alt="Icon" />
            <img src="/images/personal.png" height="35" alt="Icon" title="User" onClick={toggleDropdown} />
            {showDropdown && (
              <div className="dropdown-menu">
                {/* Dropdown menu content */}
                <ul>
                  <li>Option 1</li>
                  <li>Option 2</li>
                  <li>Option 3</li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderMenu;