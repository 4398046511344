import React, { useEffect, useState } from 'react';
import axios, { formToJSON } from 'axios';
import $ from 'jquery';
import '../css/Login.css';
import '../css/Materialize.css';
import '../css/Style.css';
import '../css/new.css';
import reloadToken from '../helper/reloadtoken';
import helperFilter from '../helper/helperfilter';
import HeaderMenu from '../template/HeaderMenu';
import { apiConfig } from '../helper/apiConfig';
import { hide,bikinselect,getFilterPageAll } from '../helper/hide';
import { ToastContainer, toast } from 'react-toastify';
import { dataconfighistory, showtoolbarConfig, ct, dataconfiFclHistory } from '../helper/luckysheetHelper';
import 'react-toastify/dist/ReactToastify.css';
import Modal from './modaldetail';
import Modalpreview from './modalpreview';
import ModalFilterFcl from './modalFilterFcl';

const tipedata = "FCL";
const host = process.env.REACT_APP_API_URL_ONLINE;
const hostdrop = process.env.REACT_APP_API_HOST_DROPDOWN;
const hostfcl = process.env.REACT_APP_API_HOST_FCL;
const hostdatcont = process.env.REACT_APP_API_HOST_DATA_CONT;
const hostcc = process.env.REACT_APP_API_HOST_COLOR_COMMENT;

const ADMIN = "admin"
const POL = "pol"
const CNEE = "cnee"
const SALES = "sales"
const KONSEPTOR = "koseptor"
const TIPE_TRANS = "tipe_trans"
const MARKING = "marking"
const PORT = "port"
const STATUS_BL = "status_bl"
const UK = "uk"
const PPN_BR = "ppn_br";
const fullURL = window.location.href;
const searchtype = "searchtype" + fullURL;
const searchbln = "searchbln" + fullURL;
const searchthn = "searchthn" + fullURL;

const notify_cant_update_br_cont = (message, time = 500) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: time,
  });
};

const notify_update_br_cont = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 800,
  });
};


const FCLHistory = () => {
  const luckysheet = window.luckysheet;
  const [token, setToken] = useState(null);
  var b = 0;
  const pagecolumn = "FCLCOLUMNADM"
  const pagerow = "FCLROW"
  var columnWidths = [];
  var kiri, atas, tinggi;
  var options = null;
  var type_row = "";
  var type_jml = 0;
  let valueppn = "YA,TIDAK";
  let valueukuran = "20,40,45";
  let valuestatusbl = "TELEX,DRAFT,ORI,HBL";
  var end_column_freze = 11;
  var size_scroll_left = 0;

  const [role, setRole] = useState("");
  const [optionsSearch, setOptionsSearch] = useState([]);
  const [loadingsearch, setLoadingSearch] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [datamodal, setDataModal] = useState(false);
  const [modalFilterVisible, setModalFilterVisible] = useState(false);

  const [modalVisible_V, setModalVisible_V] = useState(false);
  const [datamodal_V, setDataModal_V] = useState(false);

  const [selectedYear, setSelectedYear] = useState(getFilterPageAll(searchthn)); // Step 1
  const [selectedMonth, setSelectedMonth] = useState(getFilterPageAll(searchbln)); // Default to January (1)
  const [selectTypeSearch, setSelectTypeSearch] = useState(getFilterPageAll(searchtype));

  const [fikiArray, setFikiArray] = useState([]);

  const handleTypeChange = (event) => {
    setSelectTypeSearch(event.target.value);
    localStorage.setItem(searchtype, event.target.value);
  };

  const handleMonthChange = (event) => {
    const value = event.target.value;
    let intValue;
    if (value.trim() !== '') {
         intValue = parseInt(value, 10);
        if (!isNaN(intValue)) {
        } else {
          intValue="";
        }
    } else {
      intValue="";
    }
    setSelectedMonth(intValue);
    localStorage.setItem(searchbln, intValue);
  };

  const onYearChange = (year) => {
    const value = year;
    let intValue;
         intValue = parseInt(value, 10);
        if (!isNaN(intValue)) {
        } else {
          intValue="";
        }
        setSelectedYear(intValue);
    localStorage.setItem(searchthn, intValue);
  };

  const toggleFilterModal = () => {
    setModalFilterVisible(!modalFilterVisible);
  };

  function getDataFilterTersimpan() {
    let parsedData = [];
    var titlefilter = getTitleFilter()
    const fullURL = window.location.href;
    const nameFrozen = "filtersave-" + fullURL;
    const retrievedData = localStorage.getItem(nameFrozen);

    if (retrievedData){
        parsedData = JSON.parse(retrievedData);
    }

    if (Array.isArray(parsedData)) {
        // cari index sesuai pencarian fki
        const index = parsedData.findIndex(item => Object.keys(item)[0] === titlefilter);
        if (index !== -1) {
            const fikiArray = parsedData[index][titlefilter];
            //console.log("fikiArray ", fikiArray);
            setFikiArray(fikiArray);
            return fikiArray;
        } else {
            //console.log("Data not found");
        }
    } else {
        //console.log("Invalid or empty data");
    }
    return [];
  }

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, index) => currentYear - index);

  function getDataFilterTersimpan() {
    var array = [];
    var tipe1 = getFilterPageAll(searchtype); 
    var operator1 = '==';
    var value11 = getFilterPageAll(searchbln)+"-"+getFilterPageAll(searchthn);
    var value12 = null;

    if (tipe1 !== null && tipe1 !== '') {
      array.push([tipe1, operator1, value11, value12]);
    }
    return array;
  }

  const handleResetClick = () => {
    const keysToDelete = [searchtype, searchbln, searchthn];
    keysToDelete.forEach(key => {
      localStorage.removeItem(key);
    });
    window.location.reload();
  }

  const handleSearchClick = async () => {
    if (loadingsearch) {
      try {
        setLoadingSearch(false);
        const payload = {
          month: selectedMonth,
          year: selectedYear,
          tipe: selectTypeSearch,
        };

        const newToken = await reloadToken()
        setToken(newToken);
        if (newToken) {
          initializeLuckySheet(newToken, selectedMonth, selectedYear, selectTypeSearch);
        }
        console.log('Search results:');
      } catch (error) {
        console.error('Error searching:', error);
      }
    }
  };

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const toggleModal_V = () => {
    setModalVisible_V(!modalVisible_V);
  };

  function getTitleFilter(){
    const fullURL = window.location.href;
    const nameFrozen = "filtersaveTitle-" + fullURL;
    const retrievedData = localStorage.getItem(nameFrozen);
    return retrievedData;
}

  useEffect(() => {
    const role = localStorage.getItem('role');  
    setRole(role);
    
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 100));
      const newToken = await reloadToken()
      setToken(newToken);
      if (newToken) {
        initializeLuckySheet(newToken, selectedMonth, selectedYear, selectTypeSearch);
      }
    };
    fetchData();

    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log('Tab is hidden');
      } else {
        console.log('Tab is visible');
        luckysheet.refresh();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  const initializeLuckySheet = async (token, bln, thn, tipe_cari) => {
    let datacari = [];
    if ((tipe_cari !== null && tipe_cari !== "") && (bln !== null && bln !== "") && (thn !== null && thn !== "")){
      datacari.push(bln + "-" + thn + "-" + tipe_cari);
    } 

    new Promise((resolve) => setTimeout(resolve, 100));
    const config = apiConfig(token);

    const getValueDropdownSearch = async () => {
      try {
        const config = apiConfig(token);
        const response = await axios.post(`${hostfcl}MenudropdownHistory`, null, config);
        return response.data;
      } catch (error) {
        console.error('Error fetching column names:', error);
        return [];
      }
    };
    setOptionsSearch(await getValueDropdownSearch())
    
    const getColumnNames = async () => {
      try {
        const config = apiConfig(token);
        const response = await axios.post(`${hostfcl}ReactBrcontainerDetailColumn`, null, config);
        return response.data.column;
      } catch (error) {
        console.error('Error fetching column names:', error);
        return [];
      }
    };

    const getCellData = async (data, savedColumnWidths, urutanload) => {
      let fh = getDataFilterTersimpan();
      try {
        const response = await axios.post(`${hostfcl}ReactBrcontainersDetail`, { data, savedColumnWidths, urutanload, datacari,fh }, config);
        return response;
      } catch (error) {
        console.error('Error fetching cell data:', error);
        return [];
      }
    };

    const cekStatusFile = async () => {
      try {
        const response = await axios.post(`${hostfcl}Cekstatusfile`, null, config);
        return response.data;
      } catch (error) {
        console.error('Error fetching cell data:', error);
        return [];
      }
    };

    const getDropdownMarking = async (data) => {
      try {
        const response = await axios.get(data, config);
        return response.data;
      } catch (error) {
        console.error('Error fetching column names:', error);
        return [];
      }
    };

    const markingPromise = getDropdownMarking(`${hostdrop}MasterDropdownReact/marking/FCL`);
    const dropPortPromise = getDropdownMarking(`${hostdrop}MasterDropdownReact/port/FCL`);
    const dropcneePromise = getDropdownMarking(`${hostdrop}MasterDropdownReact/cnee/FCL`);
    const droppolPromise = getDropdownMarking(`${hostdrop}MasterDropdownReact/pol/FCL`);
    const dropadminPromise = getDropdownMarking(`${hostdrop}MasterDropdownReact/admin/FCL`);
    const dropkonseptorPromise = getDropdownMarking(`${hostdrop}MasterDropdownReact/konseptor/FCL`);
    const dropsalesPromise = getDropdownMarking(`${hostdrop}MasterDropdownReact/sales/FCL`);
    const droptipePromise = getDropdownMarking(`${hostdrop}MasterDropdownReact/tipe_fcl`);

    const [marking, dropPort, dropcnee, droppol, dropadmin, dropkonseptor, dropsales, droptipe] = await Promise.all([
      markingPromise,
      dropPortPromise,
      dropcneePromise,
      droppolPromise,
      dropadminPromise,
      dropkonseptorPromise,
      dropsalesPromise,
      droptipePromise,
    ]);

    const nama_column = await getColumnNames();
    const data_pilihan = ["custom", "kapal", "fe", "gudang", "biaya_lain", "inv_ppn_br"];
    let datahasil = [];
    const cariindex = data_pilihan.map(columnName => nama_column.indexOf(columnName));
    for (let i = 0; i < cariindex.length; i++) {
      const columnNumber = cariindex[i];
      const columnPosition = getColumnPosition(columnNumber);
      datahasil.push(columnPosition)
    }

    let savedColumnWidths = JSON.parse(localStorage.getItem(pagecolumn));
    if (savedColumnWidths === null || Object.keys(savedColumnWidths).length === 0) {
      savedColumnWidths = null;
    } else {
      savedColumnWidths = savedColumnWidths;
    }


    async function cekFile() {
      let cekfile = await cekStatusFile();
      if (cekfile.length !== 0) {
        if (cekfile.count > 0) {
          setTimeout(async function () {
            var dataKirimModal = {
              id: "1",
              nama: "test"
            };
            setDataModal_V(dataKirimModal);
            toggleModal_V();
          }, 2000)
        }
      }
    }
    cekFile();
    const intervalId = setInterval(cekFile, 3600000);//1 jam

    let cellDataJsonA = [];
    let totaldata = 0;


    function isObjectEmpty(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    }


    async function loaddata(offset) {
      cellDataJsonA = await getCellData(datahasil, savedColumnWidths, offset);
      if (cellDataJsonA.length === 0) {
        return {
          "data": [{}],
          "dataformula": [{}]
        };
      } else {
        if (isObjectEmpty(cellDataJsonA.data)) {
          return {
            "data": [{}],
            "dataformula": [{}]
          };
        }else {
        totaldata = totaldata + cellDataJsonA.data.totaldata + 1;
        for (let i = 0; i < cellDataJsonA.data.data.length; i++) {
          if (cellDataJsonA.data.data[i].v.ct == "0") {//title
            cellDataJsonA.data.data[i].v.ct = {
              "fa": "@",
              "t": "s",
            };
            cellDataJsonA.data.data[i].v.bl = 1;
            cellDataJsonA.data.data[i].v.ff = 1;
            cellDataJsonA.data.data[i].v.ht = 0;
            cellDataJsonA.data.data[i].v.fs = 9;
            cellDataJsonA.data.data[i].v.vt = 0;
            cellDataJsonA.data.data[i].v.tb = 2;
          } else if (cellDataJsonA.data.data[i].v.ct == "1") {//angka
            cellDataJsonA.data.data[i].v.ct = {
              "fa": "#,##0",
              "t": "n",
            };
            cellDataJsonA.data.data[i].v.bl = 2;
            cellDataJsonA.data.data[i].v.ff = 2;
            cellDataJsonA.data.data[i].v.ht = 2;
            cellDataJsonA.data.data[i].v.fs = 9;
            cellDataJsonA.data.data[i].v.vt = 0;
            cellDataJsonA.data.data[i].v.tb = 2;
          } else if (cellDataJsonA.data.data[i].v.ct == "2") {//date
            cellDataJsonA.data.data[i].v.ct = {
              "fa": "yyyy-MM-dd",
              "t": "d",
            };
            cellDataJsonA.data.data[i].v.bl = 2;
            cellDataJsonA.data.data[i].v.ff = 2;
            cellDataJsonA.data.data[i].v.ht = 0;
            cellDataJsonA.data.data[i].v.fs = 9;
            cellDataJsonA.data.data[i].v.vt = 0;
            cellDataJsonA.data.data[i].v.tb = 2;
          } else if (cellDataJsonA.data.data[i].v.ct == "3") {//link
            cellDataJsonA.data.data[i].v.ct = {
              "fa": "@",
              "t": "l",
            };
            cellDataJsonA.data.data[i].v.bl = 2;
            cellDataJsonA.data.data[i].v.ff = 2;
            cellDataJsonA.data.data[i].v.ht = 0;
            cellDataJsonA.data.data[i].v.fs = 9;
            cellDataJsonA.data.data[i].v.vt = 0;
            cellDataJsonA.data.data[i].v.tb = 2;
          } else if (cellDataJsonA.data.data[i].v.ct == "5") {//link
            cellDataJsonA.data.data[i].v.ct = {
              "fa": "General",
              "t": "g",
            };
            cellDataJsonA.data.data[i].v.bl = 2;
            cellDataJsonA.data.data[i].v.ff = 2;
            cellDataJsonA.data.data[i].v.ht = 0;
            cellDataJsonA.data.data[i].v.fs = 9;
            cellDataJsonA.data.data[i].v.vt = 0;
            cellDataJsonA.data.data[i].v.tb = 2;
          } else {
            cellDataJsonA.data.data[i].v.ct = {//text
              "fa": "@",
              "t": "n",
            };
            cellDataJsonA.data.data[i].v.bl = 2;
            cellDataJsonA.data.data[i].v.ff = 2;
            cellDataJsonA.data.data[i].v.ht = 1;
            cellDataJsonA.data.data[i].v.fs = 9;
            cellDataJsonA.data.data[i].v.vt = 0;
            cellDataJsonA.data.data[i].v.tb = 2;
          }
        }
        }
        const data_all = cellDataJsonA.data;
        return data_all;
      }
    }

    const [page0Data, page1Data, page2Data, page3Data, page4Data, page5Data] = await Promise.all([
      loaddata(0),
      loaddata(1),
      loaddata(2),
      loaddata(3),
      loaddata(4),
      loaddata(5),
    ]);

    console.log(page0Data);
    const page0 = page0Data || [];
    const page1 = page1Data || [];
    const page2 = page2Data || [];
    const page3 = page3Data || [];
    const page4 = page4Data || [];
    const page5 = page5Data || [];

    const merged = [
      ...page0.data,
      ...page1.data,
      ...page2.data,
      ...page3.data,
      ...page4.data,
      ...page5.data,
    ];

    const mergedformula = [
      ...page0.dataformula,
      ...page1.dataformula,
      ...page2.dataformula,
      ...page3.dataformula,
      ...page4.dataformula,
      ...page5.dataformula,
    ];

    const mergedDataRO = page1.readonly;//readonly 1 for all 
    const dataformula = await loaddata(0);
    const canformual = page0.dataformula;

    options = {
      container: "luckysheet",
      title: "DataCont",
      lang: 'en',
      showinfobar: false,
      allowCopy: false,
      allowEdit: true,
      column: 0,
      filter: {
        ref: 'A1:C1',
      },
      forceCalculation: true,
      showtoolbarConfig: dataconfiFclHistory,
      cellRightClickConfig: showtoolbarConfig,
      data: [
        {
          name: "Sheet",
          color: "",
          row: 1,
          index: "0",
          celldata: merged,
          calcChain: mergedformula,
          defaultRowHeight: 30,
          config: {
            columnlen: savedColumnWidths,
            colhidden: {
              0: false,
            },
            rowlen: {
              0: 40,
            },
            authority: {
              sheet: 1,
              hintText: "Kolom ini tidak bisa anda edit",
              allowRangeList: [
                {
                  "name": "area",
                  "sqref": mergedDataRO,
                },
              ],
            },
          },
        },
      ],
      enableAddRow: false,
      textWrapMode: false,
      
      hook: {
        cellRenderAfter: function (cell, position, sheetFile, ctx) {
          if (b < 1) {
            const data = [];
            const endIndex = 150;
            for (let i = 0; i <= endIndex; i++) {
              data.push(i);
            }
            var columnWidths = luckysheet.getColumnWidth(data);
            const colWidthA = columnWidths[1];
            for (let columnIndex of data) {
              const width = columnWidths[columnIndex];
              columnWidths[columnIndex.toString()] = width;
            }
            localStorage.setItem(pagecolumn, JSON.stringify(columnWidths));

            setTimeout(function () {
              b = 0;
            }, 500);
          }
          b++;
        },
        rangeSelect: function (index, sheet) {
          var column = sheet[0].column[0];
          var top = sheet[0].top;
          var left = sheet[0].left;
          var height = sheet[0].height;
          kiri = left;
          atas = top;
          tinggi = height;
          filter_data(column, top, left, height);
        },
      },
    }

    options.loading = {
      image: () => {
        return `<svg viewBox="25 25 50 50" class="circular">
        <circle cx="50" cy="50" r="20" fill="none"></circle>
        </svg>`
      },
      imageClass: "loadingAnimation"
    }

    if (totaldata > 0) {
      luckysheet.create(options)
    } else {
      notify_cant_update_br_cont("Error, Tidak dapat tersambung dengan server!", 2500);
    }

    setTimeout(() => {
      console.log("test");
      //bikinselect();
    }, 500);

    
    function validasidropdown(kolom, data) {
      let valueppnArray;
      if (kolom == PPN_BR) {
        return rollbackvalidasidropdown(valueppn, data);
      }
      else if (kolom == UK) {
        return rollbackvalidasidropdown(valueukuran, data);
      }
      else if (kolom == MARKING) {
        return rollbackvalidasidropdown(marking, data);
      }
      else if (kolom == PORT) {
        return rollbackvalidasidropdown(dropPort, data);
      }
      else if (kolom == STATUS_BL) {
        return rollbackvalidasidropdown(valuestatusbl, data);
      }
      else if (kolom == CNEE) {
        return rollbackvalidasidropdown(dropcnee, data);
      }
      else if (kolom == POL) {
        return rollbackvalidasidropdown(droppol, data);
      }
      else if (kolom == ADMIN) {
        return rollbackvalidasidropdown(dropadmin, data);
      }
      else if (kolom == SALES) {
        return rollbackvalidasidropdown(dropsales, data);
      }
      else if (kolom == KONSEPTOR) {
        return rollbackvalidasidropdown(dropkonseptor, data);
      }
      else if (kolom == TIPE_TRANS) {
        return rollbackvalidasidropdown(droptipe, data);
      }
      else {
        return true;
      }
    }

    function rollbackvalidasidropdown(valueppnArray, data) {
      if (data === null || data === undefined || data.trim() === "") {
        console.log("Data is empty or null.");
        return false;
      }
      if (valueppnArray.includes(data)) {
        return true;
      } else {
        return false;
      }
    }

    function getColumnIndexByTitle(sheetId, title) {
      const sheetData = luckysheet.getSheetData(sheetId);
      if (!sheetData) return -1; // Sheet data not found

      const firstRowData = sheetData[0].data;
      for (let colIndex = 0; colIndex < firstRowData.length; colIndex++) {
        if (firstRowData[colIndex].v === title) {
          return colIndex;
        }
      }
      return -1;
    }

    $("#luckysheet-bottom-add-row").on("click", async function () {
      var data = $("#luckysheet-bottom-add-row-input").val();
      const currentDate = getCurrentDate();
      var b = luckysheet.getRange()[0].row[0];
      for (var i = 1; i <= data; i++) {
        try {
          const response = await axios.post(`${hostfcl}BrcontainersDetail/New`, null, config);
          //luckysheet.setCellValue(b, 0, response.data.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
        b++;
      }
      window.location.reload();
    });

    function isValidFormula(value) {
      const formulaPattern = /^=([A-Za-z]+\d+\+)*[A-Za-z]+\d+$/;
      return formulaPattern.test(value);
    }

    $(document).on('click', '.luckysheet-cols-menuitem', function () {
      helperFilter(11);
    });

    $(document).on('mousedown', '.sp-thumb-el', function () {
      // var warna = $(this).attr('data-color');
      var title = $(this).attr('title');
      var datas = [];
      var jlh = 0;
      var selectedRange = luckysheet.getRange();
      if (!selectedRange) {
        console.error('No cells selected.');
        return;
      }
      var baris_awal = selectedRange[0].row[0];
      var baris_akhir = selectedRange[0].row[1];
      var kolom_awal = selectedRange[0].column[0];
      var kolom_akhir = selectedRange[0].column[1];

      const column = nama_column[kolom_awal];
      for (var row = baris_awal; row <= baris_akhir; row++) {
        for (var col = kolom_awal; col <= kolom_akhir; col++) {
          const id = luckysheet.getCellValue(baris_awal, 0);
          var datax = {};
          datax['id'] = id;
          datax['warna'] = title;
          datax['ColumnName'] = column;
          datax['ColumnField'] = "FCL";
          datas[jlh] = datax;
          jlh++;
        }
      }
      var dataxx = {};
      dataxx['data'] = JSON.stringify(datas);
      axios.post(`${hostcc}ColorBrcontainers`, dataxx, config)
        .then((response) => {
          notify_update_br_cont("Color sukses di tambahkan");
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    });

    $(document).ready(function () {
      const handleClickCustomFilter = () => {
        toggleFilterModal();
        console.log('Element with id luckysheet-btn-filterCustom clicked!');
      };
    
      $('#luckysheet-btn-filterCustom').on('click', handleClickCustomFilter);
    });

    function getColumnPosition(columnNumber) {
      let dividend = columnNumber + 1;
      let columnName = '';

      while (dividend > 0) {
        const modulo = (dividend - 1) % 26;
        columnName = String.fromCharCode(65 + modulo) + columnName;
        dividend = Math.floor((dividend - modulo) / 26);
      }
      return columnName;
    }

    const luckysheetContainer = document.getElementById('luckysheet');
    luckysheetContainer.addEventListener('scroll', () => {
      const scrollLeft = luckysheetContainer.scrollLeft;
      const frozenColumns = document.querySelectorAll('.luckysheet-cell.luckysheet-cell-frozen');
      frozenColumns.forEach((column) => {
        column.style.transform = `translateX(${scrollLeft}px)`;
      });
    });

    setTimeout(async function () {
      var calcChainLength = 0;
      if (totaldata > 0) {
        var total_data = luckysheet.getAllSheets()[0].data.length;
        calcChainLength = total_data + 1;
        luckysheet.setBothFrozen(true, { range: { row_focus: 0, column_focus: end_column_freze } });
        helperFilter(11);
        setleftScrollbarstart();
        setLoadingSearch(true);
      }

      createDataVeri(ADMIN, dropadmin.join(', '));
      createDataVeri(POL, droppol.join(', '));
      createDataVeri(CNEE, dropcnee.join(', '));
      createDataVeri(SALES, dropsales.join(', '));
      createDataVeri(KONSEPTOR, dropkonseptor.join(', '));
      createDataVeri(TIPE_TRANS, droptipe.join(', '));
      createDataVeri(MARKING, marking.join(', '));
      createDataVeri(PORT, dropPort.join(', '));
      createDataVeri(STATUS_BL, valuestatusbl);
      createDataVeri(UK, valueukuran);
      createDataVeri(PPN_BR, valueppn);

      function createDataVeri(kolom, value) {
        if (typeof luckysheet !== 'undefined') {
          const kolom_ = nama_column.indexOf(kolom);
          if (kolom_ > 0) {
            const columnPosition = getColumnPosition(kolom_);
            const output = columnPosition + "1:" + columnPosition + "" + calcChainLength;
            luckysheet.setRangeShow([output]);
            if (value.length > 0) {
              return luckysheet.setDataVerification(
                {
                  "type": "dropdown",
                  "type2": null,
                  "value1": value,
                  "value2": "",
                  "checked": false,
                  "remote": false,
                  "prohibitInput": false,
                  "hintShow": false,
                  "hintText": ""
                }
              );
            }
          }
        }
      }
    }, 1000);

    function filter_data(c, atas, kiri, tinggi) {
      const datakolom = nama_column[c]
      const isValueValid = cekdata(datakolom);

      $("#luckysheet-rich-text-editor").on('keyup', function () {
        if (isValueValid) {
          const searchText = $("#luckysheet-rich-text-editor").text().toUpperCase();
          $("#luckysheet-dataVerification-dropdown-List").hide();
          $(".dropdown-List-item").each(function () {
            const itemText = $(this).text().toUpperCase();
            if (itemText.indexOf(searchText) > -1) {
              $(this).show();
            } else {
              $(this).hide();
            }
          });

          // fiki
          const editorHeight = $("#luckysheet-rich-text-editor").outerHeight();
          const topValue = parseFloat($("#luckysheet-cell-selected-focus").css("top"));
          const leftValue = parseFloat($("#luckysheet-input-box").css("left"));

          $("#luckysheet-dataVerification-dropdown-List").css({
            display: 'block',
            // left: leftValue - 44 + 'px',
            // top: topValue + editorHeight + editorHeight - 5 + 'px',
            right: 'auto',
            left: kiri + 'px',
            top: atas + tinggi + 'px',
          });
        } else {
          $("#luckysheet-dataVerification-dropdown-List").css({
            display: 'none',
            // left: leftValue - 44 + 'px',
            // top: topValue + editorHeight + editorHeight - 5 + 'px',
            right: 'auto',
            left: kiri + 'px',
            top: atas + tinggi + 'px',
          });
        }
      });
    }

    const cekdata = (v) => {
      const datacari = [ADMIN, POL, CNEE, SALES, KONSEPTOR, TIPE_TRANS, MARKING, PORT, STATUS_BL, UK, PPN_BR];
      return datacari.includes(v);
    };

    return () => clearInterval(intervalId);
  };

  const convertToAlphabet = (number) => {
    let result = '';
    while (number >= 0) {
      result = String.fromCharCode(65 + (number % 26)) + result;
      number = Math.floor(number / 26) - 1;
    }
    return result;
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  function setleftScrollbar(kiri) {
    const luckysheetContainerX = document.getElementById('luckysheet-scrollbar-x');
    luckysheetContainerX.addEventListener('scroll', () => {
      if (luckysheetContainerX.scrollLeft > 1) {
        document.getElementById("luckysheet-dataVerification-dropdown-btn").style.setProperty('display', 'none', 'important');
        size_scroll_left = luckysheetContainerX.scrollLeft;
        const arrowCanvas = document.getElementById("arrowCanvas-overshow");
        if (arrowCanvas !== null) {
          document.getElementById("luckysheet-postil-overshow").style.display = "none";
        }
      }
    });
    if (kiri) {
      size_scroll_left = 1
      luckysheetContainerX.scrollLeft = size_scroll_left;
    }
    else {
      luckysheetContainerX.scrollLeft = size_scroll_left;
    }
  }

  function setleftScrollbarstart() {
    const luckysheetContainerX = document.getElementById('luckysheet-scrollbar-x');
    luckysheetContainerX.addEventListener('scroll', () => {
      if (luckysheetContainerX.scrollLeft > 1) {
        size_scroll_left = luckysheetContainerX.scrollLeft;
      }
      luckysheetContainerX.scrollLeft = size_scroll_left;
      // console.log("SCROLL AUTO", luckysheetContainerX.scrollLeft);
    });
  }

 // let luckyCss;

  // if (role === 'ADMSALES') {
  //   luckyCss = {
  //     margin: '110px 0px 0px 0px',
  //     padding: '0px',
  //     position: 'absolute',
  //     width: '100%',
  //     height: '88%',
  //     left: '0px',
  //     top: '0px',
  //     overflowX: 'auto',
  //   };
  // } else {
  //   luckyCss = {
  //     margin: '110px 0px 0px 0px',
  //     padding: '0px',
  //     position: 'absolute',
  //     width: '100%',
  //     height: '88%',
  //     left: '0px',
  //     top: '0px',
  //     overflowX: 'auto',
  //   };
  // }

  const forumLinkStyle = {
    zIndex: 2,
    width: '50px',
    height: '50px',
    lineHeight: '50px',
    position: 'fixed',
    right: '40px',
    bottom: '86px',
    borderRadius: '50px',
    cursor: 'pointer',
    background: 'rgb(71,133,249)',
    color: '#fff',
    textAlign: 'center',
    textDecoration: 'none',
  };

  const selectStyle = {
    // Define your custom CSS styles here
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '2px',
    fontSize: '16px',
    width: 'auto',
    height: '10%',
    marginTop: '10px',
  };

  return (
    <div>
      <HeaderMenu title="FULL CONTAINER LOAD HISTORY" subtitle={"Page data & file FCL Stuffing"} />
      <ToastContainer />
      <select style={selectStyle} value={selectTypeSearch} onChange={handleTypeChange}>
      {optionsSearch.map((optionsSearch) => (
        <option key={optionsSearch.value} value={optionsSearch.value}>
          {optionsSearch.label}
        </option>
      ))}
        {/* <option value="load_date">TGL LOAD</option>
        <option value="etd_date">ETD</option>
        <option value="eta_date">ETA</option>
        <option value="draft_fe">DRAF FE</option> */}
      </select>
      <select style={selectStyle} value={selectedMonth} onChange={handleMonthChange}>
        <option >-PILIH-</option>
        <option value={1}>JANUARI</option>
        <option value={2}>FEBRUARI</option>
        <option value={3}>MARET</option>
        <option value={4}>APRIL</option>
        <option value={5}>MEI</option>
        <option value={6}>JUNI</option>
        <option value={7}>JULI</option>
        <option value={8}>AGUSTUS</option>
        <option value={9}>SEPTEMBER</option>
        <option value={10}>OKTOBER</option>
        <option value={11}>NOVEMBER</option>
        <option value={12}>DESEMBER</option>``
      </select>
      <select style={selectStyle} value={selectedYear} onChange={(e) => onYearChange(parseInt(e.target.value))}>
      <option >-PILIH-</option>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
      <button onClick={handleSearchClick} className='btn btn-search'>Search</button>
      <button onClick={handleResetClick} className='btn btn-reset'>Reset</button>
      <div id="luckysheet" className='luckyCssWithMenuResponsive' />
      <Modal modalVisible={modalVisible} toggleModal={toggleModal} data={datamodal} />
      <Modalpreview modalVisible={modalVisible_V} toggleModal={toggleModal_V} data={datamodal_V} />
      <ModalFilterFcl modalVisible={modalFilterVisible} toggleModal={toggleFilterModal} data={datamodal} />
    </div>
  );
};

export default FCLHistory;