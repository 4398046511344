import axios from 'axios';
import { decrypt } from '../helper/Decrypte';
const host = process.env.REACT_APP_API_URL_ONLINE;

const reloadToken = async () => {
  try {
    const verificationURL = host + "login";
    const formData = new FormData();
    const user = decrypt(localStorage.getItem('user'));
    const pass = decrypt(localStorage.getItem('pass'));

    if (user && pass) {
      formData.append('user', user);
      formData.append('pass', pass);
      const response = await axios.post(verificationURL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
       const newToken = response.data.token;
      localStorage.setItem('token', newToken);
      localStorage.setItem("pengguna", response.data.pengguna);
      localStorage.setItem("master_field", response.data.master_field);
      localStorage.setItem("role", response.data.role);
      localStorage.setItem('branch', response.data.branch);
      console.log(response);
      return newToken;
    } else {
      window.location.href = "/"; 
    }
  } catch (error) {
    console.error('Error reloading token:', error);
    window.location.href = "/"; 
   //throw error;
  }
};

export default reloadToken;