const helperFilter = (row) => {
    //console.log("helper filter ",row)
    var widthelement = [];
    var c = 0;
    const luckysheetContainerX = document.getElementById('luckysheet-scrollbar-x');
    const luckysheetContainerY = document.getElementById('luckysheet-scrollbar-y');
    luckysheetContainerX.scrollLeft = 1;

    const inputBox = document.getElementById('luckysheet-rich-text-editor');
    // inputBox.addEventListener('focusin', function () {
    //     const edit = document.getElementById('luckysheet-input-box');
    //     const computedStyle = getComputedStyle(edit);
    //     const leftPosition = computedStyle.getPropertyValue('left');
    //     console.log(leftPosition);
    // });

    const inbox = document.getElementById('luckysheet-input-box');
    inbox.addEventListener('mouseover', function (even) {
        const edit = document.getElementById('luckysheet-input-box');
        const computedStyle = getComputedStyle(edit);
        const leftPosition = computedStyle.getPropertyValue('left');

        //console.log(leftPosition);
        const dropdownList = document.getElementById('luckysheet-dataVerification-dropdown-List');
        // dropdownList.style.display = 'none';
        setTimeout(() => {
            dropdownList.style.position = 'absolute';
            let c = parseInt(leftPosition) + luckysheetContainerX.scrollLeft - 44;
            dropdownList.style.left = c + "px";
            //console.log(c);
            // dropdownList.style.display = 'block';
        }, 50);
    });


    // Get all elements with the class "luckysheet-filter-options"
    const filterOptionElements = document.querySelectorAll('.luckysheet-filter-options');
    filterOptionElements.forEach(function (element) {
        const dataIndex = element.getAttribute('data-cindex');
        if (dataIndex < row) {
            element.addEventListener('click', function () {
                //console.log('Element with data-cindex ' + dataIndex + ' was clicked');
                //luckysheetContainerX.scrollLeft = 0;
            });
        }
    });

    luckysheetContainerX.addEventListener('scroll', () => {
        handleScroll('x');
        document.getElementById("luckysheet-dataVerification-dropdown-List").style.display = "none";
        document.getElementById("luckysheet-input-box").style.display = "none";
        document.getElementById("luckysheet-input-box-index").style.display = "none";
    });

    luckysheetContainerY.addEventListener('scroll', () => {
        handleScroll('y');
    });

    function handleScroll(scrollType) {
        //console.log("Scroll ", scrollType);
        const freezen_3 = document.getElementById('freezen_3');
        const freezen_7 = document.getElementById('freezen_7');
        if (freezen_7) {
            freezen_7.style.overflowX = 'auto';
            var canvas = document.getElementById("freezen_7");
            canvas.addEventListener("click", function (event) {
                //console.log("Canvas was clicked!");
            });
        }

        const filterOptionsContainers = document.querySelectorAll('.luckysheet-filter-options-c .luckysheet-filter-options');
        const firstFiveContainers = Array.from(filterOptionsContainers).slice(0, row);
        firstFiveContainers.forEach((filterOptionsContainer, index) => {
            const dataIndex = filterOptionsContainer.getAttribute('data-cindex');
            if (dataIndex >= 0 && dataIndex < row) {
                const querySelectorString = `.luckysheet-filter-options[data-cindex="${dataIndex}"]`;
                const selectedElement = document.querySelector(querySelectorString);
                if (scrollType === 'x') {
                    const luckysheetContainer = luckysheetContainerX;
                    if (luckysheetContainer.scrollLeft > 0) {
                        // Menggunakan JavaScript murni untuk menyembunyikan elemen
                        document.getElementById("luckysheet-dataVerification-dropdown-List").style.display = "none";
                        document.getElementById("luckysheet-input-box").style.display = "none";
                        document.getElementById("luckysheet-input-box-index").style.display = "none";
                        // const arrowCanvas = document.getElementsByClassName("arrowCanvas"); 
                        // if (arrowCanvas.length > 0) {
                        //     for (let i = 0; i < arrowCanvas.length; i++) {
                        //       arrowCanvas[i].style.display = "none";
                        //     }
                        // }
                        const arrowCanvas = document.getElementById("arrowCanvas-overshow");
                        if (arrowCanvas !== null) {
                            document.getElementById("arrowCanvas-overshow").style.display = "none";
                        }

                        const element = document.getElementById("luckysheet-postil-overshow-style-fiki");
                        if (element !== null) {
                            document.getElementById("luckysheet-postil-overshow-style-fiki").style.display = "none";
                        }

                        if (selectedElement) {
                            if (c < 1) {
                                // console.log(index - 1);
                                widthelement.push(selectedElement.style.left);
                            }
                            if (freezen_3) {
                                freezen_3.style.zIndex = '20';
                            }
                            //selectedElement.style.backgroundColor = 'red';
                            selectedElement.style.zIndex = '1000';
                            //console.log("total scrolling "+luckysheetContainer.scrollLeft);
                            let total = parseFloat(widthelement[index]) + parseFloat(luckysheetContainer.scrollLeft);
                            // console.log("Total left + total scrolling "+total);
                            selectedElement.style.left = total + 'px';
                        }
                        //filterColumnIcon.style.left = luckysheetContainer.scrollLeft + 'px';
                    } else {
                        if (freezen_3) {
                            freezen_3.style.zIndex = '10';
                        }
                        document.getElementById("luckysheet-dataVerification-dropdown-List").style.display = "none";
                        document.getElementById("luckysheet-input-box").style.display = "none";
                        document.getElementById("luckysheet-input-box-index").style.display = "none";

                        const arrowCanvas = document.getElementById("arrowCanvas-overshow");
                        if (arrowCanvas !== null) {
                            document.getElementById("arrowCanvas-overshow").style.display = "none";
                        }

                        const element = document.getElementById("luckysheet-postil-overshow-style-fiki");
                        if (element !== null) {
                            document.getElementById("luckysheet-postil-overshow-style-fiki").style.display = "none";
                        }
                    }
                } else if (scrollType === 'y') {
                    document.getElementById("luckysheet-dataVerification-dropdown-btn").style.setProperty('display', 'none', 'important');
                    const luckysheetContainer = luckysheetContainerX;
                    if (luckysheetContainer.scrollLeft > 0) {
                        document.getElementById("luckysheet-dataVerification-dropdown-List").style.display = "none";
                        document.getElementById("luckysheet-input-box").style.display = "none";
                        document.getElementById("luckysheet-input-box-index").style.display = "none";

                        const arrowCanvas = document.getElementById("arrowCanvas-overshow");
                        if (arrowCanvas !== null) {
                            document.getElementById("arrowCanvas-overshow").style.display = "none";
                        }

                        const element = document.getElementById("luckysheet-postil-overshow-style-fiki");
                        if (element !== null) {
                            document.getElementById("luckysheet-postil-overshow-style-fiki").style.display = "none";
                        }
                        if (selectedElement) {
                            if (c < 1) {

                                widthelement.push(selectedElement.style.left);
                            }
                            if (freezen_3) {
                                freezen_3.style.zIndex = '20';
                            }
                            //selectedElement.style.backgroundColor = 'red';
                            selectedElement.style.zIndex = '2000';
                            //console.log("total scrolling "+luckysheetContainer.scrollLeft);
                            let total = parseFloat(widthelement[index]) + parseFloat(luckysheetContainer.scrollLeft);
                            // console.log("Total left + total scrolling "+total);
                            selectedElement.style.left = total + 'px';
                        }
                        //filterColumnIcon.style.left = luckysheetContainer.scrollLeft + 'px';
                    } else {
                        document.getElementById("luckysheet-dataVerification-dropdown-List").style.display = "none";
                        document.getElementById("luckysheet-input-box").style.display = "none";
                        document.getElementById("luckysheet-input-box-index").style.display = "none";

                        const arrowCanvas = document.getElementById("arrowCanvas-overshow");
                        if (arrowCanvas !== null) {
                            document.getElementById("arrowCanvas-overshow").style.display = "none";
                        }

                        const element = document.getElementById("luckysheet-postil-overshow-style-fiki");
                        if (element !== null) {
                            document.getElementById("luckysheet-postil-overshow-style-fiki").style.display = "none";
                        }
                        if (freezen_3) {
                            freezen_3.style.zIndex = '10';
                        }
                    }
                }
            }
        });

        if (c < 1) {
            // console.log("element " + widthelement);
        }
        c++;
    }
};

export default helperFilter;
