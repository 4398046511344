import React, { useEffect, useState } from 'react';
import axios from 'axios';
import $ from 'jquery';
import '../css/Login.css';
import '../css/Materialize.css';
import '../css/Style.css';
import '../css/new.css';
import reloadToken from '../helper/reloadtoken';
import helperFilter from '../helper/helperfilter';
import HeaderMenu from '../template/HeaderMenu';
import { apiConfig } from '../helper/apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dataformula from '../sheetFormula.js';

const tipedata = "FCL";
const host = process.env.REACT_APP_API_URL_ONLINE;
const hostdrop = process.env.REACT_APP_API_HOST_DROPDOWN;
const hostfcl = process.env.REACT_APP_API_HOST_FCL;
const hostdatcont = process.env.REACT_APP_API_HOST_DATA_CONT;
const hostcc = process.env.REACT_APP_API_HOST_COLOR_COMMENT;

const notify_cant_update_br_cont = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 500,
  });
};
const notify_update_br_cont = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 500,
  });
};

const FCLadds = () => {
  const luckysheet = window.luckysheet;
  const [token, setToken] = useState(null);
  var b = 0;
  const pagecolumn = "FCLCOLUMN"
  var columnWidths = [];
  var kiri, atas, tinggi;
  var options = null;
  let valueppn = "YA,TIDAK";
  let valueukuran = "20,40,45";
  let valuestatusbl = "TELEX,DRAFT,ORI,HBL";
  let dataserver = [];

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Step 1
  const [selectedMonth, setSelectedMonth] = useState(1); // Default to January (1)

  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value, 10));
  };

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, index) => currentYear - index);

  const onYearChange = (year) => {
    setSelectedYear(year);
  };

  const handleSearchClick = async () => {
    try {
      const payload = {
        month: selectedMonth,
        year: selectedYear,
      };

      const newToken = await reloadToken()
      setToken(newToken);
      if (newToken) {
         initializeLuckySheet(newToken,selectedMonth,selectedYear);
      }
      console.log('Search results:');
    } catch (error) {
      console.error('Error searching:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 100));
      const newToken = await reloadToken()
      setToken(newToken);
      if (newToken) {
        initializeLuckySheet(newToken, null, null);
      }
    };
    fetchData();
    const handleVisibilityChange = () => {
      if (document.hidden) {
      } else {
        if (luckysheet) { 
          luckysheet.refresh();
        } else{
          window.location.reload();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  const initializeLuckySheet = async (token, bln, thn) => {
    let datacari = [];
    datacari.push(bln+"-"+thn)
    console.log(datacari);
    // const formData = new FormData();
    // formData.append("bln", bln);
    // formData.append("thn", thn);

    // formData.forEach((value, key) => {
    //   console.log(key + ': ' + value);
    // });

    new Promise((resolve) => setTimeout(resolve, 100));
    const config = apiConfig(token);

    const getColumnNames = async () => {
      try {
        const config = apiConfig(token);
        const response = await axios.post(`${hostfcl}ReactBrcontainerDetailColumn`, null, config);
        return response.data.column;
      } catch (error) {
        console.error('Error fetching column names:', error);
        return [];
      }
    };

    const getCellData = async (data, savedColumnWidths) => {
      try {
        const response = await axios.post(`${hostfcl}ReactBrcontainersDetail`, {data, savedColumnWidths, datacari }, config);
        return response.data;
      } catch (error) {
        console.error('Error fetching cell data:', error);
        return [];
      }
    };

    const getDropdownMarking = async (data) => {
      try {
        const response = await axios.get(data, config);
        return response.data;
      } catch (error) {
        console.error('Error fetching column names:', error);
        return [];
      }
    };

    await new Promise((resolve) => setTimeout(resolve, 1100));
    const marking = await getDropdownMarking(`${hostdrop}MasterDropdownReact/marking/FCL`);
    const markingString = marking.join(',');

    const dropPort_ = await getDropdownMarking(`${hostdrop}MasterDropdownReact/port/FCL`);
    const dropPort = dropPort_.join(',');

    const dropStatusbl_ = await getDropdownMarking(`${hostdrop}MasterDropdownReact/statusbl/FCL`);
    const dropStatusbl = dropStatusbl_.join(',');

    const dropCnee_ = await getDropdownMarking(`${hostdrop}MasterDropdownReact/cnee/FCL`);
    const dropCnee = dropCnee_.join(',');

    const dropPol_ = await getDropdownMarking(`${hostdrop}MasterDropdownReact/pol/FCL`);
    const dropPol = dropPol_.join(',');

    const dropAdmin_ = await getDropdownMarking(`${hostdrop}MasterDropdownReact/admin/FCL`);
    const dropAdmin = dropAdmin_.join(',');

    const dropKonseptor_ = await getDropdownMarking(`${hostdrop}MasterDropdownReact/konseptor/FCL`);
    const dropKonseptor = dropKonseptor_.join(',');

    const dropSales_ = await getDropdownMarking(`${hostdrop}MasterDropdownReact/sales/FCL`);
    const dropSales = dropSales_.join(',');

    const dropTipeFcl_ = await getDropdownMarking(`${hostdrop}MasterDropdownReact/tipe_FCL`);
    const dropTipeFcl = dropTipeFcl_.join(',');

    const nama_column = await getColumnNames();

    const data_pilihan = ["custom", "kapal", "fe", "gudang", "biaya_lain", "inv_ppn_br"];
    let datahasil = [];
    const cariindex = data_pilihan.map(columnName => nama_column.indexOf(columnName));
    for (let i = 0; i < cariindex.length; i++) {
      const columnNumber = cariindex[i];
      const columnPosition = getColumnPosition(columnNumber);
      datahasil.push(columnPosition)
    }

    let savedColumnWidths = JSON.parse(localStorage.getItem(pagecolumn));
    if (savedColumnWidths === null || Object.keys(savedColumnWidths).length === 0) {
      savedColumnWidths = null;
    } else {
      savedColumnWidths = savedColumnWidths;
    }

    let cellDataJsonA = [];
    cellDataJsonA = await getCellData(datahasil, savedColumnWidths);
    const totaldata = cellDataJsonA.totaldata + 1;

    if (cellDataJsonA.length > 0 || cellDataJsonA.data !== undefined) {
      dataserver = cellDataJsonA.data;
    } else {
      dataserver = [];
    }

    let dataCount
    if (Array.isArray(cellDataJsonA)) {
      dataCount = cellDataJsonA.length;
    }
    // console.log(cellDataJsonA.calc);

    options = {
      container: "luckysheet",
      title: "DataCont",
      lang: 'en',
      showinfobar: false,
      allowCopy: true,
      allowEdit: true,
      column: 0,
      filter: {
        ref: 'A1:C1', // Specify the range where you want the filter (row 0 and column 2)
      },
      forceCalculation: true,
      showtoolbarConfig: {
        undoRedo: false, //Undo redo
        paintFormat: false, //Format brush
        currencyFormat: false, //currency format
        percentageFormat: false, //Percentage format
        numberDecrease: false, //'Decrease the number of decimal places'
        numberIncrease: false, //'Increase the number of decimal places
        moreFormats: true, //'More Formats'
        font: false, //'font'
        fontSize: false, //'Font size'
        bold: false, //'Bold (Ctrl+B)'
        italic: false, //'Italic (Ctrl+I)'
        strikethrough: false, //'Strikethrough (Alt+Shift+5)'
        underline: false, // 'Underline (Alt+Shift+6)'
        textColor: false, //'Text color'
        fillColor: true, //'Cell color'
        border: false, //'border'
        mergeCell: false, //'Merge cells' 
        horizontalAlignMode: false, //'Horizontal alignment'
        verticalAlignMode: false, //'Vertical alignment'
        textWrapMode: true, //'Wrap mode'
        textRotateMode: false, //'Text Rotation Mode'
        image: false, // 'Insert picture'
        link: false, // 'Insert link'
        chart: false, //'chart' (the icon is hidden, but if the chart plugin is configured, you can still create a new chart by right click)
        postil: true, //'comment'
        pivotTable: false, //'PivotTable'
        function: true, //'formula'
        frozenMode: false, //'freeze mode'
        sortAndFilter: true, //'Sort and filter'
        conditionalFormat: true, //'Conditional Format'
        dataVerification: false, // 'Data Verification'
        splitColumn: false, //'Split column'
        screenshot: false, //'screenshot'
        findAndReplace: false, //'Find and Replace'
        protection: false, // 'Worksheet protection'
        print: false, // 'Print'

      },
      cellRightClickConfig: {
        copy: false, // copy
        copyAs: false, // copy as
        paste: false, // paste
        insertRow: false, // insert row
        insertColumn: false, // insert column
        deleteRow: false, // delete the selected row
        deleteColumn: false, // delete the selected column
        deleteCell: false, // delete cell
        hideRow: false, // hide the selected row and display the selected row
        hideColumn: false, // hide the selected column and display the selected column
        rowHeight: false, // row height
        columnWidth: false, // column width
        clear: false, // clear content
        matrix: false, // matrix operation selection
        sort: false, // sort selection
        filter: false, // filter selection
        chart: false, // chart generation
        image: false, // insert picture
        link: false, // insert link
        data: false, // data verification
        cellFormat: false // Set cell format
      },
      data: [dataserver],
      enableAddRow: false,
      textWrapMode: true,
      // hook: {
      //   cellRenderAfter: function (cell, position, sheetFile, ctx) {
      //     if (b < 1) {
      //       const data = [];
      //       const endIndex = 150;
      //       for (let i = 0; i <= endIndex; i++) {
      //         data.push(i);
      //       }
      //       var columnWidths = luckysheet.getColumnWidth(data);
      //       const colWidthA = columnWidths[1];
      //       //console.log(`Width of column B: ${colWidthA}`);
      //       for (let columnIndex of data) {
      //         const width = columnWidths[columnIndex];
      //         columnWidths[columnIndex.toString()] = width;
      //       }
      //       localStorage.setItem(pagecolumn, JSON.stringify(columnWidths));
      //       setTimeout(function () {
      //         b = 0;
      //       }, 500);
      //     }
      //     b++;
      //   },

      //   rangePasteBefore: function (range, data) {
      //     // return false; //Can intercept paste
      //   },

      //   commentUpdateBefore: async function (r, c, value) {
      //     const id = luckysheet.getCellValue(r, 0);
      //     const column = nama_column[c];
      //     const formData = new FormData();
      //     formData.append("code_1", id);
      //     formData.append("code_2", tipedata);
      //     formData.append("code_3", "");
      //     formData.append("row", r);
      //     formData.append("col", c);
      //     formData.append("comment", removeCrLfFromString_(removeCrLfFromString(value)));
      //     formData.append("column", column);
      //     try {
      //       const response = await axios.post(`${hostcc}Updatecomment`, formData, config);
      //       //console.log(response.data);
      //       notify_update_br_cont("Note sukses di tambahkan");
      //     } catch (error) {
      //       console.error('Error fetching data:', error);
      //     }
      //   },
      //   cellEditBefore: function (r, c, value, isRefresh) {
      //     // console.log("Edit before");
      //     // console.log(value);
      //   },

      //   cellUpdateBefore: function (r, c, value, isRefresh) {
      //     if (!isValidFormula(value)) {
      //       //console.info('cellUpdateBefore', r, c, value, isRefresh);
      //       return true;
      //     } else {
      //       //console.warn('Validation failed for cellUpdateBefore', r, c, value, isRefresh);
      //       return false;
      //     }
      //   },

      //   cellUpdated: function (r, c, oldValue, newValue, isRefresh) {
      //     if (oldValue && newValue) {
      //       const newData = newValue.m;
      //       const id = luckysheet.getCellValue(r, 0);
      //       var datax = {};
      //       var nilai = newData;
      //       if (nama_column[c] == "harga_custom" || nama_column[c] == "harga_kapal" || nama_column[c] == "lainnya" || nama_column[c] == "Jual") {
      //         nilai = parseFloat(nilai);
      //       }

      //       if (nilai !== null && nilai !== undefined && nilai !== "") {
      //         datax[nama_column[c]] = nilai;
      //       } else {
      //         datax[nama_column[c]] = "";// console.log(oldValue.m);
      //       }

      //       if (nilai !== null && nilai !== undefined && nilai !== "") {
      //         if (data_pilihan.includes(nama_column[c])) {
      //           datax[nama_column[c]] = nilai.replace(/,/g, '');
      //         } else {
      //           datax[nama_column[c]] = nilai;
      //         }
      //       } else {
      //         datax[nama_column[c]] = "";
      //       }
      //       if (oldValue.m !== newValue.m) {
      //         if (oldValue !== null && oldValue.m != newData && nama_column[c] != "") {
      //           datax["id"] = id;
      //           if (validasidropdown(nama_column[c], nilai)) {
      //             axios.post(`${hostfcl}React_UpdateBrcontainersDetail`, datax, config)
      //               .then(response => {
      //                 if (response.data.status == "success")
      //                   notify_update_br_cont("Data sukses di proses");
      //                 luckysheet.refresh();
      //               })
      //               .catch(error => {
      //                 console.error('Error fetching data:', error.response.data.message);
      //                 notify_cant_update_br_cont(error.response.data.message);
      //                 luckysheet.refresh();
      //               });
      //           } else {
      //             notify_cant_update_br_cont("Data tidak valid");
      //           }
      //         }
      //       } else {
      //         // console.log(oldValue);
      //         // console.log(newValue);
      //       }
      //     }
      //   },
      //   cellEditBefore: function (range) {
      //     const c = range[0].column[0];
      //     var title_column_select = luckysheet.getCellValue(0, c);
      //     //console.log(title_column_select);
      //   },
      //   rangeSelect: function (index, sheet) {
      //     //console.log(sheet)
      //     var top = sheet[0].top;
      //     var left = sheet[0].left;
      //     var height = sheet[0].height;
      //     kiri = left;
      //     atas = top;
      //     tinggi = height;
      //     filter_data(top, left, height);
      //   },
      // },
    }

    options.loading = {
      image: () => {
        return `<svg viewBox="25 25 50 50" class="circular">
        <circle cx="50" cy="50" r="20" fill="none"></circle>
        </svg>`
      },
      imageClass: "loadingAnimation"
    }
    luckysheet.create(options)

    function getColumnPosition(columnNumber) {
      let dividend = columnNumber + 1;
      let columnName = '';

      while (dividend > 0) {
        const modulo = (dividend - 1) % 26;
        columnName = String.fromCharCode(65 + modulo) + columnName;
        dividend = Math.floor((dividend - modulo) / 26);
      }
      return columnName;
    }

    const luckysheetContainer = document.getElementById('luckysheet');
    luckysheetContainer.addEventListener('scroll', () => {
      const scrollLeft = luckysheetContainer.scrollLeft;
      const frozenColumns = document.querySelectorAll('.luckysheet-cell.luckysheet-cell-frozen');
      frozenColumns.forEach((column) => {
        column.style.transform = `translateX(${scrollLeft}px)`;
      });
    });

    setTimeout(async function () {
      helperFilter(11);
      if (typeof (luckysheet) !== 'undefined') {
        luckysheet.setBothFrozen(true, { range: { row_focus: 0, column_focus: 10 } });
      }
    }, 1000);
  };

  const luckyCss = {
    margin: '120px 0px 0px 0px',
    padding: '0px',
    position: 'absolute',
    width: '100%',
    height: '90%',
    left: '0px',
    top: '0px',
  };

  const forumLinkStyle = {
    zIndex: 2,
    width: '50px',
    height: '50px',
    lineHeight: '50px',
    position: 'fixed',
    right: '40px',
    bottom: '86px',
    borderRadius: '50px',
    cursor: 'pointer',
    background: 'rgb(71,133,249)',
    color: '#fff',
    textAlign: 'center',
    textDecoration: 'none',
  };

  const selectStyle = {
    // Define your custom CSS styles here
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '2px',
    fontSize: '16px',
    width: '10%',
    height: '10%',
    marginTop: '10px',
  };

  return (
    <div>
      <div id="top">
        <HeaderMenu title="Halaman FCL" subtitle={"Halaman untuk data FCL"} />
      </div>
      <ToastContainer />
      <select style={selectStyle} value={selectedMonth} onChange={handleMonthChange}>
        <option value={1}>January</option>
        <option value={2}>February</option>
        <option value={3}>March</option>
        <option value={4}>April</option>
        <option value={5}>May</option>
        <option value={6}>June</option>
        <option value={7}>July</option>
        <option value={8}>August</option>
        <option value={9}>September</option>
        <option value={10}>October</option>
        <option value={11}>November</option>
        <option value={12}>December</option>
      </select>
      <select style={selectStyle} value={selectedYear} onChange={(e) => onYearChange(parseInt(e.target.value))}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
      <button onClick={handleSearchClick}>Search</button>
      <div id="luckysheet" style={luckyCss} />
    </div>
  );
};


export default FCLadds;