import React, { useEffect, useState } from 'react';
import axios, { formToJSON } from 'axios';
import $ from 'jquery';

const Blueray_Employee = () => {
  const [token, setToken] = useState(null);
  const [chart, setChart] = useState(null);
  const [data, setData] = useState([]); // State to hold fetched data
  data.employee = data.employee || [];
  // Replace with your actual API endpoint URL
  const emp_list = 'http://brapp.blueray.id:81/K2CuOxJXGnDTCI6/emp/list_emp';
  useEffect(() => {
    // setToken
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbiI6ZmFsc2UsImV4cCI6MTcxNjg4NDM0MywibWFzdGVyX2ZpZWxkIjpbImFsbCJdLCJuYW1lIjoiQW5kcmkiLCJyb2xlcyI6ImFsbCJ9.Hf09ruSd9ldJQl3IywdwUYXdOrOJ0KGUyO8V-TllXP0";
    const fetchData = async () => {
      try {

        const formData = new FormData();
        formData.append('atasan', 'BR');
        const response = await fetch(emp_list, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({atasan: 'BR'}),
        });
        if (!response.ok) {
          throw new Error(`API request failed with status ${response.status}`);
        }

        const responseData = await response.json();
        setData(responseData);
        console.log(responseData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    
  }, []);

  const renderOrg = (key, node,sub) => (
      <li key={node?.head || node?.id}> {/* Use head or id for unique key */}
        <a href="#">
          {sub==1 && <img src="http://placehold.it/110x110" alt={node?.head || 'Employee'} />}
          
          {sub==0 && <img src="http://placehold.it/25x25" alt={node?.head || 'Employee'} />}
          {node?.head || 'Employee'}  {/* Display label (head or default) */}
        </a>
        {node?.employee && node?.employee.length > 0 && (
          <ul className="ul_tree">
            {node.employee.map((child) => renderOrg(child.id, child,0))}
          </ul>
        )}
      
      </li>
  );
  
  	return (
      <div id="chart-container"></div>
		);
};

export default Blueray_Employee;