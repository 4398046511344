// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 10px auto;
  width: 400px;
}

.drop-zone {
  width: 100%;
  height: 150px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  background-color: #eee;
}

.drop-zone p {
  font-size: 16px;
  color: #aaa;
}

button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  /* margin-top: 5px; */
  cursor: pointer;
  border-radius: 5px;
  opacity: 0.9;
  transition: 0.3s;
}

button:hover {
  opacity: 1;
}

button[disabled] {
  background-color: #ccc;
  cursor: default;
  opacity: 0.6;
}
`, "",{"version":3,"sources":["webpack://./src/css/uploadFile.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;EACrB,qBAAqB;EACrB,eAAe;EACf,qBAAqB;EACrB,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,YAAY;AACd","sourcesContent":[".upload-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n  border: 1px solid #ddd;\n  border-radius: 5px;\n  margin: 10px auto;\n  width: 400px;\n}\n\n.drop-zone {\n  width: 100%;\n  height: 150px;\n  border: 2px dashed #ccc;\n  border-radius: 5px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  margin-bottom: 10px;\n  background-color: #eee;\n}\n\n.drop-zone p {\n  font-size: 16px;\n  color: #aaa;\n}\n\nbutton {\n  background-color: #4CAF50;\n  border: none;\n  color: white;\n  padding: 10px 20px;\n  text-align: center;\n  text-decoration: none;\n  display: inline-block;\n  font-size: 16px;\n  /* margin-top: 5px; */\n  cursor: pointer;\n  border-radius: 5px;\n  opacity: 0.9;\n  transition: 0.3s;\n}\n\nbutton:hover {\n  opacity: 1;\n}\n\nbutton[disabled] {\n  background-color: #ccc;\n  cursor: default;\n  opacity: 0.6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
