// Header.js
import { useEffect, useState } from 'react';
import '../css/StylesHeader.css';

const Header = ({ title, subtitle }) => {
  const [pengguna, setPengguna] = useState("");
  const [role, setRole] = useState("");
  const [currentDate, setCurrentDate] = useState('');

  function toSentenceCase(str) {
    if (typeof str !== 'string') return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  useEffect(() => {
    const pengguna = localStorage.getItem('pengguna');  
    const role = localStorage.getItem('role');  
    setPengguna(toSentenceCase(pengguna));
    setRole(role);

    const updateDate = () => {
      const today = new Date();
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const formattedDate = today.toLocaleDateString('ID', options);
      setCurrentDate(formattedDate);
    };
    updateDate();
    const intervalId = setInterval(updateDate, 1000);
    return () => clearInterval(intervalId);
    // return () => {
    //   clearInterval(intervalId);
    //   clearInterval(intervalId1);
    // };
  }, []);

  return (
    <header key="0" id="0">
      <div className="container-main-view">
        <div className="header">
          <div className="icon">
            <img src='/images/logoBR.png' height="35" alt="Icon" />
          </div>
          <div className="title" style={{textAlign:'left'}}>
            <h1><strong>{title}</strong></h1>
            <p>{subtitle}</p>
          </div>
          <div className="iconprofils" style={{ verticalAlign: 'top', display: 'flex', alignItems: 'center' }}>
            <div style={{float:'right',textAlign:'right', marginRight:'10px'}}><span>Hallo, {pengguna} </span>
            <div style={{ marginBottom: '-5px' }}></div>
            <span style={{fontSize:'10px',fontWeight:'bold'}}>{role} | {currentDate}</span></div>
            <img id="image_loading" style={{ display: 'none' }} src="/app-assets/css/loadi.gif" height="32" alt="Icon" />
            <img src="/images/personal.png" height="35" alt="Icon" title="User" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;


