// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form_menu {
  height: 90vh;
  overflow: auto;
}


.mn_form_row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;
}

.mn_form_row_title {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1px;
}

.mn-card-template {
    width: 350px;
    height: 100px;
    /* display: block; */
    position: relative;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

}

.mn-card-body {
    padding: 10px;
    text-align: left;
    background-color: #fff;
    border-radius: 5px;
}

.mn-card-body:active {
  background-color: #c2eeff;
}

.mn-row {
    display: flex;
  }
  

.mn-column {
    flex: 50% 1;
    padding: 3px;
  }
  .mn-column-left {
    flex: 90% 1;
    padding: 3px;
  }
  .mn-column-right {
    flex: 30% 1;
    padding: 3px;
  }

  h6{
    font-weight: bold !important;
  }
  
  .menu_detail{
    font-size: 14px;
    color: #4a4a4a;
  }
  .title_menu{
    float: left;
    padding: 5px 15px;
    color: #3d3d3d;
    font-size: 16px;
    font-weight: bold;
  }

  .top{
    margin-top: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/css/mn.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc;AAChB;;;AAGA;IACI,aAAa;IACb,eAAe;IACf,kBAAkB;AACtB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,kBAAkB;AACpB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,oBAAoB;IACpB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,sBAAsB;IACtB,uCAAuC;;AAE3C;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;IACI,aAAa;EACf;;;AAGF;IACI,WAAS;IACT,YAAY;EACd;EACA;IACE,WAAS;IACT,YAAY;EACd;EACA;IACE,WAAS;IACT,YAAY;EACd;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,eAAe;IACf,cAAc;EAChB;EACA;IACE,WAAW;IACX,iBAAiB;IACjB,cAAc;IACd,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[".form_menu {\n  height: 90vh;\n  overflow: auto;\n}\n\n\n.mn_form_row {\n    display: flex;\n    flex-wrap: wrap;\n    margin-bottom: 5px;\n}\n\n.mn_form_row_title {\n  display: flex;\n  flex-wrap: wrap;\n  margin-bottom: 1px;\n}\n\n.mn-card-template {\n    width: 350px;\n    height: 100px;\n    /* display: block; */\n    position: relative;\n    border-radius: 4px;\n    margin-top: 10px;\n    margin-bottom: 10px;\n    margin-left: 10px;\n    margin-right: 10px;\n    border: 1px solid #ccc;\n    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);\n\n}\n\n.mn-card-body {\n    padding: 10px;\n    text-align: left;\n    background-color: #fff;\n    border-radius: 5px;\n}\n\n.mn-card-body:active {\n  background-color: #c2eeff;\n}\n\n.mn-row {\n    display: flex;\n  }\n  \n\n.mn-column {\n    flex: 50%;\n    padding: 3px;\n  }\n  .mn-column-left {\n    flex: 90%;\n    padding: 3px;\n  }\n  .mn-column-right {\n    flex: 30%;\n    padding: 3px;\n  }\n\n  h6{\n    font-weight: bold !important;\n  }\n  \n  .menu_detail{\n    font-size: 14px;\n    color: #4a4a4a;\n  }\n  .title_menu{\n    float: left;\n    padding: 5px 15px;\n    color: #3d3d3d;\n    font-size: 16px;\n    font-weight: bold;\n  }\n\n  .top{\n    margin-top: 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
