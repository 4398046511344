import React, { useEffect } from 'react';
import axios from 'axios';
import '../css/Login.css';
import '../css/Materialize.css';
import '../css/Style.css';
import HeaderMenu from '../template/HeaderMenu';

const host = process.env.REACT_APP_API_URL_ONLINE;

const LuckySheet = () => {
  useEffect(() => {
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const getColumnNames = async () => {
      try {
        const response = await axios.post(`${host}ReactBrcontainerDetailColumn`, null, config);
        return response.data;
      } catch (error) {
        console.error('Error fetching column names:', error);
        return [];
      }
    };

    
    const getCellData = async () => {
      try {
        const response = await axios.post(`${host}ReactBrcontainersDetail`, null, config);
        return response.data;
      } catch (error) {
        console.error('Error fetching cell data:', error);
        return [];
      }
    };
    

    // Function to initialize LuckySheet
    const initializeLuckySheet = async () => {
      const nama_column = await getColumnNames();
      const cellDataJsonA = await getCellData();

      const luckysheet = window.luckysheet;
      luckysheet.create({
        title: "DataCont",
        showinfobar: false,
        showtoolbarConfig:{
          undoRedo: false, //Undo redo
          paintFormat: false, //Format brush
          currencyFormat: false, //currency format
          percentageFormat: false, //Percentage format
          numberDecrease: false, //'Decrease the number of decimal places'
          numberIncrease: false, //'Increase the number of decimal places
          moreFormats: false, //'More Formats'
          font: false, //'font'
          fontSize: false, //'Font size'
          bold: false, //'Bold (Ctrl+B)'
          italic: false, //'Italic (Ctrl+I)'
          strikethrough: false, //'Strikethrough (Alt+Shift+5)'
          underline: false, // 'Underline (Alt+Shift+6)'
          textColor: false, //'Text color'
          fillColor: true, //'Cell color'
          border: false, //'border'
          mergeCell: false, //'Merge cells'
          horizontalAlignMode: false, //'Horizontal alignment'
          verticalAlignMode: false, //'Vertical alignment'
          textWrapMode: true, //'Wrap mode'
          textRotateMode: false, //'Text Rotation Mode'
          image:false, // 'Insert picture'
          link:false, // 'Insert link'
          chart: false, //'chart' (the icon is hidden, but if the chart plugin is configured, you can still create a new chart by right click)
          postil: true, //'comment'
          pivotTable: false, //'PivotTable'
          function: false, //'formula'
          frozenMode: false, //'freeze mode'
          sortAndFilter: false, //'Sort and filter'
          conditionalFormat: true, //'Conditional Format'
          dataVerification: false, // 'Data Verification'
          splitColumn: false, //'Split column'
          screenshot: false, //'screenshot'
          findAndReplace: false, //'Find and Replace'
          protection:false, // 'Worksheet protection'
          print:false, // 'Print'

        },
        cellRightClickConfig :{
          copy: true, // copy
          copyAs: false, // copy as
          paste: false, // paste
          insertRow: false, // insert row
          insertColumn: false, // insert column
          deleteRow: false, // delete the selected row
          deleteColumn: false, // delete the selected column
          deleteCell: false, // delete cell
          hideRow: false, // hide the selected row and display the selected row
          hideColumn: false, // hide the selected column and display the selected column
          rowHeight: false, // row height
          columnWidth: false, // column width
          clear: false, // clear content
          matrix: false, // matrix operation selection
          sort: false, // sort selection
          filter: false, // filter selection
          chart: false, // chart generation
          image: false, // insert picture
          link: false, // insert link
          data: false, // data verification
          cellFormat: false // Set cell format
        },
        data: [
          {
            name: "Sheet",
            color: "",
            row: 20000,
            index: "0",
            celldata: cellDataJsonA,
          },
        ],
        container: "luckysheet",
        textWrapMode: true,
        hook : {
          commentUpdateBefore: function (r, c, value) {
              const id = luckysheet.getCellValue(r, 0);
              var datax = {};
              datax[nama_column[c]] = value;
              datax["id"] = id;
              console.log(datax);

          },
          cellUpdated: function(r, c, oldValue, newValue, isRefresh){
            const newData = newValue.m;          
            const id = luckysheet.getCellValue(r, 0);
            var datax = {};
            datax[nama_column[c]] = newData;
            datax["id"] = id;
            // $.ajax({
            //     url: 'http://127.0.0.1:8080/BrcontainersNew',
            //     type: "POST",
            //   //   data: {
            //   //     id: id,
            //   //     nama_column[c]: newData,
            //   //     column: c,
            //   //  },
            //   data: datax,

  
            // });
            // console.log(id);
            // console.log(newData);
            // console.log(c);
          }
        },
      });


      // Rest of your LuckySheet initialization code
      // ...
    };

    initializeLuckySheet();
  }, []); // The empty array as the second argument ensures this useEffect runs only once on component mount

  const luckyCss = {
    margin: '75px 0px 0px 0px',
    padding: '0px',
    position: 'absolute',
    width: '100%',
    height: '90%',
    left: '0px',
    top: '0px',
  };

  return (
    <div>
    <HeaderMenu title="Halaman Login" subtitle="Ini adalah sub title"/>
    <div id="luckysheet" style={luckyCss} />
  </div>
  );
};

export default LuckySheet;

