import React from "react";
import LoadingM from "../../ceisa/ceisaview/Ld1";

const Manifest = ({
  isCollapsedManifest,
  tg_collapse_manifest,
  isCollapsedManifestDetail,
  tg_collapse_manifestDetail,
  hi_no_bl,
  val_no_bl,
  hi_tgl_bl,
  val_tgl_bl,
  hi_kode_kantor,
  val_kode_kantor,
  val_use_skb,
  set_use_skb,
  set_no_skb,
  set_tgl_skb,
  val_no_skb,
  val_tgl_skb,
  val_detail_manifest,
  val_message_manifest,
  isLoadingM,
}) => {
  return (
    <div className="C_form-container">
      <div
        className="title_form"
        onClick={tg_collapse_manifest}
        style={{ cursor: "pointer" }}
      >
        Manifest
      </div>
      <div className={isCollapsedManifest ? "hidden" : "visible"}>
        <div className="space"></div>
        <div style={{ display: "flex", alignItems: "start" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div
                className="C_form-row"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "baseline",
                }}
              >
                <table border="0" style={{ width: "80%" }}>
                  <tr valign="">
                    <td className="tal" width={"20%"}>
                      <label className="C_form-label">No BL</label>
                    </td>
                    <td className="tal" width={"15%"}>
                      <label className="C_form-label">Tanggal BL</label>
                    </td>
                    <td className="tal" width={"15%"}>
                      <label className="C_form-label">Kode Kantor</label>
                    </td>
                    <td className="tal" style={{ textWrap: "nowrap" }}>
                      <label className="C_form-label">Use SKB</label>
                    </td>
                    <td className="tal" width={"25%"}>
                      <label className="C_form-label">Dokumen SKB</label>
                    </td>
                    <td className="tal" width={"25%"}>
                      <label className="C_form-label">Tanggal SKB</label>
                    </td>
                  </tr>
                  <tr>
                    <td className="tal">
                      <input
                        type="text"
                        placeholder="Nomor BL"
                        onChange={hi_no_bl}
                        value={val_no_bl}
                        className="C_form-input margin-left-0"
                        style={{ minWidth: "95%" }}
                      />
                    </td>
                    <td className="tal">
                      <input
                        type="date"
                        placeholder="Tanggal BL"
                        onChange={hi_tgl_bl}
                        value={val_tgl_bl}
                        className="C_form-input margin-left-0"
                        style={{ minWidth: "85%" }}
                      />
                    </td>
                    <td className="tal">
                      <input
                        type="text"
                        placeholder="Kode kantor"
                        onChange={hi_kode_kantor}
                        value={val_kode_kantor}
                        className="C_form-input margin-left-0"
                        style={{ minWidth: "90%" }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={val_use_skb}
                        onChange={set_use_skb}
                        style={{
                          width: "15px",
                          height: "15px",
                          transform: "scale(1.5)",
                          WebkitTransform: "scale(1.5)",
                        }}
                      />
                    </td>
                    <td className="tal">
                      <input
                        type="text"
                        placeholder="No SKB"
                        onChange={set_no_skb}
                        value={val_no_skb}
                        className="C_form-input margin-left-0"
                        style={{ minWidth: "90%" }}
                      />
                    </td>
                    <td>
                      <input
                        type="date"
                        onChange={set_tgl_skb}
                        value={val_tgl_skb}
                        className="C_form-input margin-left-0"
                        style={{ minWidth: "90%" }}
                      />
                    </td>
                    <td>
                      <button
                        class="styled-button mini index1"
                        style={{ backgroundColor: "#2a2a6e" }}
                        onClick={tg_collapse_manifestDetail}
                      >
                        Detail
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={isCollapsedManifestDetail ? "hidden" : "visible"}>
        <div className="C_form-container">
          {isLoadingM && <LoadingM />}
          <div
            className="title_form"
            onClick={tg_collapse_manifestDetail}
            style={{ cursor: "pointer" }}
          >
            Detail Manifest
          </div>
          <div
            style={{
              textAlign: "left",
              borderBottom: "1px solid",
              maxWidth: "max-content",
            }}
          >
            {val_message_manifest && val_message_manifest.length > 0 && (
              <div
                style={{
                  textAlign: "left",
                }}
              >
                <b style={{ color: "#FFD700" }}>CAUTION!</b> : &nbsp;
                <ul style={{ paddingLeft: "20px" }}>
                  {val_message_manifest.map((match, index) => (
                    <li
                      key={index}
                      style={{ fontWeight: "bold", fontSize: "14px" }}
                    >
                      {match.toUpperCase()}!
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="tal">
            <pre>{val_detail_manifest}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Manifest;
