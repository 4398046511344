import { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import "select2";
import "../../css/Select2.css";
import "../../css/Login.css";
import "../../css/Materialize.css";
import "../../css/Style.css";
import "../../css/new.css";
import "../../css/ceisa.css";
import {
  convertDate,
  formatJsonToText,
  doc_CKMS,
  doc_UKMS,
  doc_DKMS,
  doc_PKMS,
  sortProperties,
  ProsesFileChange,
  checkPernyataanLartas,
} from "./hsfunc.js";
import Header from "../../template/Header";
import { apiConfig } from "../../helper/apiConfig";
import "@fortawesome/fontawesome-free/css/all.min.css";
import reloadToken from "../../helper/reloadtoken";
import "react-toastify/dist/ReactToastify.css";
import { column_barang, title_kemasan } from "./ceisaview/ColT";
import Manifest from "./ceisaview/manifest";
import MenuCsa from "./ceisaview/MenuCsa";
import HeaderCsa from "./ceisaview/HeaderCsa";
import ModalLartas from "../ceisa/ceisaview/LartasModal";

// external lib
import { ToastContainer, toast } from "react-toastify";
import { HotTable } from "@handsontable/react";
import { registerAllModules } from "handsontable/registry";

// custom local lib (helper)
import { formatTimestampToDateInput } from "../../helper/formatDate";
import { formatPrice } from "../../helper/formatPrice";

// custom component
import CustomMultiSelect from "./CustomMultiSelect";
import CustomSelect from "./CustomSelect";

const hostceisa = process.env.REACT_APP_API_HOST_CEISA;
const hostcsamaster = process.env.REACT_APP_API_HOST_MASTER_ENTITAS;

const fullURL = window.location.href;
const path = window.location.pathname;
const match = path.match(/\/ceisa\/([^/]+)/);

const rp_jenis_import = "rp_jenis_import-" + fullURL;
const rp_cara_bayar = "rp_cara_bayar-" + fullURL;

const notify_cant_update_br_cont = (message, time = 1200) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: time,
  });
};
const notify_update_br_cont = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 800,
  });
};

// Initials moduls HotTable (handsontable)
registerAllModules();

const CEISA1 = () => {
  const { nomorAju } = useParams();
  // Header dropwdown data
  const [dataHeader, setDataHeader] = useState({
    pelabuhanTujuan: [],
    caraBayar: [],
    pib: [],
    impor: [],
  });

  // Entity dropdown
  const [dataEntity, setDataEntity] = useState({
    importir: [],
    nib: [
      { id: 1, name: "API U" },
      { id: 2, name: "API P" },
    ],
    status: [{ name: "MITA" }, { name: "AEO" }, { name: "LAINNYA" }],
    owners: [],
    npwp: [],
    senders: [],
    sellers: [],
    countries: [],
  });

  const [dataCarrier, setCarrier] = useState({
    pu_codes: [
      {
        id: "11",
        name: "BC 1.1",
      },
      {
        id: "12",
        name: "BC 1.2",
      },
      {
        id: "14",
        name: "BC 1.4",
      },
    ],
    carriers: [
      {
        id: 1,
        name: "LAUT",
      },
    ],
    loads: [],
    heaps: [],
    transits: [],
  });

  // Transaction
  const valutas = [
    { code: "CNY", name: "CNY - YUAN RENMINBI" },
    { code: "USD", name: "USD - US DOLLAR" },
  ];

  const priceStuff = [
    {
      code: "CIF",
      name: "CIF - COST, INSURANCE AND FREIGHT",
    },
    {
      code: "FOB",
      name: "FOB - FREE ON BOARD",
    },
  ];

  const asurance = [
    {
      code: "LN",
      name: "LUAR NEGRI",
    },
    {
      code: "DN",
      name: "DALAM NEGRI",
    },
  ];

  // all entities
  const [entities, setEntities] = useState({});
  const [isCollapsedManifest, setisCollapsedManifest] = useState(false);
  const [isCollapsedManifestDetail, setisCollapsedManifestDetail] =
    useState(true);

  const [isCollapsedHeader, setisCollapsedHeader] = useState(false);
  const [isCollapsedEntitas, setisCollapsedEntitas] = useState(false);
  const [isCollapsedDocument, setisCollapsedDocument] = useState(false);
  const [isCollapsedDocumentBarang, setisCollapsedDocumentBarang] =
    useState(false);
  const [isCollapsedPengangkut, setisCollapsedPengangkut] = useState(false);
  const [isCollapsedKemasan, setisCollapsedKemasan] = useState(false);
  const [isCollapsedTransaksi, setisCollapsedTransaksi] = useState(false);
  const [isCollapsedBarang, setisCollapsedBarang] = useState(false);
  const [isCollapsedContainers, setisCollapsedContainers] = useState(false);

  const [tableHeight, setTableHeight] = useState(200);

  const [dropdownKemasan, setDropdownKemasan] = useState([]);

  const hotRefKemasan = useRef(null);
  const [typedocuents, setDropdownDataTypeDocuments] = useState([]);
  const [token, setToken] = useState(() => {
    // Retrieve the token from localStorage
    const savedToken = localStorage.getItem("token");
    // If there's a saved token, return it, otherwise return null
    return savedToken || null;
  });

  const [inputValue, setInputValue] = useState("");
  //manifest
  const [val_no_bl, setNoBl] = useState("");
  const [val_tgl_bl, setTglBl] = useState("");
  const [val_kode_kantor, setKodeKantor] = useState("");
  const [val_detail_manifest, setDetailManifest] = useState("");
  const [messageManifest, setMessageManifest] = useState([]);
  const [val_no_skb, setNoSkb] = useState("");
  const [val_useskb, setUseSkb] = useState(false);
  const [val_tgl_skb, setTglSkb] = useState("");

  const select_pel_tujuan = useRef(null);
  const [val_pel_tujuan, set_pet_tujuan] = useState("");
  const [val_kantor_pabeban, setKantorPabean] = useState("");
  const [val_jenis_pib, setJenisPib] = useState("");
  const select_jenis_import = useRef(null);
  const select_jenis_pib = useRef(null);
  const [val_jenis_import, setJenisImport] = useState("");
  const select_cara_bayar = useRef(null);
  const [val_cara_bayar, setCaraBayar] = useState("");
  //importir
  const select_importir = useRef(null);
  const [val_imp, setImportir] = useState("");
  const [val_imp_identitas, setImpIdentitas] = useState("");
  const [val_imp_no_identitas, setImpNoIdentitas] = useState("");
  const [val_imp_nama, setImpNama] = useState("");
  const [val_imp_alamat, setImpAlamat] = useState("");

  const [val_imp_api, setImpApi] = useState("");

  //pemilik brang
  const select_pemilik_barang = useRef(null);
  const [val_pb, setPb] = useState("");
  const [val_pb_identitas, setPbIdentitas] = useState("");
  const [val_pb_no_identitas, setPbNoIdentitas] = useState("");
  const [val_pb_nama, setPbNama] = useState("");
  const [val_pb_alamat, setPbAlamat] = useState("");

  // npwp center ref
  const select_npwp = useRef(null);
  const [val_npwp, setNpwp] = useState("");
  const [val_npwp_identitas, setNpwpIdentitas] = useState("");
  const [val_npwp_no_identitas, setNpwpNoIdentitas] = useState("");
  const [val_npwp_nama, setNpwpNama] = useState("");
  const [val_npwp_alamat, setNpwpAlamat] = useState("");

  // sender
  const select_sender = useRef(null);
  const [val_sd, setSd] = useState("");
  const [val_sd_identitas, setSdIdentitas] = useState("");
  const [val_sd_no_identitas, setSdNoIdentitas] = useState("");
  const [val_sd_nama, setSdNama] = useState("");
  const [val_sd_alamat, setSdAlamat] = useState("");
  const [val_sd_negara, setSdNegara] = useState("");
  // seller
  const select_seller = useRef(null);
  const [val_pj, setPj] = useState("");
  const [val_pj_identitas, setPjIdentitas] = useState("");
  const [val_pj_no_identitas, setPjNoIdentitas] = useState("");
  const [val_pj_nama, setPjNama] = useState("");
  const [val_pj_alamat, setPjAlamat] = useState("");
  const [val_pj_negara, setPjNegara] = useState("");
  const select_country_pengirim = useRef(null);
  const select_country_seller = useRef(null);
  // pengangkut ref & state
  const select_pu = useRef(null);
  const select_country_carrier = useRef(null);
  const select_loads_harbour = useRef(null);
  const select_transit_harbour = useRef(null);
  const select_heaps_harbour = useRef(null);
  const [val_tutup_pu, setValTutupPU] = useState("");
  const [nomor_tutup_pu, setNoTutupPU] = useState("");
  const [tanggal_tutup_pu, setTglTutupPU] = useState("");
  const [nomor_pos, setNoPosPU] = useState("");
  const [nomor_sub_pos, setNoSubPosPU] = useState("");
  const [nomor_sub_sub_pos, setNoSubSubPosPU] = useState("");
  const [nama_sarana_pengangkutan, setNamaSarana] = useState("");
  const [no_pol, setNoPol] = useState("");
  const [bendera, setBenderaPengangkut] = useState("");
  const [tanggal_tiba, setTglTiba] = useState("");
  const [kode_pel_muat, setPelabuhanMuat] = useState("");
  const [kode_pel_transit, setPelabuhanTransit] = useState("");
  const [kode_tps, setTempatPenimbunnan] = useState("");

  // transaksi ref & state
  const select_valuta = useRef(null);
  const select_harga_barang = useRef(null);
  const select_asuransi = useRef(null);
  const ndpbmVal = useRef(null);
  const priceVal = useRef(null);
  const [kode_valuta, setKodeValuta] = useState("");
  const [ndpbm, setNdpbm] = useState("");
  const [kode_harga_barang, setKodeHargaBarang] = useState("");
  const [freight, setFreight] = useState("");
  const [jenis_asuransi, setAsurance] = useState("");
  const [nilai_asuransi, setNilaiAsuransi] = useState("");
  const [bruto, setBruto] = useState("");
  const [netto, setNetto] = useState("");
  const [val_harga_barang, setValHargaBarang] = useState("");
  const [val_biaya_penambah, setValBiayaPenambah] = useState("");
  const [val_biaya_pengurang, setValBiayaPengurang] = useState("");
  const [val_voluntary, setValVoluntary] = useState("");
  const [val_pabean, setValPabean] = useState("");

  // list documents barang
  const [listDocuments, setDocuments] = useState([]);

  // list matching barang seri
  const [document_series, setDocumentSeries] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState({});

  // list lartas & state
  const [listLartas, setListsLartas] = useState([]);
  const [isModalOpenLartas, setIsModalOpenLartas] = useState(false);

  // list container & state
  const [formatUse, setFormatUse] = useState("1");
  const [listContainers, setListContainers] = useState([]);
  const [dropdownOptionsDocument, setDropdownDocument] = useState([]);
  const [matchedHsCodes, setMatchedHsCodes] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState({
    jenis: [],
    ukuran: [],
    merk: [],
  });
  const inputRowContainer = useRef({});
  const inputNoContainer = useRef(null);
  const [tablesData, setTablesData] = useState({});
  const [tablesBarang, setTablesBarang] = useState({});
  const [detailVisibility, setDetailVisibility] = useState({});
  const [detailContainer, setDetailContainer] = useState({});
  const hotTableRefs = useRef({});
  const sectionContainer = useRef(null);
  const selectJenis = useRef(null);
  const selectUkuran = useRef(null);
  const selectMerk = useRef(null);

  // validate entities
  const fieldsToValidate = [
    val_imp,
    val_imp_identitas,
    val_imp_no_identitas,
    val_imp_nama,
    val_imp_alamat,
    val_imp_api,
    val_npwp,
    val_npwp_identitas,
    val_npwp_no_identitas,
    val_npwp_nama,
    val_npwp_alamat,
    val_pb,
    val_pb_identitas,
    val_pb_no_identitas,
    val_pb_nama,
    val_pb_alamat,
    val_sd,
    val_sd_identitas,
    val_sd_no_identitas,
    val_sd_nama,
    val_sd_alamat,
    val_sd_negara,
    val_pj,
    val_pj_identitas,
    val_pj_no_identitas,
    val_pj_nama,
    val_pj_alamat,
    val_pj_negara,
  ];

  // loading state
  const [isLoadingM, setIsLoadingM] = useState(false);
  const [isLoadingSendToCeisa, setIsLoadingSendToCeisa] = useState(false);

  const formattedDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const tg_collapse_manifest = () => {
    setisCollapsedManifest(!isCollapsedManifest);
  };
  const tg_collapse_manifestDetail = () => {
    setisCollapsedManifestDetail(!isCollapsedManifestDetail);
    getManifestDetail();
  };
  const tg_collapse_header = () => {
    setisCollapsedHeader(!isCollapsedHeader);
  };
  const tg_collapse_entitas = () => {
    setisCollapsedEntitas(!isCollapsedEntitas);
  };
  const tg_collapse_document_barang = () => {
    setisCollapsedDocumentBarang(!isCollapsedDocumentBarang);
  };
  const tg_collapse_pengangkut = () => {
    setisCollapsedPengangkut(!isCollapsedPengangkut);
  };
  const tg_collapse_kemasan = () => {
    setisCollapsedKemasan(!isCollapsedKemasan);
  };
  const tg_collapse_Transaksi = () => {
    setisCollapsedTransaksi(!isCollapsedTransaksi);
  };
  const tg_collapse_Barang = () => {
    setisCollapsedBarang(!isCollapsedBarang);
  };
  const tg_collapse_container = () => {
    setisCollapsedContainers(!isCollapsedContainers);
  };
  const toggleModalLartas = () => setIsModalOpenLartas((prev) => !prev);

  //pemilik barang
  const change = (event) => {};

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const hi_kantor_pabean = (event) => {
    setKantorPabean(event.target.value);
  };

  const hi_jenis_pib = (event) => {
    setJenisPib(event.target.value);
  };
  const hi_cara_bayar = (event) => {
    setCaraBayar(event.target.value);
  };

  const go_to_home = () => {
    window.location.href = "/ceisalistaju";
  };
  const collapse_all = () => {
    setisCollapsedManifest(true);
    setisCollapsedManifestDetail(true);
    setisCollapsedHeader(true);
    setisCollapsedEntitas(true);
    setisCollapsedDocument(true);
    setisCollapsedDocumentBarang(true);
    setisCollapsedPengangkut(true);
    setisCollapsedKemasan(true);
    setisCollapsedTransaksi(true);
    setisCollapsedBarang(true);
    setisCollapsedContainers(true);
  };
  const expand_all = () => {
    setisCollapsedManifest(false);
    setisCollapsedHeader(false);
    setisCollapsedEntitas(false);
    setisCollapsedDocument(false);
    setisCollapsedDocumentBarang(false);
    setisCollapsedPengangkut(false);
    setisCollapsedKemasan(false);
    setisCollapsedTransaksi(false);
    setisCollapsedBarang(false);
    setisCollapsedContainers(false);
  };

  // function debounce
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args); // Arguments passed here
      }, delay);
    };
  };

  // handle update to entities master
  const handleSaveField = async (val, property, id) => {
    const newToken = await reloadToken();
    setToken(newToken);
    if (newToken) {
      const config = apiConfig(newToken);
      try {
        const response = await axios.patch(
          `${hostceisa}csamaster/${id}`,
          {
            [property]: val,
          },
          config
        );
        getEntities();
      } catch (error) {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    }
  };

  // handle update row entity
  const handleUpdateRowEntity = async (property, id) => {
    if (id && typeof id === "string" && id.trim() !== "") {
    } else {
      clear(property);
    }
    const newToken = await reloadToken();
    setToken(newToken);
    if (newToken) {
      const config = apiConfig(newToken);
      try {
        const response = await axios.patch(
          `${hostceisa}docpabean/${match[1]}`,
          {
            [property]: id,
          },
          config
        );
        getEntities();
        refetchDetailPabean();
      } catch (error) {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    }
  };

  const clear = (data) => {
    if (data === "importir_ent_id") {
      setImpIdentitas("");
      setImpNoIdentitas("");
      setImpNama("");
      setImpAlamat("");
      setImpApi("");
    } else if (data === "pemilik_barang_ent_id") {
      setPbIdentitas("");
      setPbNama("");
      setPbNoIdentitas("");
      setPbAlamat("");
    } else if (data === "npwp_pemusatan_ent_id") {
      setNpwpIdentitas("");
      setNpwpNoIdentitas("");
      setNpwpNama("");
      setNpwpAlamat("");
    } else if (data === "pengirim_ent_id") {
      setSdNama("");
      setSdIdentitas("");
      setSdNoIdentitas("");
      setSdAlamat("");
      setSdNegara("");
    } else if (data === "pengirim_ent_id") {
      setPjNama("");
      setPjIdentitas("");
      setPjNoIdentitas("");
      setPjAlamat("");
      setPjNegara("");
    }
  };

  const handleSaveFieldDebounced = debounce(handleSaveField, 1000);
  const handleChangeImportirRow = (event, property) => {
    const ID = select_importir.current.value;
    const val = event.target.value;
    handleSaveFieldDebounced(val, property, ID);
  };

  const handleChangeOwnershipRow = (event, property) => {
    const ID = select_pemilik_barang.current.value;
    const val = event.target.value;
    handleSaveFieldDebounced(val, property, ID);
  };

  const handleChangenpwpRow = (event, property) => {
    const ID = select_npwp.current.value;
    const val = event.target.value;
    handleSaveFieldDebounced(val, property, ID);
  };

  const handleChangenSenderRow = (event, property) => {
    const ID = select_sender.current.value;
    const val = event.target.value;
    handleSaveFieldDebounced(val, property, ID);
  };

  const handleChangenSellerRow = (event, property) => {
    const ID = select_seller.current.value;
    const val = event.target.value;
    handleSaveFieldDebounced(val, property, ID);
  };

  const destroySelect2 = () => {
    $(select_importir.current).off("change", handleSelectImportir);
    $(select_pemilik_barang.current).off("change", handleSelectPemilikBarang);
    $(select_npwp.current).off("change", handleSelectNpwp);
    $(select_valuta.current).off("change");
  };

  const handleSelectImportir = (e) => {
    const val = $(e.target).val();
    //const selectedOptionText = e.params.data.text;
    //setSelectedByText(selectedOptionText)
    setImportir(val);
    handleUpdateRowEntity("importir_ent_id", val);
  };
  const handleSelectPemilikBarang = (e) => {
    const val = $(e.target).val();
    setPb(val);
    handleUpdateRowEntity("pemilik_barang_ent_id", val);
  };
  const handleSelectNpwp = (e) => {
    const val = $(e.target).val();
    setNpwp(val);
    handleUpdateRowEntity("npwp_pemusatan_ent_id", val);
  };
  const handleSelectSd = (e) => {
    const val = $(e.target).val();
    setSd(val);
    handleUpdateRowEntity("pengirim_ent_id", val);
  };
  const handleSelectPj = (e) => {
    const val = $(e.target).val();
    setPj(val);
    handleUpdateRowEntity("penjual_ent_id", val);
  };
  const handleSelectNegaraPengirim = (e) => {
    const val = $(e.target).val();
    setSdNegara(val);
    handleChangenSenderRow(e, "negara");
  };
  const handleSelectNegaraPenjual = (e) => {
    const val = $(e.target).val();
    setPjNegara(val);
    handleChangenSenderRow(e, "negara");
  };

  // handle upload file concept
  const handleFileChange = async (event, containerId) => {
    const inputFileElement = event.target;
    try {
      const response = await ProsesFileChange(
        event,
        nomorAju,
        token,
        containerId,
        formatUse
      );

      // Trigger success notification here
      notify_update_br_cont("File excel success uploaded");

      // Continue with the rest of the operations
      getLartasChecking();
      getListContainers();
      updateManifest();
      getEntities();
      getListDocuments();
      refetchDetailPabean();

      if (response.data_properties != null) {
        var data_manifest = response.data_properties;
        setNoBl(data_manifest["no_bl"]);
        setTglBl(convertDate(data_manifest["tgl_bl"]));
        setKodeKantor(data_manifest["kode_kantor"]);
      } else {
        setNoBl("");
        setTglBl("");
        setKodeKantor("");
      }

      inputFileElement.value = null;
    } catch (error) {
      console.error("Error processing file:", error);
      notify_cant_update_br_cont("Terjadi Kesalahan, Silahkan upload ulang!");
    }
  };

  // custom debounce for pabean individual field
  const debouncedHandleUpdateRowEntity = useCallback(
    debounce(handleUpdateRowEntity, 1000),
    []
  );

  // handle harga barang ( auto formatted while user input )
  const handleInputPriceStuff = (event, property) => {
    const value = event.target.value;

    // Allow only numbers, commas, and periods
    const validInput = /^[0-9,.]*$/;
    if (!validInput.test(value)) {
      return; // If input is invalid, ignore the change
    }

    // Now format the valid value
    const val = formatPrice(value);

    // pabean value pattern result
    const finalCalculation = parseInt(value.replace(/,/g, ""), 10) * ndpbm;

    setValHargaBarang(val);
    setValPabean(formatPrice(finalCalculation));
    debouncedHandleUpdateRowEntity(property, val);
  };

  // handle biaya penambah
  const handleInputAdditionalBill = (event, property) => {
    const val = formatPrice(event.target.value);
    setValBiayaPenambah(val);
    debouncedHandleUpdateRowEntity(property, val);
  };

  // handle biaya pengurang
  const handleInputReductionBill = (event, property) => {
    const val = formatPrice(event.target.value);
    setValBiayaPengurang(val);
    debouncedHandleUpdateRowEntity(property, val);
  };

  // handle biaya pengurang
  const handleInputVoluntaryBill = (event, property) => {
    const val = formatPrice(event.target.value);
    setValVoluntary(val);
    debouncedHandleUpdateRowEntity(property, val);
  };

  const handleSelectionChange = (docId, selectedItems, hsCode) => {
    setSelectedDocuments((prev) => ({
      ...prev,
      [docId]: selectedItems,
    }));

    const payload = {
      id: docId,
      ids_document: selectedItems.map((item) => item.value),
      hs_code: hsCode,
    };

    // Call the API to update the server with the new selection
    const config = apiConfig(token);
    axios
      .post(`${hostceisa}itemdocument/assign`, payload, config)
      .then((response) => {
        getListDocuments();
      })
      .catch((error) => {
        console.error("Error updating:", error);
      });
  };

  const handleAdditionalChange = (docID, property, condition) => {
    // Update the local state after making the API call
    setListsLartas((prevList) =>
      prevList.map((item) =>
        item.id === docID ? { ...item, [property]: condition } : item
      )
    );
    const config = apiConfig(token);
    axios
      .patch(
        `${hostceisa}catalog-hs-code/${docID}`,
        { [property]: condition.toString() },
        config
      )
      .then(() => {
        getLartasChecking();

        if (property === "is_confirm") {
          getListContainers();
        }
      })
      .catch((error) => {
        console.error("Error updating:", error);
      });
  };

  // send to ceisa
  const handleSubmitToCeisa = async () => {
    // Check if any field is empty
    const emptyField = fieldsToValidate.some((field) => !field);
    if (emptyField) {
      toast.error("Please fill in all required fields at ENTITAS SECTION!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    const config = apiConfig(token);
    setIsLoadingSendToCeisa(true);
    try {
      const response = await axios.post(
        `${hostceisa}docpabean/send`,
        { no_aju: nomorAju },
        config
      );
      toast.success(response.data.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsLoadingSendToCeisa(false);
    } catch (error) {
      toast.error(error.response.data.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsLoadingSendToCeisa(false);
    }
  };

  const setupSelect2 = () => {
    destroySelect2();
    $(".js-example-basic-single").select2();
    $(".js-example-basic-multiple").select2({
      theme: "classic",
    });

    $(select_pel_tujuan.current).on("change", function (e) {
      const val = $(this).val();
      set_pet_tujuan(val);
      UpdateHeader("kode_pel_tujuan", val);
      getOfficePabean(val);
    });
    $(select_jenis_pib.current).on("change", function (e) {
      const val = $(this).val();
      setJenisPib(val);
      UpdateHeader("jenis_pib", val);
    });
    $(select_jenis_import.current).on("change", function (e) {
      const val = $(this).val();
      setJenisImport(val);
      UpdateHeader("jenis_impor", val);
      localStorage.setItem(rp_jenis_import, JSON.stringify(val));
    });
    $(select_cara_bayar.current).on("change", function (e) {
      const val = $(this).val();
      setCaraBayar(val);
      UpdateHeader("cara_bayar", val);
      localStorage.setItem(rp_cara_bayar, JSON.stringify(val));
    });

    $(select_importir.current).on("select2:select", handleSelectImportir);
    $(select_pemilik_barang.current).on("change", handleSelectPemilikBarang);
    $(select_npwp.current).on("change", handleSelectNpwp);
    $(select_sender.current).on("change", handleSelectSd);
    $(select_seller.current).on("change", handleSelectPj);
    $(select_country_pengirim.current).on("change", handleSelectNegaraPengirim);
    $(select_country_seller.current).on("change", handleSelectNegaraPenjual);

    $(select_pu.current).on("change", function (e) {
      const val = $(this).val();
      handleUpdateRowEntity("kode_tutup_pu", val);
    });
    $(select_country_carrier.current).on("change", function (e) {
      const val = $(this).val();
      handleUpdateRowEntity("bendera", val);
    });
    $(select_loads_harbour.current).on("change", function (e) {
      const val = $(this).val();
      handleUpdateRowEntity("kode_pel_muat", val);
    });
    $(select_transit_harbour.current).on("change", function (e) {
      const val = $(this).val();
      handleUpdateRowEntity("kode_pel_transit", val);
    });
    $(select_heaps_harbour.current).on("change", function (e) {
      const val = $(this).val();
      handleUpdateRowEntity("kode_tps", val);
    });
    $(select_valuta.current).on("change", function (e) {
      const val = $(this).val();
      setKodeValuta(val);
      handleUpdateRowEntity("kode_valuta", val);
    });
    $(select_harga_barang.current).on("change", function (e) {
      const val = $(this).val();
      setKodeHargaBarang(val);
      handleUpdateRowEntity("kode_harga_barang", val);
    });
    $(select_asuransi.current).on("change", function (e) {
      const val = $(this).val();
      handleUpdateRowEntity("jenis_asuransi", val);
    });
    $(selectJenis.current).on("change", function (e) {
      const value = $(this).val();
      const containerId = $(this).data("container-id"); // Accessing container id
      const field = $(this).data("field"); // Accessing the field name

      handleInputContainer(containerId, field, value);
    });
    $(selectUkuran.current).on("change", function (e) {
      const value = $(this).val();
      const containerId = $(this).data("container-id"); // Accessing container id
      const field = $(this).data("field"); // Accessing the field name

      handleInputContainer(containerId, field, value);
    });
    $(selectMerk.current).on("change", function (e) {
      const value = $(this).val();
      const containerId = $(this).data("container-id"); // Accessing container id
      const field = $(this).data("field"); // Accessing the field name

      handleInputContainer(containerId, field, value);
    });
  };

  const refetchDetailPabean = async () => {
    const newToken = await reloadToken();
    setToken(newToken);
    if (newToken && nomorAju) {
      try {
        const config = apiConfig(newToken);
        const response = await axios.get(`${hostcsamaster}${nomorAju}`, config);
        const hotKemasan = hotRefKemasan.current.hotInstance;

        // transaction section optimistic UI
        setValPabean(formatPrice(response.data.data.nilai_pabean));
        setNdpbm(response.data.data.ndpbm);
        setValHargaBarang(response.data.data.nilai_harga_barang);

        // kemasan section optimistic UI
        if (response.data.kemasan.length > 0) {
          const kemasan = response.data.kemasan;
          const transformedData = kemasan.map((item) =>
            Object.values(sortProperties(item))
          );
          hotKemasan.loadData(transformedData);
          hotKemasan.render();
        } else {
          hotKemasan.loadData([]);
          hotKemasan.render();
        }

        // manifest section optimisctic UI
        if (response.data.data) {
          const data_pabean = response.data.data;

          setTimeout(() => {
            if (data_pabean.tgl_dokumen_skb && data_pabean.no_dokumen_skb) {
              setNoSkb(data_pabean.no_dokumen_skb);
              setTglSkb(data_pabean.tgl_dokumen_skb);
              setUseSkb(data_pabean.is_used_skb);
            }
          }, 100);
        }
      } catch (error) {
        console.error("Error fetching column names:", error);
        return [];
      }
    }
  };

  const LoadAllData = async (nomorAju, token) => {
    try {
      const config = apiConfig(token);
      const response = await axios.get(`${hostcsamaster}${nomorAju}`, config);
      return response.data;
    } catch (error) {
      console.error("Error fetching column names:", error);
      return [];
    }
  };

  const getManifestDetail = async () => {
    setDetailManifest("");

    const formattedDate = (dateString) =>
      dateString.split("-").reverse().join("-");
    const data = {
      bl: val_no_bl,
      tgl: formattedDate(val_tgl_bl),
      no_aju: nomorAju,
    };
    setIsLoadingM(true);
    const config = apiConfig(token);
    try {
      const response = await axios.post(
        `${hostceisa}detailmanifest`,
        data,
        config
      );
      setDetailManifest(formatJsonToText(response.data.data));
      setMessageManifest(response.data.messageManifest);
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } finally {
      setIsLoadingM(false);
    }
  };

  // get data dropwdown header
  const fetchDataHeader = async () => {
    const urls = [
      `${hostceisa}pelabuhan/get-select?type=tujuan`,
      `${hostceisa}keterangan-lainnya/get-select?type=cara_bayar`,
      `${hostceisa}keterangan-lainnya/get-select?type=pib`,
      `${hostceisa}keterangan-lainnya/get-select?type=impor`,
    ];
    const requests = urls.map((url) => getDropdown(url));
    try {
      const results = await Promise.all(requests);

      setDataHeader({
        pelabuhanTujuan: results[0].data,
        caraBayar: results[1].data,
        pib: results[2].data,
        impor: results[3].data,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const UpdateHeader = async (prop, value) => {
    const payload = {
      [prop]: value,
    };
    if (token && typeof value === "string" && value.trim() !== "-") {
      const config = apiConfig(token);
      try {
        const response = await axios.patch(
          `${hostceisa}docpabean/${nomorAju}`,
          payload,
          config
        );
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      }
    }
  };

  // get pabean office
  const getOfficePabean = async (id) => {
    if (token && id) {
      const config = apiConfig(token);
      try {
        const response = await axios.get(
          `${hostceisa}kantor-pabean/${id}`,
          config
        );
        setKantorPabean(response.data.data);
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
        setKantorPabean("");
      }
    } else {
      setKantorPabean("");
    }
  };

  // get data dropdown entity
  const getEntityDropdown = async () => {
    const urls = [
      `${hostceisa}csamaster/get-select?type=importir`,
      `${hostceisa}csamaster/get-select?type=pemilik`,
      `${hostceisa}csamaster/get-select?type=npwp`,
      `${hostceisa}csamaster/get-select?type=pengirim`,
      `${hostceisa}csamaster/get-select?type=penjual`,
      `${hostceisa}countries/get-select`,
    ];
    const newToken = await reloadToken();
    setToken(newToken);

    if (newToken) {
      const config = apiConfig(newToken);

      try {
        const requests = urls.map((url) => axios.get(url, config));
        const results = await Promise.all(requests);

        setDataEntity((prevState) => ({
          ...prevState,
          importir: results[0].data.data,
          owners: results[1].data.data,
          npwp: results[2].data.data,
          senders: results[3].data.data,
          sellers: results[4].data.data,
          countries: results[5].data.data,
        }));
      } catch (error) {
        console.error("Error fetching entity data:", error);
      }
    }
  };

  const checkIfObjectIsEmpty = (obj) => {
    return !obj || Object.keys(obj).length === 0;
  };

  // update manifest after upload file
  const updateManifest = async () => {
    const config = apiConfig(token);
    try {
      await axios.post(
        `${hostceisa}docpabean/save-manifest/${nomorAju}`,
        null,
        config
      );
    } catch (error) {
      console.error("Error update data manifest document:", error);
    }
  };

  // get entities
  const getEntities = async () => {
    const newToken = await reloadToken();
    setToken(newToken);
    if (newToken) {
      const config = apiConfig(newToken);
      try {
        const response = await axios.get(
          `${hostceisa}csamaster/entities/${nomorAju}`,
          config
        );
        setEntities(response.data.data);
        if (!checkIfObjectIsEmpty(response.data.data.importir_ent)) {
          var d = response.data.data.importir_ent;
          setImpIdentitas(d.identitas);
          setImpNoIdentitas(d.no_identitas);
          setImpNama(d.nama);
          setImpAlamat(d.alamat);
          setImpApi(d.api_nib);
        }
        if (!checkIfObjectIsEmpty(response.data.data.npwp_pemusatan_ent)) {
          var np = response.data.data.npwp_pemusatan_ent;
          setNpwpIdentitas(np.identitas);
          setNpwpNoIdentitas(np.no_identitas);
          setNpwpNama(np.nama);
          setNpwpAlamat(np.alamat);
        }
        if (!checkIfObjectIsEmpty(response.data.data.pemilik_barang_ent)) {
          var pb = response.data.data.pemilik_barang_ent;
          setPbIdentitas(pb.identitas);
          setPbNoIdentitas(pb.no_identitas);
          setPbNama(pb.nama);
          setPbAlamat(pb.alamat);
        }
        if (!checkIfObjectIsEmpty(response.data.data.pengirim_ent)) {
          var sd = response.data.data.pengirim_ent;
          setSdIdentitas(sd.identitas);
          setSdNoIdentitas(sd.no_identitas);
          setSdNama(sd.nama);
          setSdAlamat(sd.alamat);
          setSdNegara(sd.negara);
        }
        if (!checkIfObjectIsEmpty(response.data.data.penjual_ent)) {
          var pj = response.data.data.penjual_ent;
          console.log("data sd ", pj.negara);
          setPjIdentitas(pj.identitas);
          setPjNoIdentitas(pj.no_identitas);
          setPjNama(pj.nama);
          setPjAlamat(pj.alamat);
          setPjNegara(pj.negara);
        }
      } catch (error) {
        setEntities("");
        console.error("Error fetching entity data:", error);
      }
    }
  };

  // get list seri
  const getListDocumentSeries = async () => {
    const newToken = await reloadToken();
    setToken(newToken);

    if (newToken) {
      try {
        const config = apiConfig(newToken);
        const response = await axios.get(
          `${hostceisa}itemdocument/get-select?no_aju=${nomorAju}`,
          config
        );
        setDocumentSeries(response.data.data || []);
      } catch (error) {
        console.error("Error fetching data series document options:", error);
      }
    }
  };

  // get list barang seri
  const getListDocuments = async () => {
    const newToken = await reloadToken();
    setToken(newToken);
    if (newToken) {
      try {
        const config = apiConfig(newToken);
        const response = await axios.get(
          `${hostceisa}itemdocument/fetch/${nomorAju}`,
          config
        );
        setDocuments(response.data.data || []);
      } catch (error) {
        console.error("Error fetching data series document options:", error);
      }
    }
  };

  // get list lartas checking
  const getLartasChecking = async () => {
    const newToken = await reloadToken();
    setToken(newToken);
    if (newToken) {
      try {
        const config = apiConfig(newToken);
        const response = await axios.get(
          `${hostceisa}catalog-hs-code/${nomorAju}`,
          config
        );
        if (response.data.data && response.data.data.length > 0) {
          setListsLartas(response.data.data);
          setIsModalOpenLartas(true);
        } else {
          setIsModalOpenLartas(false);
        }
      } catch (error) {
        console.error("Error fetching lartas :", error);
      }
    }
  };

  const getCarrierDropdown = async () => {
    const urls = [
      `${hostceisa}pelabuhan/get-select?type=muat`,
      `${hostceisa}pelabuhan/get-select?type=timbun`,
      `${hostceisa}pelabuhan/get-select?type=transit`,
    ];
    const newToken = await reloadToken();
    setToken(newToken);

    if (newToken) {
      const config = apiConfig(newToken);

      try {
        const requests = urls.map((url) => axios.get(url, config));
        const results = await Promise.all(requests);

        setCarrier((prevState) => ({
          ...prevState,
          loads: results[0].data.data,
          heaps: results[1].data.data,
          transits: results[2].data.data,
        }));
      } catch (error) {
        console.error("Error fetching entity data:", error);
      }
    }
  };

  const getDropdown = async (url) => {
    const newToken = await reloadToken();
    setToken(newToken);
    if (newToken) {
      const config = apiConfig(newToken);
      try {
        const response = await axios.get(url, config);
        return response.data;
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
        return []; // Return an empty array in case of an error
      }
    }
  };

  useEffect(() => {
    const GetAllData = async () => {
      const hotKemasan = hotRefKemasan.current.hotInstance;
      await new Promise((resolve) => setTimeout(resolve, 100));
      const newToken = await reloadToken();
      setToken(newToken);
      if (newToken && nomorAju) {
        LoadAllData(nomorAju, newToken).then((data) => {
          //docpabean
          if (data.data) {
            var data_pabean = data.data;
            setTimeout(() => {
              set_pet_tujuan(data_pabean.kode_pel_tujuan);
              //setJenisPib(data_pabean.jenis_pib)

              // pengangkut
              setValTutupPU(data_pabean.kode_tutup_pu);
              setNoTutupPU(data_pabean.nomor_tutup_pu);
              setTglTutupPU(data_pabean.tanggal_tutup_pu);
              setNoPosPU(data_pabean.nomor_pos);
              setNoSubPosPU(data_pabean.nomor_sub_pos);
              setNoSubSubPosPU(data_pabean.nomor_sub_sub_pos);
              setNamaSarana(data_pabean.nama_sarana_pengangkutan);
              setNoPol(data_pabean.nama_pol);
              setBenderaPengangkut(data_pabean.bendera);
              setTglTiba(data_pabean.tanggal_tiba);
              setPelabuhanMuat(data_pabean.kode_pel_muat);
              setPelabuhanTransit(data_pabean.kode_pel_transit);
              setTempatPenimbunnan(data_pabean.kode_tps);

              // transaksi
              setKodeValuta(data_pabean.kode_valuta);
              setNdpbm(data_pabean.ndpbm);
              setKodeHargaBarang(data_pabean.kode_harga_barang);
              setValHargaBarang(data_pabean.nilai_harga_barang);
              setValBiayaPenambah(data_pabean.biaya_penambah);
              setValBiayaPengurang(data_pabean.biaya_pengurang);
              setValPabean(formatPrice(data_pabean.nilai_pabean));
              setFreight(data_pabean.freight);
              setAsurance(data_pabean.jenis_asuransi);
              setNilaiAsuransi(data_pabean.nilai_asuransi);
              setValVoluntary(data_pabean.voluntary_declaration);
              setBruto(data_pabean.bruto);
              setNetto(data_pabean.netto);

              if (data_pabean.tgl_dokumen_skb && data_pabean.no_dokumen_skb) {
                setNoSkb(data_pabean.no_dokumen_skb);
                setTglSkb(data_pabean.tgl_dokumen_skb);
                setUseSkb(data_pabean.is_used_skb);
                setNoBl(data_pabean.no_host_bl);
                setTglBl(formatTimestampToDateInput(data_pabean.tgl_host_bl));
                setKodeKantor(data_pabean.kode_kantor);
              }

              setJenisPib("1");
              setJenisImport(data_pabean.jenis_impor);
              setCaraBayar(data_pabean.cara_bayar);
              setImportir(data_pabean.importir_ent_id);
              setPb(data_pabean.pemilik_barang_ent_id);
              setNpwp(data_pabean.npwp_pemusatan_ent_id);
              setSd(data_pabean.pengirim_ent_id);
              setPj(data_pabean.penjual_ent_id);
            }, 200);
          }
          if (data.kemasan.length > 0) {
            var kemasan = data.kemasan;
            const transformedData = kemasan.map((item) =>
              Object.values(sortProperties(item))
            );
            hotKemasan.loadData(transformedData);
            hotKemasan.render();
          } else {
            hotKemasan.loadData([]);
            hotKemasan.render();
          }
        });
      }
    };
    setTimeout(() => {
      GetAllData();
      getEntities();
      getListDocuments();
      getListDocumentSeries();
    }, 500);

    setupSelect2();
    getListContainers();
    getListDropdownContainer();
    fetchDataHeader();
    getEntityDropdown();
    getCarrierDropdown();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setupSelect2();
    }, 500);

    getOfficePabean(val_pel_tujuan);
  }, [val_pel_tujuan]);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${hostceisa}documents`, config);

        const transformedData = response.data.dokumen.map((item, index) => ({
          value: index,
          name: item,
        }));

        setDropdownDataTypeDocuments(transformedData);
        setDropdownDocument(response.data.dokumen);
        setDropdownKemasan(response.data.kemasan);
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    };
    fetchDropdownOptions();
  }, [token]);

  function addNewRowKMS() {
    const hot = hotRefKemasan.current.hotInstance;
    const numRows = parseInt(inputValue, 10);
    if (!isNaN(numRows) && numRows > 0) {
      for (let i = 0; i < numRows; i++) {
        const newRowNumber = hot.countRows();
        hot.alter("insert_row_below", newRowNumber - 1);
        hot.setDataAtCell(newRowNumber, 1, newRowNumber + 1);
      }
      hot.resumeRender();
    }
  }

  // Containers section all function
  const getListContainers = async () => {
    const newToken = await reloadToken();
    setToken(newToken);
    if (newToken) {
      try {
        const config = apiConfig(newToken);
        const response = await axios.get(
          `${hostceisa}container/${nomorAju}`,
          config
        );
        const containersData = response.data.data;
        setListContainers(containersData);

        // list document container
        const initialTablesData = {};
        containersData.forEach((container) => {
          initialTablesData[container.id] = container.detail_container
            ?.item_documents?.length
            ? container.detail_container?.item_documents.map((doc) => [
                doc.id, // Use `id` for updates, but don't render this in the table
                doc.no_container,
                doc.jenis_dokumen,
                doc.no_dokumen,
                doc.tgl_dokumen
                  ? new Date(doc.tgl_dokumen).toISOString().split("T")[0]
                  : new Date().toISOString().split("T")[0], // Use today's date if tgl_dokumen is null
              ])
            : []; // Ensure at least one empty row
        });

        // list detail barang
        const initialTablesBarang = {};
        containersData.forEach((container) => {
          initialTablesBarang[container.id] = container.detail_container
            ?.barang_ceisas?.length
            ? container.detail_container?.barang_ceisas.map((item) => [
                item.seriBarang,
                item.hsCode,
                item.uraian,
                item.kodeNegara,
                item.kodeSatuan,
                formatPrice(item.jumlahSatuan),
                item.kodeKemasan,
                formatPrice(item.netto),
                formatPrice(item.cif),
                item.fob,
                formatPrice(item.hargaSatuan),
                item.pernyataanLartas,
                `${item.bm} %`,
                `${item.ppn} %`,
                `${item.pph} %`,
              ])
            : [];
        });

        // set to state
        setTablesData(initialTablesData);
        setTablesBarang(initialTablesBarang);
      } catch (error) {
        console.error("Error fetching data series document options:", error);
      }
    }
  };

  const AddContainer = async () => {
    const config = apiConfig(token);
    try {
      const response = await axios.post(
        `${hostceisa}container/${nomorAju}`,
        { value: parseInt(inputNoContainer.current.value) },
        config
      );
      getListContainers();
      toast.success(response.data.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const AddRowContainer = async (containerId) => {
    const rowCount = parseInt(
      inputRowContainer.current[containerId]?.value || "1",
      10
    );
    if (!rowCount || rowCount < 1) return;

    const config = apiConfig(token);

    try {
      const newRows = [];
      for (let i = 0; i < rowCount; i++) {
        newRows.push([null, containerId, "", "", ""]); // Include null for the id
      }

      setTablesData((prevData) => ({
        ...prevData,
        [containerId]: [...prevData[containerId], ...newRows],
      }));

      for (let i = 0; i < rowCount; i++) {
        await axios.post(
          `${hostceisa}itemdocument`,
          { no_aju: nomorAju, container_id: containerId },
          config
        );
      }

      getListContainers();

      if (inputRowContainer.current[containerId]) {
        inputRowContainer.current[containerId].value = 1;
      }
    } catch (error) {
      console.error("Error adding new rows:", error);
      toast.error(error.response?.data?.message || "Error adding new rows", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });

      getListContainers();
    }
  };

  const updateItemDocument = async (payloads) => {
    const config = apiConfig(token);

    try {
      await axios.patch(`${hostceisa}itemdocument/bulk`, payloads, config);
      getListContainers();
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const deleteItemDocument = async (payloads) => {
    const config = apiConfig(token);

    try {
      await axios.delete(`${hostceisa}itemdocument/bulk`, {
        data: payloads,
        ...config,
      });
      toast.success("Row deleted successfully!", { autoClose: 1000 });
      getListContainers();
    } catch (error) {
      console.error("Error deleting document(s):", error);
      toast.error(
        error.response?.data?.message || "Error deleting document(s)",
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        }
      );
    }
  };

  const removeSelectedRows = (containerId) => {
    const hotTableInstance = hotTableRefs.current[containerId].hotInstance;
    const selected = hotTableInstance.getSelected();

    if (!selected) return;

    const [startRow, , endRow] = selected[0];
    const rowsToDelete = [];

    for (let row = startRow; row <= endRow; row++) {
      const rowData = tablesData[containerId][row];
      if (rowData[0]) {
        // Ensure there's a valid ID
        rowsToDelete.push({ id: rowData[0] });
      }
    }

    if (rowsToDelete.length > 0) {
      // Send API call to delete the selected rows
      deleteItemDocument(rowsToDelete);

      // Remove rows from Handsontable
      hotTableInstance.alter("remove_row", startRow, endRow - startRow + 1);
    }
  };

  const updateNoContainer = async (id, field, value) => {
    const config = apiConfig(token);

    const formattedValue =
      typeof value === "boolean" ? (value ? "true" : "false") : value;
    try {
      await axios.patch(
        `${hostceisa}container/${id}`,
        { [field]: formattedValue },
        config
      );
      getListContainers();
      toggleDetailContainer(id);
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const deleteContainer = async (id) => {
    const config = apiConfig(token);
    try {
      await axios.delete(`${hostceisa}container/${id}`, config);
      getListContainers();
      getListDocuments();
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const generateSeri = async (id) => {
    const config = apiConfig(token);

    // selected seri (with container seleceted)
    try {
      const response = await axios.patch(
        `${hostceisa}itemdocument/generate/${id}`,
        null,
        config
      );

      const { message, messageDocument } = response.data;

      // Create an empty array for matched hs codes
      let newMatchedHsCodes = [];

      if (messageDocument && messageDocument.length > 0) {
        // Extract hs_code and full message from each messageDocument item
        const extractedHsCodes = messageDocument.map((docMessage) => {
          const hsCodeMatch = docMessage.match(/\b\d{6,10}\b/); // Extract hs_code
          return {
            hsCode: hsCodeMatch ? hsCodeMatch[0] : null,
            fullMessage: docMessage, // Capture the full message
          };
        });

        // Find matching containers based on extracted hs_code
        extractedHsCodes.forEach(({ hsCode, fullMessage }) => {
          if (hsCode) {
            // Check if hs_code matches in listDocuments
            const matchedDocument = listDocuments.find(
              (doc) => doc.hs_code === hsCode
            );

            if (matchedDocument) {
              newMatchedHsCodes.push({
                containerId: matchedDocument.no_container,
                hsCode: matchedDocument.hs_code,
                message: fullMessage, // Add the full message to the newMatchedHsCodes array
              });
            }
          }
        });

        // Rerender list containers to show caution message based on matched hs_code
        setMatchedHsCodes(newMatchedHsCodes);
        getListContainers();
        getListDocuments();
      } else {
        // Trigger toast for the success message only if no messageDocument exists
        setMatchedHsCodes([]);
        toast.success(message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const debouncedUpdateNoContainer = useCallback(
    debounce(updateNoContainer, 1000),
    []
  );

  const handleInputContainer = (containerId, field, value) => {
    setListContainers((prevContainers) =>
      prevContainers.map((container) => {
        if (container.id === containerId) {
          // Check if detail_container exists and is an object
          const detailContainer = container.detail_container || {};
          const isDetailField = field in detailContainer; // Check if field belongs to detail_container

          if (isDetailField) {
            return {
              ...container,
              detail_container: {
                ...detailContainer,
                [field]: value, // Update the specific field in detail_container
              },
            };
          } else {
            // Update the main container field (e.g., no_container)
            return { ...container, [field]: value };
          }
        }
        return container; // Return unchanged container
      })
    );

    // Call the API after a debounce delay if needed
    debouncedUpdateNoContainer(containerId, field, value);
  };

  const handleSelectFormat = (value) => {
    setFormatUse(value);
  };

  // list dropdowncontainer
  const getListDropdownContainer = async () => {
    const config = apiConfig(token);

    const urls = [
      `${hostceisa}container/get-select/jenis`,
      `${hostceisa}container/get-select/ukuran`,
      `${hostceisa}container/get-select/merk`,
    ];
    try {
      const requests = urls.map((url) => axios.get(url, config));
      const results = await Promise.all(requests);

      setDropdownOptions({
        jenis: results[0].data.data,
        ukuran: results[1].data.data,
        merk: results[2].data.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const toggleDetailVisibility = (containerId) => {
    setDetailVisibility((prevState) => ({
      ...prevState,
      [containerId]: !prevState[containerId],
    }));
  };

  const toggleDetailContainer = (containerId) => {
    setDetailContainer((prevState) => ({
      ...prevState,
      [containerId]: !prevState[containerId],
    }));
  };

  // update SKB document
  const UpdateSKB = async (field, value) => {
    const config = apiConfig(token);

    const formattedValue =
      typeof value === "boolean" ? (value ? "true" : "false") : value;

    try {
      await axios.patch(
        `${hostceisa}docpabean/${nomorAju}`,
        { [field]: formattedValue },
        config
      );
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const debounceUpdateSKB = useCallback(debounce(UpdateSKB, 1000), []);

  const handleUpdateSKB = (field, value) => {
    if (field === "no_dokumen_skb") {
      setNoSkb(value);
    } else if (field === "tgl_dokumen_skb") {
      setTglSkb(value);
    } else if (field === "no_host_bl") {
      setNoBl(value);
    } else if (field === "tgl_host_bl") {
      setTglBl(value);
    } else if (field === "kode_kantor") {
      setKodeKantor(value);
    } else if (field === "is_used_skb") {
      setUseSkb(value);
    }
    debounceUpdateSKB(field, value);
  };

  return (
    <div>
      <Header title="CEISA BR" subtitle={`AJU : ${nomorAju}`} />
      <ToastContainer />
      <ModalLartas
        isOpen={isModalOpenLartas}
        toggleModal={toggleModalLartas}
        listLartas={listLartas}
        handleAdditionalChange={handleAdditionalChange}
      />
      <div className="C_main_form">
        <MenuCsa
          go_to_home={go_to_home}
          collapse_all={collapse_all}
          expand_all={expand_all}
          sendToCeisa={handleSubmitToCeisa}
          isLoadingSendToCeisa={isLoadingSendToCeisa}
        />

        <HeaderCsa
          tg_collapse_header={tg_collapse_header}
          isCollapsedHeader={isCollapsedHeader}
          select_pel_tujuan={select_pel_tujuan}
          val_pel_tujuan={val_pel_tujuan}
          dataHeader={dataHeader}
          hi_kantor_pabean={hi_kantor_pabean}
          val_kantor_pabeban={val_kantor_pabeban}
          select_jenis_pib={select_jenis_pib}
          val_jenis_pib={val_jenis_pib}
          hi_jenis_pib={hi_jenis_pib}
          select_jenis_import={select_jenis_import}
          val_jenis_import={val_jenis_import}
          change={change}
          select_cara_bayar={select_cara_bayar}
          val_cara_bayar={val_cara_bayar}
          hi_cara_bayar={hi_cara_bayar}
        />

        <div className="C_form-container">
          <div
            className="title_form"
            onClick={tg_collapse_entitas}
            style={{ cursor: "pointer" }}
          >
            Entitas
          </div>
          <div className={isCollapsedEntitas ? "hidden" : "visible"}>
            <br></br>
            <table border="0" width="100%">
              <thead>
                <tr valign="top" className="tb_title_bg_color">
                  <th></th>
                  <th>PILIH</th>
                  <th>IDENTITAS</th>
                  <th>NO IDENTITAS</th>
                  <th>NAMA</th>
                  <th>ALAMAT</th>
                  <th>NEGARA</th>
                </tr>
                <tr>
                  <td>
                    <br></br>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr valign="top">
                  <td className="tar">
                    <label className="C_form-label">IMPORTIR</label>
                  </td>
                  <td>
                    <select
                      ref={select_importir}
                      value={val_imp}
                      className="js-example-basic-multiple C_select-input_table"
                    >
                      <option key={0} value="">
                        {" - "}
                      </option>
                      {dataEntity.importir.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.nama}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      onBlur={(event) =>
                        handleChangeImportirRow(event, "identitas")
                      }
                      onChange={(event) => setImpIdentitas(event.target.value)}
                      value={val_imp_identitas}
                      className="cfit w_cfit_1"
                    />
                  </td>
                  <td>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <input
                        type="text"
                        onBlur={(event) =>
                          handleChangeImportirRow(event, "no_identitas")
                        }
                        onChange={(event) =>
                          setImpNoIdentitas(event.target.value)
                        }
                        value={val_imp_no_identitas}
                        className="cfit w_cfit_1"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                          marginTop: "5px",
                        }}
                      >
                        <span
                          style={{
                            textAlign: "left",
                            fontSize: "14px",
                            marginLeft: "5px",
                            fontWeight: "semi-bold",
                          }}
                        >
                          NPWP 16 digit
                        </span>
                        <input
                          type="text"
                          value={entities.importir_ent?.npwp_16}
                          className="cfit w_cfit_1"
                          style={{ cursor: "not-allowed" }}
                          readOnly
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      onBlur={(event) => handleChangeImportirRow(event, "nama")}
                      onChange={(event) => setImpNama(event.target.value)}
                      value={val_imp_nama}
                      className="cfit w_cfit_1"
                    />
                  </td>
                  <td>
                    <textarea
                      className="cfit w_cfit_2 custom_txtarea_address"
                      onBlur={(event) =>
                        handleChangeImportirRow(event, "alamat")
                      }
                      onChange={(event) => setImpAlamat(event.target.value)}
                      value={val_imp_alamat}
                    />
                  </td>
                </tr>
                <tr valign="top">
                  <td className="tar">
                    <label className="C_form-label">API / NIB</label>
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      onBlur={(event) =>
                        handleChangeImportirRow(event, "api_nib")
                      }
                      onChange={(event) => setImpApi(event.target.value)}
                      value={val_imp_api}
                      className="cfit w_cfit_1"
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={7}>
                    <div className="line_g"></div>
                  </td>
                </tr>
                <tr>
                  <th></th>
                </tr>
                <tr valign="top">
                  <td className="tar">
                    <label className="C_form-label">NPWP PEMUSATAN</label>
                  </td>
                  <td>
                    <select
                      ref={select_npwp}
                      className="js-example-basic-multiple C_select-input_table"
                      value={val_npwp}
                    >
                      <option key={0} value="">
                        {" - "}
                      </option>
                      {dataEntity.npwp.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.nama}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      onBlur={(event) =>
                        handleChangenpwpRow(event, "identitas")
                      }
                      onChange={(event) => setNpwpIdentitas(event.target.value)}
                      value={val_npwp_identitas}
                      className="cfit w_cfit_1"
                    />
                  </td>
                  <td>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <input
                        type="text"
                        onBlur={(event) =>
                          handleChangenpwpRow(event, "no_identitas")
                        }
                        onChange={(event) =>
                          setNpwpNoIdentitas(event.target.value)
                        }
                        value={val_npwp_no_identitas}
                        className="cfit w_cfit_1"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                          marginTop: "5px",
                        }}
                      >
                        <span
                          style={{
                            textAlign: "left",
                            fontSize: "14px",
                            marginLeft: "5px",
                            fontWeight: "semi-bold",
                          }}
                        >
                          NPWP 16 digit
                        </span>
                        <input
                          type="text"
                          value={entities.npwp_pemusatan_ent?.npwp_16}
                          className="cfit w_cfit_1"
                          style={{ cursor: "not-allowed" }}
                          readOnly
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <input
                      type="text"
                      value={val_npwp_nama}
                      onBlur={(event) => handleChangenpwpRow(event, "nama")}
                      onChange={(event) => setNpwpNama(event.target.value)}
                      className="cfit w_cfit_1"
                    />
                  </td>
                  <td>
                    <textarea
                      value={val_npwp_alamat}
                      onBlur={(event) => handleChangenpwpRow(event, "alamat")}
                      onChange={(event) => setNpwpAlamat(event.target.value)}
                      className="cfit w_cfit_2 custom_txtarea_address"
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={7}>
                    <div className="line_g"></div>
                  </td>
                </tr>
                <tr>
                  <th></th>
                </tr>
                <tr valign="top">
                  <td className="tar">
                    <label className="C_form-label">PEMILIK BARANG</label>
                  </td>
                  <td>
                    <select
                      ref={select_pemilik_barang}
                      className="js-example-basic-multiple C_select-input_table"
                      value={val_pb}
                    >
                      <option key={0} value={""}>
                        {" - "}
                      </option>
                      {dataEntity.owners.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.nama}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      onBlur={(event) =>
                        handleChangeOwnershipRow(event, "identitas")
                      }
                      onChange={(event) => setPbIdentitas(event.target.value)}
                      value={val_pb_identitas}
                      className="cfit w_cfit_1"
                    />
                  </td>
                  <td>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <input
                        type="text"
                        onBlur={(event) =>
                          handleChangeOwnershipRow(event, "no_identitas")
                        }
                        onChange={(event) =>
                          setPbNoIdentitas(event.target.value)
                        }
                        value={val_npwp_no_identitas}
                        className="cfit w_cfit_1"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                          marginTop: "5px",
                        }}
                      >
                        <span
                          style={{
                            textAlign: "left",
                            fontSize: "14px",
                            marginLeft: "5px",
                            fontWeight: "semi-bold",
                          }}
                        >
                          NPWP 16 digit
                        </span>
                        <input
                          type="text"
                          value={entities.pemilik_barang_ent?.npwp_16}
                          className="cfit w_cfit_1"
                          style={{ cursor: "not-allowed" }}
                          readOnly
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    {" "}
                    <input
                      type="text"
                      onBlur={(event) =>
                        handleChangeOwnershipRow(event, "nama")
                      }
                      onChange={(event) => setPbNama(event.target.value)}
                      value={val_pb_nama}
                      className="cfit w_cfit_1"
                    />
                  </td>
                  <td>
                    <textarea
                      className="cfit w_cfit_2 custom_txtarea_address"
                      onBlur={(event) =>
                        handleChangeOwnershipRow(event, "alamat")
                      }
                      onChange={(event) => setPbAlamat(event.target.value)}
                      value={val_pb_alamat}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={7}>
                    <div className="line_g"></div>
                  </td>
                </tr>
                <tr>
                  <th></th>
                </tr>

                <tr valign="top">
                  <td className="tar">
                    <label className="C_form-label">PENGIRIM</label>
                  </td>
                  <td>
                    <select
                      ref={select_sender}
                      className="js-example-basic-multiple C_select-input_table"
                      value={val_sd}
                    >
                      <option key={0} value="">
                        {" - "}
                      </option>
                      {dataEntity.senders.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.nama}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      value={val_sd_identitas}
                      onBlur={(event) =>
                        handleChangenSenderRow(event, "identitas")
                      }
                      onChange={(event) => setSdIdentitas(event.target.value)}
                      className="cfit w_cfit_1"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={val_sd_no_identitas}
                      onBlur={(event) =>
                        handleChangenSenderRow(event, "no_identitas")
                      }
                      onChange={(event) => setSdNoIdentitas(event.target.value)}
                      className="cfit w_cfit_1"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={val_sd_nama}
                      onBlur={(event) => handleChangenSenderRow(event, "nama")}
                      onChange={(event) => setSdNama(event.target.value)}
                      className="cfit w_cfit_1"
                    />
                  </td>
                  <td>
                    <textarea
                      value={val_sd_alamat}
                      onBlur={(event) =>
                        handleChangenSenderRow(event, "alamat")
                      }
                      onChange={(event) => setSdAlamat(event.target.value)}
                      className="cfit w_cfit_2 custom_txtarea_address"
                    />
                  </td>
                  <td>
                    <select
                      ref={select_country_pengirim}
                      className="js-example-basic-multiple C_select-input_table"
                      value={val_sd_negara}
                    >
                      <option key={0} value={""}>
                        {" - "}
                      </option>
                      {dataEntity.countries.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td colSpan={7}>
                    <div className="line_g"></div>
                  </td>
                </tr>
                <tr>
                  <th></th>
                </tr>
                <tr valign="top">
                  <td className="tar">
                    <label className="C_form-label">PENJUAL</label>
                  </td>
                  <td>
                    <select
                      ref={select_seller}
                      className="js-example-basic-multiple C_select-input_table"
                      value={val_pj}
                    >
                      <option key={0} value="">
                        {" - "}
                      </option>
                      {dataEntity.sellers.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.nama}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      value={val_pj_identitas}
                      onBlur={(event) =>
                        handleChangenSellerRow(event, "identitas")
                      }
                      onChange={(event) => setPjIdentitas(event.target.value)}
                      className="cfit w_cfit_1"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={val_pj_no_identitas}
                      onBlur={(event) =>
                        handleChangenSellerRow(event, "no_identitas")
                      }
                      onChange={(event) => setPjNoIdentitas(event.target.value)}
                      className="cfit w_cfit_1"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={val_pj_nama}
                      onBlur={(event) => handleChangenSellerRow(event, "nama")}
                      onChange={(event) => setPjNama(event.target.value)}
                      className="cfit w_cfit_1"
                    />
                  </td>
                  <td>
                    <textarea
                      value={val_pj_alamat}
                      onBlur={(event) =>
                        handleChangenSellerRow(event, "alamat")
                      }
                      onChange={(event) => setPjAlamat(event.target.value)}
                      className="cfit w_cfit_2 custom_txtarea_address"
                    />
                  </td>
                  <td>
                    <select
                      ref={select_country_seller}
                      className="js-example-basic-multiple C_select-input_table"
                      value={val_pj_negara}
                    >
                      <option key={0} value={""}>
                        {" - "}
                      </option>
                      {dataEntity.countries.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <Manifest
          isCollapsedManifest={isCollapsedManifest}
          tg_collapse_manifest={tg_collapse_manifest}
          isCollapsedManifestDetail={isCollapsedManifestDetail}
          tg_collapse_manifestDetail={tg_collapse_manifestDetail}
          hi_no_bl={(e) => handleUpdateSKB("no_host_bl", e.target.value)}
          val_no_bl={val_no_bl}
          hi_tgl_bl={(e) => handleUpdateSKB("tgl_host_bl", e.target.value)}
          val_tgl_bl={val_tgl_bl}
          hi_kode_kantor={(e) => handleUpdateSKB("kode_kantor", e.target.value)}
          val_kode_kantor={val_kode_kantor}
          val_detail_manifest={val_detail_manifest}
          vaL_message_manifest={messageManifest}
          set_no_skb={(e) => handleUpdateSKB("no_dokumen_skb", e.target.value)}
          val_no_skb={val_no_skb}
          val_use_skb={val_useskb}
          set_use_skb={(e) => handleUpdateSKB("is_used_skb", e.target.checked)}
          set_tgl_skb={(e) =>
            handleUpdateSKB("tgl_dokumen_skb", e.target.value)
          }
          val_tgl_skb={formatTimestampToDateInput(val_tgl_skb)}
          isLoadingM={isLoadingM}
        />

        <div
          className="C_form-container"
          style={{
            paddingBottom: "15px",
            overflow: "visible",
          }}
          ref={sectionContainer}
        >
          <div
            className="title_form"
            onClick={tg_collapse_container}
            style={{ cursor: "pointer" }}
          >
            Containers
          </div>
          <div className={isCollapsedContainers ? "hidden" : "visible"}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div className="menu-container">
                <input
                  type="text"
                  className="addrowhs"
                  id="new_add_row"
                  size="2"
                  maxLength="2"
                  ref={inputNoContainer}
                  defaultValue={1}
                />
                <button
                  class="styled-button mini"
                  style={{
                    backgroundColor: "#2a2a6e",
                    padding: "8px",
                    borderRadius: "6px",
                  }}
                  onClick={AddContainer}
                >
                  Add New Container
                </button>
              </div>
            </div>
            {listContainers &&
              listContainers.map((container, index) => {
                const hasHsCodeWarning = matchedHsCodes.some(
                  (match) => match.containerId === container.no_container
                );

                const hasBarangData =
                  tablesBarang[container.id] &&
                  tablesBarang[container.id].length > 0;

                return (
                  <div
                    key={container.id}
                    style={{
                      boxShadow:
                        "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
                      padding: "20px",
                      borderRadius: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            paddingBottom: "10px",
                            paddingTop: "10px",
                            gap: "10px",
                            marginTop: "-15px",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: "16px",
                              marginTop: "-10px",
                              marginBottom: "15px",
                              width: "25px",
                              height: "25px",
                              border: "1px solid",
                              borderRadius: "100%",
                            }}
                          >
                            {index + 1}
                          </div>
                          {container.detail_container !== null && (
                            <div
                              class="styled-button mini"
                              style={{
                                backgroundColor: "#2a2a6e",
                                padding: "4px",
                                borderRadius: "6px",
                              }}
                              onClick={() =>
                                toggleDetailContainer(container.id)
                              }
                            >
                              Detail Container
                            </div>
                          )}
                        </div>
                        <label
                          style={{
                            display: "block",
                            fontWeight: "bold",
                            marginBottom: "10px",
                            fontSize: "12px",
                            color: "black",
                            textAlign: "left",
                            borderBottom: "2px solid",
                            maxWidth: "max-content",
                          }}
                        >
                          No Container
                        </label>
                        <input
                          type="text"
                          placeholder="No Container"
                          className="C_form-input"
                          value={container.no_container}
                          onChange={(e) =>
                            handleInputContainer(
                              container.id,
                              "no_container",
                              e.target.value
                            )
                          }
                          style={{
                            minWidth: "100%",
                          }}
                        />
                      </div>
                      {container.detail_container !== null && (
                        <div className="menu-container">
                          <input
                            type="text"
                            className="addrowhs"
                            id="new_add_row"
                            size="2"
                            maxLength="2"
                            ref={(el) =>
                              (inputRowContainer.current[container.id] = el)
                            }
                            defaultValue={1}
                          />
                          <button
                            className="styled-button mini"
                            style={{ backgroundColor: "#2a2a6e" }}
                            onClick={() => AddRowContainer(container.id)}
                          >
                            Add Row
                          </button>
                          <button
                            className="styled-button mini"
                            style={{ backgroundColor: "#2a2a6e" }}
                            onClick={() => generateSeri(container.id)}
                          >
                            Generate Document
                          </button>
                        </div>
                      )}
                    </div>
                    {detailContainer[container.id] && (
                      <div className="section-can-hide">
                        {container.detail_container !== null && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              margin: "-5px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyItems: "center",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <table border="0" style={{ width: "100%" }}>
                                <tr>
                                  <td
                                    className="tal"
                                    width="120"
                                    style={{ padding: "5px" }}
                                  >
                                    <label
                                      className="C_form-label"
                                      style={{ margin: "0 5px" }}
                                    >
                                      Jenis
                                    </label>
                                  </td>
                                  <td
                                    className="tal"
                                    width="100"
                                    style={{ padding: "5px" }}
                                  >
                                    <label
                                      className="C_form-label"
                                      style={{ margin: "0 5px" }}
                                    >
                                      Ukuran
                                    </label>
                                  </td>
                                  <td
                                    className="tal"
                                    width="250"
                                    style={{ padding: "5px" }}
                                  >
                                    <label
                                      className="C_form-label"
                                      style={{ margin: "0 5px" }}
                                    >
                                      Tipe
                                    </label>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="tal"
                                    style={{ padding: "5px" }}
                                  >
                                    <CustomSelect
                                      options={dropdownOptions?.jenis}
                                      placeholder="Select Jenis"
                                      preSelectedItems={
                                        container.detail_container?.jenis
                                      }
                                      onSelectionChange={(selectedItems) =>
                                        handleInputContainer(
                                          container.id,
                                          "jenis",
                                          selectedItems
                                        )
                                      }
                                      style={{ margin: "5px 0" }}
                                    />
                                  </td>
                                  <td
                                    className="tal"
                                    style={{ padding: "5px" }}
                                  >
                                    <CustomSelect
                                      options={dropdownOptions?.ukuran}
                                      placeholder="Select Ukuran"
                                      preSelectedItems={
                                        container.detail_container?.ukuran
                                      }
                                      onSelectionChange={(selectedItems) =>
                                        handleInputContainer(
                                          container.id,
                                          "ukuran",
                                          selectedItems
                                        )
                                      }
                                      style={{ margin: "5px 0" }}
                                    />
                                  </td>
                                  <td
                                    className="tal"
                                    style={{ padding: "5px" }}
                                  >
                                    <CustomSelect
                                      options={dropdownOptions?.merk}
                                      placeholder="Select Tipe"
                                      preSelectedItems={
                                        container.detail_container?.merk
                                      }
                                      onSelectionChange={(selectedItems) =>
                                        handleInputContainer(
                                          container.id,
                                          "merk",
                                          selectedItems
                                        )
                                      }
                                      style={{ margin: "5px 0" }}
                                    />
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        )}
                        <div
                          style={{
                            textAlign: "left",
                            paddingTop: "10px",
                            paddingBottom: "15px",
                          }}
                        >
                          {hasHsCodeWarning && matchedHsCodes.length > 0 && (
                            <div
                              style={{
                                textAlign: "left",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                              }}
                            >
                              <b style={{ color: "#FFD700" }}>CAUTION!</b> :
                              &nbsp;
                              <ul style={{ paddingLeft: "20px" }}>
                                {matchedHsCodes.map((match, index) => (
                                  <li
                                    key={index}
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {match.message.toUpperCase()}!
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                        {container.detail_container && (
                          <div>
                            <HotTable
                              ref={(el) =>
                                (hotTableRefs.current[container.id] = el)
                              }
                              data={tablesData[container.id]}
                              rowHeaders={true}
                              colHeaders={[
                                "ID",
                                "No Container",
                                "Jenis Dokumen",
                                "Nomor Dokumen",
                                "Tanggal",
                              ]}
                              columns={[
                                { type: "text", title: "ID", readOnly: true }, // Hidden column (doc.id)
                                {
                                  type: "text",
                                  title: "No Container",
                                  align: "center",
                                  readOnly: true,
                                },
                                {
                                  type: "dropdown",
                                  title: "Jenis Dokumen",
                                  align: "center",
                                  source: dropdownOptionsDocument,
                                },
                                {
                                  type: "text",
                                  title: "Nomor Dokumen",
                                  align: "center",
                                },
                                {
                                  type: "date",
                                  title: "Tanggal",
                                  dateFormat: "MM/DD/YYYY",
                                  correctFormat: true,
                                  defaultDate: formattedDate,
                                  align: "center",
                                },
                              ]}
                              contextMenu={{
                                items: {
                                  remove_row: {
                                    name: "Delete row",
                                    callback: () =>
                                      removeSelectedRows(container.id),
                                  },
                                },
                              }}
                              hiddenColumns={{
                                columns: [0], // Hides the first column (ID)
                                indicators: false, // Optional, hides visual indicators that a column is hidden
                                copyPasteEnabled: true,
                              }}
                              copyPaste={true}
                              afterChange={(changes, source) => {
                                if (!changes) return; // Exit if there are no changes

                                if (source === "edit") {
                                  // For single edit, update immediately
                                  changes.forEach(
                                    ([row, col, oldVal, newVal]) => {
                                      if (oldVal !== newVal) {
                                        const rowData =
                                          tablesData[container.id][row]; // Get the row data
                                        const documentId = rowData[0]; // Assuming the ID is in the first hidden column

                                        const field =
                                          col === 2
                                            ? "jenis_dokumen"
                                            : col === 3
                                            ? "no_dokumen"
                                            : "tgl_dokumen";

                                        if (field) {
                                          const payloads = {
                                            id: documentId,
                                            [field]: newVal, // Dynamically assign the field
                                          };

                                          updateItemDocument([payloads]); // Immediate API update for single edit
                                        }
                                      }
                                    }
                                  );
                                } else if (source === "CopyPaste.paste") {
                                  // For copy-paste, accumulate changes and send one API call with all updated rows
                                  const payloads = []; // Array to accumulate row payloads

                                  changes.forEach(
                                    ([row, col, oldVal, newVal]) => {
                                      if (oldVal !== newVal) {
                                        const rowData =
                                          tablesData[container.id][row];
                                        const documentId = rowData[0]; // Assuming the ID is in the first hidden column

                                        // Check if this row is already in the payloads array
                                        let rowPayload = payloads.find(
                                          (payload) => payload.id === documentId
                                        );

                                        if (!rowPayload) {
                                          // If no payload for this row, initialize one and add to the array
                                          rowPayload = {
                                            id: documentId,
                                            no_container: rowData[1],
                                            jenis_dokumen: rowData[2],
                                            no_dokumen: rowData[3],
                                            tgl_dokumen: rowData[4],
                                          };
                                          payloads.push(rowPayload);
                                        }

                                        // Update the payload based on which column is modified
                                        if (col === 2) {
                                          rowPayload.jenis_dokumen = newVal;
                                        } else if (col === 3) {
                                          rowPayload.no_dokumen = newVal;
                                        } else if (col === 4) {
                                          rowPayload.tgl_dokumen = newVal;
                                        }
                                      }
                                    }
                                  );

                                  if (payloads.length > 0) {
                                    // Send a single API call with all the changed rows
                                    updateItemDocument(payloads); // Assuming a bulk API call function
                                  }
                                }
                              }}
                              stretchH="all"
                              width="940"
                              height={
                                tablesData[container.id] &&
                                tablesData[container.id].length === 0
                                  ? "auto"
                                  : "250"
                              }
                              rowHeights={40}
                              autoRowSize={true}
                              manualColumnResize={true}
                              autoWrapRow={true}
                              autoWrapCol={true}
                              licenseKey="non-commercial-and-evaluation"
                            />
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  textAlign: "left",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "5px",
                                    alignItems: "center",
                                  }}
                                >
                                  <label
                                    className="C_form-label"
                                    style={{
                                      margin: "0 5px",
                                      textAlign: "left",
                                    }}
                                  >
                                    Format
                                  </label>
                                  <CustomSelect
                                    options={[
                                      { name: "default", value: 1 },
                                      { name: "shoes", value: 2 },
                                    ]}
                                    placeholder="Select Jenis"
                                    preSelectedItems={formatUse}
                                    onSelectionChange={(selectedItems) =>
                                      handleSelectFormat(selectedItems)
                                    }
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "5px",
                                    alignItems: "center",
                                  }}
                                >
                                  <label
                                    className="C_form-label"
                                    style={{
                                      margin: "0 5px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Use SKA?
                                  </label>
                                  <input
                                    type="checkbox"
                                    checked={
                                      container.detail_container?.is_used_ska
                                    }
                                    onChange={(e) =>
                                      handleInputContainer(
                                        container.id,
                                        "is_used_ska",
                                        e.target.checked
                                      )
                                    }
                                    style={{
                                      width: "15px",
                                      height: "15px",
                                      transform: "scale(1.5)",
                                      WebkitTransform: "scale(1.5)",
                                    }}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "left",
                                  }}
                                >
                                  <label
                                    className="C_form-label"
                                    style={{ margin: "0 5px" }}
                                  >
                                    File Concept
                                  </label>
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      handleFileChange(e, container.id)
                                    }
                                    className="w_cfit_1 custom-height-input-file"
                                    style={{ minWidth: "15%" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {hasBarangData && (
                          <div style={{ textAlign: "left", marginTop: "20px" }}>
                            <div
                              onClick={() =>
                                toggleDetailVisibility(container.id)
                              } // Toggle the visibility on click
                              style={{
                                borderBottom: "2px solid",
                                maxWidth: "max-content",
                                marginBottom: "5px",
                                cursor: "pointer",
                                fontWeight: "bold",
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              Detail Barang
                            </div>

                            {/* Only show the Detail Barang table if it's toggled visible */}
                            {detailVisibility[container.id] && (
                              <HotTable
                                data={tablesBarang[container.id]}
                                columns={column_barang}
                                className="htCenter"
                                stretchH="all"
                                height="auto"
                                licenseKey="non-commercial-and-evaluation"
                              />
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        position: "relative",
                        marginTop: "15px",
                      }}
                    >
                      <button
                        type="button"
                        className="styled-button mini red tooltip-trigger"
                        onClick={() => deleteContainer(container.id)}
                      >
                        Delete
                      </button>
                      <div className="tooltip">Delete container</div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div
          className="C_form-container form_align_left"
          style={{ overflow: "visible" }}
        >
          <div
            className="title_form"
            onClick={tg_collapse_document_barang}
            style={{ cursor: "pointer" }}
          >
            Barang Dokumen
          </div>
          <div className={isCollapsedDocumentBarang ? "hidden" : "visible"}>
            <br></br>
            <table class="custom-table">
              <thead>
                <tr>
                  <th width="55">No</th>
                  <th width="150">HS code</th>
                  <th style={{ textWrap: "nowrap" }}>No Container</th>
                  <th>Seri Dokumen</th>
                </tr>
              </thead>
              <tbody>
                {listDocuments.map((doc, index) => (
                  <tr key={doc.id}>
                    <td className="no-column">{index + 1}</td>
                    <td>{doc.hs_code}</td>
                    <td>{doc.no_container}</td>
                    <td>
                      <CustomMultiSelect
                        options={document_series}
                        placeholder="Select seri documents..."
                        preSelectedItems={doc.ids_document}
                        onSelectionChange={(selectedItems) =>
                          handleSelectionChange(
                            doc.id,
                            selectedItems,
                            doc.hs_code
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="C_form-container">
          <div
            className="title_form"
            onClick={tg_collapse_pengangkut}
            style={{ cursor: "pointer" }}
          >
            Pengangkut
          </div>
          <div className={isCollapsedPengangkut ? "hidden" : "visible"}>
            <div style={{ marginTop: "20px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Nomor Tutup PU</span>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    marginTop: "10px",
                  }}
                >
                  <select
                    ref={select_pu}
                    value={val_tutup_pu}
                    className="js-example-basic-multiple C_select-input_table"
                  >
                    <option key={0} value={""}>
                      {" - "}
                    </option>
                    {dataCarrier.pu_codes.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    onBlur={(event) =>
                      handleUpdateRowEntity(
                        "nomor_tutup_pu",
                        event.target.value
                      )
                    }
                    onChange={(event) => setNoTutupPU(event.target.value)}
                    placeholder="nomor tutup PU"
                    value={nomor_tutup_pu}
                    className="cfit w_cfit_1"
                  />
                  <input
                    type="date"
                    onBlur={(event) =>
                      handleUpdateRowEntity(
                        "tanggal_tutup_pu",
                        event.target.value
                      )
                    }
                    onChange={(event) => setTglTutupPU(event.target.value)}
                    value={formatTimestampToDateInput(tanggal_tutup_pu)}
                    className="cfit w_cfit_1"
                  />
                </div>
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Nomor Pos</span>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    marginTop: "10px",
                  }}
                >
                  <input
                    type="text"
                    onBlur={(event) =>
                      handleUpdateRowEntity("nomor_pos", event.target.value)
                    }
                    onChange={(event) => setNoPosPU(event.target.value)}
                    placeholder="Nomor pos"
                    value={nomor_pos}
                    className="cfit w_cfit_1"
                  />
                  <input
                    type="text"
                    onBlur={(event) =>
                      handleUpdateRowEntity("nomor_sub_pos", event.target.value)
                    }
                    onChange={(event) => setNoSubPosPU(event.target.value)}
                    placeholder="Nomor Subpos"
                    value={nomor_sub_pos}
                    className="cfit w_cfit_1"
                  />
                  <input
                    type="text"
                    onBlur={(event) =>
                      handleUpdateRowEntity(
                        "nomor_sub_sub_pos",
                        event.target.value
                      )
                    }
                    onChange={(event) => setNoSubSubPosPU(event.target.value)}
                    placeholder="Nomor Subsubpos"
                    value={nomor_sub_sub_pos}
                    className="cfit w_cfit_1"
                  />
                </div>
              </div>
            </div>
            <div style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Cara Pengangkutan</span>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    marginTop: "10px",
                  }}
                >
                  <select
                    value={dataCarrier.carriers[0].id}
                    className="js-example-basic-multiple C_select-input_table"
                  >
                    <option key={0} value={""}>
                      {" - "}
                    </option>
                    {dataCarrier.carriers.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Nama Sarana Angkut</span>
                <div
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <input
                    type="text"
                    onBlur={(event) =>
                      handleUpdateRowEntity(
                        "nama_sarana_pengangkutan",
                        event.target.value
                      )
                    }
                    onChange={(event) => setNamaSarana(event.target.value)}
                    value={nama_sarana_pengangkutan}
                    className="cfit w_cfit_1"
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <span style={{ fontWeight: "bold" }}>
                  Nomor Voy/Flight/No.Pol
                </span>
                <div
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <input
                    type="text"
                    onBlur={(event) =>
                      handleUpdateRowEntity("no_pol", event.target.value)
                    }
                    onChange={(event) => setNoPol(event.target.value)}
                    value={no_pol}
                    className="cfit w_cfit_1"
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Bendera</span>
                <div
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <select
                    ref={select_country_carrier}
                    value={bendera}
                    className="js-example-basic-multiple C_select-input_table"
                  >
                    <option key={0} value={""}>
                      {" - "}
                    </option>
                    {dataEntity.countries.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <span style={{ fontWeight: "bold" }}>
                  Perkiraan Tanggal Tiba
                </span>
                <div
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <input
                    type="date"
                    onBlur={(event) =>
                      handleUpdateRowEntity("tanggal_tiba", event.target.value)
                    }
                    onChange={(event) => setTglTiba(event.target.value)}
                    value={formatTimestampToDateInput(tanggal_tiba)}
                    className="cfit w_cfit_1"
                  />
                </div>
              </div>
            </div>
            <div style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Pelabuhan Muat</span>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    marginTop: "10px",
                  }}
                >
                  <select
                    ref={select_loads_harbour}
                    value={kode_pel_muat}
                    className="js-example-basic-multiple C_select-input_table"
                  >
                    <option key={0} value={""}>
                      {" - "}
                    </option>
                    {dataCarrier.loads.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Pelabuhan Transit</span>
                <div
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <select
                    ref={select_transit_harbour}
                    value={kode_pel_transit}
                    className="js-example-basic-multiple C_select-input_table"
                  >
                    <option key={0} value={""}>
                      {" - "}
                    </option>
                    {dataCarrier.transits.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Pelabuhan Tujuan</span>
                <div
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <input
                    value={val_pel_tujuan}
                    type="text"
                    className="C_form-input cfit w_cfit_1"
                    style={{ minWidth: "90%", cursor: "not-allowed" }}
                    disabled
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Tempat Penimbunan</span>
                <div
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <select
                    ref={select_heaps_harbour}
                    value={kode_tps}
                    className="js-example-basic-multiple C_select-input_table"
                  >
                    <option key={0} value={""}>
                      {" - "}
                    </option>
                    {dataCarrier.heaps.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="C_form-container form_align_left">
          <div
            className="title_form"
            onClick={tg_collapse_kemasan}
            style={{ cursor: "pointer" }}
          >
            Kemasan
          </div>
          <div className={isCollapsedKemasan ? "hidden" : "visible"}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                marginTop: "15px",
                width: "100%",
              }}
            >
              <div style={{ width: "54%" }}>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    fontWeight: "bold",
                  }}
                >
                  Kemasan
                </span>
                <div
                  style={{
                    border: "1px solid rgb(190, 190, 190)",
                    borderRadius: "8px",
                    padding: "4px",
                    marginTop: "8px",
                  }}
                >
                  <HotTable
                    ref={hotRefKemasan}
                    contextMenu={true}
                    rowHeaders={true}
                    filters={true}
                    outsideClickDeselects={false}
                    allowInsertColumn={true}
                    height={tableHeight}
                    autoRowSize={true}
                    readOnly={false}
                    manualColumnResize={true}
                    dropdownMenu={[
                      "filter_by_condition",
                      "filter_by_value",
                      "filter_action_bar",
                    ]}
                    columns={title_kemasan(dropdownKemasan)}
                    licenseKey="non-commercial-and-evaluation"
                    colWidths={[10, 10, 10, 150, 200, 200, 200]}
                    hiddenColumns={{
                      copyPasteEnabled: true,
                      indicators: true,
                      columns: [0, 1, 2, 7],
                    }}
                    beforeCreateRow={(index, amount) => {}}
                    afterCreateRow={(index, amount) => {
                      doc_CKMS(
                        index,
                        amount,
                        hotRefKemasan,
                        token,
                        nomorAju,
                        "kemasan"
                      );
                    }}
                    afterChange={(changes, source) => {
                      doc_UKMS(changes, source, hotRefKemasan, token, nomorAju);
                    }}
                    beforeRemoveRow={(
                      index,
                      amount,
                      physicalRows,
                      [source]
                    ) => {
                      doc_DKMS(
                        index,
                        amount,
                        physicalRows,
                        [source],
                        hotRefKemasan,
                        token,
                        nomorAju
                      );
                    }}
                    afterPaste={(data, coords) => {
                      doc_PKMS(data, coords, hotRefKemasan, token, nomorAju);
                    }}
                  />
                  <div className="menu-container">
                    <input
                      type="text"
                      placeholder="0"
                      className="addrowhs"
                      id="new_add_row"
                      size="3"
                      maxlength="2"
                      onChange={handleInputChange}
                    ></input>
                    <button
                      class="styled-button mini"
                      style={{ backgroundColor: "#2a2a6e" }}
                      onClick={addNewRowKMS}
                    >
                      New
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="C_form-container">
          <div
            className="title_form"
            onClick={tg_collapse_Transaksi}
            style={{ cursor: "pointer" }}
          >
            Transaksi
          </div>
          <div className={isCollapsedTransaksi ? "hidden" : "visible"}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                marginTop: "20px",
              }}
            >
              <div style={{ width: "100%" }}>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    fontWeight: "bold",
                    fontSize: "14px",
                    marginBottom: "10px",
                  }}
                >
                  Harga
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div
                      className="C_form-row"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "baseline",
                      }}
                    >
                      <label className="C_form-label">Valuta</label>
                      <select
                        ref={select_valuta}
                        value={kode_valuta}
                        className="js-example-basic-multiple C_select-input_table"
                        style={{ width: "82%" }}
                      >
                        <option key={0} value={""}>
                          {" - "}
                        </option>
                        {valutas.map((option, index) => (
                          <option key={index} value={option.code}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div
                      className="C_form-row"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "baseline",
                      }}
                    >
                      <label className="C_form-label">NDPBM</label>
                      <input
                        ref={ndpbmVal}
                        type="text"
                        className="C_form-input"
                        value={ndpbm}
                        style={{
                          maxWidth: "100%",
                          cursor: "not-allowed",
                        }}
                        disabled
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div
                      className="C_form-row"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "baseline",
                      }}
                    >
                      <label className="C_form-label">Jenis Transaksi</label>
                      <select
                        value={"LAI"}
                        className="js-example-basic-multiple C_select-input_table"
                        style={{ width: "82%" }}
                      >
                        <option key={0} value={""}>
                          {" - "}
                        </option>
                        <option value={"LAI"}>
                          LAI - TRANSAKSI PERDAGANGAN ATAU CARA PEMBAYARAN
                          LAINNYA
                        </option>
                      </select>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div
                      className="C_form-row"
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        gap: "20px",
                        width: "100%",
                      }}
                    >
                      <div
                        className="C_form-row"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "baseline",
                        }}
                      >
                        <label className="C_form-label">Harga Barang</label>
                        <select
                          ref={select_harga_barang}
                          value={kode_harga_barang}
                          className="js-example-basic-multiple C_select-input_table"
                        >
                          <option key={0} value={""}>
                            {" - "}
                          </option>
                          {priceStuff.map((option, index) => (
                            <option key={index} value={option.code}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        className="C_form-row"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "baseline",
                        }}
                      >
                        <label className="C_form-label"></label>
                        <input
                          ref={priceVal}
                          type="text"
                          value={formatPrice(val_harga_barang)}
                          onChange={(event) =>
                            handleInputPriceStuff(event, "nilai_harga_barang")
                          }
                          className="C_form-input"
                          style={{ minWidth: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div
                      className="C_form-row"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "baseline",
                      }}
                    >
                      <label className="C_form-label">Nilai Pabean</label>
                      <input
                        type="text"
                        className="C_form-input"
                        value={val_pabean}
                        style={{
                          maxWidth: "100%",
                          cursor: "not-allowed",
                        }}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    fontWeight: "bold",
                    fontSize: "14px",
                    marginBottom: "10px",
                  }}
                >
                  Harga Lainnya
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div
                      className="C_form-row"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "baseline",
                      }}
                    >
                      <label className="C_form-label">Biaya Penambah</label>
                      <input
                        type="text"
                        className="C_form-input"
                        onChange={(event) =>
                          handleInputAdditionalBill(event, "biaya_penambah")
                        }
                        value={val_biaya_penambah}
                        style={{ maxWidth: "100%" }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div
                      className="C_form-row"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "baseline",
                      }}
                    >
                      <label className="C_form-label">Biaya Pengurang</label>
                      <input
                        type="text"
                        onChange={(event) =>
                          handleInputReductionBill(event, "biaya_pengurang")
                        }
                        value={val_biaya_pengurang}
                        className="C_form-input"
                        style={{ maxWidth: "100%" }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div
                      className="C_form-row"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "baseline",
                      }}
                    >
                      <label className="C_form-label">Freight</label>
                      <input
                        type="text"
                        value={freight || 0}
                        className="C_form-input"
                        style={{ maxWidth: "100%", cursor: "not-allowed" }}
                        disabled
                      />
                    </div>
                  </div>
                  <div
                    className="C_form-row"
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      gap: "20px",
                      width: "100%",
                    }}
                  >
                    <div
                      className="C_form-row"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "baseline",
                      }}
                    >
                      <label className="C_form-label">Asuransi</label>
                      <select
                        ref={select_asuransi}
                        value={jenis_asuransi}
                        className="js-example-basic-multiple C_select-input_table"
                      >
                        <option key={0} value={""}>
                          {" - "}
                        </option>
                        {asurance.map((option, index) => (
                          <option key={index} value={option.code}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div
                      className="C_form-row"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "baseline",
                      }}
                    >
                      <label className="C_form-label"></label>
                      <input
                        type="text"
                        onChange={(event) =>
                          debouncedHandleUpdateRowEntity(
                            "nilai_asuransi",
                            event.target.value
                          )
                        }
                        value={nilai_asuransi}
                        className="C_form-input"
                        style={{ minWidth: "100%" }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div
                      className="C_form-row"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "baseline",
                      }}
                    >
                      <label className="C_form-label">
                        Voluntary Declaration
                      </label>
                      <input
                        type="text"
                        onChange={(event) =>
                          handleInputVoluntaryBill(
                            event,
                            "voluntary_declaration"
                          )
                        }
                        value={val_voluntary}
                        className="C_form-input"
                        style={{ maxWidth: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    fontWeight: "bold",
                    fontSize: "14px",
                    marginBottom: "10px",
                  }}
                >
                  Berat
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div
                      className="C_form-row"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "baseline",
                      }}
                    >
                      <label className="C_form-label">Berat Kotor (KGM)</label>
                      <input
                        type="text"
                        className="C_form-input"
                        onChange={(event) =>
                          debouncedHandleUpdateRowEntity(
                            "bruto",
                            event.target.value
                          )
                        }
                        value={bruto}
                        style={{ maxWidth: "100%" }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div
                      className="C_form-row"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "baseline",
                      }}
                    >
                      <label className="C_form-label">Berat Bersih (KGM)</label>
                      <input
                        type="text"
                        className="C_form-input"
                        value={netto}
                        style={{
                          maxWidth: "100%",
                          cursor: "not-allowed",
                        }}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CEISA1;
