import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'select2';
import '../css/Select2.css';
import '../css/Login.css';
import '../css/Materialize.css';
import '../css/Style.css';
import '../css/Styleside.css';
import '../css/new.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import reloadToken from '../helper/reloadtoken';
import helperFilter from '../helper/helperfilter';
import { dataconfig, showtoolbarConfig, ct, dataconfigfclpage, dataconfigReportDataCont } from '../helper/luckysheetHelper';
import { hide, bikinInfoSum, sumDataKerja, getFrozen, createRatioZoom, getRatioZoom, saveheighrow, getheighrow, bikinselect, getFilterPageAll, getFilterPageAllJson } from '../helper/hide';
import HeaderMenu from '../template/HeaderMenu';
import { apiConfig } from '../helper/apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dataformula from '../sheetFormula.js';
import pako from 'pako';
import Modal from './modal';
import ModalFilter from './modalFilter';
import { Autofill } from 'handsontable/plugins';

const tipedata = "DATACONTREPORT";
const host = process.env.REACT_APP_API_URL_ONLINE;
const hostdrop = process.env.REACT_APP_API_HOST_DROPDOWN;
const hostcont = process.env.REACT_APP_API_HOST_DATA_CONT;
const hostdatcogitnt = process.env.REACT_APP_API_HOST_DATA_CONT;
const hostcc = process.env.REACT_APP_API_HOST_COLOR_COMMENT;
const hostfcl = process.env.REACT_APP_API_HOST_FCL;

const fullURL = window.location.href;
const rp_type = "rp_type-" + fullURL;
const rp_sales = "rp_sales-" + fullURL;
const rp_marking = "rp_marking-" + fullURL;
const rp_item = "rp_item-" + fullURL;
const rp_aju = "rp_aju-" + fullURL;
const rp_container = "rp_container-" + fullURL;
const rp_port = "rp_port-" + fullURL;
const rp_kolom_tgl = "rp_kolom_tgl-" + fullURL;
const rp_cnee = "rp_cnee-" + fullURL;
const rp_tgl_1 = "rp_tgl_1-" + fullURL;
const rp_tgl_2 = "rp_tgl_2-" + fullURL;

const notify_cant_update_br_cont = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 500,
  });
};
const notify_update_br_cont = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 500,
  });
};

const DatacontReport = () => {
  const luckysheet = window.luckysheet;
  let merged;
  let canformual;
  let mergedDataRO;
  let luckysheetInstance;
  const [isChecked, setIsChecked] = useState(false);
  const [widthArea, setWidthArea] = useState(2322);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setWidthArea(isChecked ? 2322 : 80)
  };

  const selectForType = useRef(null);
  const selectForSales = useRef(null);
  const selectForMarking = useRef(null);
  const selectForPort = useRef(null);
  const selectForKolomTgl = useRef(null);
  const selectForCnee = useRef(null);

  const [token, setToken] = useState(null);
  var b = 0;
  const pagecolumn = "DATACONTREPORT"
  var columnWidths = [];
  var kiri, atas, tinggi;
  var options = null;
  var options5 = null;
  var type_link = "";
  var type_row = "";
  var type_jml = 0;
  let valueppn = "YA,TIDAK";
  let valueukuran = "20,40,45";
  let valuestatusbl = "TELEX,DRAFT,ORI,HBL";
  let dataserver = [];
  let dataserver1 = [];
  var end_column_freze = 10;
  var size_scroll_left = 0;

  const [modalVisible, setModalVisible] = useState(false);
  const [datamodal, setDataModal] = useState(false);
  const [modalFilterVisible, setModalFilterVisible] = useState(false);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const toggleFilterModal = () => {
    setModalFilterVisible(!modalFilterVisible);
  };
  const [isVisible, setIsVisible] = useState(false);
  const [v_type, setV_type] = useState([]);
  const [selectType, setSelectType] = useState(getFilterPageAll(rp_type));

  const [v_sales, setV_sales] = useState([]);
  const [selectSales, setSelectSales] = useState(getFilterPageAllJson(rp_sales));

  const [v_marking, setV_marking] = useState([]);
  const [selectMarking, setSelectMarking] = useState(getFilterPageAllJson(rp_marking));

  const [selectItem, setselectItem] = useState(getFilterPageAll(rp_item));

  const [selectAju, setselectAju] = useState(getFilterPageAllJson(rp_aju));

  const [selectContainer, setselectContainer] = useState(getFilterPageAllJson(rp_container));

  const [v_port, setV_port] = useState([]);
  const [selectPort, setSelectPort] = useState(getFilterPageAllJson(rp_port));

  const [v_kolom_tgl, setV_kolom_tgl] = useState([]);
  const [selectKolomTgl, setselectKolomTgl] = useState(getFilterPageAll(rp_kolom_tgl));

  const [v_cnee, setV_cnee] = useState([]);
  const [selectCnee, setselectCnee] = useState(getFilterPageAllJson(rp_cnee));

  const [selecttgl1, setselectTgl1] = useState(getFilterPageAll(rp_tgl_1));
  const [selecttgl2, setselectTgl2] = useState(getFilterPageAll(rp_tgl_2));
  const [nama_tgl, setnama_tgl] = useState(selectCnee);

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, index) => currentYear - index);

  const handleTypeChange = (event) => {
    setV_marking([]);
  }

  const change = (event) => {

  }

  const handleTextChangeMarking = (event) => {
    const inputValue = event.target.value;
    const modifiedValue = inputValue.replace(/\n/g, '');
    let modifiedValue1;

    if (modifiedValue === "") {
      localStorage.removeItem(rp_marking);
      modifiedValue1 = [];
    } else {
      modifiedValue1 = modifiedValue.split(',').map(item => item.trim());
      localStorage.setItem(rp_marking, JSON.stringify(modifiedValue1));
    }

    setSelectMarking(modifiedValue1);
  }

  const handleTextChangeItem = (event) => {
    const inputValue = event.target.value;
    const modifiedValue = inputValue.replace(/\n/g, '');

    setselectItem(modifiedValue);
    localStorage.setItem(rp_item, modifiedValue);
  };

  const handleTextChangeAju = (event) => {
    const inputValue = event.target.value;
    let modifiedValue;

    if (inputValue === "") {
      localStorage.removeItem(rp_aju);
      modifiedValue = [];
    } else {
      modifiedValue = inputValue.split(',').map(item => item.trim());
      localStorage.setItem(rp_aju, JSON.stringify(modifiedValue));
    }
    setselectAju(modifiedValue);
  };

  const handleTextChangeContainer = (event) => {
    const inputValue = event.target.value;
    let modifiedValue;

    if (inputValue === "") {
      localStorage.removeItem(rp_container);
      modifiedValue = [];
    } else {
      modifiedValue = inputValue.split(',').map(item => item.trim());
      localStorage.setItem(rp_container, JSON.stringify(modifiedValue));
    }
    setselectContainer(modifiedValue);

  };
  const handleTextChangeTgl1 = (event) => {
    setselectTgl1(event.target.value);
    localStorage.setItem(rp_tgl_1, event.target.value);
  };
  const handleTextChangeTgl2 = (event) => {
    setselectTgl2(event.target.value);
    localStorage.setItem(rp_tgl_2, event.target.value);
  };

  const handleResetClick = () => {
    const keysToDelete = [rp_type, rp_sales, rp_marking, rp_item, rp_aju, rp_container, rp_port, rp_cnee, rp_kolom_tgl, rp_tgl_1, rp_tgl_2];

    keysToDelete.forEach(key => {
      localStorage.removeItem(key);
    });

    window.location.reload();
  }

  function showSideifhavesearch() {
    if ((Array.isArray(selectSales) && selectSales.length > 0) ||
      (selectMarking && selectMarking.length > 0) ||
      (selectItem && selectItem.length > 0) ||
      (selectAju && selectAju.length > 0) ||
      (selectContainer && selectContainer.length > 0) ||
      (Array.isArray(selectPort) && selectPort.length > 0) ||
      (Array.isArray(selectCnee) && selectCnee.length > 0) ||
      (selectKolomTgl && selectKolomTgl.length > 0) ||
      (selectType && selectType.length > 0)
    ) {
      setTimeout(() => {
        handleCheckboxChange();
      }, 1000);
    }
  }

  function getDataSearch() {
    const payload = {};
    if (selectType && selectType.length > 0) {
      payload.markType = selectType;
    }

    if (selectMarking && selectMarking.length > 0) {
      payload.mark = selectMarking;
    }

    if (Array.isArray(selectSales) && selectSales.length > 0) {
      payload.sales = selectSales;
    }

    if (selectItem && selectItem.length > 0) {
      payload.item = selectItem;
    }

    if (selectAju && selectAju.length > 0) {
      payload.aju = selectAju;
    }

    if (selectContainer && selectContainer.length > 0) {
      payload.cont = selectContainer;
    }

    if (Array.isArray(selectPort) && selectPort.length > 0) {
      payload.port = selectPort;
    }

    if (Array.isArray(selectCnee) && selectCnee.length > 0) {
      payload.cnee = selectCnee;
    }

    if (selectKolomTgl && selectKolomTgl.length > 0) {
      payload.by = selectKolomTgl;

      if (selecttgl1 && selecttgl1.length > 0) {
        payload.tgl1 = selecttgl1;
      }
      if (selecttgl2 && selecttgl2.length > 0) {
        payload.tgl2 = selecttgl2;
      }
    }

    console.log("payload ", payload);

    return payload
  }

  const handleSearchClick = async () => {
    window.location.reload();
    // try {
    //   const payload = getDataSearch();
    //   const newToken = await reloadToken()
    //   setToken(newToken);
    //   if (newToken) {
    //     initializeLuckySheet(newToken, null, null, null, null, null, payload);
    //   }
    //   console.log('Search results:');
    // } catch (error) {
    //   console.error('Error searching:', error);
    // }
  };

  useEffect(() => {
    const elementScroll = document.getElementById('luckysheet-scrollbar-x');
    const elementluckysheet = document.querySelector('.luckysheet');

    if (elementScroll) {
      if (isChecked) {
        elementScroll.classList.add('active');
      } else {
        elementScroll.classList.remove('active');
      }
    }

    if (elementluckysheet) {
      if (isChecked) {
        elementluckysheet.classList.add('active');
      } else {
        elementluckysheet.classList.remove('active');
      }
    }
  
  }, [isChecked]);

  useEffect(() => {
    setupSelect2();
    const fetchData = async () => {
      const payload = getDataSearch();
      await new Promise((resolve) => setTimeout(resolve, 100));
      const newToken = await reloadToken()
      setToken(newToken);
      if (newToken) {
        // initializeLuckySheet(newToken, null, null, null, null, null);
        initializeLuckySheet(newToken, null, null, null, null, null, payload);
        //showSideifhavesearch();
      }
    };

    fetchData();
    const handleVisibilityChange = () => {
      if (document.hidden) {
      } else {
        if (luckysheet) { 
          luckysheet.refresh();
        } else{
          window.location.reload();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      // destroySelect2();
    };
  }, []);

  const destroySelect2 = () => {
    $(selectForType.current).select2('destroy');
    $(selectForSales.current).select2('destroy');
    $(selectForMarking.current).select2('destroy');
    $(selectForPort.current).select2('destroy');
    $(selectForKolomTgl.current).select2('destroy');
    $(selectForCnee.current).select2('destroy');
  }

  const setupSelect2 = () => {
    if (selectKolomTgl) {
      setIsVisible(true);
    } else {
      setIsVisible(false)
    }
    //TYPE
    $('.js-example-basic-single').select2();
    $('.js-example-basic-multiple').select2({
      theme: "classic"
    });

    $(selectForType.current).on('change', function (e) {
      const selectedValue = $(this).val();
      setSelectType(selectedValue);
      localStorage.setItem(rp_type, selectedValue);

      //   setTimeout(function () {

      // alert('test');
      //     reloadMarking();
      //   }, 500);

    });
    //SALES

    $(selectForSales.current).select2({

    });
    $(selectForSales.current).on('change', function (e) {
      const selectedValue = $(this).val();
      setSelectSales(selectedValue);
      localStorage.setItem(rp_sales, JSON.stringify(selectedValue));
    });
    //MARKING
    $(selectForMarking.current).select2({

    });
    //PORT
    $(selectForPort.current).select2({

    });
    $(selectForPort.current).on('change', function (e) {
      const selectedValue = $(this).val();
      setSelectPort(selectedValue);
      localStorage.setItem(rp_port, JSON.stringify(selectedValue));
    });
    //kolomtgl
    $(selectForKolomTgl.current).select2({
    });
    $(selectForKolomTgl.current).on('change', function (e) {
      const selectedValue = $(this).val();
      setselectKolomTgl(selectedValue);
      setnama_tgl(selectedValue + " :")
      localStorage.setItem(rp_kolom_tgl, selectedValue);

      if (selectedValue) {
        setIsVisible(true);
      } else {
        setIsVisible(false)
      }

    });
    //cnee
    $(selectForCnee.current).select2({

    });
    $(selectForCnee.current).on('change', function (e) {
      const selectedValue = $(this).val();
      setselectCnee(selectedValue);
      localStorage.setItem(rp_cnee, JSON.stringify(selectedValue));
    });
  }

  const getDropdown = async (url) => {
    const newToken = await reloadToken()
    setToken(newToken);
    if (newToken) {
      const config = apiConfig(newToken);
      try {
        const response = await axios.get(url, config);
        return response.data;
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
        return []; // Return an empty array in case of an error
      }
    }
  };

  useEffect(() => {
    setV_marking([]);
    //reloadMarking();
  }, [selectType]);

  const reloadMarking = async () => {
    try {
      let markingPromise;
      let type = getFilterPageAll(rp_type);
      setV_marking([]);
      if (type && type != null || type != "null") {
        markingPromise = getDropdown(`${hostdrop}MasterDropdownReactReport/marking/${type}`);
      } else {
        markingPromise = getDropdown(`${hostdrop}MasterDropdownReactReport/marking`);
      }

      const [marking] = await Promise.all([markingPromise]);
      setV_marking(marking);
    } catch (error) {
      console.error('Error loading marking dropdown:', error);
      // Handle the error as needed, e.g., show a message to the user
    }
  };

  const initializeLuckySheet = async (token, bln, thn, tipe, port, namatable, payload) => {
    const convertedData = convertDataToArray(payload);
    console.log("convertedData ", convertedData);

    let datacari = [];
    datacari.push(bln + "-" + thn + "-" + tipe + "-" + port)

    new Promise((resolve) => setTimeout(resolve, 100));
    const config = apiConfig(token);

    const getDropdown = async (data) => {
      try {
        const response = await axios.get(data, config);
        return response.data;
      } catch (error) {
        console.error('Error fetching column names:', error);
        return [];
      }
    };
    let type = getFilterPageAll(rp_type);
    //const markingPromise = getDropdown(`${hostdrop}MasterDropdownReact/marking`);
    const dropsalesPromise = getDropdown(`${hostdrop}MasterDropdownReact/sales/FCL`);
    const dropPortPromise = getDropdown(`${hostdrop}MasterDropdownReact/port/FCL`);
    const dropKolomTglReport = getDropdown(`${hostdrop}KolomTglReport`);
    const dropcneePromise = getDropdown(`${hostdrop}MasterDropdownReact/cnee/FCL`);
    const droppolPromise = getDropdown(`${hostdrop}MasterDropdownReact/pol/FCL`);
    const dropadminPromise = getDropdown(`${hostdrop}MasterDropdownReact/admin/FCL`);
    const dropkonseptorPromise = getDropdown(`${hostdrop}MasterDropdownReact/konseptor/FCL`);
    const droptipePromise = getDropdown(`${hostdrop}MasterDropdownReact/tipe_fcl`);
    const droptipeReport = getDropdown(`${hostdrop}MasterDropdownReact/type_report`);


    const [dropPort, dropcnee, droppol, dropadmin, dropkonseptor, dropsales, droptipe, dropkolomtgl, type_report] = await Promise.all([
      dropPortPromise,
      dropcneePromise,
      droppolPromise,
      dropadminPromise,
      dropkonseptorPromise,
      dropsalesPromise,
      droptipePromise,
      dropKolomTglReport,
      droptipeReport,
    ]);
    setV_type(type_report);
    setV_sales(dropsales);
    //setV_marking(marking);
    setV_port(dropPort);
    setV_kolom_tgl(dropkolomtgl)
    setV_cnee(dropcnee)

    const getColumnNames = async () => {
      try {
        const config = apiConfig(token);
        const response = await axios.post(`${hostcont}ReactBrcontainerColumn`, null, config);
        return response.data.column;
      } catch (error) {
        console.error('Error fetching column names:', error);
        return [];
      }
    };

    const getCellData = async (data, savedColumnWidths, urutanload, namatable) => {
      let fh = convertedData;
      try {
        const response = await axios.post(`${hostcont}ReactBrcontainers`, { data, savedColumnWidths, urutanload, datacari, namatable, fh }, config);
        return response;
      } catch (error) {
        console.error('Error fetching cell data:', error);
        return [];
      }
    };

    const nama_column = await getColumnNames();
    const data_pilihan = ["harga_custom", "harga_kapal", "lainnya", "jual", "pib", "pph_bebas", "notul", "dana_kerja", "biaya_do", "biaya_lainnya", "demmurage", "jaminan_overpayment", "sisa_jaminan", "total_biaya_do", "pendo_c", "penarikkan", "handling_kbkb", "bc", "tila_muat", "gerakkan", "storages", "ppjk", "lainnya_bh", "total_biaya_lapangan", "biaya_trucking", "lolo", "bongkar", "parkir", "repo", "penitipan_inap", "etc", "total_trucking", "lainnya_bw", "kapal", "form_e", "ls", "daop", "biaya_kerja", "sisa_dana_kerja", "sewa_pt", "jasa_kerja", "biaya_kerja_daop_pib", "Total_biaya_tanpa_kapal", "asuransi", "biaya_pelabuhan", "do_lapangan_transport_fe_ls_bc", "pib_biaya", "total_biaya", "dk_total_handling", "dk_bayar_lapangan", "dk_cross_check", "dk_selisih_hitungan", "dk_total_biaya", "dk_sisa_dana", "dk_profit", "daop_jasa", "daop_penarikkan", "daop_kuli_cntr", "daop_pemeriksaan_bagasi", "daop_koord_periksa", "daop_staff_periksa", "daop_p2_bawah", "daop_p2_bawah_wilayah", "daop_analis_p2_bawah", "daop_pfpd", "daop_pusat", "daop_wilayah_atas", "daop_pelayanan_atas", "daop_penyidik", "daop_ops_bahendle", "daop_mdn", "koordinasi_lap", "uang_bongkar", "repair", "trucking_j3", "bahendel", "aju_pib_pl", "aju_zb", "aju_ops_pel"];
    let datahasil = [];
    const cariindex = data_pilihan.map(columnName => nama_column.indexOf(columnName));
    for (let i = 0; i < cariindex.length; i++) {
      const columnNumber = cariindex[i];
      const columnPosition = getColumnPosition(columnNumber);
      datahasil.push(columnPosition)
    }

    let savedColumnWidths = JSON.parse(localStorage.getItem(pagecolumn));
    if (savedColumnWidths === null || Object.keys(savedColumnWidths).length === 0) {
      savedColumnWidths = null;
    } else {
      savedColumnWidths = savedColumnWidths;
    }

    let cellDataJsonA = [];
    let totaldata = 0;

    function isObjectEmpty(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    }

    async function loaddata(offset) {
      console.log("namatable ", namatable)
      cellDataJsonA = await getCellData(datahasil, savedColumnWidths, offset, namatable);
      if (cellDataJsonA.length === 0) {
        return {
          "data": [{}],
          "dataformula": [{}]
        };
      } else {
        if (isObjectEmpty(cellDataJsonA.data)) {
          return {
            "data": [{}],
            "dataformula": [{}]
          };
        } else {
          totaldata = totaldata + cellDataJsonA.data.totaldata + 1;
          for (let i = 0; i < cellDataJsonA.data.data.length; i++) {
            if (cellDataJsonA.data.data[i].v.ct == "0") {//title
              cellDataJsonA.data.data[i].v.ct = {
                "fa": "@",
                "t": "s",
              };
              cellDataJsonA.data.data[i].v.bl = 1;
              cellDataJsonA.data.data[i].v.ff = 1;
              cellDataJsonA.data.data[i].v.ht = 0;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
            } else if (cellDataJsonA.data.data[i].v.ct == "1") {//angka
              cellDataJsonA.data.data[i].v.ct = {
                "fa": "#,##0",
                "t": "n",
              };
              cellDataJsonA.data.data[i].v.bl = 2;
              cellDataJsonA.data.data[i].v.ff = 2;
              cellDataJsonA.data.data[i].v.ht = 2;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
            } else if (cellDataJsonA.data.data[i].v.ct == "2") {//date
              cellDataJsonA.data.data[i].v.ct = {
                "fa": "yyyy-MM-dd",
                "t": "d",
              };
              cellDataJsonA.data.data[i].v.bl = 2;
              cellDataJsonA.data.data[i].v.ff = 2;
              cellDataJsonA.data.data[i].v.ht = 0;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
            } else if (cellDataJsonA.data.data[i].v.ct == "3") {//link
              cellDataJsonA.data.data[i].v.ct = {
                "fa": "@",
                "t": "l",
              };
              cellDataJsonA.data.data[i].v.bl = 2;
              cellDataJsonA.data.data[i].v.ff = 2;
              cellDataJsonA.data.data[i].v.ht = 0;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
            } else if (cellDataJsonA.data.data[i].v.ct == "5") {//link
              cellDataJsonA.data.data[i].v.ct = {
                "fa": "0",
                "t": "g",
              };
              cellDataJsonA.data.data[i].v.bl = 2;
              cellDataJsonA.data.data[i].v.ff = 2;
              cellDataJsonA.data.data[i].v.ht = 0;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
            } else {
              cellDataJsonA.data.data[i].v.ct = {//text
                "fa": "@",
                "t": "n",
              };
              cellDataJsonA.data.data[i].v.bl = 2;
              cellDataJsonA.data.data[i].v.ff = 2;
              cellDataJsonA.data.data[i].v.ht = 1;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
            }
          }
          const data_all = cellDataJsonA.data;
          return data_all;
        }
      }
    }

    const [page0Data, page1Data, page2Data, page3Data, page4Data, page5Data] = await Promise.all([
      loaddata(0),
      loaddata(1),
      loaddata(2),
      loaddata(3),
      loaddata(4),
      loaddata(5),
    ]);

    const page0 = page0Data || [];
    const page1 = page1Data || [];
    const page2 = page2Data || [];
    const page3 = page3Data || [];
    const page4 = page4Data || [];
    const page5 = page5Data || [];

    merged = [...page0.data,
    ...page1.data,
    ...page2.data,
    ...page3.data,
    ...page4.data,
    ...page5.data,
    ];

    canformual = [...page0.dataformula,
    ...page1.dataformula,
    ...page2.dataformula,
    ...page3.dataformula,
    ...page4.dataformula,
    ...page5.dataformula,
    ];

    const dk_selisih_hitungan_ = nama_column.indexOf("dk_selisih_hitungan")
    const dk_selisih_hitungan = getColumnPosition(dk_selisih_hitungan_);
    const dk_selisih_hitungan_read = dk_selisih_hitungan + '0:' + dk_selisih_hitungan + '1';

    const dk_tgl_bayar_ = nama_column.indexOf("dk_tgl_bayar_kembali")
    const dk_tgl_bayar = getColumnPosition(dk_tgl_bayar_);

    const dk_cross_check_ = nama_column.indexOf("dk_cross_check")
    const dk_cross_check = getColumnPosition(dk_cross_check_);
    const dk_tgl_bayar_read = dk_cross_check + '0:' + dk_cross_check + '1';

    const dk_bayar_lapangan_ = nama_column.indexOf("dk_bayar_lapangan")
    const dk_bayar_lapangan = getColumnPosition(dk_bayar_lapangan_);
    const dk_bayar_lapangan_read = dk_bayar_lapangan + '0:' + dk_bayar_lapangan + '1';

    mergedDataRO = page1.readonly + "," + dk_selisih_hitungan_read + "," + dk_tgl_bayar_read + "," + dk_bayar_lapangan_read;

    options = {
      container: "luckysheet",
      title: "DataCont",
      lang: 'en',
      showinfobar: false,
      allowCopy: true,
      allowEdit: true,
      column: 0,
      forceCalculation: true,
      showtoolbarConfig: dataconfigReportDataCont,
      cellRightClickConfig: showtoolbarConfig,
      data: [
        {
          name: "Sheet",
          color: "",
          row: 1,
          index: "0",
          celldata: merged,
          calcChain: canformual,
          defaultRowHeight: 40,
          config: {
            columnlen: savedColumnWidths,
            colhidden: {
              0: false,
            },
            rowlen: {
              0: 40,
            },
            authority: {
              sheet: 1,
              hintText: "Kolom ini tidak bisa anda edit", //The text of the pop-up prompt
              allowRangeList: [
                {
                  "name": "area",
                  "sqref": mergedDataRO,
                },
              ],
            },
          },
          // filter_select: {
          //   row: [0, totaldata],
          //   column: [1, nama_column.length - 1],
          // }
        },
      ],
      enableAddRow: false,
      textWrapMode: true,
      hook: {
        frozenCreateAfter: function (frozen) {
          //console.log("frozen ",frozen)
        },
        cellRenderAfter: function (cell, position, sheetFile, ctx) {
          if (b < 1) {
            const data = [];
            const endIndex = 150;
            for (let i = 0; i <= endIndex; i++) {
              data.push(i);
            }
            var columnWidths = luckysheet.getColumnWidth(data);
            const colWidthA = columnWidths[1];
            for (let columnIndex of data) {
              const width = columnWidths[columnIndex];
              columnWidths[columnIndex.toString()] = width;
            }
            localStorage.setItem(pagecolumn, JSON.stringify(columnWidths));

            setTimeout(function () {
              b = 0;
            }, 500);
          }
          b++;
        },
        commentUpdateBefore: async function (r, c, value) {
          const id = luckysheet.getCellValue(r, 0);
          const column = nama_column[c];
          const formData = new FormData();
          const cleanedValue = value.trim().replace(/\r\n?|\n/g, ' ');
          formData.append("code_1", id);
          formData.append("code_2", tipedata);
          formData.append("code_3", "");
          formData.append("row", r);
          formData.append("col", c);
          formData.append("comment", cleanedValue);
          formData.append("column", column);
          try {
            const response = await axios.post(`${hostcc}Updatecomment`, formData, config);
            notify_update_br_cont("Note sukses di tambahkan");
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        },
        cellEditBefore: function (r, c, value, isRefresh) {
          // console.log("Edit before");
          //  console.log(value);
        },
        cellUpdated: function (r, c, oldValue, newValue, isRefresh) {
          if (r > 0) {
            if (oldValue && newValue) {
              const newData = newValue.m;
              const id = luckysheet.getCellValue(r, 0);
              var datax = {};
              var nilai = newData;
              if (nama_column[c] == "harga_custom" || nama_column[c] == "harga_kapal" || nama_column[c] == "lainnya" || nama_column[c] == "Jual") {
                nilai = parseFloat(nilai);
              }
              var column = nama_column[c];
              column = column.replace(/ /g, "_");
              if (nilai !== null && nilai !== undefined && nilai !== "") {
                // console.log(nama_column[c]);
                // console.log(data_pilihan);
                if (data_pilihan.includes(nama_column[c])) {
                  //console.log("oke ada ", nilai.replace(/,/g, ''));
                  datax[nama_column[c]] = nilai.replace(/,/g, '');
                } else {
                  datax[nama_column[c]] = nilai;
                }
              } else {
                datax[nama_column[c]] = "";
              }
              if (oldValue !== null && oldValue.m != newData && nama_column[c] != "") {
                datax["id"] = id;
                axios.post(`${hostcont}ReactUpdateBrcontainers`, datax, config)
                  .then(response => {
                    if (response.data.status == "success") notify_update_br_cont("Data Cont Updated");
                    //console.log('POST request successful:', response.data);
                  })
                  .catch(error => {
                    console.error('Error fetching data:', error.response.data.message);
                    notify_cant_update_br_cont(error.response.data.message);
                    // luckysheet.setCellValue(r, c, "");
                    luckysheet.refresh();
                  });
              }
            }
          }
        },
        cellEditBefore: function (range) {
          const c = range[0].column[0];
          var title_column_select = luckysheet.getCellValue(0, c);
        },
        rangeSelect: function (index, sheet) {
          //console.log("index ",index);
          // console.log("test 2 ", sheet);
          // setTimeout(() => {
          //luckysheet.setRangeShow({row:[1,1],column:[1,1]})
          // console.log("test 5 ", luckysheet.getRange());
          // let cekdata =  removeDuplicates(luckysheet, luckysheet.getRange());
          // console.log("hasil cek : ",cekdata)
          // }, 5000);
          //luckysheet.setRangeShow("{row:[0,1],column:[0,1]}",{show:false})

          var column = sheet[0].column[0];
          if (sheet[0].column[0] <= end_column_freze) {
            setleftScrollbar(true);
          } else {
            setleftScrollbar(false);
          }
          var top = sheet[0].top;
          var left = sheet[0].left;
          var height = sheet[0].height;
          kiri = left;
          atas = top;
          tinggi = height;
          filter_data(column, top, left, height);
        },

        cellMousedown: function (cell, postion, sheetFile, ctx) {
          // console.log("klik ", ctx)
          // console.log("cek ",luckysheet.getRangeAxis())
          var dat = postion.r + "" + postion.c;
          if (type_row == dat) {
            type_jml++;
          } else {
            type_jml = 0;
          }
          type_row = postion.r + "" + postion.c;

          if (type_jml > 0) {
            if (cell.ct.t == "l") {
              const id = luckysheet.getCellValue(postion.r, 0);
              const judul = luckysheet.getCellValue(0, postion.c);
              var dataKirimModal = {
                id: id,
                nama: judul
              };
              setDataModal(dataKirimModal);
              toggleModal();
            }
          }
        },
        afterzoomfiki: function (ration) {
          createRatioZoom(ration);
          luckysheet.setCellFormat(1, 1, "ct", { fa: "#,###", t: "n" })
          luckysheet.jfrefreshgrid();
        },
        aftercreatefilterfiki: function (data) {
          const freezen_3 = document.getElementById('freezen_3');
          if (freezen_3) {
            freezen_3.style.zIndex = '20';
          }
        },
        sheetCopyAfter: function (range, data) {
          // console.log("paste ",range)
          // console.log("data ",data)
        },
        updated: function (operate) {
          //console.log("hasil operate: ",operate);
          let data = luckysheet.getLuckysheetfile();
          if (operate) {
            if (operate.ctrlType == "resizeR") {
              let dataukuran = operate.curconfig.rowlen;

              const keys = Object.keys(dataukuran);
              const lastKey = keys[keys.length - 1];
              const lastValue = dataukuran[lastKey];

              saveheighrow(lastValue);
            }
          }
        },

        rangeSelect: function (data1, data2) {
          //  console.log("data ", data1);
          //  console.log("data2 ", data2);
        },

        rangePasteBeforefiki: function (range, data, fki) {
          // console.log("range ",fki.luckysheetfile[0].luckysheet_select_save[0])
          // console.log("data ",fki)
          const isConfirmed = window.confirm('Yakin akan melakukan paste data?');
          if (isConfirmed) {
            setTimeout(() => {
              let jsonData = fki.luckysheetfile[0].luckysheet_select_save[0];
              const rows = jsonData.row;
              const columns = jsonData.column;
              // extra columnya fk
              const [startRow, endRow] = rows;
              const [startCol, endCol] = columns;

              var dataArray = [];
              var idCounter = 1;

              for (let row = startRow; row <= endRow; row++) {
                let datax = { id: luckysheet.getCellValue(row, 0) }; // Convert to string and remove leading/trailing spaces

                for (let col = startCol; col <= endCol; col++) {
                  const nilai = luckysheet.getCellValue(row, col);
                  datax[nama_column[col]] = nilai !== null && nilai !== undefined && nilai !== "" ? nilai.toString().trim() : ""; // Convert to string and remove leading/trailing spaces
                }
                dataArray.push(datax);
              }
              //console.log(dataArray);
              UpdateFromPaste(dataArray);
            }, 400);
          } else {
            return false;
          }
        }
      },
    }

    options.loading = {
      image: () => {
        return `<svg viewBox="25 25 50 50" class="circular">
					<circle cx="50" cy="50" r="20" fill="none"></circle>
					</svg>`
      },
      imageClass: "loadingAnimation"
    }

    if (totaldata > 0) {
      setTimeout(() => {
        luckysheetInstance = luckysheet.create(options);
      }, 100)
    } else {
      notify_cant_update_br_cont("Error, Tidak dapat tersambung dengan server!", 2500);
    }


    function UpdateFromPaste(datax) {
      axios.post(`${hostcont}ReactUpdateBrcontainerspaste`, datax, config)
        .then(response => {
          if (response.data.status == "success") notify_update_br_cont("Data Cont Updated");
          //console.log('POST request successful:', response.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error.response.data.message);
          notify_cant_update_br_cont(error.response.data.message);
          // luckysheet.setCellValue(r, c, "");
          luckysheet.refresh();
        });
    }

    function rollbackvalidasidropdown(valueppnArray, data) {
      if (data === null || data === undefined || data.trim() === "") {
        console.log("Data is empty or null.");
        return false;
      }
      if (valueppnArray.includes(data)) {
        return true;
      } else {
        return false;
      }
    }

    function getColumnIndexByTitle(sheetId, title) {
      const sheetData = luckysheet.getSheetData(sheetId);
      if (!sheetData) return -1; // Sheet data not found

      const firstRowData = sheetData[0].data;
      for (let colIndex = 0; colIndex < firstRowData.length; colIndex++) {
        if (firstRowData[colIndex].v === title) {
          return colIndex;
        }
      }
      return -1; // Column title not found
    }

    function removeCrLfFromString(inputString) {
      return inputString.replace(/\r/g, ' ');
    }

    function removeCrLfFromString_(inputString) {
      return inputString.replace(/\n/g, ' ');
    }

    function isValidFormula(value) {
      const formulaPattern = /^=([A-Za-z]+\d+\+)*[A-Za-z]+\d+$/;
      return formulaPattern.test(value);
    }

    $(document).on('mousedown', '.sp-thumb-el', function () {
      // var warna = $(this).attr('data-color');
      var title = $(this).attr('title');
      var datas = [];
      var jlh = 0;
      var selectedRange = luckysheet.getRange();
      if (!selectedRange) {
        console.error('No cells selected.');
        return;
      }
      var baris_awal = selectedRange[0].row[0];
      var baris_akhir = selectedRange[0].row[1];
      var kolom_awal = selectedRange[0].column[0];
      var kolom_akhir = selectedRange[0].column[1];

      const column = nama_column[kolom_awal];
      for (var row = baris_awal; row <= baris_akhir; row++) {
        for (var col = kolom_awal; col <= kolom_akhir; col++) {
          const id = luckysheet.getCellValue(baris_awal, 0);
          var datax = {};
          datax['id'] = id;
          datax['warna'] = title;
          datax['ColumnName'] = column;
          datax['ColumnField'] = "DATACONT";
          datas[jlh] = datax;
          jlh++;
        }
      }
      var dataxx = {};
      dataxx['data'] = JSON.stringify(datas);
      axios.post(`${hostcc}ColorBrcontainers`, dataxx, config)
        .then((response) => {
          notify_update_br_cont("Color sukses di tambahkan");
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    });

    $(document).on('mousedown', '[itemname="create filter"]', function () {
      setTimeout(async function () {
        helperFilter(11);
      }, 100);
    });

    $(document).ready(function () {
      const handleClickCustomFilter = () => {
        toggleFilterModal();
      };
    });

    function getColumnPosition(columnNumber) {
      let dividend = columnNumber + 1;
      let columnName = '';

      while (dividend > 0) {
        const modulo = (dividend - 1) % 26;
        columnName = String.fromCharCode(65 + modulo) + columnName;
        dividend = Math.floor((dividend - modulo) / 26);
      }
      return columnName;
    }

    const luckysheetContainer = document.getElementById('luckysheet');
    luckysheetContainer.addEventListener('scroll', () => {
      const scrollLeft = luckysheetContainer.scrollLeft;
      const frozenColumns = document.querySelectorAll('.luckysheet-cell.luckysheet-cell-frozen');
      frozenColumns.forEach((column) => {
        column.style.transform = `translateX(${scrollLeft}px)`;
      });
    });

    setTimeout(async function () {
      if (totaldata > 0) {
        luckysheet.setBothFrozen(true, { range: { row_focus: 0, column_focus: end_column_freze } });
        helperFilter(11);
        setleftScrollbarstart();
      }
    }, 100);
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  function setleftScrollbar(kiri) {
    const luckysheetContainerX = document.getElementById('luckysheet-scrollbar-x');
    luckysheetContainerX.addEventListener('scroll', () => {
      if (luckysheetContainerX.scrollLeft > 1) {
        size_scroll_left = luckysheetContainerX.scrollLeft;
      }
    });
    if (kiri) {
      size_scroll_left = 0
      luckysheetContainerX.scrollLeft = size_scroll_left;
    }
    else {
      luckysheetContainerX.scrollLeft = size_scroll_left;
      //console.log("SCROLL ", size_scroll_left);
    }
  }

  function setleftScrollbarstart() {
    const luckysheetContainerX = document.getElementById('luckysheet-scrollbar-x');
    luckysheetContainerX.addEventListener('scroll', () => {
      if (luckysheetContainerX.scrollLeft > 1) {
        size_scroll_left = luckysheetContainerX.scrollLeft;
      }
      luckysheetContainerX.scrollLeft = size_scroll_left;
      //console.log("SCROLL AUTO", luckysheetContainerX.scrollLeft);
    });
  }

  function filter_data(atas, kiri, tinggi) {
    $("#luckysheet-rich-text-editor").on('keyup', function () {
      const searchText = $("#luckysheet-rich-text-editor").text().toUpperCase();
      $("#luckysheet-dataVerification-dropdown-List").hide();
      $(".dropdown-List-item").each(function () {
        const itemText = $(this).text().toUpperCase();
        if (itemText.indexOf(searchText) > -1) {
          $(this).show();
        } else {
          $(this).hide();
        }
      });

      // fiki
      const editorHeight = $("#luckysheet-rich-text-editor").outerHeight();
      const topValue = parseFloat($("#luckysheet-cell-selected-focus").css("top"));
      const leftValue = parseFloat($("#luckysheet-input-box").css("left"));
      $("#luckysheet-dataVerification-dropdown-List").css({
        display: 'block',
        // left: leftValue - 44 + 'px',
        // top: topValue + editorHeight + editorHeight - 5 + 'px',
        right: 'auto',
        left: kiri + 'px',
        top: atas + tinggi + 'px',
      });
    });
  }

  const luckyCss = {
    margin: '75px 10px 0px 0px',
    padding: '0px',
    position: 'absolute',
    width: '86.5%',
    height: '80%',
    right: '0',
    top: '0px',
    overflowX: 'auto',
  };

  const forumLinkStyle = {
    zIndex: 2,
    width: '50px',
    height: '50px',
    lineHeight: '50px',
    position: 'fixed',
    right: '40px',
    bottom: '86px',
    borderRadius: '50px',
    cursor: 'pointer',
    background: 'rgb(71,133,249)',
    color: '#fff',
    textAlign: 'center',
    textDecoration: 'none',
  };

  const selectStyle = {
    // Define your custom CSS styles here
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '2px',
    fontSize: '16px',
    width: 'auto',
    height: '10%',
    marginTop: '10px',
  };

  const inputSylte = {
    padding: '40px !important',
  };

  const convertDataToArray = (data) => {
    const result = [];
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (key !== "tgl1" && key !== "tgl2") {
          if (Array.isArray(data[key])) {
            data[key].forEach(value => {
              result.push([key, "containsor", value, null]);
            });
          } else {
            if (key === "by") {
              result.push([data[key], "in range", data.tgl1, data.tgl2]);
            } else {
              result.push([key, "containsor", data[key], null]);
            }
          }
        }
      }
    }
    return result;
  };

  const handleClick = () => {
    //alert('Element touched!');
  };

  return (
    <div>
      <HeaderMenu title="Data Container" subtitle={"Halaman untuk data Kontainer"} />
      <ToastContainer />

      <div onClick={handleClick}>
        <input type="checkbox" id="check" checked={isChecked} onChange={handleCheckboxChange} />
        <label htmlFor="check">
          <i className="fas fa-bars" id="btn" title='Filter Data'></i>
          <i className="fas fa-times" id="cancel"></i>
        </label>
        <div className={isChecked ? "sidebar active" : "sidebar"}>
          <header><i className="fas fa-search" style={{ marginRight: 5 + 'px' }}></i>Filter Data</header>
          <div className="scrollable-content">
            <div className='spacee'></div>
            <div className='paddingFilterSide'>
              <div className="form-group">
                <label className="form-label">Type</label>
                <select ref={selectForType} className="js-example-basic-single style-select-report" value={selectType || ""} onChange={change}>
                  <option key={0} value={""}>{" - "}</option>
                  {v_type.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label className="form-label">Marking</label>
                <textarea
                  value={selectMarking || ""}
                  onChange={handleTextChangeMarking}
                  className='form-control'
                />
              </div>
              <div className="form-group">
                <label className="form-label">Sales</label>
                <select ref={selectForSales} className="js-example-basic-multiple" multiple="multiple" value={selectSales || ""} onChange={change}>
                  <option key={0} value={"all"}>{" ALL "}</option>
                  {v_sales.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label className="form-label">Item</label>
                <textarea
                  value={selectItem || ""}
                  onChange={handleTextChangeItem}
                  className='form-control'
                />
              </div>
              <div className="form-group">
                <label className="form-label">Aju</label>
                <input type="text" value={selectAju || ""} onChange={handleTextChangeAju} className='inputsearch' />
              </div>
              <div className="form-group">
                <label className="form-label">Container</label>
                <input type="text" value={selectContainer || ""} onChange={handleTextChangeContainer} className='inputsearch' />
              </div>

              <div className="form-group">
                <label className="form-label">Port</label>
                <select ref={selectForPort} className="js-example-basic-multiple" multiple="multiple" value={selectPort || ""} onChange={change}>
                  <option key={0} value={"all"}>{" ALL "}</option>
                  {v_port.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label className="form-label">Cnee</label>
                <select ref={selectForCnee} className="js-example-basic-multiple" multiple="multiple" value={selectCnee || ""} onChange={change}>
                  <option key={0} value={""}>{" - "}</option>
                  {v_cnee.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label className="form-label">By</label>
                <select ref={selectForKolomTgl} className="js-example-basic-single style-select-report" value={selectKolomTgl || ""} onChange={change}>
                  <option key={0} value={''}>{" - "}</option>
                  {v_kolom_tgl.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>

              <div id="tgl" style={{ textAlign: "right", display: isVisible ? "block" : "none" }}>
                <div className="form-group">
                  <label className="form-label"></label>
                  <input type="date" value={selecttgl1 || ""} onChange={handleTextChangeTgl1} className='inputsearch inputsearchdate' />
                </div>

                <div className="form-group">
                  <label className="form-label"></label>
                  <input type="date" value={selecttgl2 || ""} onChange={handleTextChangeTgl2} className='inputsearch inputsearchdate' />
                </div>
              </div>

              <div id="batas"></div>
              <div className='group-botton'>
                <button onClick={handleResetClick} className='btn btn-reset mr-b-6'>Reset</button>
                <button onClick={handleSearchClick} className='btn btn-default mr-b-6'>Search</button>
              </div>
              <div id="batasend"></div>
            </div>
          </div>
        </div>
        <section>
          <div id="luckysheet" className={isChecked ? "luckyReport active" : "luckyReport"} />
        </section>
      </div>

      <Modal modalVisible={modalVisible} toggleModal={toggleModal} data={datamodal} />
      <ModalFilter modalVisible={modalFilterVisible} toggleModal={toggleFilterModal} data={datamodal} />
    </div>
  );
};

export default DatacontReport;