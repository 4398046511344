import React, { useEffect, useState } from 'react';
import axios from 'axios';
import $ from 'jquery';
import '../css/Login.css';
import '../css/Materialize.css';
import '../css/Style.css';
import '../css/new.css';
import reloadToken from '../helper/reloadtoken';
import helperFilter from '../helper/helperfilter';
import HeaderMenu from '../template/HeaderMenu';
import { apiConfig } from '../helper/apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { dataconfig, showtoolbarConfig, ct } from '../helper/luckysheetHelper';
import dataformula from '../sheetFormula.js';
import pako from 'pako';

const tipedata = "KARTUHUTANG";
const hostkh = process.env.REACT_APP_API_HOST_KARTU_HUTANG;
const hostcc = process.env.REACT_APP_API_HOST_COLOR_COMMENT;

const notify_cant_update_br_cont = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 500,
  });
};
const notify_update_br_cont = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 500,
  });
};

const KARTUHUTANG = () => {
  const luckysheet = window.luckysheet;
  const [token, setToken] = useState(null);
  var b = 0;
  const pagecolumn = "DATAKARTUHUTANG"
  var columnWidths = [];
  var kiri, atas, tinggi;
  var options = null;
  let valueppn = "YA,TIDAK";
  let valueukuran = "20,40,45";
  let valuestatusbl = "TELEX,DRAFT,ORI,HBL";
  let dataserver = [];
  let dataserver1 = [];
  var end_column_freze = 10;
  var size_scroll_left = 0;

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 100));
      const newToken = await reloadToken()
      setToken(newToken);
      if (newToken) {
        initializeLuckySheet(newToken);
      }
    };

    const initializeLuckySheet = async (token) => {
      new Promise((resolve) => setTimeout(resolve, 100));
      const config = apiConfig(token);

      const getColumnNames = async () => {
        try {
          const config = apiConfig(token);
          const response = await axios.post(`${hostkh}ReactKartuhutangColumn`, null, config);
          return response.data.column;
        } catch (error) {
          console.error('Error fetching column names:', error);
          return [];
        }
      };

      const getCellData = async (data, savedColumnWidths, urutanload) => {
        try {
          const response = await axios.post(`${hostkh}ReactKartuhutang`, { data, savedColumnWidths, urutanload }, config);
          return response;
        } catch (error) {
          console.error('Error fetching cell data:', error);
          return [];
        }
      };

      const nama_column = await getColumnNames();
      const data_pilihan = ["harga_custom","harga_kapal","lainnya","jual", "pib","pph_bebas","notul","dana_kerja","biaya_do","biaya_lainnya","demmurage","jaminan_overpayment","sisa_jaminan","total_biaya_do","pendo_c","penarikkan","handling_kbkb","bc","tila_muat","gerakkan","storages","ppjk","lainnya_bh","total_biaya_lapangan","biaya_trucking","lolo","lolo","bongkar","parkir","repo","penitipan_inap", "etc", "total_trucking", "lainnya_bw", "kapal", "form_e", "ls", "daop", "biaya_kerja", "sisa_dana_kerja", "sewa_pt", "jasa_kerja", "biaya_kerja_daop_pib", "Total_biaya_tanpa_kapal", "asuransi", "biaya_pelabuhan", "do_lapangan_transport_fe_ls_bc", "pib_biaya", "total_biaya", "dk_total_handling", "dk_bayar_lapangan", "dk_cross_check", "dk_selisih_hitungan", "dk_total_biaya", "dk_sisa_dana", "dk_profit", "daop_jasa", "daop_penarikkan", "daop_kuli_cntr", "daop_pemeriksaan_bagasi", "daop_koord_periksa", "daop_staff_periksa", "daop_p2_bawah", "daop_p2_bawah_wilayah", "daop_analis_p2_bawah", "daop_pfpd", "daop_pusat", "daop_wilayah_atas", "daop_pelayanan_atas", "daop_penyidik", "daop_ops_bahendle"];
      let datahasil = [];
      const cariindex = data_pilihan.map(columnName => nama_column.indexOf(columnName));
      for (let i = 0; i < cariindex.length; i++) {
        const columnNumber = cariindex[i];
        const columnPosition = getColumnPosition(columnNumber);
        datahasil.push(columnPosition)
      }

      let savedColumnWidths = JSON.parse(localStorage.getItem(pagecolumn));
      if (savedColumnWidths === null || Object.keys(savedColumnWidths).length === 0) {
        savedColumnWidths = null;
      } else {
        savedColumnWidths = savedColumnWidths;
      }

      let cellDataJsonA = [];
      let totaldata = 0;

      async function loaddata(offset) {
        cellDataJsonA = await getCellData(datahasil, savedColumnWidths, offset);
        if (cellDataJsonA.length === 0) {
          return {
            "data": [{}],
            "dataformula": [{}]
          };
        } else {
          totaldata = totaldata + cellDataJsonA.data.totaldata + 1;
          for (let i = 0; i < cellDataJsonA.data.data.length; i++) {
            if (cellDataJsonA.data.data[i].v.ct == "0") {//title
              cellDataJsonA.data.data[i].v.ct = {
                "fa": "@",
                "t": "s",
              };
              cellDataJsonA.data.data[i].v.bl = 1;
              cellDataJsonA.data.data[i].v.ff = 1;
              cellDataJsonA.data.data[i].v.ht = 0;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
            } else if (cellDataJsonA.data.data[i].v.ct == "1") {//angka
              cellDataJsonA.data.data[i].v.ct = {
                "fa": "#,##0",
                "t": "n",
              };
              cellDataJsonA.data.data[i].v.bl = 2;
              cellDataJsonA.data.data[i].v.ff = 2;
              cellDataJsonA.data.data[i].v.ht = 2;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
            } else if (cellDataJsonA.data.data[i].v.ct == "2") {//date
              cellDataJsonA.data.data[i].v.ct = {
                "fa": "yyyy-MM-dd",
                "t": "d",
              };
              cellDataJsonA.data.data[i].v.bl = 2;
              cellDataJsonA.data.data[i].v.ff = 2;
              cellDataJsonA.data.data[i].v.ht = 0;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
            } else if (cellDataJsonA.data.data[i].v.ct == "3") {//link
              cellDataJsonA.data.data[i].v.ct = {
                "fa": "@",
                "t": "l",
              };
              cellDataJsonA.data.data[i].v.bl = 2;
              cellDataJsonA.data.data[i].v.ff = 2;
              cellDataJsonA.data.data[i].v.ht = 0;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
            } else {
              cellDataJsonA.data.data[i].v.ct = {//text
                "fa": "0",
                "t": "n",
              };
              cellDataJsonA.data.data[i].v.bl = 2;
              cellDataJsonA.data.data[i].v.ff = 2;
              cellDataJsonA.data.data[i].v.ht = 1;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
            }
          }
          const data_all = cellDataJsonA.data;
          return data_all;
        }
      }

      const [page0Data, page1Data, page2Data, page3Data, page4Data, page5Data] = await Promise.all([
        loaddata(0),
        loaddata(1),
        loaddata(2),
        loaddata(3),
        loaddata(4),
        loaddata(5),
      ]);

      console.log(page0Data);
      const page0 = page0Data || [];
      const page1 = page1Data || [];
      const page2 = page2Data || [];
      const page3 = page3Data || [];
      const page4 = page4Data || [];
      const page5 = page5Data || [];

      const merged = [
        ...page0.data,
        ...page1.data,
        ...page2.data,
        ...page3.data,
        ...page4.data,
        ...page5.data,
      ];

      const mergedformula = [
        ...page0.dataformula,
        ...page1.dataformula,
        ...page2.dataformula,
        ...page3.dataformula,
        ...page4.dataformula,
        ...page5.dataformula,
      ];

      const mergedDataRO = page1.readonly;
      console.log("data ",mergedDataRO)
      const dataformula = await loaddata(0);
      const canformual = page0.dataformula;

      options = {
        container: "luckysheet",
        title: "DataCont",
        lang: 'en',
        showinfobar: false,
        allowCopy: true,
        allowEdit: true,
        column: 0,
        filter: {
          ref: 'A1:C1',
        },
        forceCalculation: true,
        showtoolbarConfig: dataconfig,
        cellRightClickConfig: showtoolbarConfig,
        data: [
          {
            name: "Sheet",
            color: "",
            row: 1,
            index: "0",
            celldata: merged,
            calcChain: mergedformula,
            defaultRowHeight: 30,
            config: {
              columnlen: savedColumnWidths,
              colhidden: {
                0: false,
              },
              rowlen: {
                0: 40,
              },
              authority: {
                sheet: 1,
                hintText: "Kolom ini tidak bisa anda edit",
                allowRangeList: [
                  {
                    "name": "area",
                    "sqref": mergedDataRO,
                  },
                ],
              },
            },
          },
        ],
        enableAddRow: false,
        textWrapMode: true,
        hook: {
          cellRenderAfter: function (cell, position, sheetFile, ctx) {
            if (b < 1) {
                const data = [];
                const endIndex = 150;
                for (let i = 0; i <= endIndex; i++) {
                    data.push(i);
                }
                var columnWidths = luckysheet.getColumnWidth(data);
                // const colWidthA = columnWidths[1]; 
                // console.log(`Width of column B: ${colWidthA}`);
                for (let columnIndex of data) {
                    const width = columnWidths[columnIndex];
                    columnWidths[columnIndex.toString()] = width;
                }
                localStorage.setItem(pagecolumn, JSON.stringify(columnWidths));
                setTimeout(function () {
                    b = 0;
                }, 500);
            }
            b++;
        },
          rangePasteBefore: function (range, data) {
            // return false; //Can intercept paste
          },
          commentUpdateBefore: async function (r, c, value) {
            const id = luckysheet.getCellValue(r, 0);
            var column = nama_column[c].toLowerCase();
            column = column.replace(/ /g, "_");
            // Create a new FormData object
            const formData = new FormData();
            formData.append("code_1", id);
            formData.append("code_2", tipedata);
            formData.append("code_3", "");
            formData.append("row", r);
            formData.append("col", c);
            formData.append("comment", value);
            formData.append("column", column);
            try {
                const response = await axios.post(`${hostcc}Updatecomment`, formData, config);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
          cellEditBefore: function (r, c, value, isRefresh) {
            // console.log("Edit before");
            // console.log(value);
          },
          cellUpdateBefore: function (r, c, value, isRefresh) {

            if (!isValidFormula(value)) {
              //console.info('cellUpdateBefore', r, c, value, isRefresh);
              return true;
            } else {
              //console.warn('Validation failed for cellUpdateBefore', r, c, value, isRefresh);
              return false;
            }
          },
          cellUpdated: function (r, c, oldValue, newValue, isRefresh) {
            if (oldValue && newValue) {
                const newData = newValue.m;
                const id = luckysheet.getCellValue(r, 0);
                var datax = {};
                var nilai = newData;
                var column = nama_column[c];
                column = column.replace(/ /g, "_");
                if (nilai !== null && nilai !== undefined && nilai !== "") {
                  console.log(nama_column[c]);
                  console.log(data_pilihan);
                  if (data_pilihan.includes(nama_column[c])) {
                    console.log("oke ada ",nilai.replace(/,/g, ''));
                    datax[nama_column[c]] = nilai.replace(/,/g, '');
                  } else {
                    datax[nama_column[c]] = nilai;
                  }
                } else {
                  datax[nama_column[c]] = "";
                }
                if (oldValue !== null && oldValue.m != newData && nama_column[c] != "") {
                    datax["id"] = id;
                    axios.post(`${hostkh}KartuhutangUpdate`, datax, config)
                        .then(response => {
                            if (response.data.status == "success") notify_update_br_cont("Kartu Hutang Updated");
                            //console.log('POST request successful:', response.data);
                        })
                        .catch(error => {
                            console.error('Error fetching data:', error.response.data.message);
                            notify_cant_update_br_cont(error.response.data.message);
                            // luckysheet.setCellValue(r, c, "");
                            luckysheet.refresh();
                        });
                }
            }
        },
          cellEditBefore: function (range) {
            const c = range[0].column[0];
            var title_column_select = luckysheet.getCellValue(0, c);
            //console.log(title_column_select);
          },
          rangeSelect: function (index, sheet) {
            //console.log("Column ", sheet[0].column[0]);
            if (sheet[0].column[0] <= end_column_freze) {
              setleftScrollbar(true);
            } else {
              setleftScrollbar(false);
            }
            var top = sheet[0].top;
            var left = sheet[0].left;
            var height = sheet[0].height;
            kiri = left;
            atas = top;
            tinggi = height;
            filter_data(top, left, height);
          },
        },
      }

      options.loading = {
        image: () => {
          return `<svg viewBox="25 25 50 50" class="circular">
					<circle cx="50" cy="50" r="20" fill="none"></circle>
					</svg>`
        },
        imageClass: "loadingAnimation"
      }
      if (totaldata > 0) {
        luckysheet.create(options)
      } else {
        notify_cant_update_br_cont("Error, Tidak dapat tersambung dengan server!", 2500);
      }

      function rollbackvalidasidropdown(valueppnArray, data) {
        if (data === null || data === undefined || data.trim() === "") {
          console.log("Data is empty or null.");
          return false;
        }
        if (valueppnArray.includes(data)) {
          return true;
        } else {
          return false;
        }
      }

      function getColumnIndexByTitle(sheetId, title) {
        const sheetData = luckysheet.getSheetData(sheetId);
        if (!sheetData) return -1; // Sheet data not found

        const firstRowData = sheetData[0].data;
        for (let colIndex = 0; colIndex < firstRowData.length; colIndex++) {
          if (firstRowData[colIndex].v === title) {
            return colIndex;
          }
        }
        return -1; // Column title not found
      }

      function removeCrLfFromString(inputString) {
        return inputString.replace(/\r/g, ' ');
      }

      function removeCrLfFromString_(inputString) {
        return inputString.replace(/\n/g, ' ');
      }

      $("#luckysheet-bottom-add-row").on("click", async function () {
        var data = $("#luckysheet-bottom-add-row-input").val();

        const currentDate = getCurrentDate();
        var b = luckysheet.getRange()[0].row[0];
        // luckysheet.setCellValue(b, 3, currentDate);
        // luckysheet.refresh();
        for (var i = 1; i <= data; i++) {
          try {
            const response = await axios.post(`${hostkh}BrcontainersDetail/New`, null, config);
            //luckysheet.setCellValue(b, 0, response.data.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
          b++;
        }
        window.location.reload();
      });

      function isValidFormula(value) {
        const formulaPattern = /^=([A-Za-z]+\d+\+)*[A-Za-z]+\d+$/;
        return formulaPattern.test(value);
      }

      $(document).on('mousedown', '.sp-thumb-el', function () {
        // var warna = $(this).attr('data-color');
        var title = $(this).attr('title');
        var datas = [];
        var jlh = 0;
        var selectedRange = luckysheet.getRange();
        if (!selectedRange) {
          console.error('No cells selected.');
          return;
        }
        var baris_awal = selectedRange[0].row[0];
        var baris_akhir = selectedRange[0].row[1];
        var kolom_awal = selectedRange[0].column[0];
        var kolom_akhir = selectedRange[0].column[1];

        const column = nama_column[kolom_awal];
        for (var row = baris_awal; row <= baris_akhir; row++) {
          for (var col = kolom_awal; col <= kolom_akhir; col++) {
            const id = luckysheet.getCellValue(baris_awal, 0);
            var datax = {};
            datax['id'] = id;
            datax['warna'] = title;
            datax['ColumnName'] = column;
            datax['ColumnField'] = tipedata;
            datas[jlh] = datax;
            jlh++;
          }
        }
        var dataxx = {};
        dataxx['data'] = JSON.stringify(datas);
        axios.post(`${hostcc}ColorBrcontainers`, dataxx, config)
          .then((response) => {
            notify_update_br_cont("Color sukses di tambahkan");
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      });

      function getColumnPosition(columnNumber) {
        let dividend = columnNumber + 1;
        let columnName = '';

        while (dividend > 0) {
          const modulo = (dividend - 1) % 26;
          columnName = String.fromCharCode(65 + modulo) + columnName;
          dividend = Math.floor((dividend - modulo) / 26);
        }
        return columnName;
      }

      const luckysheetContainer = document.getElementById('luckysheet');
      luckysheetContainer.addEventListener('scroll', () => {
        const scrollLeft = luckysheetContainer.scrollLeft;
        const frozenColumns = document.querySelectorAll('.luckysheet-cell.luckysheet-cell-frozen');
        frozenColumns.forEach((column) => {
          column.style.transform = `translateX(${scrollLeft}px)`;
        });
      });

      setTimeout(async function () {
        // luckysheet.setBothFrozen(true, { range: { row_focus: 0, column_focus: end_column_freze } });

        // helperFilter(11);
        // setleftScrollbarstart();
      }, 100);
    };
    fetchData();
  
    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log('Tab is hidden');
      } else {
        console.log('Tab is visible');
        luckysheet.refresh();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };


  function setleftScrollbar(kiri) {
    const luckysheetContainerX = document.getElementById('luckysheet-scrollbar-x');
    luckysheetContainerX.addEventListener('scroll', () => {
      if (luckysheetContainerX.scrollLeft > 1) {
        document.getElementById("luckysheet-dataVerification-dropdown-btn").style.setProperty('display', 'none', 'important');
        size_scroll_left = luckysheetContainerX.scrollLeft;
        const arrowCanvas = document.getElementById("arrowCanvas-overshow");
        if (arrowCanvas !== null) {
          document.getElementById("luckysheet-postil-overshow").style.display = "none";
        }
      }
    });
    if (kiri) {
      size_scroll_left = 1
      luckysheetContainerX.scrollLeft = size_scroll_left;
    }
    else {
      luckysheetContainerX.scrollLeft = size_scroll_left;
    }
  }

  function setleftScrollbarstart() {
    const luckysheetContainerX = document.getElementById('luckysheet-scrollbar-x');
    luckysheetContainerX.addEventListener('scroll', () => {
      if (luckysheetContainerX.scrollLeft > 1) {
        size_scroll_left = luckysheetContainerX.scrollLeft;
      }
      luckysheetContainerX.scrollLeft = size_scroll_left;
      console.log("SCROLL AUTO", luckysheetContainerX.scrollLeft);
    });
  }

  function filter_data(atas, kiri, tinggi) {
    $("#luckysheet-rich-text-editor").on('keyup', function () {
      const searchText = $("#luckysheet-rich-text-editor").text().toUpperCase();
      $("#luckysheet-dataVerification-dropdown-List").hide();
      $(".dropdown-List-item").each(function () {
        const itemText = $(this).text().toUpperCase();
        if (itemText.indexOf(searchText) > -1) {
          $(this).show();
        } else {
          $(this).hide();
        }
      });

      // fiki
      const editorHeight = $("#luckysheet-rich-text-editor").outerHeight();
      const topValue = parseFloat($("#luckysheet-cell-selected-focus").css("top"));
      const leftValue = parseFloat($("#luckysheet-input-box").css("left"));
      $("#luckysheet-dataVerification-dropdown-List").css({
        display: 'block',
        // left: leftValue - 44 + 'px',
        // top: topValue + editorHeight + editorHeight - 5 + 'px',
        right: 'auto',
        left: kiri + 'px',
        top: atas + tinggi + 'px',
      });
    });
  }

  const luckyCss = {
    margin: '75px 0px 0px 0px',
    padding: '0px',
    position: 'absolute',
    width: '100%',
    height: '90%',
    left: '0px',
    top: '0px',
    overflowX: 'auto',
  };

  const forumLinkStyle = {
    zIndex: 2,
    width: '50px',
    height: '50px',
    lineHeight: '50px',
    position: 'fixed',
    right: '40px',
    bottom: '86px',
    borderRadius: '50px',
    cursor: 'pointer',
    background: 'rgb(71,133,249)',
    color: '#fff',
    textAlign: 'center',
    textDecoration: 'none',
  };


  return (
    <div>
      <HeaderMenu title="KARTU HUTANG" subtitle={"Page untuk proses data Kartu Hutang"} />
      <ToastContainer />
      <div id="luckysheet" style={luckyCss} />
    </div>
  );
};


export default KARTUHUTANG;