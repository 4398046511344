import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../css/Login.css';
import '../css/Materialize.css';
import '../css/Style.css';
import reloadToken from '../helper/reloadtoken';
import { HotTable, HotColumn } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import HeaderMenu from '../template/HeaderMenu';
// register Handsontable's modules
registerAllModules();

const host = process.env.REACT_APP_API_URL_ONLINE;
const hostupload = process.env.REACT_APP_API_HOST_UPLOAD;

const DocumentUpload = () => {
  const hotRef = useRef(null);
  const [token, setToken] = useState(null);
  const [accessCode, setAccessCode] = useState('');
  const [colHeaders, setColHeaders] = useState([]);
  const widthLayout = window.innerWidth - 10;
  const heightLayout = window.innerHeight - 10;

  useEffect( async () => {
    const hot = hotRef.current.hotInstance;
    const newToken = await reloadToken();
    //console.log("test " + newToken);
    setToken(newToken);
    const config = {
      headers: {
        Authorization: `Bearer ${newToken}`,
      },
    };

    const getColumnNamesDocUpload = async () => {
      try {
        const response = await axios.post(`${hostupload}UploadDocument`, null, config);
        console.log(response.data);
        hot.loadData(response.data.data);
        setColHeaders(response.data.column); // Set the column headers from the response data
        return response.data;
      } catch (error) {
        console.error('Error fetching column names:', error);
        return [];
      }
    };

    getColumnNamesDocUpload();
  }, []);

  return (
    <div>
       <HeaderMenu title="Form Upload File"/>
      <div>
        <HotTable 
          ref={hotRef}
          contextMenu={true}
          rowHeaders={true}
          filters={true}
          outsideClickDeselects={false}
          allowInsertColumn={true}
          height={heightLayout}
          width={widthLayout}
          rowHeights={20}
          autoRowSize={false}
          manualColumnResize={true}
          fixedColumnsStart={5}
          dropdownMenu={['filter_by_condition', 'filter_by_value', 'filter_action_bar']}
          colHeaders={colHeaders} // Use the dynamically set colHeaders
          colWidths={[100, 100, 100, 150, 100, 95, 95, 95, 95, 95, 95, 95, 95, 95, 95, 95, 95, 95, 95, 95, 95, 95, 95, 95, 95, 95, 95, 95, 95, 95, 95, 95]}
          licenseKey="non-commercial-and-evaluation"
          hiddenColumns={{
            copyPasteEnabled: true,
            indicators: true,
            //columns: [0]
          }}
        >
           {colHeaders.map((colName, index) => (
            <HotColumn key={index} renderer={"html"} editor={false}/>
          ))}
        </HotTable>
      </div>
    </div>
  );
};

export default DocumentUpload;
