import React from 'react';

const Loading = () => {
    return (
        <div className="loading-container_m">
            <div className="spinner_m"></div>
            <p className="loading-text_m">Loading, please wait...</p>
        </div>
    );
};

export default Loading;