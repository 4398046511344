import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { HotTable, HotColumn } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import Header from '../../template/HeaderMenu';
import styleHs from '../../css/Hs.module.css';
import { apiConfig } from '../../helper/apiConfig';
import reloadToken from '../../helper/reloadtoken';
import styles from '../../css/Hs.module.css';

registerAllModules();
const hostuser = process.env.REACT_APP_API_HOST_MASTER_USER

const Pib = () => {
  const [tableHeight, setTableHeight] = useState(350); 
  const hotRef = useRef(null);
  const [hotInstance, setHotInstance] = useState(null);
  const [token, setToken] = useState(null);
  const [newRowValue, setNewRowValue] = useState(''); 
  let nama_pt = "ROLE";
  let tipe = "1";

  useEffect(() => {
    const setUP = async () => {
      const hot = hotRef.current.hotInstance;
      setHotInstance(hot);
      const token = await reloadToken();
      setToken(token);
    }
    setUP();
      const screenHeight = window.innerHeight;
      const headerHeight = 70;
      const footerHeight = 60;
      const calculatedHeight = screenHeight - headerHeight - footerHeight;
      setTableHeight(calculatedHeight);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      console.log(token);
      if (hotInstance && token) {
        const res = await loadData(token);
        hotInstance.addHook('afterColumnResize', handleColumnResize);
        loadColumnWidthsFromLocalStorage();
        initializeForSearchColumn();
          if (res && Array.isArray(res.data)) {
            hotInstance.loadData(res.data);
          } else {
            console.error("Invalid data format or data is missing:", res);
          }
      }
    };
    fetchData();
  }, [token]);

  const loadData = async (token) => {
    try {
      const config = apiConfig(token);
      const response = await axios.post(`${hostuser}ShowRole`, null, config);
      if (hotInstance) {
        hotInstance.loadData(response.data.data);
      } else {
        console.log("data kosong");
      }
      return response.data;
    } catch (error) {
      console.error('Error fetching column names:', error);
      return [];
    }
  };

  const initializeForSearchColumn = () => {
    const debounceFn = (colIndex, event) => {
      const filtersPlugin = hotInstance.getPlugin('filters');
      filtersPlugin.removeConditions(colIndex);
      filtersPlugin.addCondition(colIndex, 'contains', [event.target.value]);
      filtersPlugin.filter();
    };

    const addEventListeners = (input, colIndex) => {
      input.addEventListener('keydown', (event) => {
        debounceFn(colIndex, event);
      });
    };

    const addInput = (col, TH) => {
      if (typeof col !== 'number') {
        return col;
      }

      if (col >= 0 && TH.childElementCount < 2) {
        TH.appendChild(getInitializedElements(col));
      }
    };

    const getInitializedElements = (colIndex) => {
      const div = document.createElement('div');
      const input = document.createElement('input');
      addEventListeners(input, colIndex);
      div.appendChild(input);
      return div;
    };

    const doNotSelectColumn = (event, coords) => {
      if (coords.row === -1 && event.target.nodeName === 'INPUT') {
        event.stopImmediatePropagation();
        hotInstance.deselectCell();
      }
    };

    hotInstance.updateSettings({
      afterGetColHeader: addInput,
      beforeOnCellMouseDown: doNotSelectColumn,
    });
  }

  const handleSaveButtonClick = () => {
    const config = apiConfig(token);
    var requestData = { data: hotInstance.getData() }; // No need to stringify
    var alertConfirm = window.confirm("Data akan di proses?");
    if (alertConfirm) {
      axios.post(`${hostuser}saveBiaya/PIB`, requestData, config)
        .then(response => {
          if (response.data.status === 'success') {
            hotInstance.loadData([]);
            var data = response.data.data;
            var kode = response.data.group;
            window.location.href = `/pibdetail/pib/${kode}`;
            console.log('data:', data);
            console.log('Kode:', kode);
          } else {
            var message = response.data.message;
            console.log('Error:', message);
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error.response.data.message);
        });
    }
  };

  const loadColumnWidthsFromLocalStorage = () => {
    const savedWidths = {};
    for (let i = 0; i < hotInstance.countCols(); i++) {
      const width = localStorage.getItem(`${nama_pt}-${tipe}-${i}`);
      savedWidths[i] = width ? parseInt(width) : 100;
    }
    hotInstance.updateSettings({
      colWidths: function (column) {
        return savedWidths[column];
      },
    });
  };

  const handleColumnResize = (size, column) => {
    localStorage.setItem(`${nama_pt}-${tipe}-${column}`, size);
  };

  const handleInputChange = (event) => {
    setNewRowValue(event.target.value);
  };

  const handleAddRowClick = () => {
    const valueTotalinput = newRowValue;
    const hotInstance = hotRef.current.hotInstance;
    hotInstance.suspendRender();
    hotInstance.alter('insert_row_below',  hotInstance.ending, 1);
    hotInstance.resumeRender();
  };

  const handleClick = (data) => {
    if (data == "role"){
      window.location.href = "/role";
    } else if (data == "role_detail"){
      window.location.href = "/roledetail";
    } else if (data == "masteruser"){
      window.location.href = "/masteruser";
    }
  };
  

  const handleAfterCreateRow = async (index) => {
    var i = parseInt(index);
    try {
      const response = await axios.post(`${hostuser}Role/New`, null, apiConfig(token));
      if (response.data.status === 'success') {
        hotInstance.setDataAtCell(i, 0, response.data.data);
      } else {
        console.log('Error:', response.data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleAfterChange = async (changes, source) => {
    if (source === 'edit') {
      setTimeout(async function () {
        const rowIndex = changes[0][0];
        const id = hotInstance.getDataAtCell(rowIndex, 0);
        const dataBank = {
          "nama_role": hotInstance.getDataAtCell(rowIndex, 1),
          "role": hotInstance.getDataAtCell(rowIndex, 2),
          "role_multi": hotInstance.getDataAtCell(rowIndex, 3),
        };
        try {
          const response = await axios.post(`${hostuser}Role/${id}`, dataBank, apiConfig(token));
          if (response.data.status === 'success') {
           
          } else {
            const message = response.data.message;
            console.log('Error:', message);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }, 100);
    }
  };

  const handleAfterRemoveRow = async (index, amount, physicalRows, [source]) => {
    const id = hotInstance.getDataAtCell(index, 0);
  
    // Ask for confirmation before proceeding with the delete
    const confirmed = window.confirm("Are you sure you want to delete this role?");
  
    if (!confirmed) {
      // hotInstance.undo();
      return;
    }
  
    try {
      const response = await axios.post(`${hostuser}RoleDel/${id}`, null, apiConfig(token));
  
      if (response.data.status === 'success') {
        // Handle success (if needed)
      } else {
        const message = response.data.message;
        console.log('Error:', message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  const customRenderer = (instance, td, row, col, prop, value, cellProperties) => {
    // Use dangerouslySetInnerHTML to render HTML content
    if (typeof value === 'string') {
        td.innerHTML = value;
    }
};
  const columns = [
    { type: 'text', title: 'ID', align: 'center' },
    { type: 'text', title: '<b>Nama Role</b>', align: 'center' },
    { type: 'text', title: '<b>Role</b>', align: 'center' },
    { type: 'text', title: '<b>Role Multi</b>' },
  ];
  const colHeaders = columns.map(column => column.title);
  return (
    <div>
      <Header title="Master Role" />
      <div>
        <HotTable
          ref={hotRef}
          settings={{
            afterCreateRow: handleAfterCreateRow,
            afterChange: handleAfterChange,
            beforeRemoveRow:handleAfterRemoveRow
          }}
          renderer={customRenderer}
          contextMenu={true}
          rowHeaders={true}
          filters={true}
          outsideClickDeselects={false}
          allowInsertColumn={true}
          height={tableHeight}
          width={1465}
          autoRowSize={true}
          readOnly={false}
          manualColumnResize={true}
          dropdownMenu={['filter_by_condition', 'filter_by_value', 'filter_action_bar']}
          columns={columns}
          // colWidths={[100, 185, 185, 185, 185, 185, 185, 185, 185, 185, 185]}
          licenseKey="non-commercial-and-evaluation"
          hiddenColumns={{
            copyPasteEnabled: true,
            indicators: true,
            columns: [0]
          }}
        />

        <div className="positionfix" style={{ textAlign: 'left' }}>
            <div id="footer_data" style={{ margin: '5px', verticalAlign: 'text-bottom' }}>
            <input className={`${styleHs.inputText}`} id="new_add_row" value={newRowValue} onChange={handleInputChange} type="text" placeholder="0"size="3" maxLength="2"/>
            <button className={`${styleHs.button} ${styleHs.buttonnew}`} id="btn_save" onClick={() => handleAddRowClick()}>
               +  Add New Row
            </button>
            <button className={`${styleHs.button} ${styleHs.buttonload}`} id="btn_save" onClick={() => handleClick("role")}>
              Role
            </button>
            <button className={`${styleHs.button} ${styleHs.buttonload}`} id="btn_save" onClick={() => handleClick("role_detail")}>
              Role Detail
            </button>
            <button className={`${styleHs.button} ${styleHs.buttonload}`} id="btn_save" onClick={() => handleClick("masteruser")}>
              Master User
            </button>
            </div>
        </div>

      </div>
    </div>
  );
};

export default Pib;