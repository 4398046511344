const ModalLartas = ({
  isOpen,
  toggleModal,
  listLartas,
  handleAdditionalChange,
}) => {
  if (!isOpen) return null;

  return (
    <div className="modal-lartas-overlay">
      <div className="modal-content-lartas">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              backgroundColor: "#ffcc00",
              padding: "5px",
              width: "fit-content",
              borderRadius: "5px",
              color: "black",
              fontWeight: "500",
            }}
          >
            Isi terlebih dahulu pernyataan berikut!
          </div>
          <button className="modal-close" onClick={toggleModal}>
            &times;
          </button>
        </div>
        <div className="modal-body">
          <div>
            <table
              className="custom-table"
              style={{ width: "100%", borderCollapse: "collapse" }}
            >
              <thead>
                <tr>
                  <th style={{ textWrap: "nowrap" }}>Hs Code</th>
                  <th style={{ textWrap: "nowrap" }}>Pernyataan lartas</th>
                  <th style={{ textWrap: "nowrap" }}>Pernyataan SPI</th>
                  <th style={{ textWrap: "nowrap" }}>Pernyataan LS</th>
                  <th style={{ textWrap: "nowrap" }}>Pernyataan ECOO</th>
                  <th style={{ textWrap: "nowrap" }}>Pernyataan CONFIRM</th>
                </tr>
              </thead>
              <tbody>
                {listLartas &&
                  listLartas.map((item) => (
                    <tr key={item.id}>
                      <td>{item.hs_code}</td>
                      <td>
                        <select
                          style={{ width: "100%", textAlign: "center" }}
                          value={item.is_lartas || ""}
                          onChange={(e) =>
                            handleAdditionalChange(
                              item.id,
                              "is_lartas",
                              e.target.value
                            )
                          }
                        >
                          <option disabled value="">
                            Pilih status lartas
                          </option>
                          <option value="Y">Y</option>
                          <option value="T">T</option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            handleAdditionalChange(
                              item.id,
                              "is_need_spi",
                              e.target.checked
                            )
                          }
                          checked={item.is_need_spi || false}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            handleAdditionalChange(
                              item.id,
                              "is_need_ls",
                              e.target.checked
                            )
                          }
                          checked={item.is_need_ls || false}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            handleAdditionalChange(
                              item.id,
                              "is_need_ecoo",
                              e.target.checked
                            )
                          }
                          checked={item.is_need_ecoo || false}
                        />
                      </td>
                      <td>
                        <button
                          className="styled-button mini"
                          style={{
                            backgroundColor: "#2a2a6e",
                            padding: "8px",
                            borderRadius: "6px",
                          }}
                          onClick={() =>
                            handleAdditionalChange(item.id, "is_confirm", true)
                          }
                        >
                          Confirm
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalLartas;
