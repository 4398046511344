import React, { useEffect, useState } from 'react';
import axios from 'axios';
import $ from 'jquery';
import '../css/Login.css';
import '../css/Materialize.css';
import '../css/Style.css';
import '../css/new.css';
import reloadToken from '../helper/reloadtoken';
import helperFilter from '../helper/helperfilter';
import { dataconfig, showtoolbarConfig,dataconfigDatacontHistory, ct } from '../helper/luckysheetHelper';
import { hide, bikinInfoSum, bikinInfoSumUMDNew, sumDataKerja, getFrozen, createRatioZoom, getRatioZoom, saveheighrow, getheighrow, bikinselect, getFilterPageAll } from '../helper/hide';
import HeaderMenu from '../template/HeaderMenu';
import { apiConfig } from '../helper/apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dataformula from '../sheetFormula.js';
import pako from 'pako';
import ModalHistory from './modalHistory';

const tipedata = "DATACONT";
const host = process.env.REACT_APP_API_URL_ONLINE;
const hostdrop = process.env.REACT_APP_API_HOST_DROPDOWN;
const hostcont = process.env.REACT_APP_API_HOST_DATA_CONT;
const hostdatcogitnt = process.env.REACT_APP_API_HOST_DATA_CONT;
const hostcc = process.env.REACT_APP_API_HOST_COLOR_COMMENT;

const fullURL = window.location.href;
const hs_type = "hs_type-" + fullURL;
const hs_bln = "hs_bln-" + fullURL;
const hs_thn = "hs_thn-" + fullURL;
const hs_port = "hs_port-" + fullURL;

const notify_cant_update_br_cont = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 500,
  });
};
const notify_update_br_cont = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 500,
  });
};

const DATACONTHISTORY = () => {
  const luckysheet = window.luckysheet;
  const [token, setToken] = useState(null);
  var b = 0;
  const pagecolumn = "DATACONTCOLUMN"
  var columnWidths = [];
  var kiri, atas, tinggi;
  var options = null;
  var type_link = "";
  var type_row = "";
  var type_jml = 0;
  let valueppn = "YA,TIDAK";
  let valueukuran = "20,40,45";
  let valuestatusbl = "TELEX,DRAFT,ORI,HBL";
  let dataserver = [];
  let dataserver1 = [];
  var end_column_freze = 10;
  var size_scroll_left = 0;
  var defaultratio = 1;

  const [modalVisible, setModalVisible] = useState(false);
  const [datamodal, setDataModal] = useState(false);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const [modalVisible_V, setModalVisible_V] = useState(false);
  const [datamodal_V, setDataModal_V] = useState(false);

  const [optionsSearch, setOptionsSearch] = useState([]);
  const [loadingsearch, setLoadingSearch] = useState(true);

  const [selectTypeSearch, setSelectTypeSearch] = useState(getFilterPageAll(hs_type));
  const [selectport, setSelectPort] = useState(getFilterPageAll(hs_port));
  const [selectedYear, setSelectedYear] = useState(getFilterPageAll(hs_thn));
  const [selectedMonth, setSelectedMonth] = useState(getFilterPageAll(hs_bln));

  const handleTypeChange = (event) => {
    setSelectTypeSearch(event.target.value);
    localStorage.setItem(hs_type, event.target.value);
  };

  const handleMonthChange = (event) => {
    const value = event.target.value;
    let intValue;
    if (value.trim() !== '') {
         intValue = parseInt(value, 10);
        if (!isNaN(intValue)) {
        } else {
          intValue="";
        }
    } else {
      intValue="";
    }
    setSelectedMonth(intValue);
    localStorage.setItem(hs_bln, intValue);
  };

  const onYearChange = (year) => {
    const value = year;
    let intValue;
         intValue = parseInt(value, 10);
        if (!isNaN(intValue)) {
        } else {
          intValue="";
        }
        setSelectedYear(intValue);
    localStorage.setItem(hs_thn, intValue);
  };
  
  const handleSelectedPort = (e) => {
    setSelectPort(e.target.value);
    localStorage.setItem(hs_port, e.target.value);
  };


  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, index) => currentYear - index);

  function getDataFilterTersimpan() {
    var array = [];
    var tipe1 = getFilterPageAll(hs_type); 
    var operator1 = '==';
    var value11 = getFilterPageAll(hs_bln)+"-"+getFilterPageAll(hs_thn);
    var value12 = null;

    var tipe2 = 'port'; 
    var operator2 = '=';
    var value21 = getFilterPageAll(hs_port);
    var value22 = null;

    if (tipe1 !== null && tipe1 !== '') {
      array.push([tipe1, operator1, value11, value12]);
    }
    if (value21 !== null && value21 !== 'ALL' && value21 !== '') {
      array.push([tipe2, operator2, value21, value22]);
    }
    return array;
  }

  const handleResetClick = () => {
    const keysToDelete = [hs_bln, hs_thn, hs_port, hs_type];
    keysToDelete.forEach(key => {
      localStorage.removeItem(key);
    });
    window.location.reload();
  }

  const handleSearchClick = async () => {
    try {
      const payload = {
        month: selectedMonth,
        year: selectedYear,
      };

      const newToken = await reloadToken()
      setToken(newToken);
      if (newToken) {
        initializeLuckySheet(newToken, selectedMonth, selectedYear, selectTypeSearch, selectport);
      }
    } catch (error) {
      console.error('Error searching:', error);
    }
  };

  function callFunctionlocalstorage() {
    const ratiozoom = getRatioZoom();
    defaultratio = ratiozoom;
  }


  useEffect(() => {
    callFunctionlocalstorage();
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 100));
      const newToken = await reloadToken()

      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        setToken(storedToken);
      } else {
        reloadToken().then(newToken => {
          setToken(newToken);
          localStorage.setItem('token', newToken);
        });
      }

      if (newToken) {
        initializeLuckySheet(newToken, selectedMonth, selectedYear, selectTypeSearch, selectport);
      }
    };
    fetchData();

    const handleVisibilityChange = () => {
      if (document.hidden) {
      } else {
        if (luckysheet) { 
          luckysheet.refresh();
        } else{
          window.location.reload();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  const initializeLuckySheet = async (token, bln, thn, tipe, port) => {
    let datacari = [];
    if ((tipe !== null && tipe !== "") && (bln !== null && bln !== "") && (thn !== null && thn !== "") && (port !== null && port !== "")){
      datacari.push(bln + "-" + thn + "-" + tipe + "-" + port);
    } 
    new Promise((resolve) => setTimeout(resolve, 100));
    const config = apiConfig(token);

    const getValueDropdownSearch = async () => {
      try {
        const config = apiConfig(token);
        const response = await axios.post(`${hostcont}MenudropdownHistory`, null, config);
        return response.data;
      } catch (error) {
        console.error('Error fetching column names:', error);
        return [];
      }
    };
    setOptionsSearch(await getValueDropdownSearch())

    const getColumnNames = async () => {
      try {
        const config = apiConfig(token);
        const response = await axios.post(`${hostcont}ReactBrcontainerColumn`, null, config);
        return response.data.column;
      } catch (error) {
        console.error('Error fetching column names:', error);
        return [];
      }
    };

    const getCellData = async (data, savedColumnWidths, urutanload) => {
      let fh = getDataFilterTersimpan();
      try {
        const response = await axios.post(`${hostcont}ReactBrcontainers`, { data, savedColumnWidths, urutanload, datacari, fh }, config);
        return response;
      } catch (error) {
        console.error('Error fetching cell data:', error);
        return [];
      }
    };

    const nama_column = await getColumnNames();
    const data_pilihan = ["harga_custom", "harga_kapal", "lainnya", "jual", "pib", "pph_bebas", "notul", "dana_kerja", "biaya_do", "biaya_lainnya", "demmurage", "jaminan_overpayment", "sisa_jaminan", "total_biaya_do", "pendo_c", "penarikkan", "handling_kbkb", "bc", "tila_muat", "gerakkan", "storages", "ppjk", "lainnya_bh", "total_biaya_lapangan", "biaya_trucking", "lolo", "lolo", "bongkar", "parkir", "repo", "penitipan_inap", "etc", "total_trucking", "lainnya_bw", "kapal", "form_e", "ls", "daop", "biaya_kerja", "sisa_dana_kerja", "sewa_pt", "jasa_kerja", "biaya_kerja_daop_pib", "Total_biaya_tanpa_kapal", "asuransi", "biaya_pelabuhan", "do_lapangan_transport_fe_ls_bc", "pib_biaya", "total_biaya", "dk_total_handling", "dk_bayar_lapangan", "dk_cross_check", "dk_selisih_hitungan", "dk_total_biaya", "dk_sisa_dana", "dk_profit", "daop_jasa", "daop_penarikkan", "daop_kuli_cntr", "daop_pemeriksaan_bagasi", "daop_koord_periksa", "daop_staff_periksa", "daop_p2_bawah", "daop_p2_bawah_wilayah", "daop_analis_p2_bawah", "daop_pfpd", "daop_pusat", "daop_wilayah_atas", "daop_pelayanan_atas", "daop_penyidik", "daop_ops_bahendle"];
    let datahasil = [];
    const cariindex = data_pilihan.map(columnName => nama_column.indexOf(columnName));
    for (let i = 0; i < cariindex.length; i++) {
      const columnNumber = cariindex[i];
      const columnPosition = getColumnPosition(columnNumber);
      datahasil.push(columnPosition)
    }

    let savedColumnWidths = JSON.parse(localStorage.getItem(pagecolumn));
    if (savedColumnWidths === null || Object.keys(savedColumnWidths).length === 0) {
      savedColumnWidths = null;
    } else {
      savedColumnWidths = savedColumnWidths;
    }

    let cellDataJsonA = [];
    let totaldata = 0;
    let totaldataasli = 0;

    function isObjectEmpty(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    }

    async function loaddata(offset) {
      cellDataJsonA = await getCellData(datahasil, savedColumnWidths, offset);
      if (cellDataJsonA.length === 0) {
        return {
          "data": [{}],
          "dataformula": [{}]
        };
      } else {
        if (isObjectEmpty(cellDataJsonA.data)) {
          return {
            "data": [{}],
            "dataformula": [{}]
          };
        } else {
          totaldata = totaldata + cellDataJsonA.data.totaldata + 1;
          totaldataasli = totaldataasli + cellDataJsonA.data.totaldata;
          for (let i = 0; i < cellDataJsonA.data.data.length; i++) {
            if (cellDataJsonA.data.data[i].v.ct == "0") {//title
              cellDataJsonA.data.data[i].v.ct = {
                "fa": "@",
                "t": "s",
              };
              cellDataJsonA.data.data[i].v.bl = 1;
              cellDataJsonA.data.data[i].v.ff = 1;
              cellDataJsonA.data.data[i].v.ht = 0;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
              //cellDataJsonA.data.data[i].v.fc ="#8c8c8c"
            } else if (cellDataJsonA.data.data[i].v.ct == "1") {//angka
              cellDataJsonA.data.data[i].v.ct = {
                "fa": "#,##0",
                "t": "n",
              };
              cellDataJsonA.data.data[i].v.bl = 2;
              cellDataJsonA.data.data[i].v.ff = 2;
              cellDataJsonA.data.data[i].v.ht = 2;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
            } else if (cellDataJsonA.data.data[i].v.ct == "2") {//date
              cellDataJsonA.data.data[i].v.ct = {
                "fa": "yyyy-MM-dd",
                "t": "d",
              };
              cellDataJsonA.data.data[i].v.bl = 2;
              cellDataJsonA.data.data[i].v.ff = 2;
              cellDataJsonA.data.data[i].v.ht = 0;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
            } else if (cellDataJsonA.data.data[i].v.ct == "3") {//link
              cellDataJsonA.data.data[i].v.ct = {
                "fa": "@",
                "t": "l",
              };
              cellDataJsonA.data.data[i].v.bl = 2;
              cellDataJsonA.data.data[i].v.ff = 2;
              cellDataJsonA.data.data[i].v.ht = 0;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
            } else if (cellDataJsonA.data.data[i].v.ct == "4") {//text center
              cellDataJsonA.data.data[i].v.ct = {
                "fa": "0",
                "t": "n",
              };
              cellDataJsonA.data.data[i].v.bl = 2;
              cellDataJsonA.data.data[i].v.ff = 2;
              cellDataJsonA.data.data[i].v.ht = 0;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
            } else {
              cellDataJsonA.data.data[i].v.ct = {//text
                "fa": "@",
                "t": "n",

              };
              cellDataJsonA.data.data[i].v.bl = 2;
              cellDataJsonA.data.data[i].v.ff = 2;
              cellDataJsonA.data.data[i].v.ht = 1;
              cellDataJsonA.data.data[i].v.fs = 9;
              cellDataJsonA.data.data[i].v.vt = 0;
              cellDataJsonA.data.data[i].v.tb = 2;
            }
          }
        }
        const data_all = cellDataJsonA.data;
        return data_all;
      }
    }

    const [page0Data, page1Data, page2Data, page3Data, page4Data, page5Data] = await Promise.all([
      loaddata(0),
      loaddata(1),
      loaddata(2),
      loaddata(3),
      loaddata(4),
      loaddata(5),
    ]);

    const page0 = page0Data || [];
    const page1 = page1Data || [];
    const page2 = page2Data || [];
    const page3 = page3Data || [];
    const page4 = page4Data || [];
    const page5 = page5Data || [];

    const merged = [...page0.data,
    ...page1.data,
    ...page2.data,
    ...page3.data,
    ...page4.data,
    ...page5.data,
    ];

    const canformual = [...page0.dataformula,
    ...page1.dataformula,
    ...page2.dataformula,
    ...page3.dataformula,
    ...page4.dataformula,
    ...page5.dataformula,
    ];
    const mergedDataRO = page1.readonly;

    options = {
      container: "luckysheet",
      title: "DataCont",
      lang: 'en',
      showinfobar: false,
      allowCopy: true,
      allowEdit: true,
      column: 0,
      forceCalculation: true,
      showtoolbarConfig: dataconfigDatacontHistory,
      cellRightClickConfig: showtoolbarConfig,
      data: [
        {
          name: "Sheet",
          color: "",
          row: 1,
          index: "0",
          celldata: merged,
          calcChain: canformual,
          defaultRowHeight: 40,
          config: {
            columnlen: savedColumnWidths,
            colhidden: {
              0: false,
            },
            rowlen: {
              0: 40,
            },
            authority: {
              sheet: 1,
              hintText: "Kolom ini tidak bisa anda edit", //The text of the pop-up prompt
              allowRangeList: [
                {
                  "name": "area",
                  "sqref": mergedDataRO,
                },
              ],
            },
          },
          // filter_select: {
          //   row: [0, totaldata],
          //   column: [1, nama_column.length - 1],
          // }
        },
      ],
      enableAddRow: false,
      textWrapMode: true,
      hook: {
        cellRenderAfter: function (cell, position, sheetFile, ctx) {
          // console.log("cell ",cell)
          // console.log("position ",position)
          // console.log("sheetFile ",sheetFile)
          // console.log("ctx ",ctx)
          if (b < 1) {
            const data = [];
            const endIndex = 150;
            for (let i = 0; i <= endIndex; i++) {
              data.push(i);
            }
            var columnWidths = luckysheet.getColumnWidth(data);
            for (let columnIndex of data) {
              const width = columnWidths[columnIndex];
              columnWidths[columnIndex.toString()] = width;
            }
            localStorage.setItem(pagecolumn, JSON.stringify(columnWidths));
            setTimeout(function () {
              b = 0;
            }, 500);
          }
          b++;
        },

        frozenCreateAfter: function (data) {
          console.log("cell")
        },
        rangePasteBefore: function (range, data) {
          // return false; //Can intercept paste
        },
        commentUpdateBefore: async function (r, c, value) {
          const id = luckysheet.getCellValue(r, 0);
          var column = nama_column[c].toLowerCase();
          column = column.replace(/ /g, "_");
          // Create a new FormData object
          const formData = new FormData();
          formData.append("code_1", id);
          formData.append("code_2", tipedata);
          formData.append("code_3", "");
          formData.append("row", r);
          formData.append("col", c);
          formData.append("comment", value);
          formData.append("column", column);
          try {
            const response = await axios.post(`${hostcc}Updatecomment`, formData, config);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        },
        cellEditBefore: function (r, c, value, isRefresh) {
          // console.log("Edit before");
          // console.log(value);
        },
        cellUpdateBefore: function (r, c, value, isRefresh) {
          if (!isValidFormula(value)) {
            return true;
          } else {
            return false;
          }
        },
        cellUpdated: function (r, c, oldValue, newValue, isRefresh) {
          if (oldValue && newValue) {
            const newData = newValue.m;
            const id = luckysheet.getCellValue(r, 0);
            var datax = {};
            var nilai = newData;
            var column = nama_column[c];
            column = column.replace(/ /g, "_");
            if (nilai !== null && nilai !== undefined && nilai !== "") {
              // console.log(nama_column[c]);
              // console.log(data_pilihan);
              if (data_pilihan.includes(nama_column[c])) {
                //console.log("oke ada ", nilai.replace(/,/g, ''));
                datax[nama_column[c]] = nilai.replace(/,/g, '');
              } else {
                datax[nama_column[c]] = nilai;
              }
            } else {
              datax[nama_column[c]] = "";
            }
            if (oldValue !== null && oldValue.m != newData && nama_column[c] != "") {
              datax["id"] = id;
              axios.post(`${hostcont}ReactUpdateBrcontainers`, datax, config)
                .then(response => {
                  if (response.data.status == "success") notify_update_br_cont("Data Cont Updated");
                  //console.log('POST request successful:', response.data);
                })
                .catch(error => {
                  console.error('Error fetching data:', error.response.data.message);
                  notify_cant_update_br_cont(error.response.data.message);
                  // luckysheet.setCellValue(r, c, "");
                  luckysheet.refresh();
                });
            }
          }
        },
        cellEditBefore: function (range) {
          const c = range[0].column[0];
          var title_column_select = luckysheet.getCellValue(0, c);
          //console.log(title_column_select);
        },
        rangeSelect: function (index, sheet) {
          //console.log("Column ", sheet[0].column[0]);
          if (sheet[0].column[0] <= end_column_freze) {
            setleftScrollbar(true);
          } else {
            setleftScrollbar(false);
          }
          var top = sheet[0].top;
          var left = sheet[0].left;
          var height = sheet[0].height;
          kiri = left;
          atas = top;
          tinggi = height;
          filter_data(top, left, height);
        },
        afterzoomfiki: function (ration) {
          createRatioZoom(ration);
        },
        cellMousedown: function (cell, postion, sheetFile, ctx) {
          var dat = postion.r + "" + postion.c;
          if (type_row == dat) {
            type_jml++;
          } else {
            type_jml = 0;
          }
          type_row = postion.r + "" + postion.c;

          if (type_jml > 0) {
            if (cell.ct.t == "l") {
              const id = luckysheet.getCellValue(postion.r, 0);
              const judul = luckysheet.getCellValue(0, postion.c);
              var dataKirimModal = {
                id: id,
                nama: judul
              };
              //console.log(dataKirimModal)
              setDataModal(dataKirimModal);
              toggleModal();
            }
          }
        },
      },
    }

    options.loading = {
      image: () => {
        return `<svg viewBox="25 25 50 50" class="circular">
					<circle cx="50" cy="50" r="20" fill="none"></circle>
					</svg>`
      },
      imageClass: "loadingAnimation"
    }

    if (totaldata > 0) {
      luckysheet.create(options)
    } else {
      notify_cant_update_br_cont("Error, Tidak dapat tersambung dengan server!", 2500);
    }

    function rollbackvalidasidropdown(valueppnArray, data) {
      if (data === null || data === undefined || data.trim() === "") {
        console.log("Data is empty or null.");
        return false;
      }
      if (valueppnArray.includes(data)) {
        return true;
      } else {
        return false;
      }
    }

    function getColumnIndexByTitle(sheetId, title) {
      const sheetData = luckysheet.getSheetData(sheetId);
      if (!sheetData) return -1; // Sheet data not found

      const firstRowData = sheetData[0].data;
      for (let colIndex = 0; colIndex < firstRowData.length; colIndex++) {
        if (firstRowData[colIndex].v === title) {
          return colIndex;
        }
      }
      return -1; // Column title not found
    }

    function removeCrLfFromString(inputString) {
      return inputString.replace(/\r/g, ' ');
    }

    function removeCrLfFromString_(inputString) {
      return inputString.replace(/\n/g, ' ');
    }

    $("#luckysheet-bottom-add-row").on("click", async function () {
      var data = $("#luckysheet-bottom-add-row-input").val();

      const currentDate = getCurrentDate();
      var b = luckysheet.getRange()[0].row[0];
      // luckysheet.setCellValue(b, 3, currentDate);
      // luckysheet.refresh();
      for (var i = 1; i <= data; i++) {
        try {
          const response = await axios.post(`${hostcont}BrcontainersDetail/New`, null, config);
          //luckysheet.setCellValue(b, 0, response.data.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
        b++;
      }
      window.location.reload();
    });

    function isValidFormula(value) {
      const formulaPattern = /^=([A-Za-z]+\d+\+)*[A-Za-z]+\d+$/;
      return formulaPattern.test(value);
    }

    $(document).on('mousedown', '.sp-thumb-el', function () {
      // var warna = $(this).attr('data-color');
      var title = $(this).attr('title');
      var datas = [];
      var jlh = 0;
      var selectedRange = luckysheet.getRange();
      if (!selectedRange) {
        console.error('No cells selected.');
        return;
      }
      var baris_awal = selectedRange[0].row[0];
      var baris_akhir = selectedRange[0].row[1];
      var kolom_awal = selectedRange[0].column[0];
      var kolom_akhir = selectedRange[0].column[1];

      const column = nama_column[kolom_awal];
      for (var row = baris_awal; row <= baris_akhir; row++) {
        for (var col = kolom_awal; col <= kolom_akhir; col++) {
          const id = luckysheet.getCellValue(baris_awal, 0);
          var datax = {};
          datax['id'] = id;
          datax['warna'] = title;
          datax['ColumnName'] = column;
          datax['ColumnField'] = "DATACONT";
          datas[jlh] = datax;
          jlh++;
        }
      }
      var dataxx = {};
      dataxx['data'] = JSON.stringify(datas);
      axios.post(`${hostcc}ColorBrcontainers`, dataxx, config)
        .then((response) => {
          notify_update_br_cont("Color sukses di tambahkan");
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    });

    $(document).on('mousedown', '[itemname="create filter"]', function () {
      setTimeout(async function () {
        helperFilter(11);
      }, 100);
    });

    function getColumnPosition(columnNumber) {
      let dividend = columnNumber + 1;
      let columnName = '';

      while (dividend > 0) {
        const modulo = (dividend - 1) % 26;
        columnName = String.fromCharCode(65 + modulo) + columnName;
        dividend = Math.floor((dividend - modulo) / 26);
      }
      return columnName;
    }

    const luckysheetContainer = document.getElementById('luckysheet');
    luckysheetContainer.addEventListener('scroll', () => {
      const scrollLeft = luckysheetContainer.scrollLeft;
      const frozenColumns = document.querySelectorAll('.luckysheet-cell.luckysheet-cell-frozen');
      frozenColumns.forEach((column) => {
        column.style.transform = `translateX(${scrollLeft}px)`;
      });
    });

    function setSheetZoomWithValidation(defaultratio) {
      if (defaultratio != null && defaultratio !== undefined) {
        if (luckysheet) {
          luckysheet.setSheetZoom(defaultratio);
        }
      } else {
        console.error("Invalid defaultratio:", defaultratio);
      }
    }

    setTimeout(async function () {
      if (totaldata > 0) {
        luckysheet.setBothFrozen(true, { range: { row_focus: 0, column_focus: end_column_freze } });
        if (!isMobileDevice()){
          setSheetZoomWithValidation(defaultratio);
        }
        helperFilter(11);
        setleftScrollbarstart();
      }
    }, 100);

    function isMobileDevice() {
      const mobileScreenWidth = 768;
      return window.innerWidth < mobileScreenWidth;
    }

  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  function setleftScrollbar(kiri) {
    const luckysheetContainerX = document.getElementById('luckysheet-scrollbar-x');
    luckysheetContainerX.addEventListener('scroll', () => {
      if (luckysheetContainerX.scrollLeft > 1) {
        size_scroll_left = luckysheetContainerX.scrollLeft;
      }
    });
    if (kiri) {
      size_scroll_left = 0
      luckysheetContainerX.scrollLeft = size_scroll_left;
    }
    else {
      luckysheetContainerX.scrollLeft = size_scroll_left;
      //console.log("SCROLL ", size_scroll_left);
    }
  }

  function setleftScrollbarstart() {
    const luckysheetContainerX = document.getElementById('luckysheet-scrollbar-x');
    luckysheetContainerX.addEventListener('scroll', () => {
      if (luckysheetContainerX.scrollLeft > 1) {
        size_scroll_left = luckysheetContainerX.scrollLeft;
      }
      luckysheetContainerX.scrollLeft = size_scroll_left;
      //console.log("SCROLL AUTO", luckysheetContainerX.scrollLeft);
    });
  }

  function filter_data(atas, kiri, tinggi) {
    $("#luckysheet-rich-text-editor").on('keyup', function () {
      const searchText = $("#luckysheet-rich-text-editor").text().toUpperCase();
      $("#luckysheet-dataVerification-dropdown-List").hide();
      $(".dropdown-List-item").each(function () {
        const itemText = $(this).text().toUpperCase();
        if (itemText.indexOf(searchText) > -1) {
          $(this).show();
        } else {
          $(this).hide();
        }
      });

      // fiki
      const editorHeight = $("#luckysheet-rich-text-editor").outerHeight();
      const topValue = parseFloat($("#luckysheet-cell-selected-focus").css("top"));
      const leftValue = parseFloat($("#luckysheet-input-box").css("left"));
      $("#luckysheet-dataVerification-dropdown-List").css({
        display: 'block',
        // left: leftValue - 44 + 'px',
        // top: topValue + editorHeight + editorHeight - 5 + 'px',
        right: 'auto',
        left: kiri + 'px',
        top: atas + tinggi + 'px',
      });
    });
  }

  const luckyCss = {
    margin: '120px 0px 0px 0px',
    padding: '0px',
    position: 'absolute',
    width: '100%',
    height: '84.5%',
    left: '0px',
    top: '0px',
    overflowX: 'auto',
  };


  const forumLinkStyle = {
    zIndex: 2,
    width: '50px',
    height: '50px',
    lineHeight: '50px',
    position: 'fixed',
    right: '40px',
    bottom: '86px',
    borderRadius: '50px',
    cursor: 'pointer',
    background: 'rgb(71,133,249)',
    color: '#fff',
    textAlign: 'center',
    textDecoration: 'none',
  };

  const selectStyle = {
    // Define your custom CSS styles here
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '2px',
    fontSize: '16px',
    width: 'auto',
    height: '10%',
    marginTop: '10px',
  };

  return (
    <div>
      <HeaderMenu title="Data Container" subtitle={"Halaman untuk data Kontainer"} />
      <ToastContainer />
      <select style={selectStyle} value={selectTypeSearch} onChange={handleTypeChange}>
        {optionsSearch.map((optionsSearch) => (
          <option key={optionsSearch.value} value={optionsSearch.value}>
            {optionsSearch.label}
          </option>
        ))}
      </select>
      <select style={selectStyle} value={selectedMonth} onChange={handleMonthChange}>
        <option value={""}>-PILIH-</option>
        <option value={1}>January</option>
        <option value={2}>February</option>
        <option value={3}>March</option>
        <option value={4}>April</option>
        <option value={5}>May</option>
        <option value={6}>June</option>
        <option value={7}>July</option>
        <option value={8}>August</option>
        <option value={9}>September</option>
        <option value={10}>October</option>
        <option value={11}>November</option>
        <option value={12}>December</option>
      </select>
      <select style={selectStyle} value={selectedYear} onChange={(e) => onYearChange(parseInt(e.target.value))}>
      <option value="">-PILIH-</option>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>

      <select style={selectStyle} value={selectport} onChange={handleSelectedPort}>
        <option value="">-PILIH-</option>
        <option value="ALL">ALL</option>
        <option value="PRIOK">PRIOK</option>
        <option value="PRIOK2">PRIOK2</option>
        <option value="PRIOK3">PRIOK3</option>
        <option value="PRIOK4">PRIOK4</option>
        <option value="PRIOK5">PRIOK5</option>
        <option value="SMG">SMG</option>
        <option value="SBY">SBY</option>
        <option value="MDN">MDN</option>
      </select>
      <button onClick={handleSearchClick} className='btn btn-default'>Search</button>
      <button onClick={handleResetClick} className='btn btn-default'>Reset</button>
      <div id="luckysheet" className='luckyCssWithMenuResponsive' />
      <ModalHistory modalVisible={modalVisible} toggleModal={toggleModal} data={datamodal} />
    </div>
  );
};

export default DATACONTHISTORY;