import React from "react";

const MenuBtn = ({
  go_to_home,
  toggleModalHarbour,
  go_to_master_entitas,
  go_to_entitas,
  go_to_master_aju,
}) => {
  return (
    <div className="menu-container" style={{ marginTop: "4px" }}>
      <button
        className="styled-button mini_icon home margin-btn-action"
        onClick={go_to_home}
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/png/house.png`}
          alt="Home"
          className="icon_free_margin text-white"
        />
      </button>
      {go_to_master_aju && (
        <button
          className="styled-button mini_icon pk margin-btn-action"
          onClick={go_to_master_aju}
        >
          List Aju
        </button>
      )}
      <button
        className="styled-button mini_icon green margin-btn-action"
        onClick={toggleModalHarbour}
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/png/add-post.png`}
          alt="Add Post"
          className="icon text-white"
        />
        Buat Dokument
      </button>

      <button
        className="styled-button mini_icon orange margin-btn-action"
        onClick={go_to_master_entitas}
      >
        Master Entitas
      </button>
      <button
        className="styled-button mini_icon brmd margin-btn-action"
        onClick={go_to_entitas}
      >
        Referensi Code
      </button>
    </div>
  );
};

export default MenuBtn;
