// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/flat-bg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*----------------------------------------
    Login Page
------------------------------------------*/
.login-bg
{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-size: cover;
}

#login-page
{
    display:         flex;

    height: 100vh;
    justify-content: center;
    align-items: center;
}

#login-page .card-panel.border-radius-6.login-card
{
    margin-left: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/css/Login.css"],"names":[],"mappings":"AAAA;;2CAE2C;AAC3C;;IAEI,yDAAiD;IACjD,4BAA4B;IAC5B,sBAAsB;AAC1B;;AAEA;;IAKI,qBAAqB;;IAErB,aAAa;IAKL,uBAAuB;IAIvB,mBAAmB;AAC/B;;AAEA;;IAEI,yBAAyB;AAC7B","sourcesContent":["/*----------------------------------------\n    Login Page\n------------------------------------------*/\n.login-bg\n{\n    background-image: url('../../public/flat-bg.jpg');\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n\n#login-page\n{\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display:         flex;\n\n    height: 100vh;\n\n    -webkit-box-pack: center;\n    -webkit-justify-content: center;\n    -ms-flex-pack: center;\n            justify-content: center;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n    -ms-flex-align: center;\n            align-items: center;\n}\n\n#login-page .card-panel.border-radius-6.login-card\n{\n    margin-left: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
