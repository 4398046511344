export function formatTimestampToDateInput(
  timestamp = Date.now(),
  format = "YYYY-MM-DD"
) {
  // If the passed timestamp is null or undefined, use Date.now()
  const validTimestamp = timestamp || Date.now();

  const date = new Date(validTimestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  // Replace the format string with the actual values
  const formattedDate = format
    .replace("YYYY", year)
    .replace("MM", month)
    .replace("DD", day);

  return formattedDate;
}
