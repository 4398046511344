const sheetFormula = {
	"name": "Formula",
	"color": "",
	"config": {
	},
	"index": "1",
	"chart": [],
	"order": "1",
	"column": 18,
	"row": 25,
	"defaultRowHeight": 25,
	"celldata": [{
		"r": 16,
		"c": 7,
		"v": {
			"v": 152,
			"ct": {
				"fa": "General",
				"t": "n"
			},
			"m": "152",
			"bg": null,
			"bl": 0,
			"it": 0,
			"ff": 9,
			"fs": 10,
			"fc": "rgb(0, 0, 0)",
			"ht": 1,
			"vt": 0,
			"f": "=SUM(F17:G17)"
		}
	}],
	"calcChain": [
	 {
		"r": 16,
		"c": 7,
		"index": 1,
		// "func": [true, 152, "=SUBTOTAL(9,OFFSET(F15,ROW(F15:F18)-ROW(F15),1,3))"],
		"color": "w",
		"parent": null,
		"chidren": {},
		"times": 0
	}
	],
	"ch_width": 1723,
	"rh_height": 1010,
	"luckysheet_select_save": [{
		"left": 532,
		"width": 123,
		"top": 780,
		"height": 19,
		"left_move": 532,
		"width_move": 123,
		"top_move": 780,
		"height_move": 19,
		"row": [39, 39],
		"column": [6, 6],
		"row_focus": 39,
		"column_focus": 6
	}],
	"luckysheet_selection_range": [],
	"scrollLeft": 0,
	"scrollTop": 0,
	"frozen": {"type":"row"}
}

export default sheetFormula