import '../css/uploadFile.css'; // Import custom styles
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import datepicker styles
import axios from 'axios'; // Import axios for making API requests

const UploadFile = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const [shipDate, setShipDate] = useState(new Date());
  const [uploadError, setUploadError] = useState(null); // State for upload error messages
  const [success_link, set_success_link] = useState(null); // State for Link Download

  const onDrop = (acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleShipDateChange = (date) => {
    setShipDate(date);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setUploadError('Please select a file to upload');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('ship_date', shipDate.toISOString()); // Convert date to ISO string

    try {
      const response = await axios.post('http://brapp.blueray.id:81/file/UploadFilePL_Import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set content type for file upload
          'Authorization': 'Bearer ' + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbiI6ZmFsc2UsImV4cCI6MTcxNzMxMTk0NSwibWFzdGVyX2ZpZWxkIjpbIk1ETiJdLCJuYW1lIjoiNDIyMzkwMDgiLCJwZW5nZ3VuYSI6IlNURUZBTlVTIiwicm9sZXMiOiJBRE1fTUROIn0.9kCsskfyME021wWkuWYlZII-8NzxtagpI77WxCtJeds' // Set token in header for authorization
        //   'Authorization': 'Bearer ' + localStorage.getItem('token') // Set token in header for authorization
        },
      });

      console.log('Upload successful:', response.data);
      setUploadError('Upload successful : '+ response.data.message);
      set_success_link(response.data.url);
      // Handle successful upload response (e.g., display success message)
    } catch (error) {
      console.error('Upload error:', error);
      setUploadError('Error uploading file. Please try again.');
    }
  };

  return (
    <div className="upload-container">
      <div {...getRootProps({ className: 'drop-zone' })}>
        <input {...getInputProps()} />
        {selectedFile ? (
          <p>Selected file: {selectedFile.name}</p>
        ) : (
          <p>Drag and drop your file here or click to select</p>
        )}
      </div>
      <DatePicker
        selected={shipDate}
        onChange={handleShipDateChange}
        dateFormat="yyyy-MM-dd"
        placeholderText="Ship Date (YYYY-MM-DD)"
      />
      <button disabled={!selectedFile} onClick={handleUpload}>
        Upload
      </button>
      {uploadError && <p className="error-message">{uploadError}</p>}
      {success_link && <a className="btn btn-info" href={success_link}>File Konsep</a>}
    </div>
  );
};

export default UploadFile;
