import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import Header from '../../template/HeaderMenu';
import styleHs from '../../css/Hs.module.css';
import { apiConfig } from '../../helper/apiConfig';
import reloadToken from '../../helper/reloadtoken';
import '../../css/Element.css';

registerAllModules();
const host = process.env.REACT_APP_API_URL_ONLINE;
const hostbiaya = process.env.REACT_APP_API_HOST_BIAYA
const hostdrop = process.env.REACT_APP_API_HOST_DROPDOWN;
const hostcc = process.env.REACT_APP_API_HOST_COLOR_COMMENT;

const PibDetail = () => {

  const [tableHeight, setTableHeight] = useState(350); 
  const [data] = useState([]);
  const { param1, param2 } = useParams();
  const hotRef = useRef(null);
  const hotRefaddbank = useRef(null);
  const hotRefselectbank = useRef(null);
  const [hotInstance, setHotInstance] = useState(null);
  const [hotBank, setHotBank] = useState(null);
  const [hotBankSelected, setHotBankSelected] = useState(null);
  const [token, setToken] = useState('');
  const totalSpanRef = useRef(null);
  const totalSpanTagihan = useRef(null);
  let nama_pt = "BIAYA-PIB-DETAIL";
  let tipe = "1";
  var data_all = [];
  var cneeValue;

  useEffect(() => {
    const setUP = async () => {
      const hot = hotRef.current?.hotInstance;
      setHotInstance(hot);
      const hotBankadd = hotRefaddbank.current?.hotInstance;
      setHotBank(hotBankadd);
      const hotBankSelected = hotRefselectbank.current?.hotInstance;
      setHotBankSelected(hotBankSelected);
      const token = await reloadToken();
      setToken(token);
    }
    setUP();

    const screenHeight = window.innerHeight;
    const headerHeight = 210;
    const footerHeight = 220;
    const calculatedHeight = screenHeight - headerHeight - footerHeight;
    console.log(calculatedHeight)
    setTableHeight(calculatedHeight);

  }, [hotInstance]);

  useEffect(() => {
    const fetchData = async () => {
      if (hotInstance && token) {
        const res = await loadDataPengajuan(token);
        if (res && Array.isArray(res.data)) {
          if (res.cnee) {
            cneeValue = res.cnee;
          }
          hotInstance.loadData(res.data);
          await loadDataBank(token);
          if (hotBank){
            initializeForSearchColumn();
            attachButtonPilihHandlers();
          }
        } else {
          console.error("Invalid data format or data is missing:", res);
        }
      }
    };
    fetchData();
  }, [hotBank,hotInstance,token]);

  const updateTotalSum = () => {
    if (hotBankSelected){
      const data = hotBankSelected.getData();
      const sum = data.reduce((acc, row) => {
        const value = parseFloat(row[4]); // Assuming column 4 (index 3) contains numeric values
        return isNaN(value) ? acc : acc + value;
      }, 0);
      console.log(sum.toLocaleString());
      totalSpanRef.current.textContent = sum.toLocaleString(); 
    } 
  };
  
  const loadDataPengajuan = async (token) => {
    try {
      const config = apiConfig(token);
      const response = await axios.post(`${hostbiaya}getdrafpengajuan/${param1}`, null, config);
      // Check if hotInstance is available before using it
      if (hotInstance) {
        hotInstance.loadData(response.data.data);
        hotInstance.addHook('afterColumnResize', handleColumnResize);
        loadColumnWidthsFromLocalStorage();
        CalculateColumnSum(hotInstance, [5]);
      } else {
        console.log("data kosong");
      }
      return response.data;
    } catch (error) {
      console.error('Error fetching column names:', error);
      return [];
    }
  };

  const loadDataBank = async (token) => {
    const loadBank = await loadDataBankBr(token);
    if (loadBank.length !== 0) {
      const modifiedData = loadBank.data.map(row => {
        const [id, bank, norek, pemilik, buttons] = row;
        return [id, bank, norek, pemilik, buttons];
      });
      hotBank.loadData(modifiedData);
      attachButtonDeleteHandlers();
      attachButtonPilihHandlers();
    } else {
      //window.alert("Data tidak ditemukan");
      // window.history.back();
    }
  };

  const deleteBank = async (id) => {
    console.log("cnee ",cneeValue)
    var alertConfirm = window.confirm("Data akan di hapus?");
    if (alertConfirm) {
      try {
        const response = await axios.post(`${hostbiaya}bankcnee/delete/${id}`, null, apiConfig(token));
        if (response.data.status === 'success') {
          await loadDataBank(token, cneeValue);
        } else {
          console.log('Error:', response.data.message);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const loadDataBankBr = async (token) => {
    try {
      const config = apiConfig(token);
      const response = await axios.post(`${hostbiaya}bankbr/show`, null, config);
      return response.data;
    } catch (error) {
      console.error('Error fetching column names:', error);
      return [];
    }
  };

  const handleSaveButtonClick = async () => {
    const config = apiConfig(token);
    var bankSelected = { data: hotBankSelected.getData() }; // No need to stringify
    var requestData = {
      type: param1,
      cnee: cneeValue,
      group_id: param2,
      data_bank_pengajuan: hotBankSelected.getData()
    };

        const tagihanValue = totalSpanTagihan.current ? totalSpanTagihan.current.textContent : '';
        const totalValue = totalSpanRef.current ? totalSpanRef.current.textContent : '';
  
        if (tagihanValue === totalValue) {
          var alertConfirm = window.confirm("Yakin data akan di proses?");
          if (alertConfirm) {
            if (bankSelected.data.length === 0) {
              alert("Tidak ada data bank teripiliih !");
            } else {
              console.log(requestData)
              axios.post(`${hostbiaya}SavePengajuanBiaya/PIB`, requestData, config)
                .then(response => {
                  if (response.data.status === 'success') {
                    //hotInstance.loadData([]);
                    var data = response.data.data;
                    var kode = response.data.group;
                    window.location.href = `/drafpengajuan`;
                    console.log('data:', data);
                    console.log('Kode:', kode);
                  } else {
                    var message = response.data.message;
                    console.log('Error:', message);
                  }
                })
                .catch(error => {
                  console.error('Error fetching data:', error.response.data.message);
                });
            }
          }
        } else {
          console.log('Data is different. Cannot proceed with save.');
        }
  };

  const attachButtonDeleteHandlers = () => {
    const buttons = document.getElementsByClassName('buttonHapus');
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].addEventListener('click', () => {
        const id = buttons[i].getAttribute('data-id');
        deleteBank(id);
      });
    }
  };

  const attachButtonPilihHandlers = () => {
    const buttons = document.getElementsByClassName('buttonPilih');
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].addEventListener('click', () => {
        const id = buttons[i].getAttribute('data-id');
        pilihBank(i);
      });
    }
  };

  function pilihBank(row) {
    var selectedID = hotBank.getDataAtCell(row, 0);
    var bank = hotBank.getDataAtCell(row, 1);
    var norek = hotBank.getDataAtCell(row, 2);
    var pemilik = hotBank.getDataAtCell(row, 3);
    var selectedAksi = `<button class='buttonHS button2 buttonHapusSelected' id='pilihButton-${row}'>Hapus</button>`;

    // Check if the value already exists in hotBankSelected
    var isDuplicate = hotBankSelected.getData().some(function (row) {
      return row[2] === norek;
    });

    const totalSpanTagihan1 = totalSpanTagihan.current ? totalSpanTagihan.current.textContent : '';

    if (!isDuplicate) {
      hotBankSelected.alter('insert_row_below');
      hotBankSelected.setDataAtCell(hotBankSelected.countRows() - 1, 0, selectedID);
      hotBankSelected.setDataAtCell(hotBankSelected.countRows() - 1, 1, bank);
      hotBankSelected.setDataAtCell(hotBankSelected.countRows() - 1, 2, norek);
      hotBankSelected.setDataAtCell(hotBankSelected.countRows() - 1, 3, pemilik);
      hotBankSelected.setDataAtCell(hotBankSelected.countRows() - 1, 4, null);
      hotBankSelected.setDataAtCell(hotBankSelected.countRows() - 1, 5, selectedAksi);
    }
  }

  const handleCellMouseDown = (event, coords, TD) => {
    const row = coords.row;
    const col = coords.col;
    const cellData = hotBankSelected.getDataAtCell(row, col);

    if (col === 5 && cellData && cellData.includes('button')) {
      hotBankSelected.alter('remove_row', row);
      updateTotalSum();
    }
  };

  const handleColumnResize = (size, column) => {
    localStorage.setItem(`${nama_pt}-${tipe}-${column}`, size);
  };

  const loadColumnWidthsFromLocalStorage = () => {
    const savedWidths = {};
    for (let i = 0; i < hotInstance.countCols(); i++) {
      const width = localStorage.getItem(`${nama_pt}-${tipe}-${i}`);
      savedWidths[i] = width ? parseInt(width) : 100;
    }
    hotInstance.updateSettings({
      colWidths: function (column) {
        return savedWidths[column];
      },
    });
  };

  function CalculateColumnSum(hot, data_row) {
    var rowCount = hot.countRows();
    hot.alter('insert_row_below', rowCount);
    data_row.forEach(function (i) {
      var columnData = hot.getData().map(function (row) {
        return parseFloat(row[i]); // Replace 2 with the index of your desired column
      });
      var sum = columnData.reduce(function (acc, val) {
        return acc + (isNaN(val) ? 0 : val);
      }, 0);
      var columnIndex = i;
      hot.setDataAtCell(rowCount, columnIndex, sum);

      totalSpanTagihan.current.textContent = sum.toLocaleString(); 
    });

    var mergeCells = [
      { row: rowCount, col: 1, rowspan: 1, colspan: 4 }
    ];

    var topLeftRowIndex = mergeCells[0].row;
    var topLeftColIndex = mergeCells[0].col;
    hot.setDataAtCell(topLeftRowIndex, topLeftColIndex, 'Total');

    hot.updateSettings({
      mergeCells: true,
      mergeCells: mergeCells,
    });
  }

    const handleAfterChange = (changes, source) => {
    changes?.forEach(([row, prop, oldValue, newValue]) => {
      // Your logic to handle the change goes here
      console.log('Change detected at row:', row);
      console.log('Property:', prop);
      console.log('Old Value:', oldValue);
      console.log('New Value:', newValue);
      
      // You can add your custom logic here to process the change

      updateTotalSum();
    });
  }

  const handleAfterRemoveRow = (index, amount, logicalRows, source) => {
    // Your logic to handle row removal goes here
    console.log('Rows removed:', amount);
    console.log('Removed row indexes:', logicalRows);
    console.log('Removal source:', source);
    
    // You can add your custom logic here to process the row removal
  };

  function customNumericRenderer(hot, instance, td, row, col, prop, value, cellProperties) {
    hot.renderers.TextRenderer.apply(this, arguments);
    if (typeof value === 'number') {
      td.textContent = numberWithCommas(value);
    }
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const initializeForSearchColumn = () => {
    
    const debounceFn = (colIndex, event) => {
      const filtersPlugin = hotBank.getPlugin('filters');
      filtersPlugin.removeConditions(colIndex);
      filtersPlugin.addCondition(colIndex, 'contains', [event.target.value]);
      filtersPlugin.filter();
    };

    const addEventListeners = (input, colIndex) => {
      input.addEventListener('keydown', (event) => {
        debounceFn(colIndex, event);
        attachButtonPilihHandlers();
      });
    };

    const addInput = (col, TH) => {
      if (typeof col !== 'number') {
        return col;
      }

      if (col >= 0 && TH.childElementCount < 2) {
        TH.appendChild(getInitializedElements(col));
      }
    };

    const getInitializedElements = (colIndex) => {
      const div = document.createElement('div');
      const input = document.createElement('input');
      if (colIndex !== 4){
        addEventListeners(input, colIndex);
        div.appendChild(input);
      } 
      return div;
    };

    const doNotSelectColumn = (event, coords) => {
      if (coords.row === -1 && event.target.nodeName === 'INPUT') {
        event.stopImmediatePropagation();
        hotBank.deselectCell();
      }
    };

    hotBank.updateSettings({
      afterGetColHeader: addInput,
      beforeOnCellMouseDown: doNotSelectColumn,
    });
  }

  const columns = [
    { type: 'text', title: 'ID', align: 'center' },
    { type: 'text', title: 'Transaksi', align: 'center' },
    { type: 'text', title: 'No. Rekening', align: 'center' },
    { type: 'text', title: 'Nama PT', align: 'center' },
    { type: 'text', title: 'Tanggal Pengajuan' },
    {
      type: 'numeric',
      title: 'Nilai',
      align: 'right',
      numericFormat: {
        pattern: '0,0', // Use the pattern for thousands separators
        culture: 'id-ID',
      },
    },
    { type: 'text', title: 'Status' },
    //{ type: 'text', renderer: "html", editor: false, title: 'AKSI ', align: 'center' },
  ];

  const columnsbank = [
    { type: 'text', title: 'ID', editor: false, align: 'center' },
    { type: 'text', title: 'BANK', editor: false, align: 'left' },
    { type: 'text', title: 'NO REKENING', editor: false, align: 'left' },
    { type: 'text', title: 'NAMA PEMILIK', editor: false, align: 'left' },
    { type: 'text', renderer: "html", editor: false, title: 'AKSI ', align: 'left' },
  ];
  const columnsbankterpilih = [
    { type: 'text', title: 'ID', align: 'center' },
    { type: 'text', title: 'BANK', align: 'left' },
    { type: 'text', title: 'NO REKENING', align: 'left' },
    { type: 'text', title: 'NAMA PEMILIK', align: 'left' },
    {
      type: 'numeric',
      title: 'NOMINAL',
      align: 'right',
      numericFormat: {
        pattern: '0,0', // Use the pattern for thousands separators
        culture: 'id-ID',
      },
    },
    { type: 'text', renderer: "html", editor: false, title: 'AKSI ', align: 'left' },
  ];
  const colHeaders = columns.map(column => column.title);

  return (
    <div>
      <Header title="Pengajuan Biaya PIB" />
      <div>
        <HotTable
          data={data_all}
          ref={hotRef}
          contextMenu={true}
          rowHeaders={true}
          filters={true}
          outsideClickDeselects={false}
          allowInsertColumn={true}
          height={tableHeight}
          width={1465}
          autoRowSize={true}
          readOnly={true}
          manualColumnResize={true}
          dropdownMenu={['filter_by_condition', 'filter_by_value', 'filter_action_bar']}
          columns={columns}
          licenseKey="non-commercial-and-evaluation"
          hiddenColumns={{
            copyPasteEnabled: true,
            indicators: true,
            columns: [0]
          }}
        />
        <div id="view_duplicate" className="view-duplicate">
          <div className={`${styleHs.positionbankpembayaran}`}>
            <section className={`${styleHs.container2layout}`}>
              <div className={`${styleHs.leftcontainer2layout}`}>
                <article>
                  <div className={`${styleHs.titleduplicate}`}>
                    <h4 className={`${styleHs.title} ${styleHs.h4}`}>Daftar Bank</h4>
                  </div>
                  <HotTable
                    data={data}
                    ref={hotRefaddbank}
                    contextMenu={true}
                    rowHeaders={true}
                    filters={true}
                    outsideClickDeselects={false}
                    allowInsertColumn={true}
                    height={255}
                    width={1465}
                    autoRowSize={true}
                    manualColumnResize={true}
                    columns={columnsbank}
                    licenseKey="non-commercial-and-evaluation"
                    colWidths={[100,80,120,120,80]}
                    className={`${styleHs.margintable}`}
                    hiddenColumns={{
                      copyPasteEnabled: true,
                      indicators: true,
                      columns: [0]
                    }}
                  />
                </article>
              </div>
              <div className={`${styleHs.rightcontainer2layout}`}>
                <article>
                  <div className={`${styleHs.titleduplicate}`}>
                    <h4 className={`${styleHs.title} ${styleHs.h4}`}>Bank Terpilih</h4>
                    List daftar bank, input bank jika ada data baru dan pilih klik (+) untuk memilih bank
                  </div>
                  <HotTable
                    data={data_all}
                    ref={hotRefselectbank}
                    contextMenu={true}
                    rowHeaders={true}
                    filters={true}
                    settings={{
                      afterOnCellMouseDown: handleCellMouseDown,
                    }}
                    afterChange={handleAfterChange}
                    afterRemoveRow={handleAfterRemoveRow}
                    outsideClickDeselects={false}
                    allowInsertColumn={true}
                    height={220}
                    width={1465}
                    autoRowSize={true}
                    manualColumnResize={true}
                    dropdownMenu={['filter_by_condition', 'filter_by_value', 'filter_action_bar']}
                    columns={columnsbankterpilih}
                    licenseKey="non-commercial-and-evaluation"
                    className={`${styleHs.margintable}`}
                    hiddenColumns={{
                      copyPasteEnabled: true,
                      indicators: true,
                      columns: [0]
                    }}
                  />
                </article>
              </div>
            </section>
          </div>
        </div>

        <div className="positionfix">
          <div className={`${styleHs.flexbox100}`}>
            <div id="footer_data" style={{ margin: '5px', verticalAlign: 'text-bottom' }}>
              <span id="detailValue"></span>
            </div>
            <div id="footer_data_1" style={{ margin: '5px', verticalAlign: 'text-bottom' }}>
            <span id="total" className={`${styleHs.styleTotalTitle}`}>Total Tagihan : </span>
            <span id="total" className={`${styleHs.styleTotal}`} ref={totalSpanTagihan}></span>
            <span id="total" className={`${styleHs.styleTotalTitle}`}>Total Bayar : </span>
            <span id="total" className={`${styleHs.styleTotal}`} ref={totalSpanRef}></span>
              <button className={`${styleHs.button} ${styleHs.buttonproses}`} id="btn_save" onClick={handleSaveButtonClick}>
                Proses & Simpan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default PibDetail;