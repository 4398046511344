// modal.js
import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import '../css/Modal.css';
import axios from 'axios';
import { HotTable, HotColumn } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import styleHs from '../css/Hs.module.css';
import reloadToken from '../helper/reloadtoken';
import { apiConfig } from '../helper/apiConfig';

const Modal = ({ modalVisible, toggleModal, data }) => {
    const [tableHeight, setTableHeight] = useState(350);
    const hotRef = useRef(null);
    const isMounted = useRef(true);
    const [hotInstance, setHotInstance] = useState(null);
    const [token, setToken] = useState(null);
    const [newRowValue, setNewRowValue] = useState('');
    const [colHeaders, setColHeaders] = useState([]);
    let nama_pt = "ROLE";
    let tipe = "1";
    var loaddata = 0;
    const hostcont = process.env.REACT_APP_API_HOST_FCL;
    var data_all = [];

    const fetchData = async () => {
        try {
            const newToken = await reloadToken();
            setToken(newToken);
            if (hotRef.current && token && isMounted.current) {
                const res = await loadData(token, data.id);
                if (res && Array.isArray(res.data.data)) {
                    if (hotRef.current.hotInstance) {
                        hotRef.current.hotInstance.loadData(res.data.data);
                        //setColHeaders(res.data.column);
                    } else {
                        console.error("Hot instance is null or undefined");
                    }
                } else {
                    console.error("Invalid data format or data is missing:", res);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const isDataEmpty = !data || !data.nama;
    if (modalVisible) {
        document.body.classList.add('active-modal');
        fetchData();
    } else {
        document.body.classList.remove('active-modal');
    }

    const closeModal = () => {
        toggleModal();
    };

    const loadData = async (token, id) => {
        try {
            const config = apiConfig(token);
            const response = await axios.post(`${hostcont}Cekstatusfile`, null, config);
            return response;
        } catch (error) {
            console.error('Error fetching column names:', error);
            return [];
        }
    };

    const handleClick = (data) => {
        if (data == "role") {
            window.location.href = "/role";
        } else if (data == "role_detail") {
            window.location.href = "/roledetail";
        } else if (data == "masteruser") {
            window.location.href = "/masteruser";
        }
    };


    const columns = [
        { type: 'text', title: 'ID', align: 'center' },
        { type: 'text', title: '<b>Kontainer</b>', editor: false, align: 'center' },
        { type: 'text', title: '<b>Nama File</b>', editor: false, align: 'center' },        
        { type: 'text', title: '<b>Catatan</b>', editor: false, className: 'htCenter'},
        { type: 'text', title: '<b>name column</b>', className: 'htCenter'},
        { type: 'text', title: '<b>tipe column</b>', className: 'htCenter'},
        { type: 'text', title: '<b>Download</b>', editor: false, className: 'htCenter', renderer: 'html' },
    ];

    const customRenderer = (instance, td, row, col, prop, value, cellProperties) => {
        if (typeof value === 'string') {
            td.innerHTML = value;
        }
    };

    const customHeader = (value, visualColumnIndex, headerLevel) => {
        // Customize the column header content based on your logic
        return `Column ${visualColumnIndex + 1}`;
    };



    return createPortal(
        modalVisible ? (
            <div className="modal">
                <div onClick={closeModal} className="overlay"></div>
                <div className="modal-content-detail">
                    {isDataEmpty ? (<p>Error, data is empty</p>) : (<div className="welcome-container">
                        <img src="/images/bell.gif" alt="Welcome" className="welcome-image" width="50" height="30" />
                        <div className="welcome-text">
                            <h6>Update File!</h6>
                            <p>Silahkan proses file terbaru dengan klik tombol download pada kolom</p>
                        </div>
                    </div>)}
                    <div>
                        <HotTable
                            ref={hotRef}
                            columns={columns}
                            data={data_all}
                            renderer={customRenderer}
                            contextMenu={true}
                            rowHeaders={true}
                            filters={true}
                            outsideClickDeselects={false}
                            allowInsertColumn={true}
                            rowHeights={30}
                            autoRowSize={false}
                            manualColumnResize={true}
                            fixedColumnsStart={5}
                            //height={tableHeight}
                            height={420}
                            width={705}
                            dropdownMenu={false}
                            colHeaders={colHeaders}
                           //colWidths={[100, 180, 210, 75, 70, 95, 95]}
                            licenseKey="non-commercial-and-evaluation"
                            hiddenColumns={{
                                copyPasteEnabled: true,
                                indicators: true,
                                columns: [0,4,5]
                            }}
                        >
                            {colHeaders.map((colName, index) => (
                                <HotColumn key={index} renderer={"html"} editor={false} />
                            ))}
                        </HotTable>
                    </div>
                    <p>
                        {/* Your modal content */}
                    </p>
                    <button className="btn_close-modal btn_close" onClick={closeModal}>
                        Close
                    </button>
                </div>
            </div>
        ) : null,
        document.body
    );
};

export default Modal;