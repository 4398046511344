import React from 'react';
import OrgChart from 'react-orgchart';
import 'react-orgchart/index.css';
import '../css/OrgChartStyles.css';

const datascource = {
  name: 'Lao Lao',
  title: 'general manager',
  children: [
    {
      name: 'Bo Miao',
      title: 'department manager',
      className: 'middle-level',
      children: [
        { name: 'Li Jing', title: 'senior engineer', className: 'product-dept' },
        {
          name: 'Li Xin',
          title: 'senior engineer',
          className: 'product-dept',
          children: [
            { name: 'To To', title: 'engineer', className: 'pipeline1' },
            { name: 'Fei Fei', title: 'engineer', className: 'pipeline1' },
            { name: 'Xuan Xuan', title: 'engineer', className: 'pipeline1' }
          ]
        }
      ]
    },
    {
      name: 'Su Miao',
      title: 'department manager',
      className: 'middle-level',
      children: [
        { name: 'Pang Pang', title: 'senior engineer', className: 'rd-dept' },
        {
          name: 'Hei Hei',
          title: 'senior engineer',
          className: 'rd-dept',
          children: [
            { name: 'Xiang Xiang', title: 'UE engineer', className: 'frontend1' },
            { name: 'Dan Dan', title: 'engineer', className: 'frontend1' },
            { name: 'Zai Zai', title: 'engineer', className: 'frontend1' }
          ]
        }
      ]
    }
  ]
};

const OrgChartNode = ({ node }) => (
  <div className={`orgchart-node ${node.className}`}>
    <div className="title">{node.name}</div>
    <div className="content">{node.title}</div>
  </div>
);

const OrgChartComponent = () => (
  <div id="chart-container">
    <OrgChart
      tree={datascource}
      NodeComponent={OrgChartNode}
    />
  </div>
);

export default OrgChartComponent;
