// decrypte.js
import CryptoJS from 'crypto-js';

const secretKey = 'fki1234'; // Replace with your secret key

const encrypt = (data) => {
  const ciphertext = CryptoJS.AES.encrypt(data, secretKey).toString();
  return ciphertext;
};

const decrypt = (ciphertext) => {
  if (!ciphertext) {
    return null; // or handle it according to your application logic
  }

  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);

  // Check if decryption was successful
  if (bytes.sigBytes === 0) {
    return null; // or handle it according to your application logic
  }

  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

export { encrypt, decrypt };