import { useEffect, useRef, useState } from "react";

const CustomMultiSelect = ({
  options,
  preSelectedItems,
  onSelectionChange,
  placeholder,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleSelect = (item) => {
    const newSelectedItems = selectedItems.includes(item)
      ? selectedItems.filter((i) => i !== item)
      : [...selectedItems, item];

    setSelectedItems(newSelectedItems);
    onSelectionChange(newSelectedItems);
  };

  const handleDelete = (item) => {
    const newSelectedItems = selectedItems.filter((i) => i !== item);
    setSelectedItems(newSelectedItems);
    onSelectionChange(newSelectedItems);
  };

  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (preSelectedItems) {
      setSelectedItems(preSelectedItems);
    }
  }, [preSelectedItems]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="multi-select" ref={dropdownRef}>
      <div className="input" onClick={toggleDropdown}>
        {selectedItems.length === 0 ? (
          <span className="placeholder">{placeholder}</span>
        ) : (
          selectedItems.map((item) => (
            <span key={item.value} className="selected-item">
              {item.name}
              <button onClick={() => handleDelete(item)}>x</button>
            </span>
          ))
        )}
      </div>

      {isDropdownOpen && (
        <div className="dropdown">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
          <div className="dropdown-list">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <div
                  key={option.value}
                  className={`dropdown-item ${
                    selectedItems.includes(option) ? "selected" : ""
                  }`}
                  onClick={() => handleSelect(option)}
                >
                  {option.name}
                </div>
              ))
            ) : (
              <div className="no-results">No results found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomMultiSelect;
