// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lm-row .lm-col {
    box-sizing: border-box;
    min-height: 1px;
    padding: 0 0.75rem;
    min-width: 300px;
    align-items: center;
    
}
.lm-input-field {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.lm-row .lm-col .lm-s12 {
    right: auto;
    left: auto;
    width: 100%;
    min-width:200px;
}


.lm-row .lm-col.lm-m6 {
    right: auto;
    left: auto;
    width: 50%;
    min-width: 300px;
    margin-left: auto;
}

.lm-row .lm-col.lm-s6 {
    right: auto;
    left: auto;
    width: 50%;
    min-width: 300px;
    margin-left: auto;
}

.lm-margin {
    margin: 0 !important;
}

.lm-input-field {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.lm-input-field .prefix {
    font-size: 2rem;
    position: absolute;
    top: 0.5rem !important;
    width: 7rem;
    transition: color .2s;
}`, "",{"version":3,"sources":["webpack://./src/css/LoginMaster.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;;AAEvB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,UAAU;IACV,WAAW;IACX,eAAe;AACnB;;;AAGA;IACI,WAAW;IACX,UAAU;IACV,UAAU;IACV,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,UAAU;IACV,UAAU;IACV,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,sBAAsB;IACtB,WAAW;IACX,qBAAqB;AACzB","sourcesContent":[".lm-row .lm-col {\n    box-sizing: border-box;\n    min-height: 1px;\n    padding: 0 0.75rem;\n    min-width: 300px;\n    align-items: center;\n    \n}\n.lm-input-field {\n    position: relative;\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n}\n\n.lm-row .lm-col .lm-s12 {\n    right: auto;\n    left: auto;\n    width: 100%;\n    min-width:200px;\n}\n\n\n.lm-row .lm-col.lm-m6 {\n    right: auto;\n    left: auto;\n    width: 50%;\n    min-width: 300px;\n    margin-left: auto;\n}\n\n.lm-row .lm-col.lm-s6 {\n    right: auto;\n    left: auto;\n    width: 50%;\n    min-width: 300px;\n    margin-left: auto;\n}\n\n.lm-margin {\n    margin: 0 !important;\n}\n\n.lm-input-field {\n    position: relative;\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n}\n\n.lm-input-field .prefix {\n    font-size: 2rem;\n    position: absolute;\n    top: 0.5rem !important;\n    width: 7rem;\n    transition: color .2s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
