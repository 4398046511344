export const column_brg = [
  { type: "text", title: "ID", align: "center", readOnly: "true" },
  { type: "text", title: "Aju", align: "center", readOnly: "true" },
  { type: "text", title: "Seri Barang", align: "center", readOnly: "true" },
  { type: "text", title: "No Container", align: "center", readOnly: "true" },
  { type: "text", title: "HS Code", align: "center", readOnly: "true" },
  { type: "text", title: "Kode barang", align: "center", readOnly: "true" },
  { type: "text", title: "Uraian", align: "center", readOnly: "true" },
  { type: "text", title: "Merek", align: "center", readOnly: "true" },
  { type: "text", title: "Tipe", align: "center", readOnly: "true" },
  { type: "text", title: "Ukuran", align: "center", readOnly: "true" },
  {
    type: "text",
    title: "SPESIFIKASI LAIN",
    align: "center",
    readOnly: "true",
  },
  { type: "text", title: "Kode Satuan", align: "center", readOnly: "true" },
  { type: "text", title: "Jumlah Satuan", align: "center", readOnly: "true" },
  { type: "text", title: "Kode Kemasan", align: "center", readOnly: "true" },
  { type: "text", title: "Jumlah Kemasan", align: "center", readOnly: "true" },
  { type: "text", title: "Netto", align: "center", readOnly: "true" },
  { type: "text", title: "CIF", align: "center", readOnly: "true" },
  { type: "text", title: "Cif Rupiah", align: "center", readOnly: "true" },
  { type: "text", title: "Ndpbm", align: "center", readOnly: "true" },
  { type: "text", title: "FOB", align: "center", readOnly: "true" },
  { type: "text", title: "Asuransi", align: "center", readOnly: "true" },
  { type: "text", title: "Freight", align: "center", readOnly: "true" },
  { type: "text", title: "Nilai tambah", align: "center", readOnly: "true" },
  { type: "text", title: "Diskon", align: "center", readOnly: "true" },
  {
    type: "text",
    title: "HARGA PENYERAHAN",
    align: "center",
    readOnly: "false",
  },
  {
    type: "text",
    title: "HARGA PEROLEHAN",
    align: "center",
    readOnly: "false",
  },
  { type: "text", title: "Harga Satuan", align: "center", readOnly: "true" },
  { type: "text", title: "HARGA EKSPOR", align: "center", readOnly: "false" },
  { type: "text", title: "NILAI BARANG", align: "center", readOnly: "false" },
  { type: "text", title: "NILAI JASA", align: "center", readOnly: "false" },
  {
    type: "text",
    title: "KODE JENIS NILAI",
    align: "center",
    readOnly: "false",
  },
  {
    type: "text",
    title: "KODE KONDISI BARANG",
    align: "center",
    readOnly: "false",
  },
  {
    type: "text",
    title: "KODE NEGARA ASAL",
    align: "center",
    readOnly: "false",
  },
  {
    type: "text",
    title: "Pernyataan Lartas",
    align: "center",
    readOnly: "true",
  },
  {
    type: "text",
    title: "ISI PER KEMASAN",
    align: "center",
    readOnly: "false",
  },
];

export const column_barang = [
  {
    type: "text",
    title: "Seri Barang",
    align: "center",
    readOnly: true,
  },
  {
    type: "text",
    title: "HS Code",
    align: "center",
    readOnly: true,
  },
  {
    type: "text",
    title: "Uraian",
    align: "center",
    readOnly: true,
  },
  {
    type: "text",
    title: "Kode Negara",
    align: "center",
    readOnly: true,
  },
  {
    type: "text",
    title: "Kode Satuan",
    align: "center",
    readOnly: true,
  },
  {
    type: "text",
    title: "Jumlah Satuan",
    align: "center",
    readOnly: true,
  },
  {
    type: "text",
    title: "Kode Kemasan",
    align: "center",
    readOnly: true,
  },
  {
    type: "text",
    title: "Netto",
    align: "center",
    readOnly: true,
  },
  {
    type: "text",
    title: "CIF",
    align: "center",
    readOnly: true,
  },
  {
    type: "text",
    title: "FOB",
    align: "center",
    readOnly: true,
  },
  {
    type: "text",
    title: "Harga Satuan",
    align: "center",
    readOnly: true,
  },
  {
    type: "text",
    title: "Pernyataan Lartas",
    align: "center",
    readOnly: true,
  },
  {
    type: "text",
    title: "BM",
    align: "center",
    readOnly: true,
  },
  {
    type: "text",
    title: "PPN",
    align: "center",
    readOnly: true,
  },
  {
    type: "text",
    title: "PPH",
    align: "center",
    readOnly: true,
  },
];

export // backup
// export const column_brg = [
//   { type: "text", title: "ID", align: "center", readOnly: "true" },
//   { type: "text", title: "Aju", align: "center", readOnly: "true" },
//   { type: "text", title: "HS Code", align: "center", readOnly: "true" },
//   { type: "text", title: "Seri Barang", align: "center", readOnly: "true" },
//   { type: "text", title: "Jenis Dokumen", align: "center", readOnly: "false" },
//   { type: "text", title: "Asuransi", align: "center", readOnly: "true" },
//   { type: "text", title: "Bruto", align: "center", readOnly: "true" },
//   { type: "text", title: "Netto", align: "center", readOnly: "true" },
//   { type: "text", title: "CIF", align: "center", readOnly: "true" },
//   { type: "text", title: "Cif Rupiah", align: "center", readOnly: "true" },
//   { type: "text", title: "Diskon", align: "center", readOnly: "true" },
//   { type: "text", title: "FOB", align: "center", readOnly: "true" },
//   { type: "text", title: "Freight", align: "center", readOnly: "true" },
//   { type: "text", title: "Harga Satuan", align: "center", readOnly: "true" },
//   { type: "text", title: "Jumlah Satuan", align: "center", readOnly: "true" },
//   { type: "text", title: "Jumlah Kemasan", align: "center", readOnly: "true" },
//   { type: "text", title: "Kode Kemasan", align: "center", readOnly: "true" },
//   { type: "text", title: "Kode Satuan", align: "center", readOnly: "true" },
//   { type: "text", title: "Ndpbm", align: "center", readOnly: "true" },
//   {
//     type: "dropdown",
//     title: "Pernyataan Lartas",
//     align: "center",
//     source: ["Y","T"]},
//   {
//     type: "text",
//     title: "Presentase Import",
//     align: "center",
//     readOnly: "true",
//   },
//   { type: "text", title: "Merek", align: "center", readOnly: "true" },
//   { type: "text", title: "Pos Tarif", align: "center", readOnly: "true" },
//   { type: "text", title: "Saldo Awal", align: "center", readOnly: "true" },
//   { type: "text", title: "Saldo Akhir", align: "center", readOnly: "true" },
//   { type: "text", title: "Seri Izin", align: "center", readOnly: "true" },
//   { type: "text", title: "Tipe", align: "center", readOnly: "true" },
//   { type: "text", title: "Uraian", align: "center", readOnly: "true" },
//   { type: "text", title: "Volume", align: "center", readOnly: "true" },
// ];

const today = new Date();
const formattedDate = today.toISOString().split("T")[0];
export function title_doc(dropdownDocument) {
  const columns = [
    { type: "text", title: "No Container", align: "center", readOnly: "true" },
    {
      type: "dropdown",
      title: "Jenis Dokumen",
      align: "center",
      source: dropdownDocument,
    },
    { type: "text", title: "Nomor Dokumen", align: "center" },
    {
      type: "date",
      title: "Tanggal",
      dateFormat: "MM/DD/YYYY",
      correctFormat: true,
      defaultDate: formattedDate,
    },
  ];
  return columns;
}

export function title_kemasan(dropdownKemasan) {
  const columnKemasan = [
    { type: "text", title: "ID", align: "center" },
    { type: "text", title: "Aju", align: "center", readOnly: "true" },
    { type: "text", title: "Seri", align: "center", readOnly: "true" },
    { type: "text", title: "Nomor", align: "center" },
    { type: "text", title: "Jumlah", align: "center" },
    {
      type: "dropdown",
      title: "Jenis",
      align: "center",
      source: dropdownKemasan,
    },
    { type: "text", title: "Merek", align: "center" },
    { type: "text", title: "Tipe_data", align: "center" },
  ];
  return columnKemasan;
}

export function title_kem_peti(dropdownPetiKemas) {
  const columnPeti = [
    { type: "text", title: "ID", align: "center" },
    { type: "text", title: "Aju", align: "center", readOnly: "true" },
    { type: "text", title: "Seri", align: "center", readOnly: "true" },
    { type: "text", title: "Nomor", align: "center" },
    {
      type: "dropdown",
      title: "Ukuran",
      align: "center",
      source: ["20", "40", "50"],
    },
    {
      type: "dropdown",
      title: "Jenis",
      align: "center",
      source: dropdownPetiKemas,
    },
    { type: "text", title: "Tipe", align: "center" },
    { type: "text", title: "Tipe_data", align: "center" },
  ];

  return columnPeti;
}
