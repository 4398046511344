// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* OrgChartStyles.css */
.orgchart-node{

  width: 70%;
  margin:0px auto;
  border-radius: 10px;
}
  .orgchart-node.middle-level {
    background-color: #ffd700;
  }
  
  .orgchart-node.product-dept {
    background-color: #87ceeb;
  }
  
  .orgchart-node.pipeline1 {
    background-color: #32cd32;
  }
  
  .orgchart-node.rd-dept {
    background-color: #ff7f50;
  }
  
  .orgchart-node.frontend1 {
    background-color: #dda0dd;
  }
  `, "",{"version":3,"sources":["webpack://./src/css/OrgChartStyles.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;;EAEE,UAAU;EACV,eAAe;EACf,mBAAmB;AACrB;EACE;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* OrgChartStyles.css */\n.orgchart-node{\n\n  width: 70%;\n  margin:0px auto;\n  border-radius: 10px;\n}\n  .orgchart-node.middle-level {\n    background-color: #ffd700;\n  }\n  \n  .orgchart-node.product-dept {\n    background-color: #87ceeb;\n  }\n  \n  .orgchart-node.pipeline1 {\n    background-color: #32cd32;\n  }\n  \n  .orgchart-node.rd-dept {\n    background-color: #ff7f50;\n  }\n  \n  .orgchart-node.frontend1 {\n    background-color: #dda0dd;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
